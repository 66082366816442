import { Capacitor } from '@capacitor/core';
import {
  CardActions, CardHeader, Dialog,
  DialogContent, Grid, makeStyles,
  Button as MuiButton,
  Typography, useMediaQuery
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { userApi } from 'api';
import usePageTitle from 'common/hooks';
import { tooltipHoc } from 'common/ui';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IRootState, PASDispatch } from 'reducers';
import ChangePasswordDialog from 'views/UserDetail/components/ChangePasswordDialog';


const Button = tooltipHoc(MuiButton);

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    background: '#fff',
    color: '#000',
  },
  dialogContent: {
    width: 500,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    flexGrow: 1,
  },
  brand: {
    color: '#183B68',
    display: 'flex', flexDirection: 'row', alignItems: 'center',
    lineHeight: '40px',
    fontWeight: 'bold',
  },
}));


const resetPwLang: { [key: string]: string } = {
  // msgPleaseWait: '請等候... \n Please wait...',
  // msgChangePwSuccess: '成功修改密碼 Password changed successfully',
  // msgProcessing: '正在修改密碼, 請勿退出當前頁面 \n Changing password, please do not leave current page...',
  // msgLinkExpired: '連結已失效 This link has expired',
  pas: '物業代理系統 Property Management System',
}

const ForceChangePasswordPage = () => {
  const isSmallScreen = useMediaQuery('(max-width: 400px)');

  const { lang, langUser } = useSelector((state: IRootState) => state.locale);

  const { langUser: langUserZh, lang: langZh } = useSelector((state: IRootState) => state.locale._bundle['zh_HK']);
  const { langUser: langUserEn, lang: langEn } = useSelector((state: IRootState) => state.locale._bundle['en']);
  
  const dispatch = useDispatch() as PASDispatch;
  const history = useHistory();

  const [status, setStatus] = useState<'success' | 'pendingForUserInput'>('pendingForUserInput');
  const { token } = useSelector((state: IRootState) => state.login);
  const classes = useStyles();

  // const [indexPageMsg , setIndexPageMsg ] = useState(langSignDialog.msgmsgmsgPleaseWait);

  const successMsg = langUser.msgChangePwSuccess;

  const { iosStatusBarHeight } = useSelector((state: IRootState) => state.layout);

  usePageTitle(langUser.titleChangePassword);

  const successPanel = () =>
    <div>
      <CardHeader
        // subheader={langSignDialog.msgGenerated}
        title={successMsg}
      />
      <CardActions>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => {
            history.replace('/sign-in');
            dispatch({ type: 'Login.LogoutRequested' });
          }}
        >
          {lang.msgSignInAgain}
        </Button>
      </CardActions>
    </div>;

  const [] = useState(false);

  const { PASSWORD_HISTORY } = useSelector((state: IRootState) =>
    state.systemSettings?.System?.PASSWORD) ?? {};
  const [incorrect, setIncorrect] = useState(false);
  const pendingForUserInputPanel = () => (
    <div className={classes.root}>
      <Typography style={{ marginBottom: 5, marginLeft: 5 }}>{langZh.msgPasswordExpired + ' ' + langEn.msgPasswordExpired}</Typography>
      {Capacitor.getPlatform() === "android" ? <Typography
        color={"secondary"}
        variant="subtitle1"
      >
        {langUser.msgThirdPartyKeyboard}
      </Typography> : null}
      <Grid container md={6} xs={12} spacing={1}>
        <ChangePasswordDialog
          open={true}
          onClose={() => { return; }}
          isForceChangePasswordPage={true}
          onChangePassword={({ oldPassword, newPassword }) => {
            setIncorrect(true);
            dispatch({ type: 'Layout.MaskPresentRequested' });
            userApi.changePassword(oldPassword, newPassword, token ?? '').then(({ error }) => {
              if (error) {
                let errorMsg = '';
                switch (error) {
                  case 'ERR_PASSWORD_MISMATCHED': errorMsg = langUser.msgPasswordMismatched; break;
                  case 'ERR_PW_FAIL_COMPLEXITY_REQUIREMENT': errorMsg = langUser.msgPasswordFailComplexityRequirement; break;
                  case 'ERR_PREVIOUS_PW_USED': errorMsg = langUser.msgPreviousPasswordUsed; break;
                  default: errorMsg = lang.msgNetworkError;
                }
                dispatch({
                  type: 'Layout.AlertMessageAdded',
                  payload: {
                    message: errorMsg,
                  },
                });
                setIncorrect(false);
              } else {

                setStatus('success');
              }
              dispatch({ type: 'Layout.MaskDismissRequested' });
            });
          }}
          incorrectPassword={incorrect}
        />
      </Grid>
    </div>
  );



  return (
    <Dialog
      keepMounted
      open={true}
      fullScreen
      // onClose={handleClose}
      aria-labelledby="reset-password-dialog"
      aria-describedby="reset-password-form"
    >
      <AppBar position="relative" style={{ background: '#fff', color: '#000', paddingTop: iosStatusBarHeight }}>
        <Toolbar>
          <Typography className={classes.brand}>
          {isSmallScreen ? 
          <img src="images/logo-toolbar-1920.png" style={{ height: 40, marginRight: 16 }} /> :
          <img src="images/logo-toolbar-1920.png" style={{ height: 60, marginRight: 16 }} /> 
          } 
          </Typography>
          <Typography style={{ flexGrow: 1, }}>
            {resetPwLang.pas}
          </Typography>
          <Button
            color="inherit"
            onClick={() => {
              history.replace('/sign-in');
              dispatch({ type: 'Login.LogoutRequested' });
            }}
          >
            {lang.actionLogout}
          </Button>
        </Toolbar>

      </AppBar>

      <DialogContent>
        {/* {{
          signing: signaturePanel,
          generating: loadingPanel,
          success: successPanel,
          pendingContactVerification: pendingVerifiactionPanel,
          verifyingContact: verifyingPanel,
          index: indexPanel
        }[status]()} */}
        {status === 'success' ? successPanel() : null}
        {status === 'pendingForUserInput' ? pendingForUserInputPanel() : null}
      </DialogContent>
    </Dialog>
  );
};

export default ForceChangePasswordPage;
