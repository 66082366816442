import { propertyApi } from 'api';
import { hasPermission } from 'common/access-control';
import { CheckDuplicatedPropertyStockResultDTO, ClientFileMap, MemoDTO, PropertyFileMap, PropertyMultiValueMap, PropertyStockDTO } from "common/dto";
import NavigationBlockerDialog from 'common/elements/NavigationBlockerDialog';
import usePageTitle, { useForm } from "common/hooks";
import { getPropertyStockSpecialFieldsDTO, isNonEmpty, priceFromView, priceToView } from 'common/utils';
import deepEqual from 'deep-equal';
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router";
import { IRootState, PASDispatch } from "reducers";
import WarningDialogForCreatingExisingPropertyStock from './components/WarningDialogForCreatingExisingPropertyStock';
import propertyFormValidations from "./property-validations";
import PropertyDetail from "./PropertyDetail";



const EMPTY = {};

export default function PropertyUpdatePage() {
  const dispatch = useDispatch() as PASDispatch;
  // const [ isNew, setIsNew ] = useState(true);
  // const pid = 'PS10000';
  const { propertyNo }: any = useParams();
  const propertyStockDto = useSelector((state: IRootState) => state.property.fetchedDetails[propertyNo!]) ?? EMPTY as Partial<PropertyStockDTO>;
  const { locale, langPropertyStock, lang } = useSelector((state: IRootState) => state.locale);
  const { privileges, token } = useSelector((state: IRootState) => state.login);
  const memos = useSelector((state: IRootState) => state.property.memos ?? [] as MemoDTO[]);
  const propertyStockOpen = propertyStockDto.status !== 'SOLD' && propertyStockDto.status !== 'LEASED';
  // Permissions
  // const canRead = propertyStockDto.companyStock ? 
  //   (propertyStockDto.canReadCompanyStock && hasPermission(privileges, 'READ', 'COMPANY_STOCK'))
  //    :
  //   (propertyStockDto.isLeadAgent && hasPermission(privileges, 'READ', 'OWN_PROPERTY_STOCK')) ||
  //   (propertyStockDto.isTeamHead && hasPermission(privileges, 'READ', 'TEAM_PROPERTY_STOCK')) ||
  //   (
  //     hasPermission(privileges, 'READ', 'UNOWNED_PROPERTY_STOCK')
  //   )
  //   ||
  //   (propertyStockDto.canReadCompanyStock && hasPermission(privileges, 'READ', 'COMPANY_STOCK'))
  // ;
  const canUpdateOwn = propertyStockDto.isLeadAgent && hasPermission(privileges, 'UPDATE', 'OWN_PROPERTY_STOCK');
  const canUpdate = propertyStockDto.companyStock ?
    (hasPermission(privileges, 'UPDATE', 'COMPANY_STOCK') && propertyStockOpen)
    : (
      canUpdateOwn ||
      (propertyStockDto.isTeamHead && hasPermission(privileges, 'UPDATE', 'TEAM_PROPERTY_STOCK')) ||
      (!propertyStockDto.isTeamHead &&
        !propertyStockDto.isLeadAgent &&
        hasPermission(privileges, 'UPDATE', 'UNOWNED_PROPERTY_STOCK')
      )
    )
    && propertyStockOpen;
  const hideFloor = !hasPermission(privileges, 'READ', 'UNOWNED_PROPERTY_STOCK') &&
    !canUpdateOwn;

  const hideStreet = propertyStockDto.usage === 'V' && !hasPermission(privileges, 'READ', 'UNOWNED_PROPERTY_STOCK') && !canUpdateOwn;
  const hideLot = propertyStockDto.usage === 'V' && !hasPermission(privileges, 'READ', 'UNOWNED_PROPERTY_STOCK') && !canUpdateOwn;

  // 
  usePageTitle(langPropertyStock.titlePropertyDetail);
  
  // Form
  const stockForm = useForm<PropertyStockDTO>({
    ...propertyStockDto,
    // Price unit transformation
    price: propertyStockDto.price ? priceToView(propertyStockDto.price, locale) : 0,
    bottomPrice: propertyStockDto.bottomPrice ? priceToView(propertyStockDto.bottomPrice, locale) : 0,
  }, {
    validations: propertyFormValidations(langPropertyStock, lang, false),
    getDisabledProps: () => ({ disabled: true }),
    allDisabled: !canUpdate,
  });
  //console.log("stockForm", stockForm);

  const previousLocale = useRef(locale);
  useLayoutEffect(() => {
    if (stockForm.values.price) {
      stockForm.updateValues('price', priceToView(
        priceFromView(stockForm.values.price, previousLocale.current),
        locale,
      ));
    }

    if (stockForm.values.bottomPrice) {
      stockForm.updateValues('bottomPrice', priceToView(
        priceFromView(stockForm.values.bottomPrice, previousLocale.current),
        locale,
      ));
    }
    previousLocale.current = locale;
  }, [locale]);

  const tagForm = useForm<PropertyMultiValueMap>({
    VIEW: [],
    OTHER_FEATURES: [],
    SYMBOL: [],
    LIVING_ROOM: [],
    CLUB_HOUSE_FACILITIES: [],
    OTHERS: [],
    BALCONY_SIZES: [],
  } as PropertyMultiValueMap, {
    getDisabledProps: () => ({ disabled: true }),
    allDisabled: !canUpdate,
  });

  const fileForm = useForm<PropertyFileMap>({
    PHOTO: [],
    FLOOR_PLAN: [],
    LAND_SEARCH: [],
    SA_SEARCH: [],
    OP: [],
    OTHERS: [],
    COMPANY_SEARCH: [],
    FORM_1: [],
    FORM_2: [],
    FORM_3: [],
    FORM_5: [],
    PROVISIONAL_AGREEMENT: [],
    SUPPLEMENTAL_DOCUMENT: [],
    CLOSE_TRNX_SUPP: []
  } as PropertyFileMap, {
    allDisabled: !canUpdate,
  });


  const clientFileForm = useForm<ClientFileMap>({
    FORM_4: [],
    FORM_6: [],
  } as ClientFileMap);

  // useEffect(() => {
  //   window.SCBeacon.trackGoal("Browse Property", { data: "propertyStockId", dataKey: id });
  // }, [ id ]);

  // Request API again when the id param changed
  useEffect(() => {
    dispatch({ type: 'Property.FetchByPropertyNoRequested', payload: { propertyNo: propertyNo ?? '' } });
  }, [propertyNo]);

  // Refresh form object when propertyStockDto changed
  useEffect(() => {


    const stockFormValues = {
      ...propertyStockDto,
      // Price unit transformation
      price: propertyStockDto.price ? priceToView(propertyStockDto.price, locale) : undefined,
      bottomPrice: propertyStockDto.bottomPrice ? priceToView(propertyStockDto.bottomPrice, locale) : undefined,
    };
    stockForm.setValues(stockFormValues);

    // Transform features to view model
    let featureMap: { [type: string]: string[] } = {};
    propertyStockDto.features?.filter(f => isNonEmpty(f.value))?.forEach(f => {
      if (!featureMap[f.type]) {
        featureMap[f.type] = [];
      }

      featureMap[f.type].push(f.value);
    });

    const tagFormValues = {
      ...tagForm.values,
      ...featureMap,
    };

    tagForm.setValues(tagFormValues);

    // Transform property attachment to view model
    const fileMap: PropertyFileMap = {
      PHOTO: [],
      FLOOR_PLAN: [],
      LAND_SEARCH: [],
      SA_SEARCH: [],
      OP: [],
      COMPANY_SEARCH: [],
      OTHERS: [],
      FORM_1: [],
      FORM_2: [],
      FORM_3: [],
      FORM_5: [],
      PROVISIONAL_AGREEMENT: [],
      SUPPLEMENTAL_DOCUMENT: [],
      CLOSE_TRNX_SUPP: []
    };
    propertyStockDto.attachments?.forEach(attachment => {
      const type = attachment.type as keyof PropertyFileMap;
      if (fileMap[type]) {
        fileMap[type].push(attachment);
      }
    });
    fileForm.setValues(fileMap);

    setInitialFormValues([stockFormValues, tagFormValues, fileMap]);

    //form4 and form6 ready only
    const clientFileMap: ClientFileMap = {
      FORM_4: [],
      FORM_6: [],
    };

    propertyStockDto.form4Attachments?.forEach(attachment => {
      const type = 'FORM_4';
      if (clientFileMap[type]) {
        clientFileMap[type].push(attachment);
      }
    });

    propertyStockDto.form6Attachments?.forEach(attachment => {
      const type = 'FORM_6';
      if (clientFileMap[type]) {
        clientFileMap[type].push(attachment);
      }
    });
    clientFileForm.setValues(clientFileMap);

  }, [propertyStockDto]);

  // if (!stockForm.values.id) {
  //   return <div style={{ height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  //     <PropertyDetailIcon color="disabled" style={{ fontSize: 40 }} />
  //   </div>;
  // }

  const assembleFormToDto = () => {
    const updatedResult = {
      ...stockForm.values,
      shouldResetApprovalStage: shouldUpdateApprovalStage,
      // status: stockForm.values.price && stockForm.values.rent ? 'BOTH' :
      //   stockForm.values.price ? 'SALES' :
      //   stockForm.values.rent ? 'RENT' : 'PENDING',
      // Price unit transformation
      price: stockForm.values.price ? priceFromView(stockForm.values.price, locale) : 0,
      bottomPrice: stockForm.values.bottomPrice ? priceFromView(stockForm.values.bottomPrice, locale) : 0,
      features: Object.keys(tagForm.values)
        .map(type => tagForm.values[type as keyof PropertyMultiValueMap]
          ?.map(value => ({ type, value, id: propertyStockDto.features?.filter(f0 => f0.type === type && f0.value === value)[0]?.id }))
        )
        .reduce((a, b) => (a ?? []).concat(b ?? []), []),
      attachments: Object.keys(fileForm.values)
        .map(type => fileForm.values[type as keyof PropertyFileMap] ?? [])
        .reduce((a, b) => (a ?? []).concat(b ?? []), []),
      hasGarden: isNonEmpty(stockForm.values.gardenArea),
      hasRooftop: isNonEmpty(stockForm.values.rooftopArea),
      balcony: stockForm.values.balconySizes?.filter(v => (isNonEmpty(v) && parseFloat(v) != 0))?.length ?? 0,
      balconySizes: stockForm.values.balconySizes?.filter(v => (isNonEmpty(v) && parseFloat(v) != 0)),
    } as Record<string, any>;
    Object.keys(updatedResult).forEach(key => {
      if (typeof updatedResult[key as keyof PropertyStockDTO] === "string" && updatedResult[key as keyof PropertyStockDTO].trim() === "") {
        updatedResult[key as keyof PropertyStockDTO] = null;
      }
    });
    return (updatedResult as PropertyStockDTO);
  };

  // Unsaved Check
  const [initialFormValues, setInitialFormValues] = useState<any>({});

  const unsaved = !canUpdate ? false : !deepEqual([stockForm.values, tagForm.values, fileForm.values], initialFormValues, { strict: true });
  // 
  const [shouldUpdateApprovalStage, setShouldUpdateApprovalStage] = useState(false);

  useEffect(() => {
    // 
    // 
    // Object.keys(stockForm.values).forEach((key) => {
    //   
    //   if (stockForm.values?.[key as keyof Partial<PropertyStockDTO>] !== initialFormValues['0']?.[key]) {
    //     
    //   }
    // });
    // 
    //   [ 
    //     fileForm.values?.CLOSE_TRNX_SUPP, 
    //     fileForm.values?.COMPANY_SEARCH, 
    //     fileForm.values?.FLOOR_PLAN,
    //     fileForm.values?.LAND_SEARCH, 
    //     fileForm.values?.OP, 
    //     fileForm.values?.OTHERS,
    //     fileForm.values?.PHOTO,
    //     fileForm.values?.SA_SEARCH,
    //     fileForm.values?.SUPPLEMENTAL_DOCUMENT 
    //   ],
    //   [
    //     initialFormValues['2']?.CLOSE_TRNX_SUPP,
    //     initialFormValues['2']?.COMPANY_SEARCH,
    //     initialFormValues['2']?.FLOOR_PLAN,
    //     initialFormValues['2']?.LAND_SEARCH,
    //     initialFormValues['2']?.OP,
    //     initialFormValues['2']?.OTHERS,
    //     initialFormValues['2']?.PHOTO,
    //     initialFormValues['2']?.SA_SEARCH,
    //     initialFormValues['2']?.SUPPLEMENTAL_DOCUMENT
    // ], { strict: true }));
    const shouldSetApprovalStage = !deepEqual([getPropertyStockSpecialFieldsDTO(stockForm.values ?? {})], [getPropertyStockSpecialFieldsDTO(initialFormValues['0'] ?? {})], { strict: true })
      || !deepEqual([tagForm.values], [initialFormValues['1']], { strict: true })
      || !deepEqual(
        [
          // fileForm.values?.CLOSE_TRNX_SUPP, 
          // fileForm.values?.COMPANY_SEARCH, 
          fileForm.values?.FLOOR_PLAN,
          // fileForm.values?.LAND_SEARCH, 
          // fileForm.values?.OP, 
          // fileForm.values?.OTHERS,
          fileForm.values?.PHOTO,
          // fileForm.values?.SA_SEARCH,
          // fileForm.values?.SUPPLEMENTAL_DOCUMENT,
          //fileForm.values?.FORM_3,
          //fileForm.values?.FORM_5,
        ],
        [
          // initialFormValues['2']?.CLOSE_TRNX_SUPP,
          // initialFormValues['2']?.COMPANY_SEARCH,

          initialFormValues['2']?.FLOOR_PLAN,

          // initialFormValues['2']?.LAND_SEARCH,
          // initialFormValues['2']?.OP,
          // initialFormValues['2']?.OTHERS,
          initialFormValues['2']?.PHOTO,
          // initialFormValues['2']?.SA_SEARCH,
          // initialFormValues['2']?.SUPPLEMENTAL_DOCUMENT,
          //initialFormValues['2']?.FORM_3,
          //initialFormValues['2']?.FORM_5,
        ], { strict: true })
    //|| stockForm.values.status !== "BOTH" && isNonEmpty(stockForm.values.price) && isNonEmpty(stockForm.values.rent) && (!isNonEmpty(initialFormValues['2']?.FORM_3) || !isNonEmpty(initialFormValues['2']?.FORM_5))
    //|| stockForm.values.status == "RENT" && isNonEmpty(stockForm.values.price) && !isNonEmpty(stockForm.values.rent) && !isNonEmpty(initialFormValues['2']?.FORM_3)
    //|| stockForm.values.status == "SALES" && !isNonEmpty(stockForm.values.price) && isNonEmpty(stockForm.values.rent) && !isNonEmpty(initialFormValues['2']?.FORM_5);
    //|| (fileForm.values?.FORM_3?.filter(f => !isNonEmpty(f.id)).length ?? 0) > 0 || (fileForm.values?.FORM_5?.filter(f => !isNonEmpty(f.id)).length ?? 0) > 0;
    // 
    setShouldUpdateApprovalStage(shouldSetApprovalStage);

  }, [stockForm.values, tagForm.values, fileForm]);

  // check duplicate
  //warning dialog
  const [openWarningDialog, setOpenWarningDialog] = React.useState(false);
  const handleConfirm = () => {
    setOpenWarningDialog(false);
  };
  const [checkResult, setCheckResult] = React.useState<Partial<CheckDuplicatedPropertyStockResultDTO>>(EMPTY as CheckDuplicatedPropertyStockResultDTO);
  const getCheckDuplicateResult = () => {
    if (isNonEmpty(stockForm.values.buildingId)) {
      if ((stockForm.values.hasFloorDefinitions === true && !isNonEmpty(stockForm.values.floor))
        || (stockForm.values.hasUnitDefinitions === true && !isNonEmpty(stockForm.values.unit))
      ) {
        return;
      }
      // stockForm.updateValues('isCreatingDuplicatedPropertyStock', undefined);
      // stockForm.updateValues('isCreatingDuplicatedCompanyStock', undefined);
      //call api
      if (stockForm.values.status !== 'SOLD' && stockForm.values.status !== 'LEASED') {
        // ONLY check when isOpen.
        dispatch({ type: 'Layout.MaskPresentRequested' });
        propertyApi.checkDuplicatedPropertyStock({
          id: stockForm.values.id,
          buildingId: stockForm.values.buildingId,
          usage: stockForm.values.usage,
          district: stockForm.values.district,
          block: stockForm.values.block,
          blockEn: stockForm.values.blockEn,
          street: stockForm.values.street,
          streetEn: stockForm.values.streetEn,
          lot: stockForm.values.lot,
          building: stockForm.values.building,
          buildingEn: stockForm.values.buildingEn,
          floor: stockForm.values.floor ?? [],
          unit: stockForm.values.unit ?? [],
        } as PropertyStockDTO, token ?? '').then(result => {
          if (result.data) {
            const duplicated = !!(
              result.data.duplicated &&
              result.data.propertyNo !== propertyNo
            ) || result.data.hasPendingForApprovalOrIsOpen;
            // const duplicated = result.data.hasPendingForApprovalOrIsOpen;
            setOpenWarningDialog(duplicated ?? false);
            if (!duplicated) {
              dispatch({ type: 'Layout.MaskDismissRequested' }); //if duplicated, dismiss when dialog open
            }
            setCheckResult(result.data);
            // dispatch({ type: 'Layout.MaskDismissRequested' });
            // stockForm.updateValues('isCreatingDuplicatedPropertyStock', result.data.duplicated);
            // stockForm.updateValues('isCreatingDuplicatedCompanyStock', result.data.duplicated && result.data.companyStock);
          } else {
            setCheckResult(EMPTY);
            dispatch({ type: 'Layout.MaskDismissRequested' });
          }

        });
      }
    }
  }

  return <>
    <WarningDialogForCreatingExisingPropertyStock forEditing
      form={stockForm}
      openDialog={openWarningDialog}
      setOpenDialog={setOpenWarningDialog}
      handleConfirm={handleConfirm}
      checkResult={checkResult}
    />
    <NavigationBlockerDialog matchesUrl={`/properties-edit/${propertyNo}`} unsaved={unsaved} goBack />
    <PropertyDetail getCheckDuplicateResult={getCheckDuplicateResult} unsaved={unsaved} shouldResetApprovalStage={shouldUpdateApprovalStage} onSave={(noNavigate?: boolean) => {
      if (!stockForm.validate()) {
        window.scrollTo(0, 0);
        return;
      }
      // if (checkResult.duplicated && checkResult.propertyNo !== propertyNo && (stockForm.values.approvalStage ?? 0) < 1) {
      //   setOpenWarningDialog(true);
      //   return;
      // }




      dispatch({ type: 'Property.UpdateRequested', payload: assembleFormToDto(), meta: { noNavigate } });
    }} form={stockForm} tagForm={tagForm} fileForm={fileForm} memos={memos} saveDisabled={!canUpdate} hideStreet={hideStreet} hideLot={hideLot} hideFloor={hideFloor} clientFileForm={clientFileForm} checkResult={checkResult}
    />
  </>;
}