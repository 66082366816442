import { LocaleState } from "reducers";

const en: LocaleState = {
  locale: "en",
  langCompany: {
    address: "25, SAI KUNG TOWN CENTRAL, SAI KUNG, NEW TERRITORIES",
    name: "Property Mavin Limited",
    businessReg: "69336684",
    licence: "C-012345",
  },
  langCreationUpdateMessages: {
    msgCreation: "%s created successfully",
    msgUpdate: "%s updated successfully",
    msgSign: "%s signed successfully",
    msgConfirmRequest: "%s was requested for confirmation successfully",
    msgConfirm: "%s confirmed successfully",
    msgApprovalSubmit: "%s was submitted for approval successfully",
    msgApprove: "%s approved successfully",
    msgReject: "%s rejected successfully",
    itemBuilding: "Building",
    itemPropertyStock: "Property stock",
    itemClient: "Client",
    itemUser: "User",
    itemForm1: "Form 1",
    itemForm2: "Form 2",
    itemForm3: "Form 3",
    itemForm4: "Form 4",
    itemForm5: "Form 5",
    itemForm6: "Form 6",
    itemSalePurchaseAgreement: "Sale Purchase Provisional Agreement",
    itemTenancyAgreement: "Tenancy Provisional Agreement",
    itemCloseTrnx: "Close Transaction",
    itemTransaction: "Transaction",
  },
  lang: {
    msgUserPwIncorrect: "Username or password incorrect.",
    msgPwMaxFailAttempt:
      "Too many fail login attempts, account has been locked.",
    msgPwMaxFailAttemptReset: "Account unlocked",
    msgNetworkError: "System error",
    msgFailedToConnect: "Failed to connect to server",
    msgMaliciousError:
      "This file has malicious contents, please upload another file",
    msgLoggedOut: "You have been logged out, please log in again.",
    msgSessionExpired: "Session expired, please log in again.",
    msgSignInAgain: "Sign in again",
    msgPasswordExpired: "Password has expired, please change password",
    msgDataNotFound: "Resources can not be found.",
    msgSystemBusy: "System is busy, please retry.",
    msgPermissionDenied: "You have no access right to current resource.",
    msgVersionMismatched:
      "Current data have been modified by another user, please reload.",
    msgClientVersionMismatched:
      "Current client profile has been modified, please reload.",
    msgDuplicatedBuilding: "Building record already exists.",
    msgAlreadyAssignedClient: "The client has been already claimed",
    msgAlreadyAssignedPropertyStock: "The property has been already claimed",
    msgFloorUnitValidationError:
      "Please input in correct format, e.g. G,1-2,3-10,11",
    msgFloorLevelValidationError:
      "Some floors are not covered by level definition",
    msgLevelOverlapError: "Some floors are overlapping in level definition",
    msgFloorUnitInUseByProperty:
      "Cannot delete the floors, units and level definitions that are in use by a property",
    msgFailedToSendEmailError: "Failed in sending email. Please try again.",
    msgFailedClientSignError: "Failed in signing. Please try again.",
    msgUnsupportedFileFormat: "Unsupported file format: %s",
    msgBuildingAliasAlreadyExists: "Building secondary address already exists.",
    msgFileSizeExceed: "File size exceeds the limit",
    msgConfirmDelete: "Are you sure to delete?",
    msgConfirmDone: "Are you sure to mark this message as processed?",
    msgLoading: "Loading",
    msgWelcome: "Welcome",
    msgMustBeNumber: "Must be number",
    msgPleaseTryAgain: "Please try again",
    msgUnsavedChanges: "Changes are not saved. Do you want to leave ?",
    u10k: "10K",
    u1M: "M",
    u100M: "100M",
    u1B: "B",
    uFt: "Ft",
    to: "-",

    dateModified: "Last Modified",
    modifiedBy: "Modified By",
    dateCreated: "Date Created",
    createdBy: "Created By",

    remarks: "Remarks",

    pageTitle: "Property Management System",

    //memo
    memos: "Memos",
    content: "Content",
    author: "Author",
    actionAddMemo: "Add Memo",
    msgPleaseInputMemo: "Please input....",

    captionUploadedAt: "uploaded at",
    captionUnsaved: "Unsaved",
    captionName: "Name",
    captionEnglishName: "English Name",
    captionChineseName: "Chinese Name",
    captionDeleted: "Deleted",
    captionMore: "More",
    captionNone: "None",
    captionTotalPages: "%s Page(s)",

    actionUpload: "Upload",
    actionConfirmAndUpload: "Confirm & Upload",
    actionDownload: "Download",
    actionDownloadOrPreview: "Download/Preview",
    actionDownloadOrPreviewWithSpace: "Download / Preview",
    actionNextStep: "Next",
    actionConfirm: "Confirm",
    actionSave: "Save",
    actionApply: "Apply",
    actionApplySave: "Apply & Save",
    actionAdd: "Add",
    actionDelete: "Delete",
    actionSaveForm: "Save Form",
    actionAddForm: "Add Form",
    actionGenerate: "Generate",
    actionGenerateAndSign: "Sign",
    actionUserProfile: "User Settings",
    actionLogout: "Logout",
    actionYes: "Yes",
    actionNo: "No",
    actionSearch: "Search",
    actionClearSorting: "Clear Sorts",
    actionClose: "Close",
    actionCancel: "Cancel",
    actionGoBack: "Back",
    actionSkip: "Skip",
    actionContinue: "Continue",
    actionRemoveSelected: "Remove",
    actionManage: "Manage",
    actionView: "View",
    actionEdit: "Edit",
    actionEnd: "End",

    captionHKI: "Hong Kong Island",
    captionKLN: "Kowloon",
    captionNT: "New Territories",
    captionSearch: "Search...",

    // Quick Access Bar Lang
    navDashboard: "Dashboard",
    navPropertyStockSearch: "Property Stock",
    navTransaction: "Transaction",
    navClient: "Client Master",
    navBuilding: "Building Master",
    navMemoEnquiry: "Memo Enquiry",
    navCommissionReport: "Sales & Commission Report",
    navSystemSetting: "System Administration",
    navUserMgt: "User Mgt.",
    navRoleMgt: "Role Mgt.",
    navSystemSettingListPage: "System Setting",
    navActivityAuditing: "Activity Auditing",
    navOptionEditor: "Options Setting",
    navClientAppSetting: "Client App Settings",
    navHomePageFeaturedTags: "Homepage Featured Tags",
    navAgentRatingComment: "Agent Rating and Comment Management",
    navJobMonitor: "Job Monitor",

    // notification
    titleNotifications: "Notifications",
    titleGotoNotificationCenter: "Go to Notification Center",
    captionIsRead: " (Read)",
    captionExpired: "Expired",
    actionDone: "Done",

    //language (SAME in all language file)
    traditionalChinese: "繁體中文",
    simplifiedChinese: "简体中文",
    english: "English",
    traditionalChineseAbbr: "繁",
    simplifiedChineseAbbr: "简",
    englishAbbr: "EN",

    legend: "Legend",

    rowsCount: "Rows Per Page",

    langSearchInCreatePage:
      "Please save the new property stock before you request for land search",

    downloadAPK: "Download Android App",
    downloadIOS: "Go to App Store",

    textOverLimit: "Text length exceeds limit",
  },
  langBuilding: {
    titleBuildingSearch: "Building Search",
    titleBuildingNew: "New Building",
    titleBuildingDetail: "Building Detail",
    titleBasicInfo: "Basic Info & Main Address",
    titleBuildingFeature: "Building Feature",

    captionBasicInfo: "Basic Info",
    captionLocation: "Location",
    captionFloorUnit: "Floor and Unit",

    actionSearch: "Search",
    actionNewBuilding: "New Building",
    actionAdd: "Add",
    actionCenterMap: "Center Map",

    usage: "Usage",
    district: "District",
    street: "Street",
    streetZh: "Street(Chinese)",
    streetEn: "Street(English)",
    latitude: "latitude",
    longitude: "longitude",
    building: "Building",
    buildingOrVillage: "Building/Village",
    buildingNameZh: "Building Name(Chinese)",
    buildingNameEn: "Building Name(English)",
    villageNameZh: "Village Name(Chinese)",
    villageNameEn: "Village Name(English)",
    buildingOrVillageZh: "Building/Village(Chinese)",
    buildingOrVillageEn: "Building/Village(English)",
    block: "Block",
    blockZh: "Block(Chinese)",
    blockEn: "Block(English)",
    lot: "Lot Number",
    occupancyPermitDate: "OP Date",
    occupancyPermitAge: "OP Age(Year)",
    expiryYear: "Expiry Year",
    developer: "Developer",
    floor: "Floor",
    unit: "Unit",
    lowLevel: "Low Level",
    midLevel: "Middle Level",
    highLevel: "High Level",
    clubHouseFacilities: "ClubHouse Facilities",
    others: "Others",
    primarySchoolNet: "Primary School Net",
    secondarySchoolNet: "Secondary School Net",
    remarks: "Remarks",
    locationScore: "Map Accurancy",

    msgInputDistrict: "Please select District",
    msgInputStreetName: "Please input Street Name",
    msgInputBuildingName: "Please input Building Name",
    msgPleaseSelect: "Please select",
    msgPleaseEnterChinOrEngName:
      "Either Chinese or English Building Name must be filled in",
    msgPleaseEnterFloorDefinition: "Please enter values in Floor and Unit",
    msgInputExpiryYear: "Please enter 4 numbers",

    titlePleaseSelectBuildingToMerge: "Select a building to merge",
    titlePleaseConfirmBuildingMerge: "Select direction of merging",
    titleBuildingAlias: "Secondary Addresses",
    titleAddAlias: "New Building Secondary Address",

    captionSearchBuilding: " Search Building...",
    captionMergeFrom: "Merge",
    captionMergeInto: "↓Into↓",
    captionBuildingWillBeDeleted: "Will be deleted",
    captionBuildingWillBeRetained: "Will be retained",
    captionAddToAlias: "Add the deleted building (%s) as secondary address",

    msgConfirmMerge: "Confirm to merge?",
    msgConfirmDeleteFloor: "Confirm to delete this floor & unit definition?",

    actionMergeBuilding: "Merge Building",
    actionSwitchMergeDirection: "Switch Merge Direction",

    textOverLimit: "Text length exceeds limit",
  },
  //#region Property Stock Language
  langPropertyStock: {
    titlePropertySearch: "Property Stock Search",
    titlePropertyDetail: "Property Stock Detail",
    titleClaimPropertySummary: "Property Stock Summary",
    titlePropertyMatching: "Property Stock Matching",
    titleBasicInfo: "Basic Info",
    titleSaleInfo: "Sale Info",
    titleContactInfo: "Client Contact Info",
    titleRelatedAgentsInfo: "Related Agents Info",
    titlePropertyFeatures: "Property Features",
    titleMgtInfo: "Mgt. Info",
    titleMemo: "Memo",
    titleForm: "Fill in Form",
    titleForm1: "Form 1",
    titleForm2: "Form 2",
    titleForm3: "Form 3",
    titleForm4: "Form 4",
    titleForm5: "Form 5",
    titleForm6: "Form 6",
    titlePropertySummary: "Property Summary (For AD)",
    titleProvisionalAgreement: "Fill in Provisional Agreement",
    titleSaleAndPurchase: "Sale & Purchase",
    titleTenancy: "Tenancy",
    titleNewBuildingRequest: "New Building Master Creation Request",
    titleUploadFiles: "View Files",
    titleFile: "Documents",
    titleFormAndAgreement: "Forms & Agreements",

    captionBasicInfo: "Basic Info",
    captionLocation: "Location",
    captionArea: "Area",
    captionNetOrGross: "Net/Gross",
    captionPriceOrRent: "Price/Rent",
    captionPrice: "Price",
    captionRent: "Rent",
    captionNet: "Net",
    captionNetAbbr: "Net",
    captionGross: "Gross",
    captionGrossAbrr: "Gross",
    captionUnlimited: "Unlimited",
    captionMore: "More",
    captionFeature: "Features",
    captionOthers: "Others",
    captionSearchConditions: "Search Conditions",
    captionMatchingConditions: "Matching Conditions",
    captionCover: "Cover",
    captionTransaction: "Transaction Information",
    captionNotApproved: "Unapproved",
    captionApproveStage1: "Internal Matching",
    captionApproveStage2: "Publicize",
    captionWithPhoto: "* With Photo",
    captionFormLocale: "Form Language",
    captionAgreementLocale: "Agreement Language",
    captionRejectionReason: "Approval request rejected, reason: ",
    captionPleaseInputRejectionReason:
      "Please input rejection reason to confirm",
    captionInputRejectionReason: "Rejection Reason",
    captionRevertToStage1: "Just revert publicization",
    captionRevertToStage0: "Revert both publicization and internal-matching",
    aptionAgent: "Agent",
    captionContact: "Contact",
    captionClientName: "Client Name",
    captionTransactionHistory: "Transaction(s)",
    captionWithKey: "Has Key",
    captionWithoutKey: "No Key",
    captionKeyNo: "Key Holder",
    captionHotPick: "Premium",
    captionNonHotPick: "Non-premium",
    captionHotPickExpiry: "Expiry Date",
    captionGrantedForForm46: "Granted For Wishlist",
    captionLeadAgent: "Lead Agent(s)",
    captionHotPickValidUntil: "Valid Until",

    actionSearch: "Search",
    actionClearSorting: "Clear Sorts",
    actionNewPropertyStock: "New Property Stock",
    actionNewBuildingRequest: "New Building Request",
    actionSetCover: "Set As Cover",
    actionChangeStatus: "Change Status",
    actionApprove: "Approve",
    actionReject: "Reject",
    actionUnapproved: "Unapproved",
    actionApproveStage1: "Internal Matching",
    actionApproveStage2: "Publicize",
    actionApproveRevert: "Revert",
    actionRequestForApproval: "Request For Approval",
    actionClaimProperty: "Claim",
    actionDeletePropertyStock: "Delete Property Stock",
    actionCloseTransaction: "Close Transaction",
    actionSendRequest: "Send Request",
    actionCancel: "Cancel",
    actionMatch: "Match",
    actionViewClientMaster: "View Client Master",
    actionRemoveLeadAgent: "Remove",
    actionPARegenerate: "Re-Sign",
    actionPAContinueExisting: "To Existing Document",
    actionGeneratePropertySummaryReport: "Generate Property Summary",
    actionGrantedForForm46: "Grant Wishlist Agent",

    actionRemoveBuyer: "Confirm to remove buyer?",
    actionRemoveSeller: "Confirm to remove seller?",

    actionAddLeadAgent: "Add Client & Lead Agent",
    actionRevealSellerInfo: "View Client Contact",
    msgSellerInfoRevealed:
      "Contact of the client has been revealed for you, the lead agent will receive a message.",

    actionPrevPhoto: "Previous",
    actionNextPhoto: "Next",

    actionBuildingUpdateReq: "Incorrect Building Info?",
    titleBuildingUpdateReq: "Building Update Request",
    megRequestBuildingUpdate_1: "You are issuing building update request for ",
    megRequestBuildingUpdate_2:
      ". Your request will be sent to office admin via email.",
    msgInputBuildingUpdateSuggestion: "Please input update suggestion...",
    msgIfBuildingNotFound: "If you cannot find the building, please issue",

    internal: "Internal",
    labelStockByCurrentUser: "Handled By Current User",

    // Messages
    msgDeletePropertyStock: "Confirm to delete property stock?",
    msgChangeStatus: "Confirm to change status?",
    msgApprovalOrReject: "Confirm to approve or reject",
    msgPleaseChooseToRevert: "Please choose to revert",
    msgBuildingSelect: "Input district & building to search building master",
    msgPhotoLimit: "No more than %d photos are allowed to upload",
    msgFileLimit: "No more than %d files are allowed to upload",
    msgFloorPlanLimit: "No more than %d floor plans are allowed to upload",
    msgPhotoHint: "Please ensure photo quality to gain more attraction",
    msgFirst4PhotoForPropertySummary:
      "Only first 4 photos will be displayed in Property Summary.",
    msgInputValidPeriodStartAndEnd:
      "Start Date should not be later than End Date",
    msgInputValidDate: "Date should not be later than upload date",
    msgUploadRequirement: "Show file upload requirement",
    msgUploadRequirementTemplate:
      "Size of file cannot exceed %sMB; supported file types: %s; aspect ratio: %s",
    msgUploadRequirementPhoto:
      "Size of file cannot exceed 6MB; supported file types: JPG, PNG, GIF; aspect ratio: 1:2",
    msgUploadRequirementFloorPlan:
      "Size of file cannot exceed 6MB; supported file types: JPG, PNG, GIF;",
    msgUploadRequirementFile:
      "Size of file cannot exceed 10MB; supported file types: DOC, DOCX, PDF, XLS, XLSX, JPG, PNG, GIF",
    msgRequireApproveAgain:
      "The property need approving again after being edited, continue?",
    msgConfirmApproval: "Are you sure to approve?",
    msgPublishedInClientAppAsPreviousVersion:
      "The Previous version of this property before edition is still in public promotion",
    msgConfirmClaimProperty: "Are you sure to claim this property?",
    msgConfirmRequestForApproval: "Are you sure to request for approval?",
    msgConfirmDeleteFile: "Are you sure to delete this file?",
    msgConfirmRemoveAgent: "Are you sure to remove?",
    msgConfirmRemoveLastAgent:
      "This is the last lead agent of the property stock, after removal the property stock will not be associated to any client.",
    msgCannotAddCompanyClient:
      "Company client cannot be added as this property stock already has non-company client(s).",
    msgWarningUpdateAsCompanyStock:
      'You are adding company client to this property stock, the stock will become company stock after clicking "%s"',
    msgWarningTheAgentIsAlreadyLeadAgent:
      'The agent you attempted to add is already the lead agent of the property stock, if you want to replace the associated client, please click "Confirm".',
    msgNoLeadAgent: "This property stock does not have lead agent.",
    msgWarningCurrentUserIsAlreadyLeadAgent:
      'You are already the lead agent of the existing property stock, if you want to replace the associated client, please click "Continue" to go to the property stock record to update the client',
    msgConfirmPARegenerate:
      "Confirm to re-sign? Previous SignNow document will be replaced.",
    msgWarningReplaceExistingClient:
      "The current associated client will be replaced by this client",
    msgWarningRequireApproveAgain:
      "The property need approving again after being edited",
    msgCannotRequestForApprovalStage2:
      "Cannot request for approval due the the following reason(s):",
    msgCannotApproveApproval: "Cannot proceed due the the following reason(s):",
    msgLongLatNotDefined: "Longitude and Latitude not defined",
    msgNoValidForm35: "No valid signed Form 3 and/or Form 5",
    msgNoValidlatestForm3: "The latest form 3 has been deleted/expired",
    msgNoValidlatestForm5: "The latest form 5 has been deleted/expired",
    msgNoValidlatestForm35:
      "The latest form 3 and/or form 5 has been deleted/expired",
    msgStatusNotForPublicListing:
      "Current property stock status (%s) does not allow public listing.",
    msgStatusNotForInternalMatching:
      "Current property stock status (%s) does not allow internal matching.",
    msgUnpublishedDueToForm35:
      "The property stock has been unpublished due to missing form 3/5 or form 3/5 expired. The property stock will be published again automatically after digital signing new form 3/5. If you manually upload new form 3/5 instead, the property stock need to be approved again.",
    msgUnpublishedDueToForm3Expired:
      "The property stock has been unpublished due to form 3 expired. The property stock will be published again automatically after digital signing new form3. If you manually upload new form 3 instead, the property stock need to be approved again.",
    msgUnpublishedDueToForm5Expired:
      "The property stock has been unpublished due to form 5 expired. The property stock will be published again automatically after digital signing new form5. If you manually upload new form 5 instead, the property stock need to be approved again.",
    msgUnpublishedDueToForm35Expired:
      "The property stock has been unpublished due to missing form 3/5 or form 3/5 expired. The property stock will be published again automatically after digital signing new form 3/5. The status of property stock will be changed to Sales/Rent/Both depends on the signing form(s). If you manually upload new form 3/5 instead, the property stock need to be approved again.",
    msgStatusBothChangeToSales:
      "The property stock is not available for Rent due to form 5 expired. The property stock will be able for Rent again automatically after digital signing new form 5. If you manually upload new form 5 instead, the property stock need to be approved again.",
    msgStatusBothChangeToRent:
      "The property stock is not available for Sales due to form 3 expired. The property stock will be able to Sales again automatically after digital signing new form 3. If you manually upload new form 3 instead, the property stock need to be approved again.",
    msgHasUnapprovedForm3:
      "After manually uploading new form3, the property stock need to be approved again",
    msgHasUnapprovedForm5:
      "After manually uploading new form5, the property stock need to be approved again",
    msgHasUnapprovedForm35:
      "After manually uploading new form3 and form5, the property stock need to be approved again",

    msgInputAtLeastPriceOrRent: "Please input at least one of price or rent",
    msgBottomPriceExceedsPrice: "Bottom price is higher than price",
    msgBottomRentExceedsRent: "Bottom rent is higher than rent",
    msgInputNet: "Please input net",
    msgInputGross: "Please input gross",
    msgInputClient:
      "Please search the client by keyword of client's name or contact detail",
    msgInputBuilding: "Please select building",
    msgInputFloor: "Please select floor",
    msgInputUnit: "Please select unit",
    msgInputKeyNo: "Please input key holder.",
    msgInputNetOrGross: "Please input net or gross",
    msgInputHotPickExpiry: "Please input expiry date",
    msgInputUsage: "Please select usage",
    msgInputOwner: "Please input Owner",

    msgInputPropertyInfo:
      "Please input Property Information to confirm Deletion",
    msgInputBuildingDescription: "Please input building's description",
    msgBuildingRequestSuccess:
      "The New Building Creation Request has been sent.",
    msgContactNotFound: "Cannot find client's contact",
    msgNewClient: "Not Found? Create New Client Master",
    msgAtLeastOneClient: "Please input at least one %s",
    msgBuildingHelperText:
      "Can input multiple buildings separated by comma(,) or semicolon(;)",
    msgStreetHelperText:
      "Can input multiple streets separated by comma(,) or semicolon(;)",

    msgRemarksForNonHKID:
      "* indicates that the ID number provided by the client is non-HKID",
    msgPleaseInputValidFloor:
      "Please input correct floor, separate multiple floors using comma(,)",
    msgPleaseInputValidUnit:
      "Please input correct unit, separate multiple units using comma(,)",

    // for property stock deletion
    msgUnmatchedBuilding: "Please input the correct building name",
    msgUnmatchedBlock: "Please input the correct block",
    msgUnmatchedFloor: "Please input the correct floor",
    msgUnmatchedUnit: "Please input the correct unit",
    msgUnmatchedLot: "Please input the correct lot number",

    // approval suggestions
    msgApprovalSuggestion:
      "Reapproval is required after editing property stock, please select:",
    msgApprovalSuggestionFirstTime:
      "The property is ready for publicize, please select:",
    msgApprovalSuggestionToStage1Pending:
      "Submit for approval for Internal Matching",
    msgApprovalSuggestionToStage2Pending:
      "Submit for approval for Internal Matching & Publicize",
    msgApprovalSuggestionToStage2PendingFirstTime:
      "Submit for approval for Internal Matching & Publicize",
    msgApprovalSuggestionToStage1Resolve: "Approve for Internal Matching",
    msgApprovalSuggestionToStage2Resolve:
      "Approve for Internal Matching & Publicize",
    msgApprovalSuggestionToStage2ResolveFirstTime:
      "Approve for Internal Matching & Publicize",
    msgApprovalSuggestionNoActionPending:
      "Do not submit for approval this time",
    msgApprovalSuggestionNoActionResolve: "Do not reapprove this time",
    msgPleaseSaveBeforeGenerateSummary:
      "Confirm to save before generating property summary?",

    // Lead Agent Panel
    msgGrantFullAddress:
      "Granted agents can access the full address of the property, and sign form4/form6 of this property.",

    // Land Search Request
    msgLandSearchRequestRequired:
      "You can perform land search through office admin.",
    msgLandSearchRequestOutOfCredit:
      "You are out of land search credit, please contact office admin for adding credits.",
    msgConfirmLandSearchRequest:
      'Confirm to spend 1 credit for land search request, please click "Request for Land Search" (Remaining credits: %s)',
    msgManualUploadLandSearch:
      "You can also upload a land search document directly, but the document you uploaded will need confirmation from office admin.",
    msgLandSearchRequestSuccess: "Land search request sent.",
    msgLandSearchRequestFailed:
      "Land search request failed, please contact office admin.",
    titleLandSearchRequest: "Land Search Request",
    msgLandSearchRequiredForForm35:
      "Signing form 3/5 requires at least one valid land search document. A valid land search requires confirmation from office admin, and being done within %d days.",
    actionRequestForLandSearch: "Request for Land Search",
    actionManualUploadLandSearch: "Manual Upload",
    captionConfirmLandSearch: "Confirm the land search is valid",

    // property stock claim
    msgStockCountPerDistrict:
      "Number of property stocks owned by the seller client (per district)",
    msgClaimAllUnderTheClient:
      "You will claim all the property stocks under the seller client.",

    // Form Upload Pane
    captionAgreementBy: "Agreement By",
    captionValidityPeriod: "Validity Period",
    captionExpired: "Expired",
    captionRemarks: "Remarks",
    captionProvisionalAgreementType: "Agreement Type",
    captionSignDate: "Sign Date",
    captionBuyer: "Buyer",
    captionSeller: "Seller",
    captionTenant: "Tenant",
    captionLandlord: "Landlord",
    captionName: "Name",
    captionSale: "Sale & Purchase",
    captionRental: "Tenancy",
    captionFormProperty: "Schedule 1 - Properties",

    cationType: "Type",
    captionAuthorCol: "Author",
    captionClientCol: "Client Name",
    captionNameCol: "Name", //??
    captionDate: "Date",
    captionNote: "Notes",
    captionHistory: "History",

    captionNameHidden: "(Hidden)",

    // Upload pane
    landSearch: "Land Search",
    saSearch: "SA Search",
    occPermit: "Occupation Permit",
    businessReg: "BR",
    otherDoc: "Others/FAX",
    form1: "Form 1 (Property Information Form)",
    form2: "Form 2 (Leasing Information Form)",
    form3: "Form 3 (Estate Agency Agreement for Sale with Vendor)",
    form4: "Form 4 (Estate Agency Agreement for Purchase with Purchaser)",
    form5: "Form 5 (Estate Agency Agreement for Leasing with Landlord)",
    form6: "Form 6 (Estate Agency Agreement for Leasing with Tenant)",
    provisionalAgreement: "Provisional Agreement",
    provisionalAgreementSuppDoc:
      "Supplemental Document(s) for Provisional Agreement",
    form46ClientAgentPrefix: "Agent ",

    // Features
    facing: "Facing",
    deco: "Deco",
    view: "View",
    otherFeature: "Features",
    otherFeatures: "Features",
    room: "Bedroom",
    suite: "Suite",
    livingRoom: "Living Room",
    livingRooms: "Living Room",
    helperRoom: "Helper Room",
    balcony: "Balcony",
    balconyArea: "Balcony Area",
    bathroom: "Bathroom",
    remarks: "Public Remarks",
    privateRemarks: "Private Remarks",
    video: "Video",
    photo: "Photo",
    floorPlan: "Floor Plan",
    heading: "Heading",
    punchline: "Punchline",
    clubHouseFacilities: "Club House",
    others: "Others",
    garden: "Garden",
    gardenArea: "Garden Area",
    rooftop: "Rooftop",
    rooftopArea: "Rooftop Area",
    primarySchoolNet: "School Net (Primary School)",
    secondarySchoolNet: "School Net (Secondary School)",
    hotPick: "Premium",
    propertyNo: "Property No.",

    // Fields
    buildingName: "Building Name",
    buildingOrVillageName: "Building/Village Name",
    expiryYear: "Expiry Year",

    usage: "Usage",
    stockNo: "Stock NO.",
    occupancyPermitDate: "OP",
    occupancyPermitAge: "Occupancy Permit Age(Year)",
    address: "Address",
    district: "District",
    street: "Street",
    streetZh: "Street(Chinese)",
    streetEn: "Street(English)",
    lot: "Lot",
    building: "Building",
    buildingOrVillage: "Building/Village",
    streetOrBuilding: "Street/Building",
    block: "Block",
    floor: "Floor",
    level: "Level",
    unit: "Unit",
    symbol: "Symbol",
    size: "Size(ft.)",
    gross: "Gross(ft.)",
    net: "Net(ft.)",
    status: "Status",
    target: "Target",
    key: "Key",
    vacant: "Vacant",
    price: "Price(M)",
    unitPrice: "Price(M/ft.)",
    bottomPrice: "Bottom Price",
    rent: "Rent",
    unitRent: "Rent(/ft.)",
    pricePerNet: "Net(Price/Rent)",
    pricePerNetSorted: "Net($Price/Rent)",
    rentPerNetSorted: "Net(Price/$Rent)",
    pricePerGross: "Gross(Price/Rent)",
    pricePerGrossSorted: "Gross($Price/Rent)",
    rentPerGrossSorted: "Gross(Price/$Rent)",
    bottomRent: "Bottom Rent",
    currentRent: "Current Rent",
    owner: "Owner",
    sellerOrOwner: "Seller/Owner",
    leaseCommencement: "Current Lease Period",
    mainContact: "Client Contact",
    companyStock: "Company Stock",
    dateCreated: "Date Added",
    dateModified: "Last Modified",
    modifiedBy: "Modified By",
    to: "-",
    buyer: "Buyer",
    seller: "Seller",
    transactionDate: "Transaction Date",
    baseFloor: "Basement Floor",
    groundFloor: "Ground Floor",
    mezzanineFloor: "Mezzanine Floor",
    otherArea: "Other Floor",
    baseFloorGross: "Basement Floor Gross",
    baseFloorNet: "Basement Floor Net",
    groundFloorGross: "Ground Floor Gross",
    groundFloorNet: "Ground Floor Net",
    mezzanineFloorGross: "Mezzanine Floor Gross",
    mezzanineFloorNet: "Mezzanine Floor Net",
    otherAreaGross: "Other Floor Gross",
    otherAreaNet: "Other Floor Net",
    rentFreePeriod: "Rent Free Period (Day)",
    _yield: "Yield",
    tenancyRemarks: "Tenancy Remarks",
    toilet: "Toilet",
    backDoor: "Backdoor",
    mezzanineFloorArea: "Cockloft", //Mezzanine Floor Size in Floor Plan
    storeFrontWidth: "Storefront Width",
    storeFrontHeight: "Storefront Height",
    shopDepth: "Shop Depth",

    developer: "Developer",
    assessmentNum: "Assessment Number",
    prn: "PRN no.", //Property Reference Number/ PRN no.
    ratesPropertyAddress: "Rates Property Address",
    quarterlyRates: "Gov Rate", //Government Rates/ Gov Rate
    mgtCompanyRegNum: "Com Reg", //Company Registration Number/ Com Reg
    annualGovRent: "Gov Rent", //Government Rent/ Gov Rent
    monthlyMgtFee: "Management Fee", //Management Fee/ Mgn F
    mgtFeeIncl: "Management Fee Incl.",
    mgtFeeGross: "Mgnt F(G)", //Management Fee/f  (Gross)/  Mgnt F(G)
    mgtFeeNet: "Mgnt F(N)", //Management Fee/f (Net)/ Mgnt F(N)
    mgtProfile: "Profile",
    mgtCommission: "Owner's Commission",

    captionDormantPropertyStock: "Dormant",
    captionNonDormantPropertyStock: "Non-dormant",
    captionOwnPropertyStock: "Own Stocks",
    canClaimBy: "Can be Claimed by",
    captionHistoryTrnx: "History Transaction",
    captionPerGross: "Gross",
    captionPerNet: "Net",

    // Property Stock Matching
    captionPropertyMatching1: "Property Stock Matching for ",
    captionPropertyMatching2: "",
    captionChosenClientsForMatching: "Chosen Clients",
    actionAddToWishlist: "Add to Wishlist",
    actionCancelMatching: "Cancel Matching",
    actionAddToMultiWishlists: "Add to Wishlist",
    actionAddToMultiClientWishlists: "Add to Client's Wishlists",
    msgAddToMultiClientWishlistsSuccess:
      "Property has been added to client's wishlists",

    //Property Stock Release Status
    statusChangeHeading: "Change Status To",
    captionReleasePrefix: "Release from ",
    captionReleaseSuffix: "",

    //warning dialog for creating a duplicated property stock
    waringDialogTitle: "Warning",
    propertyStock: "property stock",
    msgDuplicatedPropertyStockAddress:
      "There is already an existing %s with the same address: %s",
    msgCannotEditToExistingAddress:
      "Cannot update address of current property stock, because there is already an existing approved %s with the same address: %s",
    msgAddressNonEditable:
      "Cannot update address of current property stock, because there is more than one lead agents associated.",
    msgPleaseAddClient:
      'If you want to add yourself as one of lead agents of the existing property stock, please click "Continue" to enter the client information.',
    msgAddClientByAdmin:
      'If you want to add the agents of this property to the approved property stock, please click "Continue".',
    msgConfirmAddAllAgentsAndClients:
      "Confirm add all Agents and their Clients to the property stock",
    msgConfirmAsOneOfLeadAgent:
      'If you want to add yourself as one of lead agents of the existing property stock, please click "Continue"',
    actionRedirectToExistingProperty: "Redirect to Existing Property",

    //preview property stock
    titleAgentAndClientDetail: "Agent & Client detail",
    captionAssociatedClient: "Associated Client",
    captionPropertyStockAgent: "Agent(s)",
    captionCurrentLeaseRemarks: "Current Lease Remarks",
    monthlyMgtFeePerNet: "/ft.(Net)",
    monthlyMgtFeePerGross: "/ft.(Gross)",
    saleAbbr: "Sale",
    rentAbbr: "Rent",
    notSpecified: "Not specified",
    msgWarningNoOwnerInfo:
      'You do not have the owner\'s contact information yet, please click "Continue" to register.',

    //seller client dialog
    titleSellerClientDialog: "%s Client Contact Info", //%s is the action, e.g. 'Add' or 'Edit'

    // provisional agreement messages
    msgSaleAgreementExists:
      "Another lead agent has already issued a sale agreement. If you want to issue one instead, please contact the issuer or office admin.",
    msgTenancyAgreementExists:
      "Another lead agent has already issued a tenancy agreement. If you want to issue one instead, please contact the issuer or office admin.",
    msgSpecifiedRoleCanFillIn: "Only %s can fill in this information",
    msgConfirmDeleteProvisionalAgreemnt:
      "Are you sure to delete this agreement? This operation cannot be reversed. After deleting, other lead agents will be able to issue new agreement again.",
    msgDeleteExsitingPaBeforeUpload:
      "There is existing provisional agreement in the proeprty stock. To re-upload please remove the existing one.",
    msgSignGenerate:
      "If you need to use SignNow digital signing, please manually upload the generated PDF to SignNow:",

    msgDeleteExisingForm3:
      "There is an existing form 3 waiting for approval. To digitally sign or upload a new form 3, please remove the existing one.",
    msgDeleteExisingForm5:
      "There is an existing form 5 waiting for approval. To digitally sign or upload a new form 5, please remove the existing one.",

    textOverLimit: "Text length exceeds limit",
    noValidForm: "No valid form",
  },
  //#endregion Property Stock Language
  langCloseTransaction: {
    tenancyContract: "Tenancy Contract",
    salePurchaseContract: "Sale Purchase Contract",
    restCommission:
      "The remaining commission balance will be kept by the company",
    confirmForm134:
      "Please confirm that Form 1,Form 3 and Form 4 have been uploaded.",
    confirmForm256:
      "Please confirm that Form 2, Form 5 and Form 6 have been uploaded.",
    confirmForm13: "Please confirm that Form 1 and Form 3 have been uploaded.",
    confirmForm14: "Please confirm that Form 1 and Form 4 have been uploaded.",
    confirmForm25: "Please confirm that Form 2 and Form 5 have been uploaded.",
    confirmForm26: "Please confirm that Form 2 and Form 6 have been uploaded.",
    confirmProvisionalAndLandSearch:
      "Please confirm that Provisional Agreement and Land Search have been uploaded",
    missingDocuments: "Missing documents",
    msgFileLimit:
      "No more than %d supplemental document(s) are allowed to upload",
    msgUploadRequirement: "Show file upload requirement",
    msgUploadRequirementFile:
      "Size of file cannot exceed 10MB; supported file types: DOC, DOCX, PDF, XLS, XLSX, JPG, PNG, GIF",
    msgAgentConfirm: "Agent, please sign to confirm",
    msgApproverConfirm: "Approver, please sign to confirm",
    msgConfirmRemoveAgent: "Are you sure to remove this agent?",
    msgConfirmApprovalRequest: "Are you sure to submit for approval?",
    msgConfirmApproval: "Are you sure to approve?",
    msgSpecifyBuyerClient:
      "Internal buyer agent must link the buyer client record",
    msgSpecifyTenantClient:
      "Internal tenant agent must link the tenant client record",
    actionEditLinkage: "Edit Linkage",
    actionRemoveLinkage: "Remove Linkage",
    titleBuyerClientLinkage: "Link With Buyer Client",
    titleTenantClientLinkage: "Link With Tenant Client",
    msgBuyerClientLinkage:
      "Please specify the buyer client of the current transaction from the client master",
    msgTenantClientLinkage:
      "Please specify the tenant client of the current transaction from the client master",
    pleaseSelect: "Please Select ",
    pleaseInput: "Please Input ",
    msgBuyerAgentCommExceed:
      "Sum of buyer agent commission should not be greater then total buyer side commission.",
    msgBuyerAgentCommNotEqual:
      "Sum of buyer agent commission should be equal total buyer side commission.",
    msgSellerAgentCommExceed:
      "Sum of seller agent commission should not be greater then total seller side commission.",
    msgSellerAgentCommNotEqual:
      "Sum of seller agent commission should be equal to total seller side commission.",
    msgTenantAgentCommExceed:
      "Sum of tenant agent commission should not be greater then total tenant side commission.",
    msgTenantAgentCommNotEqual:
      "Sum of tenant agent commission should be equal total tenant side commission.",
    msgLandlordAgentCommExceed:
      "Sum of landlord agent commission should not be greater then total landlord side commission.",
    msgLandlordAgentCommNotEqual:
      "Sum of landlord agent commission should be equal to total landlord side commission.",
    msgInvalidInitialDepositAndBigDepositSum:
      "Sum of Initial Deposit & Big Deposit should not be greater than the Purchase Price",

    titleCloseTransactionRequest: "Close Transaction",
    titleClients: "Client(s)",
    titleAgents: "Agent(s)",
    titleGeneralInformation: "General Information",
    titleSupplementalDocuments: "Supplemental Document(s)",

    captionConfirmed: "Confirmed",
    captionSubmitted: "Submitted",
    captionRejectionRemarks: "Rejection Remarks",
    captionForChecklist: "Form Check List",
    captionPrice: "Price",
    captionRent: "Rent",
    captionBuyerCommReceivedDate: "Buyer Commission Received Date",
    captionSellerCommReceivedDate: "Seller Commission Received Date",
    captionTenantCommReceivedDate: "Tenant Commission Received Date",
    captionLandlordCommReceivedDate: "Landlord Commission Received Date",
    captionTransactionDate: "Transaction Date",
    captionBuyerSide: "Buyer Side",
    captionTenantSide: "Tenant Side",
    captionSellerSide: "Seller Side",
    captionLandlordSide: "Landlord Side",
    captionCommission: "Commission",
    captionCommissionShareForAgents: "Commission Share for Agents",

    captionClientName: "Client Name",
    captionClientContact: "Client Contact",
    captionClientAddress: "Client Address",

    captionAgentType: "Agent Type",
    captionInternal: "Internal",
    captionExternal: "External",
    captionAgentName: "Agent Name",
    captionAgentContact: "Agent Contact",
    captionBranchCode: "Branch Code",
    captionRemark: "Remark",

    captionInitialDeposit: "Initial Deposit",
    captionBigDeposit: "Big Deposit",
    captionCompletion: "Completion",
    date: "Date",

    actionRequestForConfirmation: "Request for Confirmation",
    actionConfirm: "Confirm & Sign",
    actionSubmitForApproval: "Submit for Approval",
    actionApprove: "Approve",
    actionReject: "Reject",
    captureSubmitted: "Submitted for Approval",
    captureApproved: "Approved",

    //for validation msg
    titlePremises: "Premises",
  },
  langTransaction: {
    titleTransactionDetail: "Transaction Detail",
    titleBasicInfo: "Basic Information",
    titleSalesInfo: "Sales Information",
    titleTransactionInfo: "Transaction Information",
    titleTransactionSearch: "Transaction Search",
    // for property draft edit
    msgPropertyDraftUpdateRequired:
      'Some fields of the transaction data need correction. Please correct and click "Correct Info.".',
    msgPleaseInputValidBuildingName:
      "Please input at least one of the Chinese/English building/village name",
    msgPleaseInputValidUsage: "Please input the correct usage",
    msgPleaseInputValidFloor:
      'Please input the correct floor, please separate multiple floors with ","',
    msgPleaseInputValidUnit:
      'Please input the correct unit, please separate multiple units with ","',
    msgInvalidDistrict:
      "Unrecognized district code: %s, please select one in options",
    msgInvalidUsage:
      "Unrecognized usage code: %s, please select one in options",
    msgAddDraftAsAlias:
      "Confirm to add uncorrected building address as secondary building address?",

    captionShowInvalidOnly: "Invalid Items Only",
    captionShowValidOnly: "Valid Items Only",
    captionShowAllValidity: "All Items",

    usage: "Usage",
    status: "Status",
    lot: "Lot",
    district: "District",
    street: "Street",
    building: "Building",
    buildingOrVillageName: "Building/Village Name",
    buildingOrVillage: "Building/Village",
    block: "Block",
    floor: "Floor",
    level: "Level",
    unit: "Unit",
    gross: "Gross(feet)",
    net: "Net(feet)",
    price: "Price($)",
    rent: "Rent($)",
    priceOrRent: "Price/Rent($)",
    type: "Type",
    pricePerNet: "Price/Net($/ft.)",
    pricePerNetAbbr: "$/ft.",
    pricePerGross: "Price/Gross($/ft.)",
    rentPerNet: "Rent/Net($/ft.)",
    rentPerGross: "Rent/Gross($/ft.)",
    priceOrRentPerNet: "(Price/Rent)/Net($/ft.)",
    priceOrRentPerGross: "(Price/Rent)/Gross($/ft.)",
    buyer: "Buyer",
    buyerOrTenant: "Buyer/Tenant",
    buyerContact: "Buyer's Contact",
    seller: "Seller",
    sellerOrLandlord: "Seller/Landlord",
    sellerContact: "Seller's Contact",
    landlord: "Landlord",
    tenant: "Tenant",
    transactionDate: "Transaction Date",
    documentDate: "Doc Date",
    buildingAge: "Building Age",
    buildingAgeAbbr: "Age",
    occupancyPermitDate: "Occupancy Permit Date",
    internal: "Internal Transaction",

    clientType: "Client Type",
    contactType: "Contact Type",
    contactName: "Contact Name",
    contactRemarks: "Remarks",

    actionClearSorting: "Clear Sorts",
    actionNewPropertyStock: "Create Property Stock",
    actionUpdatePropertyDraft: "Correct Info.",
  },
  langSettings: {
    titleOptionSetting: "Option Setting",
  },
  langForm1: {
    //General
    dateOfObtainingInfo: "Date of obtaining the information",
    source: "Prescribed source",
    year: "year(s)",
    month: "month(s)",
    day: "day(s)",
    HKDollar: "HK$",
    captionFormLocale: "Form Language",
    part: "Part ",
    statement: "Statement ",
    is: "is ",
    form: "Form",

    //Part 1
    titlePart1: "PART 1 - PROPERTY INFORMATION",
    //Part A
    formName: "PROPERTY INFORMATION FORM",
    titlePartA: "Part A: Address of the Property",
    propertyAddress: "Address of the Property",
    //Part B1
    titlePartB: "Part B: Prescribed information of the Property",
    titlePartB1:
      "Particulars of current ownership and subsisting encumbrances registered in the Land Registry",
    ownerName: "Name(s) of owner(s)",
    courtOrders: "Court orders",
    buildingOrders: "Building orders",
    slopeMaintenanceOrders: "Slope maintenance orders",
    lease: "Lease",
    mortgageOrCharge: "Mortgage or charge",
    otherMattersRegisteredAsEncumbrances:
      "Other matters registered as encumbrances",
    //Part B2
    titlePartB2: "Floor area of the Property",
    B2_optionA: "saleable area provided by the Rating and Valuation Department",
    B2_optionB:
      "saleable area stipulated in the agreement for sale and purchase of the first assignment",
    B2_optionC: "(c)	no information can be obtained under paragraph (a) or (b).",
    //Part B3
    titlePartB3: "Year of completion",
    B3_description:
      "Year of completion of the Property (or the building of which the Property forms part) as stipulated in the occupation permit or, if the occupation permit is not available, the relevant certificate of compliance or letter of no objection to occupy",
    yearOfCompletion: "Year of completion",
    //Part B4
    titlePartB4: "User restrictions",
    B4_description: "User under the occupation permit:",
    //Part B5
    titlePartB5: "Government lease",
    unexpiredTermOfGovLease: "Unexpired term of the lease",
    rightOfRenewal: "Right of renewal",
    //Part B6
    titlePartB6:
      "If a Government lease is to be granted, state the term of the proposed lease",
    proposedLease: "Term of the proposed lease",

    //Part C
    titlePartC:
      "Part C: Other information about the Property which may be provided",
    annualGovRent: "The annual Government rent",
    quarterlyRates: "The quarterly rates ",
    monthlyManagementFee: "The monthly management fee ",
    ownersIncorporationEstablished: "Owners incorporation established",
    floorAreaAndSourceOfInfo: "The floor area and source of information are :",
    propertyCategoriesSelect:
      "The Property falls within the following categories",
    tenantPurchaseScheme: "Tenant Purchase Scheme",
    homeOwnershipScheme: "Home Ownership Scheme",
    privateSectorParticipationScheme: "Private Sector Participation Scheme",
    sandwichClassHousing: "Sandwich Class Housing",
    flatForSaleScheme: "Flat for Sale Scheme (Housing Society)",
    propertyCategoriesRemarks:
      "(Note :	In the categories above, flats on which premium has been paid are not included in the category concerned.)",
    villageTypeHouse: "“Village” type houses in the New Territories",
    others: "Others, please specify:",

    //Part 2
    part2: "Part 2",
    titlePart2: "PART 2 - VENDOR’S STATEMENT",
    part2_1_description:
      "Are there any structural additions to or alterations of any part of the Property or the building of which the Property forms part, which have been or are or shall be made by the Vendor or any other person, during the period of the Vendor’s ownership of the Property?",
    part2_2_description:
      "Are there any structural additions to or alterations of any part of the Property or the building of which the Property forms part, which have been made by any person, before the period of the Vendor’s ownership of the Property?",
    part2_3_description:
      "Are there any reinstatements, rectification, repairs or improvements to any part of the Property or the building of which the Property forms part, which have been or are or shall be required by the Government or the management office or the owners incorporation of the building, during the period of the Vendor’s ownership of the Property?",
    part2_4_description:
      "Are there any reinstatements, rectification, repairs or improvements to any part of the Property or the building of which the Property forms part, which have been required by the Government or the management office or the owners incorporation of the building, before the period of the Vendor’s ownership of the Property?",
    part2_5_description1:
      "Are there any of the following works, the cost of which a purchaser of the Property shall be wholly or partly liable, which have been or are or shall be required or proposed by the Government or the management office or the owners incorporation of the building of which the Property forms part as regards any part of the Property or the building?",
    part2_5_description2:
      "Please state the nature of the work required or proposed, if any, and the cost/estimated cost* of works for which the purchaser will be liable: ",
    part2_5_description2_v2:
      "Please state the nature of the work required or proposed, if any, and the ",
    part2_5_description2_v2_part2:
      "of works for which the purchaser will be liable",

    cost: "cost",
    estimatedCost: "estimated cost",
    both: "cost/estimated cost",
    part2_6_optionA: "The Property is to be sold with vacant possession.",
    part2_6_optionB:
      "The Property is to be sold subject to a lease/licence for a specific term/statutory tenancy* as follows:",
    part2_6_optionB_sentence1: "The Property is to be sold subject to a",
    part2_6_optionB_sentence2: "as follows:",
    pleaseSelect: "Please select ",
    leaseOption: "lease option",
    leaseOptions1: "lease",
    leaseOptions2: "licence for a specific term",
    leaseOptions3: "statutory tenancy",

    monthlyRental: "The monthly rental ",
    inclusiveOf: " inclusive of  ",
    rates: "rates",
    governmentRent: "Government rent",
    managementFee: "management fee",
    tenancyTerm: "The term ",
    tenancyTermRemarks: "(both days inclusive)",
    dateFrom: "beginning date",
    dateTo: "expiring date",
    otherTerms: "Other terms:",
    breakClause: "break clause ",
    rentalReview: "rental review",
    optionToRenew: "option to renew",
    otherSpecialTermsParticular: "other special terms particular",
    otherSpecialTerms: "other special terms; please specify: ",
    informationProvidedByVendor:
      "All the information given by Vendor in Part 2 of this Form are true and correct within the Vendor’s knowledge.",
    vendorName: "Vendor Name",

    //Agnet details
    agentInfo: "Agent info",
    agentName: "Agent Name",
    nameAndLicenceNumberOfSignatory: "Name and licence number of the signatory",
    address: "Address",
    telephoneNumber: "Telephone number",
    faxNumber: "Fax number",
    date: "Date",

    helperTextParticulars: "If “yes”, please provide particulars",

    //to be confirmed
    numberOfAgentStatement:
      "Number of Agent’s statement of particulars of business",

    //validation msg
    pleaseInput: "Please input ",
    particulars: " particulars",
    msgSelectDateOfObtainingInfo:
      "Please select Date of obtaining the information",
    msgSelectionValidDate:
      "Please select Date of Obtaining the information before date of filing in form",
    msgInputValidPhoneNumber: "Please input valid telephone number",
    msgInputMissingFields: "Please input the following information",
    msgSelectAtLeastOneForPart6: "Please select at least one option",
    msgInputSelectedFields: "Please input category",
  },
  form1SourceOptions: {
    A: "Land Registry",
    B: "Rating and Valuation Department ",
    C: "Buildings Department",
  },
  form1CostOfPurchaserOptions: {
    a: "Reinstatement or rectification work",
    b: "Repairs or improvements work",
    c: "Slope maintenance work",
    d: "Others",
    e: "None",
    f: "Not known",
  },
  langForm2: {
    formName: "LEASING INFORMATION FORM",

    //General
    dateOfObtainingInfo: "Date of obtaining the information",
    source: "Prescribed source",
    HKDollar: "HK$",
    captionFormLocale: "Form Language",
    part: "Part ",
    form: "Form",

    //part A

    titlePartA: "Part A: Address of the Property",
    propertyAddress: "Address of the Property",

    //part B1
    titlePartB: "Part B: Prescribed information of the Property",
    titlePartB1:
      "Particulars of current ownership and subsisting encumbrances registered in the Land Registry",
    ownerName: "Name(s) of owner(s):",
    possessionOrders: "Possession orders",
    mortgageOrCharge: "Mortgage or charge",

    //part B2
    titlePartB2: "Floor area of the Property",
    B2_optionA: "saleable area provided by the Rating and Valuation Department",
    B2_optionB:
      "saleable area stipulated in the agreement for sale and purchase of the first assignment",
    B2_optionC: "(c)	no information can be obtained under paragraph (a) or (b).",

    //part B3
    titlePartB3: "Year of completion",
    yearOfCompletion:
      "Year of completion of the Property (or the building of which the Property forms part)",

    //Part B4
    titlePartB4: "User restrictions",
    B4_description: "User under the occupation permit",

    //part B5
    titlePartB5: "Restrictions on leasing",
    B5_descripton:
      "Restrictions on leasing imposed under legislation/Government lease",

    //part C
    titlePartC:
      "Part C: Other information about the Property which may be provided",
    annualGovRent: "The annual Government rent",
    quarterlyRates: "The quarterly rates",
    monthlyManagementFee: "The monthly management fee",
    annualGovRentnotneg: "The annual Government rent can't be negative",
    quarterlyRatesnotneg: "The quarterly rates can't be negative",
    monthlyManagementFeenotneg: "The monthly management fee can't be negative",
    subletParticulars: "sublet particulars",
    toBeSublet:
      "The Property is to be sublet and is presently subject to a lease",
    toBeSubletSentence1: "The Property is to be sublet and",
    toBeSubletSentence2: "is presently subject to a lease",

    //Agnet details
    agentInfo: "Agent info",
    agentName: "Agent Name",
    nameAndLicenceNumberOfSignatory: "Name and licence number of the signatory",
    address: "Address",
    telephoneNumber: "Telephone number",
    faxNumber: "Fax number",
    date: "Date",

    helperTextParticulars: "If “yes”, please provide particulars",

    //to be confirmed
    numberOfAgentStatement:
      "Number of Agent’s statement of particulars of business",

    //validation msg
    pleaseInput: "Please input ",
    pleaseSelect: "Please select ",
    particulars: " particulars",
    msgSelectDateOfObtainingInfo:
      "Please select Date of obtaining the information",
    msgSelectionValidDate:
      "Please select Date of Obtaining the information before date of filing in form",
    msgInputValidPhoneNumber: "Please input valid telephone number",
    msgInputMissingFields: "Please input the following information",
  },
  form2SourceOptions: {
    A: "Land Registry",
    B: "Rating and Valuation Department",
    C: "Housing Society",
    D: "Housing Department",
    E: "Buildings Department",
  },
  langForm3: {
    formName:
      "	ESTATE AGENCY AGREEMENT FOR SALE OF RESIDENTIAL	PROPERTIES IN HONG KONG",

    //general
    HKDollar: "HK$",
    pleaseInput: "Please input ",
    pleaseSelect: "Please select ",
    captionFormLocale: "Form Language",
    form: "Form",

    //part 1
    titlePart1: "Appointment of Agent and Validity Period of this Agreement",
    vendorName: "Vendor Name",
    agentName: "Agent Name",
    exclusiveAgent: "Exclusive agent",
    propertyAddress: "Property",
    validityPeriod: "Validity Period",
    validityPeriodStart: "start date",
    validityPeriodEnd: "end date",
    validityPeriodRemarks: "(both days inclusive)",

    //part 2
    titlePart2: "Agency Relationship and Duties of Agent",
    relationship: "Agency relationship",
    singleAgency: "single agency",
    dualAgency: "dual agency",
    potentiallyDualAgency: "potentially dual agency",

    //part 3
    titlePart3: "List Price",
    listPrice: "List Price",

    //part 6
    titlePart6: "Property Inspection",
    allowViewing:
      "(a) 	The Vendor agrees to allow viewing of the Property by the Agent/purchaser(s)(1) at a mutually agreeable time.",
    allowViewingSentence1:
      "The Vendor agrees to allow viewing of the Property by the ",
    allowViewingSentence2: "at a mutually agreeable time.",
    agent: "Agent",
    purchaser: "purchaser(s)",
    bothAgentAndPurchaser: "Agent/purchaser(s)",
    passKeyForSafeCustody:
      "The Vendor agrees to pass the keys of the Property to the Agent for safe custody and for the viewing of the Property.",
    passKeyToThirdParty:
      "The Vendor authorizes the Agent to pass the keys to other estate agents/persons(1) for the viewing of the Property.",
    passKeyToThirdPartySentence1:
      "The Vendor authorizes the Agent to pass the keys to other ",
    passKeyToThirdPartySentence2: "for the viewing of the Property.",

    estateAgent: "estate agents",
    otherPerson: "persons",
    bothEstateAgentOrPerson: "estate agents/persons",

    //part 7
    titlePart7: "Sub-listing",
    allowSubListing:
      "(a)	The Vendor authorizes the Agent to sub-list the Property and pass relevant information about the Vendor and the Property supplied by the Vendor to other estate agents for sub-listing purposes. ",

    //part 8
    titlePart8: "Advertising",
    allowAdvertisement:
      "The Vendor authorizes the Agent to issue advertisement in respect of the Property.",

    //part 9
    titlePart9: "Disclosure of Interest by Agent",
    disclosureOfInterestByAgent:
      "The person signing this Agreement on behalf of the Agent discloses that he or his specified relative, or any nominee of his or of his specified relative, or the Agent or any employee/substantial shareholder/partner/director of the Agent has a pecuniary or other beneficial interest in the Property:",

    //part 10
    titlePart10: "Acknowledgement by Vendor",
    vendorAcknowledgement1:
      "Received the Property Information Form including the Vendor’s Statement required to be provided under clause 5 of this Agreement",
    vendorAcknowledgement2:
      "Agreed to receive the Property Information Form including the Vendor’s Statement before entering into a binding agreement for sale and purchase between the Vendor and a purchaser.",

    //part 13
    titlePart13: "Extra Terms",
    extraTermsCautions:
      "[CAUTION: These extra terms cannot conflict with or limit the other terms of this Agreement]",

    //schedule 2
    titleSchedule2: "Schedule 2",
    titleCommission: "Commission to be Paid by Vendor",
    commission: "Commission",
    commissionIn: "Commission in",
    amount: "Amount",
    percentage: "Rate",
    paymentTime: "Commission to be paid upon",
    paymentTimeOption1:
      "upon the signing of the agreement for sale and purchase.",
    paymentTimeOption2:
      " upon the completion of the property transaction as specified in the agreement for sale and purchase.",

    titlePart2Point2:
      "Return commission with interest if the  property transaction falls through without fault on the part of the Vendor",
    withInterest: "with interest",
    withoutInterest: "without interest",

    //general info
    address: "Address",
    phoneNumber: "Telephone number",
    faxNumber: "Fax number",
    date: "Date",

    //vendor info
    vendorInfo: "Vendor Information",
    HKID: "Hong Kong identity card(s) number(s)",
    ifApplicable: "(if applicable)",
    whereVendorIsCompany: "Where the Vendor is a company, please state:",
    nameOfSignatoryOfVendor: "Name of the Signatory",
    positionOfSignatoryOfVendor: "Position of the Signatory",
    businessRegistrationCertificateNumberOfVendor:
      "Business Registration Certificate number of the Vendor",

    //agent info
    agentInfo: "Agent Information",
    nameAndLicenceNumberOfAgent: "Name and licence number of the signatory:",
    numberOfStatementOfParticularsOfBusiness:
      "Number of Agent’s statement of particulars of business",

    helperTextParticulars: "If “yes”, please provide particulars",

    msgInputVendorName: "Please input Vendor Name",
    msgInputAgentName: "Please input Agent Name",
    msgInputPropertyAddress: "Please input Property",
    msgInputValidityPeriodStart: "Please input Start Date",
    msgInputvalidityPeriodEnd: "Please input End Date",
    msgInputValidPeriodStartAndEnd:
      "Start Date should not be later than End Date",
    msgSelectRelationship: "Please select Agency Relationship",
    msgInputListPrice: "Please input List Price",
    msgInputInterestsInProperty:
      "Please input Particulars of the pecuniary or other beneficial interests",
    msgSelectVendorAcknowledgementOption:
      "Please select Vendor acknowledgement option",
    msgSelectCommissionIn: "Please select Commission calculation method",
    msgInputCommissionAmount: "Please input Commission Amount",
    msgInputCommissionRate: "Please input Commission Rate",
    msgSelectPaymentTime: "Please select Payment Time",
    msgSelectWithInterest: "Please select With/without interest",
    msgInputHKID: "Please input HKID",
    msgInputAddress: "Please input Address",
    msgInputPhoneNumber: "Please input Telephone number",
    msgInputFaxNumber: "Please input Fax number",
    msgInputDate: "Please input Date",
    msgIputNameOfSignatoryOfVendor: "Please input Name of the Signatory",
    msgPositionOfSignatoryOfVendor: "Please input Position of the Signatory",
    msgIputBusinessRegistrationCertificateNumberOfVendor:
      "Please input Business Registration Certificate number of the Vendor",
    msgIputNameAndLicenceNumberOfAgent:
      "Please input Name and licence number of the signatory",
    msgIputNumberOfStatementOfParticularsOfBusiness:
      "Please input Number of Agent’s statement of particulars of business",
    msgInputValidPhoneNumber: "Please input valid telephone number",
    msgInputSignatoryInfoOrHKID:
      "Please input HKID or the company(representative) information",
    msgSelectAtLeastOneForPart6: "Please select at least one option",

    msgInputMissingFields: "Please input the following information",
  },
  langForm4: {
    formName:
      "	ESTATE AGENCY AGREEMENT FOR PURCHASE OF		FORM 4 RESIDENTIAL PROPERTIES IN HONG KONG",

    pleaseInput: "Please input ",
    captionFormLocale: "Form Language",
    pleaseSelect: "Please select ",
    form: "Form",

    //part 1
    titlePart1: "Appointment of Agent and Validity Period of this Agreement",
    purchaserName: "Purchaser",
    agentName: "Agent",
    validityPeriod: "Validity Period",
    validityPeriodStart: "Start Date",
    validityPeriodEnd: "End Date",
    validityPeriodRemarks: "(both days inclusive)",

    //part 5
    titlePart5: "Disclosure of Interest by Agent",
    disclosureOfInterestByAgent:
      "The person signing this Agreement on behalf of the Agent discloses that he or his specified relative, or any nominee of his or of his specified relative, or the Agent or any employee/substantial shareholder/partner/director of the Agent has a pecuniary or other beneficial interest in the Properties",

    //part 9
    titlePart9: "Extra Terms",
    extraTermsCautions:
      "[CAUTION: These extra terms cannot conflict with or limit the other terms of this Agreement] : ",

    //general info
    address: "Address",
    phoneNumber: "Telephone number",
    faxNumber: "Fax number",
    date: "Date",

    //purchaser info
    purchaserInfo: "Purchaser Information",
    HKID: "Hong Kong identity card(s) number(s)",
    ifApplicable: "(if applicable)",
    wherePurchaserIsCompany: "Where the Purchaser is a company, please state:",
    nameOfSignatoryOfPurchaser: "Name of the Signatory",
    positionOfSignatoryOfPurchaser: "Position of the Signatory",
    businessRegistrationCertificateNumberOfPurchaser:
      "Business Registration Certificate number of the Purchaser",

    //agent info
    agentInfo: "Agent Information",
    nameAndLicenceNumberOfAgent: "Name and licence number of the signatory",
    numberOfStatementOfParticularsOfBusiness:
      "Number of Agent’s statement of particulars of business",

    //schedule 1
    titleSchedule1: "Schedule 1",
    column1: "Properties",
    column2: "Date of inspection",
    column3: "Purchaser agrees not to inspect",
    column4:
      "Purchaser waives his right to receive Property Information Forms including Vendor’s Statements",
    column5: "Agency Relationship",
    column6: "Amount or rate of commission to be paid by vendor, if applicable",
    column7: "Amount or rate of commission to be paid by Purchaser",

    property: "",
    dateOfInspection: "",
    notToInspect: "",
    waiveRight: "",
    agencyRelationShip: "",
    vendorCommissionAmountOrRate: "",
    agentCommissionAmountOrRate: "",

    singleAgency: "Single",
    dualAgency: "Dual",
    potentiallyDualAgency: "Potentially dual",

    //schedule 2
    titleSchedule2: "Schedule 2",
    titleCommission: "Commission to be Paid by Purchaser",
    paymentTime: "Commission to be paid upon",
    paymentTimeOption1:
      "upon the signing of the agreement for sale and purchase",
    paymentTimeOption2:
      "upon the completion of the property transaction as specified in the agreement for sale and purchase",

    titleSchedulePoint2:
      "Return commission with interest if the  property transaction falls through without fault on the part of the Purchaser",
    withInterest: "with interest",
    withoutInterest: "without interest",

    helperTextParticulars: "If “yes”, please provide particulars",

    msgInputPurchaserName: "Please input Purchaser Name",
    msgInputAgentName: "Please input Agent Name",
    msgInputValidityPeriodStart: "Please input Start Date",
    msgInputvalidityPeriodEnd: "Please input End Date",
    msgInputValidPeriodStartAndEnd:
      "Start Date should not be later than End Date",
    msgSelectRelationship: "Please select Agency Relationship",
    msgInputInterestsInProperty:
      "Please input particulars of the pecuniary or other beneficial interests",
    msgSelectPurchaserAcknowledgementOption:
      "Please select Purchaser acknowledgement option",
    msgSelectPaymentTime: "Please select Payment Time",
    msgSelectWithInterest: "Please select With/without interest",
    msgInputSignatoryInfoOrHKID:
      "Please input HKID or the company(representative) information",
    msgInputHKID: "Please input HKID",
    msgInputAddress: "Please input Address",
    msgInputPhoneNumber: "Please input Telephone number",
    msgInputFaxNumber: "Please input Fax number",
    msgInputDate: "Please input Date",
    msgIputNameOfSignatoryOfPurchaser: "Please input Name of the Signatory",
    msgPositionOfSignatoryOfPurchaser: "Please input Position of the Signatory",
    msgIputBusinessRegistrationCertificateNumberOfPurchaser:
      "Please input Business Registration Certificate number of the Purchaser",
    msgIputNameAndLicenceNumberOfAgent:
      "Please input Name and licence number of the signatory",
    msgIputNumberOfStatementOfParticularsOfBusiness:
      "Please input Number of Agent’s statement of particulars of business",
    msgInputSchedul1Info: "Please input required information",
    msgInputDateToInspect:
      "Please input Date of inspection if Purchaser is going to inspect",
    msgInputValidPhoneNumber: "Please input valid telephone number",

    msgInputMissingFields: "Please input the following information",
  },
  langForm5: {
    formName:
      "	ESTATE AGENCY AGREEMENT FOR LEASING OF RESIDENTIAL PROPERTIES IN HONG KONG",

    //general
    HKDollar: "HK$",
    pleaseInput: "Please input ",
    pleaseSelect: "Please select ",
    captionFormLocale: "Form Language",
    form: "Form",

    //part 1
    titlePart1: "Appointment of Agent and Validity Period of this Agreement",
    landlordName: "Landlord Name",
    agentName: "Agent Name",
    exclusiveAgent: "Exclusive agent",
    propertyAddress: "Property",
    validityPeriod: "Validity Period",
    validityPeriodStart: "start date",
    validityPeriodEnd: "end date",
    validityPeriodRemarks: "(both days inclusive)",

    //part 2
    titlePart2: "Agency Relationship and Duties of Agent",
    relationship: "Agency relationship",
    singleAgency: "single agency",
    dualAgency: "dual agency",
    potentiallyDualAgency: "potentially dual agency",

    //part 3
    titlePart3: "List Rental",
    listRental: "List Rental",
    includeRatesAndMgtFee: "inclusive of rates and management fee",

    //part 6
    titlePart6: "Property Inspection",
    allowViewingSentence1:
      "The Landlord agrees to allow viewing of the Property by the",
    allowViewingSentence2: "at a mutually agreeable time.",
    agent: "Agent",
    tenants: "tenant(s)",
    bothAgentAndPurchaser: "Agent/tenant(s)",
    passKeyForSafeCustody:
      "The Landlord agrees to pass the keys of the Property to the Agent for safe custody and for the viewing of the Property.",
    passKeyToThirdParty:
      "The Landlord authorizes the Agent to pass the keys to other estate agents/persons for the viewing of the Property",
    passKeyToThirdPartySentence1:
      "The Landlord authorizes the Agent to pass the keys to other",
    passKeyToThirdPartySentence2: "for the viewing of the Property. ",
    estateAgent: "estate agents",
    otherPerson: "persons",
    bothEstateAgentOrPerson: "estate agents/persons",

    //part 7
    titlePart7: "Sub-listing",
    allowSubListing:
      "The Landlord authorizes the Agent to sub-list the Property and pass relevant information about the Landlord and the Property supplied by the Landlord to other estate agents for sub-listing purposes.",

    //part 8
    titlePart8: "Advertising",
    allowAdvertisement:
      "The Landlord authorizes the Agent to issue advertisement in respect of the Property.",

    //part 9
    titlePart9: "Disclosure of Interest by Agent",
    disclosureOfInterestByAgent:
      "The person signing this Agreement on behalf of the Agent discloses that he or his specified relative, or any nominee of his or of his specified relative, or the Agent or any employee/substantial shareholder/ partner/director of the Agent has a pecuniary or other beneficial interest in the Property: ",

    //part 10
    titlePart10: "Acknowledgement by Landlord",
    landlordAcknowledgement1:
      "Received the Leasing Information Form required to be provided under clause 5 of this Agreement.",
    landlordAcknowledgement2:
      "Agreed to receive the Leasing Information Form before entering into a binding lease between the Landlord and a tenant",
    //part 12
    titlePart12: "Extra Terms",
    //part 13
    titlePart13: "Extra Terms",
    extraTermsCautions:
      "[CAUTION: These extra terms cannot conflict with or limit the other terms of this Agreement.]: ",

    //general info
    address: "Address",
    phoneNumber: "Telephone number",
    faxNumber: "Fax number",
    date: "Date",

    //schedule 2
    titleSchedule2: "Schedule 2",
    titleCommission: "Commission to be Paid by Landlord",
    commission: "Commission",
    commissionIn: "Commission in",
    amount: "Amount",
    percentage: "Rate",
    paymentTime: "Commission to be paid upon",
    paymentTimeOption1: "upon the signing of the lease",
    paymentTimeOption2:
      "upon the commencement of the tenancy as specified in the lease",

    titleSchedulePoint2:
      "Return commission with interest if the  commencement of the binding lease falls through without fault on the part of the Landlord",
    withInterest: "with interest",
    withoutInterest: "without interest",

    //landlord info
    landlordInfo: "Landlord Information",
    HKID: "Hong Kong identity card(s) number(s)",
    ifApplicable: "(if applicable)",
    whereLandlordIsCompany: "Where the Landlord is a company, please state: ",
    nameOfSignatoryOfLandlord: "Name of the Signatory",
    positionOfSignatoryOfLandlord: "Position of the Signatory",
    businessRegistrationCertificateNumberOfLandlord:
      "Business Registration Certificate number of the Landlord",

    //agent info
    agentInfo: "Agent Information",
    nameAndLicenceNumberOfAgent: "Name and licence number of the signatory",
    numberOfStatementOfParticularsOfBusiness:
      "Number of Agent’s statement of particulars of business ",

    helperTextParticulars: "If “yes”, please provide particulars",

    msgInputLandlordName: "Please input Landlord Name",
    msgInputAgentName: "Please input Agent Name",
    msgInputPropertyAddress: "Please input Property",
    msgInputValidityPeriodStart: "Please input Start Date",
    msgInputvalidityPeriodEnd: "Please input End Date",
    msgInputValidPeriodStartAndEnd:
      "Start Date should not be later than End Date",
    msgSelectRelationship: "Please select Agency Relationship",
    msgInputListRental: "Please input List Rental",
    msgInputInterestsInProperty:
      "Please input particulars of the pecuniary or other beneficial interests",
    msgSelectLandlordAcknowledgementOption:
      "Please select Landlord acknowledgement option",
    msgSelectCommissionIn: "Please select Commission calculation method",
    msgInputCommissionAmount: "Please input Commission Amount",
    msgInputCommissionRate: "Please input Commission Rate",
    msgSelectPaymentTime: "Please select Payment Time",
    msgSelectWithInterest: "Please select With/without interest",
    msgInputHKID: "Please input HKID",
    msgInputAddress: "Please input Address",
    msgInputPhoneNumber: "Please input Telephone number",
    msgInputFaxNumber: "Please input Fax number",
    msgInputDate: "Please input Date",
    msgIputNameOfSignatoryOfLandlord: "Please input Name of the Signatory",
    msgPositionOfSignatoryOfLandlord: "Please input Position of the Signatory",
    msgIputBusinessRegistrationCertificateNumberOfLandlord:
      "Please input Business Registration Certificate number of the Landlord",
    msgIputNameAndLicenceNumberOfAgent:
      "Please input Name and licence number of the signatory",
    msgIputNumberOfStatementOfParticularsOfBusiness:
      "Please input Number of Agent’s statement of particulars of business",
    msgInputValidPhoneNumber: "Please input valid telephone number",
    msgInputSignatoryInfoOrHKID:
      "Please input HKID or the company(representative) information",
    msgSelectAtLeastOneForPart6: "Please select at least one option",

    msgInputMissingFields: "Please input the following information",
  },
  langForm6: {
    formName:
      "	ESTATE AGENCY AGREEMENT FOR LEASING OF FORM 6 RESIDENTIAL PROPERTIES IN HONG KONG",

    pleaseInput: "Please input ",
    captionFormLocale: "Form Language",
    pleaseSelect: "Please select ",
    form: "Form",

    //part 1
    titlePart1: "Appointment of Agent and Validity Period of this Agreement",
    tenantName: "Tenant",
    agentName: "Agent",
    validityPeriod: "Validity Period",
    validityPeriodStart: "Start Date",
    validityPeriodEnd: "End Date",
    validityPeriodRemarks: "(both days inclusive)",

    //part 5
    titlePart5: "Disclosure of Interest by Agent",
    disclosureOfInterestByAgent:
      "The person signing this Agreement on behalf of the Agent discloses that he or his specified relative, or any nominee of his or of his specified relative, or the Agent or any employee/substantial shareholder/partner/director of the Agent has a pecuniary or other beneficial interest in the Properties",

    //part 9
    titlePart9: "Extra Terms",
    extraTermsCautions:
      "[CAUTION: These extra terms cannot conflict with or limit the other terms of this Agreement] : ",

    //general info
    address: "Address",
    phoneNumber: "Telephone number",
    faxNumber: "Fax number",
    date: "Date",

    //tenant info
    tenantInfo: "Tenant Information",
    HKID: "Hong Kong identity card(s) number(s)",
    ifApplicable: "(if applicable)",
    whereTenantIsCompany: "Where the Tenant is a company, please state:",
    nameOfSignatoryOfTenant: "Name of the Signatory",
    positionOfSignatoryOfTenant: "Position of the Signatory",
    businessRegistrationCertificateNumberOfTenant:
      "Business Registration Certificate number of the Tenant",

    //agent info
    agentInfo: "Agent Information",
    nameAndLicenceNumberOfAgent: "Name and licence number of the signatory",
    numberOfStatementOfParticularsOfBusiness:
      "Number of Agent’s statement of particulars of business",

    //schedule 1
    titleSchedule1: "Schedule 1",
    column1: "Properties",
    column2: "Date of inspection",
    column3: "Tenant agrees not to inspect",
    column4: "Tenant waives his right to receive Leasing Information Forms",
    column5: "Agency Relationship",
    column6:
      "Amount or rate of commission to be paid by landlord, if applicable",
    column7: "Amount or rate of commission to be paid by Tenant ",

    property: "",
    dateOfInspection: "",
    notToInspect: "",
    waiveRight: "",
    agencyRelationShip: "",
    landlordCommissionAmountOrRate: "",
    agentCommissionAmountOrRate: "",

    singleAgency: "Single",
    dualAgency: "Dual",
    potentiallyDualAgency: "Potentially dual",

    //schedule 2
    titleSchedule2: "Schedule 2",
    titleCommission: "Commission to be Paid by Tenant",
    paymentTime: "Commission to be paid upon",
    paymentTimeOption1: "upon the signing of the lease",
    paymentTimeOption2:
      "upon the commencement of the tenancy as specified in the lease",

    titleSchedulePoint2:
      "Return commission with interest if the  commencement of the binding lease falls through without fault on the part of the Tenant",
    withInterest: "with interest",
    withoutInterest: "without interest",

    helperTextParticulars: "If “yes”, please provide particulars",

    msgInputTenantName: "Please input Tenant Name",
    msgInputAgentName: "Please input Agent Name",
    msgInputValidityPeriodStart: "Please input Start Date",
    msgInputvalidityPeriodEnd: "Please input End Date",
    msgInputValidPeriodStartAndEnd:
      "Start Date should not be later than End Date",
    msgSelectRelationship: "Please select Agency Relationship",
    msgInputInterestsInProperty:
      "Please input particulars of the pecuniary or other beneficial interests",
    msgSelectTenantAcknowledgementOption:
      "Please select Tenant acknowledgement option",
    msgSelectPaymentTime: "Please select Payment Time",
    msgSelectWithInterest: "Please select With/without interest",
    msgInputSignatoryInfoOrHKID:
      "Please input HKID or the following information",
    msgInputHKID: "Please input HKID",
    msgInputAddress: "Please input Address",
    msgInputPhoneNumber: "Please input Telephone number",
    msgInputFaxNumber: "Please input Fax number",
    msgInputDate: "Please input Date",
    msgIputNameOfSignatoryOfTenant: "Please input Name of the Signatory",
    msgPositionOfSignatoryOfTenant: "Please input Position of the Signatory",
    msgIputBusinessRegistrationCertificateNumberOfTenant:
      "Please input Business Registration Certificate number of the Tenant",
    msgIputNameAndLicenceNumberOfAgent:
      "Please input Name and licence number of the signatory",
    msgIputNumberOfStatementOfParticularsOfBusiness:
      "Please input Number of Agent’s statement of particulars of business",
    msgInputSchedul1Info: "Please input required information",
    msgInputDateToInspect:
      "Please input Date of inspection if Tenant is going to inspect",
    msgInputValidPhoneNumber: "Please input valid telephone number",

    msgInputMissingFields: "Please input the following information",
  },
  langSalePurchaseAgreement: {
    captionAgreementLocale: "Agreement Language",

    formName: "Provisional Agreement for Sale and Purchase",
    titleParties: "A. Involved Parties",
    titleStatements: "B. Statements",

    titleAgreementDate: "Agreement Date",
    date: "Date",

    titleVendor: "Vendor(s)",
    titlePurchaser: "Purchaser(s)",
    vendor: "Vendor",
    purchase: "Purchaser",
    name: "Name",
    identityType: "ID Type",
    identityNumber: "ID No.",
    address: "Address",
    idCardImage: "ID Card Images",

    titleAgent: "Agent",
    agentName: "Name",
    agentBusinessRegNo: "Business Registration No.",
    agentLicenceNo: "Estate Agency (Company) Licence No.",
    agentAddress: "Registered Address",
    agentType: "Agent Type",
    agentTypeInternal: "Internal",
    agentTypeExternal: "External",
    agentLicenseNumber: "Licence Number",

    titleSellerAgent: "Seller Agent",
    sellerAgentName: "Name",
    sellerAgentContact: "Contact",
    sellerAgentHelpText:
      "If the agent is internal agent, he/she will be able to access this page and update the agreement info",

    titleBuyerAgent: "Buyer Agent",
    buyerAgentName: "Name",
    buyerAgentContact: "Contact",
    buyerAgentHelpText:
      "If the agent is internal agent, he/she will be able to access this page and update the agreement info. \nIf the agent is external agent, the agreement should be prepared by that agent.",
    externalAgentHelpText:
      "If the agent is external agent, the agreement should be prepared by that agent.",
    internalAgentHelpText:
      "If the agent is internal agent, he/she will be able to access this page and update the agreement info.",
    titlePremises: "Premises",

    titleConsiderationPayment: "Consideration and Payment",
    purchasePrice: "Purchase Price",
    initialDeposit: "Initial Deposit",
    formalAgreementSignDeadline:
      "Formal Agreement Shall be Signed On or Before",
    fullDepositDate: "Further Deposit Shall be Paid On or Before",
    fullDeposit: "Further Deposit",
    paymentCompletionDate:
      "Balance of Purchase Price Shall be Paid upon Completion On or Before",
    remainingSum: "Remaining Balance of Purchase Price",

    titleVacantPossessionTerm: "Vacant Possession Term",
    vacantPossessionTerm1:
      "Upon Sales & Purchase completion, the Vendor shall deliver vacant possession of the said premises to the Purchaser",
    vacantPossessionTerm2:
      "Purchaser agrees to purchase the said premises subject to the existing tenancy",

    titleVendorAsConfirmor: "Selling as confirmor",
    vendorIsSellingAsConfirmor: "The Vendor is selling as confirmor",
    titleSolicitor: "Solicitors",
    vendorSolicitor: "Vendor Solicitor",
    purchaserSolicitor: "Purchaser Solicitor",

    titleAgentCommission: "Agent Comission",
    vendorCommission: "Agent Commission from Vendor",
    purchaserCommission: "Agent Commission from Purchaser",
    commissionPaymentDeadline: "Commission Payment Deadline",

    titleCompensationToAgent: "Compensation to Agent",
    compensation: "Compensation Amount",

    residentialOrNot: "Property Usage",
    residential: "Residential",
    nonResidential: "Non-residential",

    titleAgentAppointment: "Appointment of Agent",
    agentFor: "Agent for",
    // both: 'Both',
    // vendorOnly: 'Vendor Only',
    // purchaserOnly: 'Purchaser Only',

    titlePrevailingVersion: "Interpretation",
    version:
      "In case of Ambiguities, Agreement should be intrepreted in version of",

    certificateOfValue: "Certificate Of Value (Not Exceed)",
    remarks: "Remarks",

    titleInitialDepositPaymentMethod: "Initial Deposit Payment Method",
    cash: "Cash",
    cheque: "Cheque",
    chequeNo: "Cheque Number",
    chequeBank: "Cheque Issuing Bank",
    supplementalDoc: "C. Supplemental Document(s)",

    //messages
    pleaseSelect: "Please select ",
    pleaseInput: "Please input ",
    msgAtLeastOneVendor: "Please input at least one vendor",
    msgAtLeastOnePurchaser: "Please input at least one purchaser",
    msgInputRequiredInfo: "Please input the required information",
    msgInvalidRemainingSum:
      "Sum of Initial Deposit & Further Deposit should not be greater than the Purchase Price",
    msgInvalidBuyerOrSellerAgentType:
      "At least one from Seller Side Agent and Buyer Side Agent should be internal",
    msgInvalidBuyerOrSellerAgent:
      "At least one from Seller Side Agent and Buyer Side Agent should be uploader",
  },
  langTenancyAgreement: {
    captionAgreementLocale: "Agreement Language",

    formName: "Provisional Agreement for Tenancy",
    titleParties: "A. Involved Parties",
    titleStatements: "B. Statements",

    titleAgreementDate: "Agreement Date",
    date: "Date",

    titleLandlord: "Landlord(s)",
    titleTenant: "Tenant(s)",
    landlord: "Landlord",
    tenant: "Tenant",
    name: "Name",
    identityType: "ID Type",
    identityNumber: "ID No.",
    address: "Address",
    idCardImage: "ID Card Images",

    titleAgent: "Agent",
    agentName: "Name",
    agentBusinessRegNo: "Business Registration No.",
    agentLicenceNo: "Estate Agency (Company) Licence No.",
    agentAddress: "Registered Address",
    agentType: "Agent Type",
    agentTypeInternal: "Internal",
    agentTypeExternal: "External",
    agentLicenseNumber: "Licence Number",

    titleLandlordAgent: "Landlord Agent",
    landlordAgentName: "Name",
    landlordAgentContact: "Contact",
    landlordAgentHelpText:
      "Fill in Landlord Agent's name so that the agent user can access this page to edit relevant agreement details",

    titleTenantAgent: "Tenant Agent",
    tenantAgentName: "Name",
    tenantAgentContact: "Contact",
    tenantAgentHelpText:
      "Fill in Tenant Agent's name so that the agent user can access this page to edit relevant agreement details",
    externalAgentHelpText:
      "If the agent is external agent, the agreement should be prepared by that agent.",
    internalAgentHelpText:
      "If the agent is internal agent, he/she will be able to access this page and update the agreement info.",
    titlePremises: "Address of Premises",

    titleMonthlyRental: "Monthly Rental",
    rental: "Rent",
    inclusive: "Inclusive of Government Rent, Rates and Management Fee",

    titleDeposit: "Deposit",
    titleInitialDeposit: "Initial Deposit",

    titleTenancy: "Term of Tenancy",
    // tenancy: 'Tenancy',
    from: "Tenancy Period Start",
    to: "Tenancy Period End",

    titleBreakClause: "Break Clause",
    monthInAdvance:
      "The no. of month(s) earlier of written notice to terminate the agreement:",
    rentInLieu:
      "The no. of month(s) of rent in lieu to be paid to terminate the agreement:",
    rentForAtleast:
      "The tenant can terminate the agreement earliest after the following no. of month(s) from the commencement date of the term:",

    titleVacantPossessionDelivery: "Vancant Possession Delivery",
    titleDeliveryBefore:
      "Vancant possession to be delivered by Landlord before:",

    titlePremisesUsage: "Premises Usage",

    titleSigning: "Formal Agreement Signing",
    formalAgreementSignDeadline:
      "Formal Agreement shall be signed on or before",
    signAt: "Both Parties shall sign the agreement at",
    solicitorOffice: "",

    titleAgentCommission: "Agent Comission",
    landlordCommission: "Agent Commission from Landlord",
    tenantCommission: "Agent Commission from Tenant",
    commissionPaymentDeadline: "Commission Payment Deadline",

    titleCompensationToAgent: "Compensation to Agent",
    compensation: "Compensation Amount",

    titleAgentAppointment: "Appointment of Agent",
    agentFor: "Agent for",
    // both: 'Both',
    // vendorOnly: 'Vendor Only',
    // purchaserOnly: 'Purchaser Only',

    titlePrevailingVersion: "Interpretation",
    version:
      "In case of ambiguities, Agreement should be intrepreted in version of",

    remarks: "Remarks",

    titleInitialDepositPaymentMethod: "Initial Deposit Payment Method",
    cash: "Cash",
    cheque: "Cheque",
    chequeNo: "Cheque Number",
    chequeBank: "Cheque Issuing Bank",
    supplementalDoc: "C. Supplemental Document(s)",

    pleaseSelect: "Please Select ",
    pleaseInput: "Please Input ",
    msgAtLeastOneLandlord: "Please input at least one landlord",
    msgAtLeastOneTenant: "Please input at least one tenant",
    msgInputRequiredInfo: "Please input the required information",
    msgInvalidTenantOrLandlordAgentType:
      "At least one from Landlord Side Agent and Tenant Side Agent should be internal",
  },
  identityTypeOptions: {
    H: "HKID",
    B: "Business Registration No.",
  },
  agentAppointmentOptionsSale: {
    BOTH: "Both",
    VENDOR_ONLY: "Vendor Only",
    PURCHASER_ONLY: "Purchaser Only",
  },
  agentAppointmentOptionsTenancy: {
    BOTH: "Both",
    LANDLORD_ONLY: "Landlord Only",
    TENANT_ONLY: "Tenant Only",
  },
  prevailingVersionOptions: {
    ENGLISH: "English",
    CHINESE: "Chinese",
  },
  yesNoOptions: {
    Y: "Yes",
    N: "No",
    U: "Not known",
  },
  dormancyOptions: {
    D: "Same District Agents",
    A: "All Agents",
    N: "None (Property w/o Client)",
  },
  langClientDetail: {
    //action
    actionNewClient: "New Client",
    actionRemoveClient: "Delete Client",
    actionStartMatching: "Start Matching",
    actionClaimClient: "Claim",
    actionForm: "Fill in Form",
    actionUploadForms: "Upload Form...",
    actionFillForm4: "Fill in Form 4",
    actionFillForm6: "Fill in Form 6",
    actionForm4: "Form 4",
    actionForm6: "Form 6",
    actionGeneratePropertySummaryReport: "Generate Property Summary",
    actionGenerate: "Generate",
    actionAssignAgent: "Assign Agent",
    actionReassignAgent: "Reassign Agent",
    actionRemoveAgent: "Remove Agent from Client",

    //title
    titleNewClientDetail: "New Client",
    titleClientInfo: "View Client Info",
    titleViewClientDetail: "Client Detail",
    titleBasicInfo: "Basic Info",
    titleSellerStock: "Property Stock Info",
    titlePreference: "Buyer/Tenant Specific Information",
    titleWishlist: "Wishlist",
    titleUploadForms: "View Files",
    titleSummary: "Client Detail",
    titleGeneratePropertySummaryReport: "Generate Property Summary",
    titleForm: "Forms",

    //Message
    msgOnlyForBuyerOrTenant: "For Buyer or Tenant Only",
    msgPleaseEnterChinOrEngName:
      "Either Chinese Name or English Name must be filled in",
    msgPleaseEnterClientName: "Please input client name",
    msgPleaseEnterRemarksHere: "Please enter remarks here...",
    msgAtLeastOneContact: "Please input at least one contact",
    msgInputAgent: "Please select agent",
    msgInputSource: "Please select source",
    msgInputClientType: "Please select client type",
    msgInputUsage: "Please select usage",
    msgInputDistrict: "Please select district",
    msgInputNet: "Please input net",
    msgInputPrice: "Please input price",
    msgInputRent: "Please input rent",
    msgInputValidPhoneNumber: "Please input valid telephone number",
    msgInputValidEmail: "Please input valid email",
    msgConfirmRemoveAgent: "Are you sure to remove agent from client?",
    msgConfirmClaimClient: "Are you sure to claim this client?",
    msgConfirmDeleteSelected: "Are you sure to remove?",
    msgNoOtherContacts: "Other contacts not available",
    msgConfirmRemoveClient: "Are you sure to remove this client?",
    msgWarningCreateDormantClient:
      "No agent is assigned to this client, after saving the client will be open for claim by agents of the same district.",
    msgSelectDistrictForDormantClient:
      "No agent is assigned to this client. Please select the district(s) for claiming by agents, otherwise this client can be claimed by all agents: ",
    msgConfirmDistrictForDormantClient:
      "No agent is assigned to this client. Please confirm the district(s) for claiming by agents, otherwise this client can be claimed by all agents: ",
    msgPleaseSelect: "Please select",
    msgConfirmRemoveContact: "Confirm to remove this contact?",
    msgPleaseInputContact: "Please input contact",
    msgForm4ForSalesPropertyStockOnly:
      "Form 4 is applicable for sales property stock(s) only",
    msgForm6ForRentPropertyStockOnly:
      "Form 6 is applicable for leasing property stock(s) only",
    msgPleaseSaveBeforeMatching:
      "Confirm to save client detail before property matching?",
    msgNotGrantedWishlistItem:
      "You have not yet been granted for Form4/6 signing on this property, please contact lead agent for granting.",
    msgDuplicatedContact:
      "Same contact value exists in own clients and/or company clients.",
    msgDuplicatedContactOnSave:
      "Same contact value exists in own clients and/or company clients, are you confirm to save?",
    msgMergeClientHavingContact:
      "The client %s you just claimed has the following contact(s):",
    msgMergeClientChoosingToMerge:
      "The following existing clients have at least one contact that is the same as the above customers, you can choose to merge the above client into one of the following client:",
    actionCancelRetainClaimedClient: "Cancel (Retain the claimed client)",
    msgConfirmToMerge: "Confirm to merge into this client?",
    msgConfirmNotToMerge:
      "Confirm to cancel the merging? The claimed clinet will be retained independently.",
    msgSaveBeoreUploading:
      "Confirm to save the Client Detail? Client Detail needed to be saved before you upload new Form4 / 6.",
    //basic info
    clientStatus: "Status",
    companyClient: "Company Client",
    clientNo: "Client NO.",
    clientName: "Client Name",
    chineseName: "Chinese Name",
    englishName: "English Name",
    contactNoOrEmail: "Contact",
    sourceOfClient: "Source",
    agent: "Agent",
    remarks: "Remarks",

    //contact info
    clientType: "Client Type",
    contactType: "Contact Type",
    contactName: "Contact Name",
    contactRemarks: "Remarks",
    agentsContactRemarks1: "(Agent ",
    agentsContactRemarks2: ")",
    otherContacts: "Other Contacts",

    //address
    adderss: "Address",
    address1: "Block/Floor/Flat ",
    address2: "Building",
    address3: "Estate, Street/Road",
    address4: "District",

    //client preference
    captionOthers: "Others",
    reason: "Reason",
    attitude: "Attitude",
    propertyStatus: "Property Status",

    //client search/client list
    propertyUsage: "Property Usage",
    clientSearch: "Client Search",
    canClaimBy: "Can be Claimed By",
    dormantClients: "Dormant",
    nonDormantClients: "Non-dormant",
    ownClients: "Own Clients",
    phoneOrEmail: "Phone/Email",

    //property summary report
    selectLanguageTitle: "Please Select Language",
    traditionalChinese: "Eng + T. Chinese",
    simplifiedChinese: "Eng + S. Chinese",

    //filter stock cart
    actionFilter: "Filter",
    captionBuyPropertyStock: "Buy",
    captionRentPropertyStock: "Rent",

    captionInterestedProperties: "Interested Properties: ",
  },
  langMemoEnquiry: {
    titleMemoEnquiry: "Memo Enquiry",
    captionBasicInfo: "Basic Info",
    type: "Memo Type",
    username: "Username",
    dateCreated: "Date Created",
    user: "User",
    content: "Content",
    id: "Memo Id",
    clientName: "Client Name",
    property: "Property",
  },
  langNotification: {
    titleNotificationsCentre: "Notifications Centre",
    captionFilter: "Filter",
    captionModule: "Module",
    captionType: "Message Type",
    captionAction: "Action",
    actionMarkAsRead: "Mark as Read",
    actionMarkAsUnread: "Mark as Unread",
    captionAllRead: "All Statuses",
    captionAllModule: "All Types",
    captionUnread: "Unread",
    captionNotYetProcessed: "Not Yet Processed",
    navPropertyStock: "Go to Property Stock...",
    navSalesCommissionReport: "View Report...",
    viewPropertyStock: "View Property Stock...",
    navClient: "Go to Client...",
    viewClient: "View Client...",
    captionClaimed: "(Already Claimed)",
    captionProcessed: "(Already Processed)",
    captionImpersonationEnded: "(Already Ended)",
    actionDone: "Mark as processed",
    actionRead: "Mark as read/unread",
    actionDelete: "Delete",
    ALL: "All",
    actionLandSearchDone: "Finish land search",
    actionLandSearchCancel: "Cancel land search",
  },
  notificationTypeOptions: {
    BUILDING_MASTER_CREATION_REQUEST: "Building Master",
    PROPERTY_STOCK: "Property Stock",
    FORM_SIGNING: "Form Signing",
    PROPERTY_STOCK_DORMANCY: "Update Property Stock",
    CLIENT_DORMANCY: "Update Client",
    CLAIM_PROPERTY_STOCK: "Claim Property Stock",
    CLAIM_CLIENT: "Claim Client",
    IMPERSONATION: "Impersonation",
    TRNX_DATAFEED: "System Job", //transaction -> system job
  },
  sourceOfClientOptions: {
    A: "Agent",
    C: "Cold-Call",
    D: "Distribute Card",
    E: "Exchange",
    F: "Fax",
    H: "Hawk",
    I: "Internet",
  },

  // #region User Languages
  langUser: {
    actionNewUser: "New User",
    actionSetAsDefault: "Set Default",
    actionDefault: "Default",
    actionChangePassword: "Change Password",
    actionGotoDetail: "Goto Details",
    actionSearch: "Search",
    actionUploadPhoto: "Upload photo",

    titleUserDetail: "User Setting",
    titleBasicInfo: "Basic Information",
    titleContacts: "Contacts",
    titleChangePassword: "Change Password",
    titlePageHeading: "Users Management",
    titleUserPreference: "User Preference",

    msgUsernameRequired: "Please input username",
    msgPasswordRequired: "Please input password",
    msgSelectAtLeastOneRole: "Please assign at least one role",
    msgAtLeastOneContact: "Please input at least one contact",
    msgEmailRequired: "Please input email",
    msgInputValidEmail: "Please input valid email",
    msgPasswordMismatched: "Incorrect Old Password",
    msgDuplicatedUsername: "This username has been used",
    msgPreviousPasswordUsed:
      "New password cannot be the same as the previous password(s)",
    msgPasswordFailComplexityRequirement:
      "The new password must meet complexity requirements",
    msgNoEmailForResetPw: "Please set up at least one email for password reset",
    msgConfirmToResetPw: "Confirm to reset password?",
    msgConfirmToUnlock: "Confirm to unlock account?",
    msgNoNeedToUnlock: "No need to unlock",
    msgPleaseEnterChinOrEngName:
      "Either Chinese Name or English Name must be filled in",
    msgPleaseInput: "Please input",
    msgNewPasswordMismatch: "The two passwords inputted are not the same",
    msgSelectStatus: "Please select status",
    msgInputLicenseNumber: "Please input Licence Number",
    msgUserNoFound: "Cannot find user",
    msgInputTeamName: "Please input Team Name",

    //forgot password
    msgPleaseWait: "Please wait...",
    msgChangePwSuccess: "Password changed successfully",
    msgProcessing: "Changing password, please do not leave current page...",
    msgLinkExpired: "This link has expired",

    captionOperations: "Operations",
    captionResetPassword: "Reset Password",
    captionUnlock: "Unlock",

    newPassword: "New Password",
    oldPassword: "Old Password",
    confirmNewPassword: "Confirm New Password",
    name: "Name",
    username: "Username",
    password: "Password",
    chineseName: "Name(Chinese)",
    englishName: "Name(English)",
    salutation: "Salutation",
    licenseNumber: "Licence Number",
    email: "Email",
    teamName: "Team Name",
    title: "Title",
    teamLead: "Belongs To Team",
    district: "District",
    actAsTeamHead: "Team",
    actAsTeamHeadYes: "Team Head",
    actAsTeamHeadNo: "Team Member",
    roles: "Role",
    status: "Status",
    teamMemberCount: "Team Member Count",
    cannotSwitchTeamHead:
      "Since this team has members, Team Head cannot be changed",
    otherContacts: "Other contacts",
    contact: "Contact",
    displayLanguage: "Display Language",
    photo: "Photo",
    landSearchCredit: "Land Search Credits",

    //password complexity
    pwMinLength: "The minimum length is ",
    pwAtLeast: "with at least ",
    pwUnit: " ",
    pwLowercaseChar: "lowercase letter(s)",
    pwUppercaseChar: "uppercase letter(s)",
    pwSpecialChar: "special character(s)",
    pwNumber: "number(s)",

    //user preference
    propertyStockColumnPreference: "Property Stock Column Preference",
    clientColumnPreference: "Client Column Preference",
    transactionColumnPreference: "Transaction Column Preference",
    columnPreferenceHelperText: "Please drag the item you want to re-arrange",
    rowsCountPreference: "Rows Count Preference",
    rowsCount: "Rows Per Page",
    client: "Client",
    propertyStock: "Property Stock",
    transaction: "Transaction",
    building: "Building",
    memoEnquiry: "Memo Enquiry",
    salesCommissionReport: "Sales & Commission Report",
    notificationCenter: "Notification Centre",
    globalPreference: "All Listings",
    captionShowIndividualSetting: "Adjust Individual Listing",

    //impersonation
    actionImpersonationRequest: "Impersonation Request",
    actionAuthorize: "Authorize",
    impersonating: "%s is now impersonating %s",
    titleAuthorizeImpersonation: "Authorize Impersonation",
    actionStartImpersonation: "Start Impersonation",
    actionStopImpersonation: "Stop Authorization",
    captionYourPassword: "Your password",
    captionAlreadyAuthorized: "Already Authorized",
    msgInputPasswordToConfirmIdentity:
      "Input your own password to confirm your identity",
    msgImpersonationSessionExpired: "Impersonation session expired",
    msgIncorrectPassword: "Incorrect Password",
    msgAuthorizeImpersonation:
      "After you authorized this impersonation request, %s can login as your user account to view/update your client and/or property stock data. \n\n Are your sure to authorize this impersonation request?",
    msgActiveImpersonationSessionExists:
      "There already exists an impersonation session in effect authorized by this user, so you cannot submit a new request",
    msgSearchUserToImpersonate:
      "Search for the User to impersonate by entering username keyword",
    msgRequestSentSuccessfully: "Impersonation request sent successfully",
    msgConfirmStopImpersonation: "Are you sure to stop impersonation?",
    msgThirdPartyKeyboard:
      "Using third party keyboard may have potential security risks, using the default keyboard of your device is strongly recommended.",
  },
  langRolePrivilege: {
    titlePageHeading: "Role Management",
    titleRoleBasicInfo: "Basic Info",
    titlePrivilegeSetting: "Privilege Setting",
    titleRoleDetail: "Role Detail",

    actionNewRole: "New Role",

    captionRoleName: "Role Code Name",
    captionDescriptionTC: "Description(TC)",
    captionDescriptionSC: "Description(SC)",
    captionDescriptionEN: "Description(EN)",
    captionDataStatus: "Status",

    msgPleaseInput: "Please input ",
    msgPleaseSelect: "Please select ",
    msgDuplicatedRoleName: "Role code name must be unique",
    msgInactivateRoleForbidden:
      "Cannot inactivate this role as there exists users assigned to this role",
  },
  langSystemSetting: {
    titlePageHeading: "System Setting List",
    titleSystemSettingDetailDialog: "System Setting",

    actionNewSystemSetting: "New System Setting",

    id: "ID",
    module: "Module",
    type: "Type",
    key: "Key",
    value: "Value",
    dataStatus: "Status",
    description: "Description",
    seq: "Sequence",

    msgPleaseInput: "Please input ",
    msgPleaseSelect: "Please select ",
    msgDuplicatedSystemSetting:
      "There already exists a record with the same module, type, key and value",

    msgVersionUpdate: "Version Update",
    msgUpdateVersion:
      "We have released a new version, please click OK to reload all pages.",
    msgUpdateAppVersion:
      "We have released a new version, please click OK to update the app.",
  },
  langActivityAuditing: {
    titleActivityAuditingList: "Activities",

    dateCreated: "Date Created",
    username: "User",
    module: "Module",
    subModule: "Function",
    action: "Action",
    enterAt: "Enter At",
    exitAt: "Exit At",
    ipAddress: "IP",
    objectId: "No.",
    propertyAddress: "Property Address",
    clientName: "Client Name",
    captionCheckIpLocation: "View IP Location",
    impersonatedBy: "(Impersonated by %s)",
  },
  langSignDialog: {
    actionSign: "Sign",
    actionClear: "Clear",
    actionDownloadDoc: "Download Signed Doc",
    actionGoBack: "Back",
    actionConfirmAndSign: "Confirm & Sign",
    actionSendCopyToClient: "Send",
    actionSendEmail: "Send Email",
    actionInviteClientToSign: "Send Invitation Email to Client",
    actionGenerateSigningLink: "Generate Link for Signing",
    actionVerifyContact: "驗證 Verify",
    actionClientSignNow: "Client Sign Now",
    actionGenerateClientSigningLink: "Generate Client Signing Link",
    actionPreviewForm: "Preview Form",
    actionDocumentPreview: "Doc. Preview",
    actionCopyLink: "Copy link",

    captionStepSignByAgent: "Sign By Agent",
    captionStepSignByClient: "Sign by Client",
    captionStepInviteClientToSign: "Invite Client to sign",
    captionStepDone: "Completed",
    captionStepVerification: "Verification",
    captionStepSignForm: "Sign the Form",
    captionFormType: "Type of Form",

    captionAgentSigned: "Agent signed",
    captionWaitForClientSign: "Waiting for client to sign",
    captionClientSigned: "Both agent and client signed",

    captionClient: "Client",
    captionProperty: "Property",
    captionVendor: "Vendor",
    captionAgent: "Agent",
    captionIdentificationDocNo: "Identification Document Number",
    captionClientEmail: "Client email",
    captionClientVerificationContact: "Client contact (for verify client)",
    captionEmail: "Email",

    titleGenerated: "Document Signed",
    titleGeneratedEn: "Document Signed",
    titleAlreadySigned: "Document signed already",
    titleClientSign: "客戶表格簽署 Form Signing by Client",

    msgInputVerificationContact: "Please input verification contact",
    msgInputEmailToSendCopy: "Input client email to send a copy",
    msgSigningTips: "Please sign on the yellow area.",
    msgGenerating: "Signing document, please do not leave current page...",
    msgGenerated: "Singed document was sent via email.",
    msgSentSuccess: "Sent successfully",
    msgClientConfirm: "Client, please sign to confirm",
    msgVerifying: "Verifying, please do not leave current page...",
    msgPleaseWait: "Please wait...",
    msgInvalidVerificationContact:
      "Invalid verification contact, please input again",
    msgSuccessAgentSign:
      "Agent signed successfully. Please input the followings and invite client to sign the form.",
    msgPleaseInput: "Please input",
    msgWarnSignedFormExist:
      "There exists a signed form with overlapping validity period, are you sure to continue?",
    msgWarnSignedFormExistForForm46:
      "There exists a signed form with overlapping validity period and properties, are you sure to continue?",
    msgWarnInvitationSentAlready:
      "Form signing invitation has already been sent to client, are you sure to continue?",
    msgWarnLinkExpiry: " Please note that this link will expire in %s hours.",
    msgSuccessClientSignByEmail:
      "The Form has been successfully signed! An email with the signed Form attached will be sent to your email for your reference. Thank you!",
    msgSignAreaTitle: "%s: Please sign on the yellow area",
    msgSigningTipsShort:
      "Please try to fill your signature in the area as much as possible",

    msgSuccessAgentSignAndSelect:
      "Agent has signed successfully! Please select the next step to proceed:",
    msgSuccessInvitationSent:
      'Invitation Email has been sent successfully! Click "End" to go back to the Property Stock Detail Page.',
    msgSuccessLinkGenerated:
      'Signing Link has been generated successfully! Client can sign the form through the following link shown in the text area below.\nClick "End" to go back to the Property Stock Detail Page.',
    msgSuccessClientSign:
      'Client has signed successfully! Click "End" to go back to the Property Stock Detail Page.',
    msgWarningSignByUrl:
      "Warning: This link cannot be retrieved again after leaving this page. You need to restart the signing flow from the beginning to generate a new link. \n\nAre you sure to leave this page?",
    msgSuccessClientContactVerfification:
      "Verification succeeded! Please input your identification document number to proceed:",
    msgSuccessClientSignByLink:
      "The Form has been successfully signed! Thank you!",
    msgInputEmailToReceiveCopy:
      "You may enter your email address below to receive a copy of the signed form if you wish to:",
    msgSuccessSentCopyToClient:
      "The signed form has been successfully sent to the email address you provided. Thank you!",
    msgNonHKID: "Non-HKID!",
    msgFormatForHKID: "Format for HKID: A123456(7)",
    msgSending: "Sending...",
    msgSuccessLinkCopied: "Link copied",
    msgClientAlreadySigned: "Document signed already, cannot sign again",

    msgWarnOverwrite:
      "Form signing invitation has already been sent to client, this action will update the form detail and make the sigining link sent expire, are you sure to continue?",
    msgLinkExpired: "This link has expired",
  },
  langFeatureTagMgt: {
    titlePageHeading: "Client App Homepage Featured Tags Management",

    captionTagNameEN: "Tag Name (EN)",
    captionTagNameTC: "Tag Name (TC)",
    captionTagNameSC: "Tag Name (SC)",
    captionCoverPhoto: "Cover Photo",

    msgConfirmRemoveTag: "Are you sure to remove?",
    msgWarnExceedVisibleTagsLimit:
      "There cannot be more than 4 visible featured tags",
    msgInputAtLeaseOneTagName: "Please input at least one tag name",
    msgInputAtLeaseOnePropertyFeature:
      "Please select at least one property feature",
  },
  langAgentRatingCommentMgt: {
    titlePageHeading: "Agent Rating and Comment Management",

    actionApprove: "Approve",
    actionReject: "Reject",
    captionAction: "Action",

    captionAgentName: "Agent Name",
    captionCustomerName: "Customer Name",
    captionReviewStatus: "Review Status",
    captionSubmitDate: "Submit Date",
    captionNoComment: "No comment",

    msgConfirmApprove: "Are you sure to approve this rating and comment?",
    msgConfirmReject: "Are you sure to reject this rating and comment?",
    msgCanOnlyHandlePendingAgentRatingComment:
      'Can only handle ratings and comments with status "Pending"',
    msgApprovedBy: "Approved by %s",
    msgRejectedBy: "Rejected by %s",
  },
  reviewStatusOptions: {
    PENDING: "Pending ",
    APPROVED: "Approved",
    REJECTED: "Rejected",
  },
  langDashboard: {
    welcomeTitle: "Dashboard",
    titleConsole: "Control Panel",
    periodFrom: "Period From",
    periodTo: "Period To",
    region: "Region",
    all: "ALL",
    hki: "Hong Kong Island",
    kln: "Kowloon",
    nt: "New Territories",
    district: "District(s)",
    districtTotal: "District Total",
    districtTotalHki: "HK Island Total",
    districtTotalKln: "Kowloon Total",
    districtTotalNt: "N.T. Total",
    ownTotal: "Own Total",
    amount: "Amount ($)",
    numberOfVisit: "Number of property visits",
    numberOfNewClients: "Number of new clients",
    numberOfClientUpdates: "Number of client updates",
    numberOfNewProperties: "Number of new properties",
    numberOfpropertyUpdates: "number of property updates",
    titleTopNAndOwnDistrict: "(Top %d & Own Districts)",
    titleTotalCommission: "Total Sales Commission",
    titleClientPropertyVisits: "Total Property visits",
    titleNewClients: "Total Number of New Clients",
    titleNewClientUpdates: "Total Number of New Client Updates",
    titleNewProperties: "Total Number of New Properties",
    titleNewPropertyUpdates: "Total Number of New Property Updates",
    optionNewClients: "Total Number of New Clients",
    optionClientUpdates: "Total Number of New Client Updates",
    optionNewProperties: "Total Number of New Properties",
    optionPropertyUpdates: "Total Number of New Property Updates",
    optionTotalCommission: "Total Sales Commission",
    optionClientPropertyVisits: "Total Property visits",
    dialogTitleHidden: "Hide",
    dialogTitleShown: "Show",

    captionLastModified: "Last Update",
  },
  langJobMonitor: {
    titleJobMonitor: "Job Monitor",
    captionLastStartTime: "Last Start Time",
    msgRunningLongerThanExpected: "(Last job was missed)",
    captionLastEndTime: "Last End Time",
    msgStartedExpectedly: "(Started expectedly)",
    msgJobMissed: "(Last job was missed)",

    actionFailed: "Failed",
    actionDone: "Done",
    actionRunning: "Running",
  },
  rowsCountPrefOptions: {
    "10": "10",
    "25": "25",
    "50": "50",
    "100": "100",
  },

  activityAuditingModuleOptions: {
    PROPERTY_STOCK: "Property Stock",
    CLIENT: "Client",
    BUILDING: "Building",
    TRANSACTION: "Transaction",
    ACCOUNT: "Account",
  },
  //basic Status Options
  statusOptions: {
    A: "Active",
    I: "Inactive",
  },
  // #endregion User Languages

  // #region Options
  facingOptions: {
    E: "East",
    S: "South",
    W: "West",
    N: "North",
    SE: "Southeast",
    NE: "Northeast",
    SW: "Southwest",
    NW: "Northwest",
  },
  viewOptions: {
    GARDEN: "Garden View",
    MOUNTAIN: "Mountain View",
    POLL: "Poll View",
    FULL_SEA: "Full Sea View",
    LESS_SEA: "Less Sea View",
    SEA: "Sea View",
    RACECOURSE: "Racecourse View",
  },
  decoOptions: {
    OLD: "Old",
    INCOMPLETE: "Incomplete",
    GENERAL: "General",
    LUXURY: "Luxury",
    PRETTY: "Pretty",
    TASTE: "Taste",
  },
  otherFeatureOptions: {
    PLATFORM: "Platform",
    PRIVATE_GARDEN: "Private Garden",
    PRIVATE_POOL: "Private Poll",
    CLUB_HOUSE: "Club House",
    GARAGE: "Garage",
    DOUBLE_UNIT: "Double Unit",
    COMPANY_TRANSFER: "Company Transfer",
  },
  districtOptions: {
    HQB: "Quarry Bay",
    HNP: "North Point",
  },
  // clientStatusOptions: {
  //   RENT: 'Rent', SALES: 'Sales', LEASED: 'Leased', SOLD: 'Sold', BOTH: 'Both',
  //   PENDING: 'Pending', UNKNOWN: 'Unknown',
  // },
  propertyStockStatusOptions: {
    RENT: "Rent",
    SALES: "Sales",
    LEASED: "Leased",
    SOLD: "Sold",
    BOTH: "Both",
    PENDING: "Pending",
    UNKNOWN: "Unknown",
  },
  transactionStatusOptions: {
    LEASED: "Leased",
    SOLD: "Sold",
    PENDING: "Pending",
  },
  salesCommissionTypeOptions: {
    SALE: "Sale",
    RENTAL: "Rent",
  },
  targetOptions: {
    V: "Buy",
    L: "Lease",
  },
  usageOptions: {
    R: "Resident",
    S: "Shop",
    O: "Office",
    I: "Industrial",
    M: "Miscellaneous",
    P: "Pre-Sale Unit",
    V: "Village House",
    G: "Government House",
  },
  levelOptions: {
    HIGH: "High",
    MIDDLE: "Middle",
    LOW: "Low",
  },
  vacantOptions: {
    VACANT: "Vacant",
    IN_USE: "In Use",
    AVAILABLE: "Available",
    BLANK: "Blank",
    WITH_CONTRACT: "With Contract",
  },
  symbolOptions: {
    CONFIRMOR: "Confirmor",
    TEST: "Test",
    SOLE_AGENT: "Sole Agent",
    SHELL_COMPANY: "Shell Company",
  },
  mgtFeeInclOptions: {
    A: "Air Conditioning Fee",
    M: "Management Fee",
    AM: "Air Conditioning & Management Fee",
    AMC: "Air Conditioning, Management & Interior Cleaning Fee",
  },
  clientTypeOptions: {
    B: "Buyer",
    L: "LandLord",
    S: "Seller",
    T: "Tenant",
  },
  formLocaleOptions: {
    zh_HK: "Traditional Chinese",
    en: "English",
  },
  clientFormSelections: {
    select: "Select",
    selectBuyerAndForm4:
      "Please specify the buyer client of the current transaction and the corresponding Form 4",
    selectTenantAndForm6:
      "Please specify the tenant client of the current transaction and the corresponding Form 6",
    linkBuyerInfo: "Link BuyerInfo",
    buyerInfoLinked: "BuyerInfo has been linked",
    tenantInfoLinked: "TenantInfo has been linked",
  },
  // #endregion Options
};

export default en;