import {
	Button as MuiButton, Card, CardActions, CardContent, CardHeader, Checkbox, Divider, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, InputAdornment, makeStyles, MenuItem, Popover, Radio, RadioGroup, TextareaAutosize, TextField, TextFieldProps,
	Tooltip, Typography, withStyles
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ErrorIcon from '@material-ui/icons/Error';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker } from '@material-ui/pickers';
import fileApi from 'api/fileApi';
import userApi from 'api/userApi';
import clsx from 'clsx';
import { AgreementClientDTO, PropertyAttachmentDTO, PropertyStockDTO, TenancyAgreementDTO, UserListItemDTO } from 'common/dto';
import { FormPresenter } from "common/hooks";
import { HandleFormHelperText, tooltipHoc } from 'common/ui';
import { checkIsHKID, genAddress, initFormKeysAndBooleanMap, isNonEmpty, limitNumberFieldLength, limitTextFieldLength, multiLang } from 'common/utils';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { IMaskInput } from 'react-imask';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'reducers';
import theme from 'theme';
import { format } from 'util';
import { TenancyPaAccessControls } from '../provisionalAgreement-access-controls';

const Button = tooltipHoc(MuiButton);

const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];
const regex = new RegExp(/(^\d*\.?\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/);
const NumberField = withStyles({
	root: {
		'& input': {
			textAlign: 'right',
			'-moz-appearance': 'textfield',
			'&::-webkit-outer-spin-button': {
				'-webkit-appearance': 'none',
			},
			'&::-webkit-inner-spin-button': {
				'-webkit-appearance': 'none',
			},
		},
	},
},
)((props: TextFieldProps) => {
	return <TextField type={"text"}{...props} inputProps={{
		...props.inputProps,
		min: 0,
		max: Math.max(),
		inputMode: 'decimal',
		pattern: "([^0-9]*)",
		step: 0.01
	}}

		onKeyDown={e => {
			// If the key pressed is not a number or a period, nothing is printed
			if (!e.key.match(regex)) {

				e.preventDefault();

			}
			// if (!e.key.match(regex) || (e.key === '.' && String(props.value).indexOf('.') > 0) || (e.key === '.' && String(props.value).length === 0) || e.keyCode === 229 || e.key === 'Unidentified') {

			//   e.preventDefault();

			// }

		}}

		onKeyPress={e => {
			// If the key pressed is not a number or a period, nothing is printed
			if (!e.key.match(regex)) {

				e.preventDefault();

			}
		}}

	/>;
});

const MaskedInput = (props: TextFieldProps) => {
	return (
		<IMaskInput
			{...props}
			mask={Number}
			unmask={true}
			thousandsSeparator=","
		/>
	);
}

const useStyles = makeStyles(() => ({
	root: {},
	fileItem: {
		'& .fileControl': {
			display: 'none',
		},
		'&:hover .fileControl': {
			display: 'inherit',
		},
	},
	partTitle: {
		fontWeight: 'bold',
	},
	textArea: {
		width: '100%',
		margin: 'dense',
		padding: '8px',
		fontSize: '14px'
	},
	table: {
		minWidth: 650,
	},
	textField: {
		width: '30%',
	},
	datePicker: {
		width: '30%',
	},
	column0: {
		width: '5%',
	},
	column20: {
		width: '20%'
	},
	column23: {
		width: '23%'
	},
	column30: {
		width: '30%'
	},
	column60: {
		width: '60%'
	},
	dollarField: {
		width: '30%',
		'& input': {
			textAlign: 'right'
		}
	},
	numberFields: {
		width: '30%',
	},
	subTableCell: {
		borderBottomColor: '#FFFFFF',
	},
	divider: {
		backgroundColor: '#1565c0',
	},
	cardHeaderBackground: {
		height: '35px',
		backgroundColor: '#fafafa',
	},
	cardHeaderElement: {
		display: 'inline-block',
	},
	cardHeaderTips: {
		fontSize: '12px',
		color: '#546e7a',
		paddingLeft: 8,
	},
	patchOutlineAutocomplete: {
		'& .MuiAutocomplete-inputRoot': {
			padding: 0,
			paddingLeft: 8,
		}
	},
}));

const UPLOAD_LIMITS = {
	ID_CARD_IMAGE: 1,
	SUPPLEMENTAL_DOCUMENT: 4
};

const errorColor = '#e53935';

export const tenancyInitial: Partial<TenancyAgreementDTO> = {
	landlords: [],
	tenants: [],
	supplementalDoc: [],
	feesInclusive: false,
	isCheque: false,
	tenantAgentType: 'I',
};

interface TenancyAgreementProps {
	className?: string;
	propertyStockId: string;
	form: FormPresenter<TenancyAgreementDTO>;

	accessControls: TenancyPaAccessControls;
}

const TenancyDialog = (props: TenancyAgreementProps) => {
	const { className, propertyStockId, form, accessControls,
		...rest } = props;
	const {
		canEditLandlordFields,
		canEditTenantFields,
		canEditTenancyPaAssignees,
	} = accessControls;
	const classes = useStyles();
	const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);
	const { lang, langTenancyAgreement, langPropertyStock, langSignDialog, formLocaleOptions, identityTypeOptions, agentAppointmentOptionsTenancy, agentTypeOptions, locale } = useSelector((state: IRootState) => state.locale);
	const dispatch = useDispatch();

	const agencyCompanyInfo = useSelector((state: IRootState) => state.systemSettings.Company.AGENCY_COMPANY_INFO);

	const bind = form.bind;

	const [agents, setAgents] = useState<UserListItemDTO[]>([]);
	const [agentListLoading, setAgentListLoading] = useState(false);

	const token: string = useSelector((state: IRootState) => state.login.token ?? '');
	const currentUid = useSelector((state: IRootState) => state.login.uid);

	// const disableLandlordFields = isTenantAgent && !isCompanyStock;

	const [keyBooleanMap, setKeyBooleanMap] = useState(initFormKeysAndBooleanMap(form));
	const fetchAgents = (keyword: string) => {
		if (keyword.length < 2) { return; }
		setAgentListLoading(true);
		userApi.getListForAutocomplete({ name: keyword, onlyHasCreateOwnClientPrivilege: true }, token).then(result => {
			if (result.data?.content) {
				setAgents(result.data!.content);
				setAgentListLoading(false);
				setAgentUsername(result.data!.content.filter(agent => agent.username != 'companyStockAgent').map((agent) => agent.username));
			}
		});
	}

	const [agentUsername, setAgentUsername] = useState(agents.filter(agent => agent.username != 'companyStockAgent').map((agent) => agent.username));
	const agentMap: { [name: string]: UserListItemDTO } = {};
	agents.forEach((agent) => {
		agentMap[agent.username] = agent;
	})

	const [keywordOfAgent, setKeywordOfAgent] = useState('')

	useEffect(() => {
		setKeyBooleanMap(initFormKeysAndBooleanMap(form));
	}, [Object.keys(form.values).length])

	useEffect(() => {
		const timer = setTimeout(() => {
			fetchAgents(keywordOfAgent);
		}, 1000);
		return () => clearTimeout(timer);
	}, [keywordOfAgent]);

	const addLandlord = () => {
		form.updateValues('landlords',
			[...form.values.landlords ?? [], { type: 'LANDLORD', name: '', identityType: '', identityNumber: '', address: '', identityFilename: null, idCardImage: [], agentUserId: Number(currentUid) } as AgreementClientDTO])
	}

	const addTenant = () => {
		form.updateValues('tenants',
			[...form.values.tenants ?? [], { type: 'TENANT', name: '', identityType: '', identityNumber: '', address: '', identityFilename: null, idCardImage: [], agentUserId: Number(currentUid) } as AgreementClientDTO])
	}

	const removeLandlord = (i: number) => {
		form.updateValues('landlords',
			form.values.landlords!.filter((_: any, _i: number) => i !== _i)
		);
	}

	const removeTenant = (i: number) => {
		form.updateValues('tenants',
			form.values.tenants!.filter((_: any, _i: number) => i !== _i)
		);
	}

	const setLandlordIdCardImage = (i: number, newList: PropertyAttachmentDTO[]) =>
		form.updateValues('landlords',
			form.values.landlords!.map((v: AgreementClientDTO, idx: number) => idx === i ?
				{ ...v, identityFilename: newList!.length < 1 ? null : v.identityFilename, idCardImage: newList } : v));

	const setTenantIdCardImage = (i: number, newList: PropertyAttachmentDTO[]) =>
		form.updateValues('tenants',
			form.values.tenants!.map((v: AgreementClientDTO, idx: number) => idx === i ?
				{ ...v, identityFilename: newList!.length < 1 ? null : v.identityFilename, idCardImage: newList } : v));


	const handleLandlordIdCardUpload = (type: string, i: number) => (ev: React.ChangeEvent<HTMLInputElement>) => {
		let files = Array.from(ev.target.files ?? []);
		const currentLength = form.values.landlords![i]!.idCardImage?.length ?? 0;
		const limit = UPLOAD_LIMITS[type as keyof typeof UPLOAD_LIMITS] ?? 0;
		let remainLength = limit ?? 0 - currentLength > 0 ? limit ?? 0 - currentLength : 0;
		if (remainLength === 0) return;

		Promise.all(files.slice(0, remainLength).map(file => {
			return fileApi.addFile(file, true, 'HKID').then(result => result.data as string)
		})).then(paths => {
			paths = paths.filter(path => form.values.landlords![i]!.idCardImage?.map(f => f.filename).indexOf(path) < 0);

			form.updateValues('landlords',
				form.values.landlords!.map((v: AgreementClientDTO, idx: number) => idx === i ?
					{ ...v, identityFilename: paths![0], idCardImage: [...v.idCardImage ?? [], ...paths.map(path => ({ filename: path, type, uploadDate: (new Date).toISOString(), remarks: '', agentUserId: Number(currentUid) }))] }
					: v
				)
			);
		});
	}

	const handleTenantIdCardUpload = (type: string, i: number) => (ev: React.ChangeEvent<HTMLInputElement>) => {
		let files = Array.from(ev.target.files ?? []);
		const currentLength = form.values.tenants![i]!.idCardImage?.length ?? 0;
		const limit = UPLOAD_LIMITS[type as keyof typeof UPLOAD_LIMITS] ?? 0;
		let remainLength = limit ?? 0 - currentLength > 0 ? limit ?? 0 - currentLength : 0;
		if (remainLength === 0) return;

		Promise.all(files.slice(0, remainLength).map(file => {
			return fileApi.addFile(file, true, 'HKID').then(result => result.data as string)
		})).then(paths => {
			paths = paths.filter(path => form.values.tenants![i]!.idCardImage?.map(f => f.filename).indexOf(path) < 0);

			form.updateValues('tenants',
				form.values.tenants!.map((v: AgreementClientDTO, idx: number) => idx === i ?
					{ ...v, identityFilename: paths![0], idCardImage: [...v.idCardImage ?? [], ...paths.map(path => ({ filename: path, type, uploadDate: (new Date).toISOString(), remarks: '', agentUserId: Number(currentUid) }))] }
					: v
				)
			);
		});
	}

	const propertyStockSettings = useSelector((state: IRootState) =>
		state.systemSettings?.PropertyStock) ?? {};

	const uploadPolicy = propertyStockSettings[`SUPPLEMENTAL_DOCUMENT_UPLOAD_POLICY`];

	//   const fileAccept = uploadPolicy.SUPPORT_FILE_TYPE?.split(',').map((str:string) => `.${str.trim().toUpperCase()}`);
	//   const uploadLimit = +(uploadPolicy.MAX_NUM_OF_FILE) ?? 0;
	//   const sizeLimit = (+(uploadPolicy.MAX_FILE_SIZE) * 1024 * 1024) ?? 0;

	const fileRef = React.createRef<HTMLInputElement>();
	// upload req popup
	// const [ reqPopupOpened, setReqPopupOpened ] = useState(false);
	const [reqPopupOpened, setReqPopupOpened] = useState<{ [key: string]: boolean }>({});
	const [popupAnchor, setPopAnchor] = useState<HTMLAnchorElement>();
	// const refPhotoUploadRequirement = React.createRef<HTMLAnchorElement>();
	const openPopup = (key: string) => (ev: React.MouseEvent<HTMLAnchorElement>) => {
		setPopAnchor(ev.currentTarget);
		setReqPopupOpened(prev => ({ ...prev, [key]: true }));
	};
	const closePopup = (key: string) => () => setReqPopupOpened(prev => ({ ...prev, [key]: false }));

	const invalidLandlordIndexArray = form.hasError('invalidLandlords')?.split('_') ?? [];
	const invalidTenantIndexArray = form.hasError('invalidTenants')?.split('_') ?? [];

	const hasErrorForClientInfo = (clientType: string, idx: number, value: string) => {
		const indexArray = clientType === 'LANDLORD' ? invalidLandlordIndexArray : invalidTenantIndexArray;
		if (!indexArray.includes(idx.toString())) {
			return false;
		} else {
			if (isNonEmpty(value)) {
				return false;
			}
			return true;
		}
	}

	const disablePrevDates = (startDates: any) => {
		const startSeconds = Date.parse(startDates);
		return (date: any) => {
			return Date.parse(date) < startSeconds;
		}
	}

	const propertyStockDto = useSelector((state: IRootState) => state.property.fetchedDetailsByPropertyStockId[props.propertyStockId]) ?? {} as Partial<PropertyStockDTO>;
	const { districtHkiOptions: districtHkiOptionsEn, districtNtOptions: districtNtOptionsEn, districtKltOptions: districtKltOptionsEn } = useSelector((state: IRootState) => state.locale._bundle['en']);
	const { districtHkiOptions: districtHkiOptionsZhHK, districtNtOptions: districtNtOptionsZhHK, districtKltOptions: districtKltOptionsZhHK } = useSelector((state: IRootState) => state.locale._bundle['zh_HK']);

	const districtEnOptions = {
		...districtHkiOptionsEn,
		...districtNtOptionsEn,
		...districtKltOptionsEn,
	};
	const districtZhHkOptions = {
		...districtHkiOptionsZhHK,
		...districtNtOptionsZhHK,
		...districtKltOptionsZhHK,
	};

	const companyDetailsLocale = (locale: string) => {
		return (locale === 'en') ? { name: agencyCompanyInfo['COMPANY_NAME_EN'], address: agencyCompanyInfo['ADDRESS_EN'], businessReg: agencyCompanyInfo['BUSINESS_REG_NO'], licence: agencyCompanyInfo['LICENCE_NO'] } : { name: agencyCompanyInfo['COMPANY_NAME_CN'], address: agencyCompanyInfo['ADDRESS_CN'], businessReg: agencyCompanyInfo['BUSINESS_REG_NO'], licence: agencyCompanyInfo['LICENCE_NO'] };
	};
	const propertyAddress = (locale: string) => {

		const address = genAddress(locale,
			propertyStockDto.unit?.join(',') ?? '',
			propertyStockDto.floor?.join(',') ?? '',
			propertyStockDto.block ?? '', propertyStockDto.blockEn ?? '',
			propertyStockDto.building ?? '', propertyStockDto.buildingEn ?? '',
			propertyStockDto.street ?? '', propertyStockDto.streetEn ?? '',
			(locale === 'en') ? districtEnOptions[propertyStockDto.district ?? ''] : districtZhHkOptions[propertyStockDto.district ?? '']);
		return address;
	};
	const updateCompanyCurrentLocaleDetails = (locale: string) => {
		let details = companyDetailsLocale(locale);
		form.updateValues('agentName', details.name);
		form.updateValues('agentBusinessRegNo', details.businessReg);
		form.updateValues('agentLicenceNo', details.licence);
		form.updateValues('agentAddress', details.address);
		form.updateValues('premises', propertyAddress(locale));
	};

	return (
		<Card
			{...rest}
			className={clsx(classes.root, className)}>
			<CardContent>

				<Grid container xs={12} spacing={1}>
					{/*---------------------------agreement language----------------------*/}
					<Grid
						item
						sm={2}
						md={2}
						xs={12}
					>
						<Typography>{langPropertyStock.captionAgreementLocale}</Typography>
						<TextField select
							fullWidth
							margin="dense"
							{...bind('agreementLocale')}
							required
							variant="outlined"
							onChange={(event) => {
								bind('agreementLocale').onChange(event);
								updateCompanyCurrentLocaleDetails(event.target.value);
							}}>
							{
								Object.keys(formLocaleOptions).map((opt) => <MenuItem value={opt}>{formLocaleOptions[opt]}</MenuItem>)
							}
						</TextField>
					</Grid>
					{/*---------------------------Seller Agent----------------------*/}
					<Grid
						item
						md={12}
						xs={12}
					>
						<Typography style={{ paddingTop: theme.spacing(1) }}>
							{langTenancyAgreement.titleLandlordAgent}
							<br />
							<FormHelperText>{langTenancyAgreement.internalAgentHelpText}</FormHelperText>
						</Typography>
					</Grid>

					<Grid
						item
						md={12}
						xs={12}
						spacing={1}
						container
					>
						<Grid item md={4} xs={12}>
							<TextField
								fullWidth
								label={langTenancyAgreement.agentType}
								margin="dense"
								required
								select
								variant="outlined"
								// {...bind('landlordAgentType')}
								value={form.values.landlordAgentType}
								error={!!form.hasError('landlordAgentType') || !!form.hasError('invalidTenantOrLandlordAgentType')}
								disabled={!canEditTenancyPaAssignees}
								helperText={form.hasError('landlordAgentType') || form.hasError('invalidTenantOrLandlordAgentType')}
								onChange={(e) => {
									if (form.values.landlordAgentType !== e.target.value) {
										form.setValues({
											...form.values,
											landlordAgentType: e.target.value,
											landlordAgentUserId: undefined,
											landlordAgentName: undefined,
											landlordAgentNameEn: undefined,
											landlordAgentNameZh: undefined,
											landlordAgentLicenseNumber: undefined,
											agentFor: e.target.value === 'I' ? 'BOTH' : 'LANDLORD_ONLY',
										});
									} else {
										form.updateValues('agentFor', e.target.value === 'I' ? 'BOTH' : 'LANDLORD_ONLY');
									}
								}}

							>
								{
									Object.keys(agentTypeOptions ?? {}).map(key => (
										<MenuItem key={key} value={key}>{agentTypeOptions[key]}</MenuItem>
									))
								}
							</TextField>

						</Grid>
						<Grid item md={4} xs={12}>
							{/* {isBuyerAgent ? 
								<TextField
									className={classes.patchOutlineAutocomplete}
									fullWidth
									label={langSalePurchaseAgreement.buyerAgentName}
									margin="dense"
									required
									variant="outlined"
									value={multiLang(locale, form.values.buyerAgentNameZh, form.values.buyerAgentNameEn)}
								/>
							: */}
							{form.values.landlordAgentType === 'I' ?
								<Autocomplete
									popupIcon={""}
									options={form.values.landlordAgentType === 'I' ? agentUsername : []}
									noOptionsText={''}
									loading={agentListLoading}
									loadingText={lang.msgLoading}
									renderOption={(option) => <div>
										<div><Typography >{`${agents.filter(agent => agent.username === option)?.[0]?.englishName ?? ''}${agents.filter(agent => agent.username === option)?.[0]?.chineseName ? '/' : ''}${agents.filter(agent => agent.username === option)?.[0]?.chineseName ?? ''}`}</Typography></div>
										<div><Typography variant="caption">{agents.filter(agent => agent.username === option)?.[0]?.mainContact}</Typography></div>
									</div>}
									filterOptions={(option) => option}
									disabled={!canEditTenancyPaAssignees}
									renderInput={(params: any) => (
										<TextField
											{...params}
											className={classes.patchOutlineAutocomplete}
											fullWidth
											label={langTenancyAgreement.landlordAgentName}
											margin="dense"
											required
											variant="outlined"
											onFocus={(ev) => {
												setAgentUsername([]);
												setKeywordOfAgent(ev.target.value);
											}}
											onChange={(ev) => {
												// fetchAgents(ev.target.value)
												setAgents([]);
												setAgentUsername([]);
												setKeywordOfAgent(ev.target.value);
												setAgentListLoading(true);
												if (ev.target.value.length < 2 || !isNonEmpty(ev.target.value)) {
													setAgentListLoading(false);
												}
											}
											}
											error={!!form.hasError('landlordAgentName')}
											helperText={form.hasError('landlordAgentName')}
										/>
									)}
									value={form.values.landlordAgentName ?? ''}

									onChange={(_: any, val: any) => {
										if (agentMap[val]) {
											const { id, chineseName, englishName, licenseNumber } = agentMap[val];
											form.setValues({
												...form.values,
												landlordAgentUserId: id,
												landlordAgentName: multiLang(locale, chineseName, englishName),
												landlordAgentNameEn: englishName,
												landlordAgentNameZh: chineseName,
												landlordAgentLicenseNumber: licenseNumber,
											});
										} else {
											form.setValues({
												...form.values,
												landlordAgentUserId: undefined,
												landlordAgentName: undefined,
												landlordAgentNameEn: undefined,
												landlordAgentNameZh: undefined,
												landlordAgentLicenseNumber: undefined,
											});
										}
									}}
									getOptionLabel={() => multiLang(locale, form.values.landlordAgentNameZh, form.values.landlordAgentNameEn) ?? ''}
								/>
								:
								<TextField
									fullWidth
									label={langTenancyAgreement.landlordAgentName}
									margin="dense"
									required
									variant="outlined"
									{...bind('landlordAgentName')}
									onChange={(e) => {
										limitTextFieldLength(e, 255, 'landlordAgentName', form, keyBooleanMap, setKeyBooleanMap);
									}}
									disabled={!canEditTenancyPaAssignees}
								// value={agent.name}
								// error={!!getAgentValidationMsg('SELLER', 'name', agent.name)}
								// helperText={getAgentValidationMsg('SELLER', 'name', agent.name)}
								// onChange={(e) => {
								//   const updatedAgent = { ...agent, name: e.target.value };
								//   form.updateValues('sellerAgents',
								//     form.values.sellerAgents?.map((a: any, i: number) => idx === i ? updatedAgent : a)
								//   );
								// }}
								/>
							}
							<FormHelperText>{keyBooleanMap.get('landlordAgentName') && form.values.landlordAgentType !== 'I' && isNonEmpty(form.values.landlordAgentName) ? lang.textOverLimit : ''}</FormHelperText>

							{/* } */}
						</Grid>
						<Grid item md={4} xs={12}>
							<TextField
								fullWidth
								label={langTenancyAgreement.agentLicenseNumber}
								margin="dense"
								required
								variant="outlined"
								{...bind('landlordAgentLicenseNumber')}
								onChange={(e) => {
									limitTextFieldLength(e, 255, 'landlordAgentLicenseNumber', form, keyBooleanMap, setKeyBooleanMap);
								}}
								disabled={!canEditTenancyPaAssignees}
							// value={agent.name}
							// error={!!getAgentValidationMsg('SELLER', 'name', agent.name)}
							// helperText={getAgentValidationMsg('SELLER', 'name', agent.name)}
							// onChange={(e) => {
							//   const updatedAgent = { ...agent, name: e.target.value };
							//   form.updateValues('landlordAgents',
							//     form.values.landlordAgents?.map((a: any, i: number) => idx === i ? updatedAgent : a)
							//   );
							// }}
							/>
							<FormHelperText>{keyBooleanMap.get('landlordAgentLicenseNumber') && isNonEmpty(form.values.landlordAgentLicenseNumber) ? lang.textOverLimit : ''}</FormHelperText>
						</Grid>

					</Grid>
					{/*---------------------------Tenant Agent----------------------*/}
					<Grid
						item
						md={12}
						xs={12}
					>
						<Typography style={{ paddingTop: theme.spacing(1) }}>
							{langTenancyAgreement.titleTenantAgent}
							<br />
							<FormHelperText>{langTenancyAgreement.internalAgentHelpText}</FormHelperText>
							<FormHelperText>{langTenancyAgreement.externalAgentHelpText}</FormHelperText>
						</Typography>
					</Grid>

					<Grid
						item
						md={12}
						xs={12}
						container
						spacing={1}
					>
						<Grid item md={4} xs={12}>
							<TextField
								fullWidth
								label={langTenancyAgreement.agentType}
								margin="dense"
								required
								select
								variant="outlined"
								// {...bind('tenantAgentType')}
								disabled={true}
								value={form.values.tenantAgentType}
								error={!!form.hasError('tenantAgentType') || !!form.hasError('invalidTenantOrLandlordAgentType')}
								helperText={form.hasError('tenantAgentType') || form.hasError('invalidTenantOrLandlordAgentType')}
								onChange={(e) => {
									form.setValues({
										...form.values,
										tenantAgentType: e.target.value,
										tenantAgentUserId: undefined,
										tenantAgentName: undefined,
										tenantAgentNameEn: undefined,
										tenantAgentNameZh: undefined,
										tenantAgentLicenseNumber: undefined,
									});
								}}

							>
								{
									Object.keys(agentTypeOptions ?? {}).map(key => (
										<MenuItem key={key} value={key}>{agentTypeOptions[key]}</MenuItem>
									))
								}
							</TextField>

						</Grid>
						<Grid item md={4} xs={12}>
							{/* {disableLandlordFields?
								<TextField
									className={classes.patchOutlineAutocomplete}
									fullWidth
									label={langTenancyAgreement.tenantAgentName}
									margin="dense"
									required
									variant="outlined"
									value={multiLang(locale, form.values.tenantAgentNameZh, form.values.tenantAgentNameEn)}
								/>
							: */}
							{form.values.tenantAgentType === 'I' ?
								<Autocomplete
									popupIcon={""}
									options={form.values.tenantAgentType === 'I' ? agentUsername : []}
									noOptionsText={''}
									loading={agentListLoading}
									loadingText={lang.msgLoading}
									renderOption={(option) => <div>
										<div><Typography >{`${agents.filter(agent => agent.username === option)?.[0]?.englishName ?? ''}${agents.filter(agent => agent.username === option)?.[0]?.chineseName ? '/' : ''}${agents.filter(agent => agent.username === option)?.[0]?.chineseName ?? ''}`}</Typography></div>
										<div><Typography variant="caption">{agents.filter(agent => agent.username === option)?.[0]?.mainContact}</Typography></div>
									</div>}
									filterOptions={(option) => option}
									disabled={!canEditTenancyPaAssignees}
									renderInput={(params: any) => (
										<TextField
											data-key={'field-tenancy-tenantAgentName'}
											{...params}
											className={classes.patchOutlineAutocomplete}
											fullWidth
											label={langTenancyAgreement.tenantAgentName}
											margin="dense"
											required
											variant="outlined"
											onFocus={(ev) => {
												setAgentUsername([]);
												setKeywordOfAgent(ev.target.value);
											}}
											onChange={(ev) => {
												// fetchAgents(ev.target.value)
												setAgents([]);
												setAgentUsername([]);
												setKeywordOfAgent(ev.target.value);
												setAgentListLoading(true);
												if (ev.target.value.length < 2 || !isNonEmpty(ev.target.value)) {
													setAgentListLoading(false);
												}
											}
											}
											error={!!form.hasError('tenantAgentName')}
											helperText={form.hasError('tenantAgentName')}
										/>
									)}

									value={form.values.tenantAgentName ?? ''}

									onChange={(_: any, val: any) => {
										if (agentMap[val]) {
											const { id, chineseName, englishName, licenseNumber } = agentMap[val];
											form.setValues({
												...form.values,
												tenantAgentUserId: id,
												tenantAgentName: multiLang(locale, chineseName, englishName),
												tenantAgentNameEn: englishName,
												tenantAgentNameZh: chineseName,
												tenantAgentLicenseNumber: licenseNumber,
											});

										} else {
											form.setValues({
												...form.values,
												tenantAgentUserId: undefined,
												tenantAgentName: undefined,
												tenantAgentNameEn: undefined,
												tenantAgentNameZh: undefined,
												tenantAgentLicenseNumber: undefined,
											});
										}
									}}
									getOptionLabel={() => multiLang(locale, form.values.tenantAgentNameZh, form.values.tenantAgentNameEn) ?? ''}
								/>
								:
								<TextField
									data-key={'field-tenancy-tenantAgentName'}
									fullWidth
									label={langTenancyAgreement.tenantAgentName}
									margin="dense"
									variant="outlined"
									required
									{...bind('tenantAgentName')}
									onChange={(e) => {
										limitTextFieldLength(e, 255, 'tenantAgentName', form, keyBooleanMap, setKeyBooleanMap);
									}}
									disabled={!canEditTenancyPaAssignees}
								// value={agent.name}
								// error={!!getAgentValidationMsg('SELLER', 'name', agent.name)}
								// helperText={getAgentValidationMsg('SELLER', 'name', agent.name)}
								// onChange={(e) => {
								//   const updatedAgent = { ...agent, name: e.target.value };
								//   form.updateValues('tenantAgents',
								//     form.values.tenantAgents?.map((a: any, i: number) => idx === i ? updatedAgent : a)
								//   );
								// }}
								/>
							}
							<FormHelperText>{keyBooleanMap.get('tenantAgentName') ? lang.textOverLimit : ''}</FormHelperText>
							{/* } */}
						</Grid>

						<Grid item md={4} xs={12}>
							<TextField
								fullWidth
								label={langTenancyAgreement.agentLicenseNumber}
								margin="dense"
								variant="outlined"
								required
								{...bind('tenantAgentLicenseNumber')}
								onChange={(e) => {
									limitTextFieldLength(e, 255, 'tenantAgentLicenseNumber', form, keyBooleanMap, setKeyBooleanMap);
								}}
								disabled={!canEditTenancyPaAssignees}
							// value={agent.name}
							// error={!!getAgentValidationMsg('SELLER', 'name', agent.name)}
							// helperText={getAgentValidationMsg('SELLER', 'name', agent.name)}
							// onChange={(e) => {
							//   const updatedAgent = { ...agent, name: e.target.value };
							//   form.updateValues('tenantAgents',
							//     form.values.tenantAgents?.map((a: any, i: number) => idx === i ? updatedAgent : a)
							//   );
							// }}
							/>
							<FormHelperText>{keyBooleanMap.get('tenantAgentLicenseNumber') ? lang.textOverLimit : ''}</FormHelperText>
						</Grid>

					</Grid>
				</Grid>

				<Grid item xs={12} spacing={2}>
					<br /><br />
				</Grid>

				<Grid container xs={12} spacing={1}>
					<Grid
						item
						md={12}
						xs={12}
					>
						<Typography style={{ fontSize: '16px' }} className={classes.partTitle} >{langTenancyAgreement.formName}</Typography>
					</Grid>

				</Grid>

				{/* ------------------------- Agreement Date ----------------------------- */}
				<Grid container xs={12} spacing={1}>
					<Grid
						item
						md={12}
						xs={12}
					>
						<TableContainer component={Paper}>
							<Table className={classes.table} size="small" aria-label="Agreement Date">
								<TableHead>
									<TableRow>
										{/* <TableCell className={classes.column0}>1.</TableCell> */}
										<TableCell colSpan={8} align="left">{langTenancyAgreement.titleAgreementDate}</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{/*----------- Date ---------- */}
									<TableRow >
										<TableCell component="th" scope="row"></TableCell>
										<TableCell>{langTenancyAgreement.date}</TableCell>
										<TableCell>
											<KeyboardDatePicker autoOk={true}
												data-key={'field-tenancy-agreementDate'}
												className={classes.datePicker}
												variant="inline"
												inputVariant="outlined"
												format={DISPLAY_DATE_FORMAT}
												margin="dense"
												error={!!form.hasError('agreementDate')}
												helperText={form.hasError('agreementDate')}
												value={form.values.agreementDate ? moment(form.values.agreementDate) : null}
												onChange={(value) => {
													form.setValues({
														...form.values,
														agreementDate: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined
													});
												}}
												KeyboardButtonProps={{
													'aria-label': 'change date',
												}}
											/>
											{/* {form.hasError('agreementDate') ? <FormHelperText data-key={'field-tenancy-agreementDate'} style={{color: form.hasError('agreementDate') ? errorColor : 'inherit'}}>{langTenancyAgreement.msgSelectDateOfObtainingInfo}</FormHelperText> : null}   */}

										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
				</Grid>
				{/* -------------------------- Involved Parties ----------------------*/}
				<Grid container xs={12} spacing={1}>
					<Grid
						item
						md={12}
						xs={12}
					>
						<Typography className={classes.partTitle}>{langTenancyAgreement.titleParties}</Typography>
					</Grid>
					{/* ------------------------- Landlord --------------------------- */}
					<Grid
						item
						md={12}
						xs={12}
					>
						<Card>
							<CardHeader className={classes.cardHeaderBackground}
								title={<div>
									<div className={classes.column0 + ' ' + classes.cardHeaderElement}>
										1.1</div><div className={classes.cardHeaderElement}>{langTenancyAgreement.titleLandlord}
										{canEditLandlordFields ? null : <div className={classes.cardHeaderElement + ' ' + classes.cardHeaderTips}>{
											format(langPropertyStock.msgSpecifiedRoleCanFillIn, langTenancyAgreement.titleLandlordAgent)
										}</div>}
									</div>
								</div>}
							/>
							<Divider />
							<CardContent>
								<Grid
									item
									md={12}
									xs={12}
								>
									<Typography>
										{langTenancyAgreement.landlord}
										{!canEditLandlordFields ? null :
											<Tooltip title={lang.actionAdd}>
												<IconButton color="primary" onClick={() => addLandlord()}>
													<AddIcon />
												</IconButton>
											</Tooltip>
										}
									</Typography>
									{form.hasError('landlords') ? <FormHelperText style={{ color: form.hasError('landlords') ? errorColor : 'inherit' }}>{form.hasError('landlords')}</FormHelperText> : null}
									{form.hasError('invalidLandlords') ? <FormHelperText style={{ color: form.hasError('invalidLandlords') ? errorColor : 'inherit' }}>{langTenancyAgreement.msgInputRequiredInfo}</FormHelperText> : null}
								</Grid>
								{
									form.values.landlords?.map((landlord: AgreementClientDTO, idx: number) => {
										return (
											<Grid item container md={12} xs={12} spacing={1}>
												{!canEditLandlordFields ? null :
													<Grid
														item
														md={1}
														xs={12}
													>
														<Typography style={{ display: 'flex', alignItems: 'center', flexGrow: 2, width: 200 }}>

															<IconButton key={idx} onClick={() => removeLandlord(idx)}>
																<DeleteIcon />
															</IconButton>

														</Typography>
													</Grid>
												}
												<Grid
													item
													md={2}
													xs={12}
												>
													<TextField
														fullWidth
														label={langTenancyAgreement.name}
														margin="dense"
														required
														variant="outlined"
														value={landlord.name}
														disabled={!canEditLandlordFields}
														error={hasErrorForClientInfo('LANDLORD', idx, landlord.name)}
														onChange={(e) => {
															if (e.target.value.length > 255) {
																e.preventDefault();
																const updatedLandlord = { ...landlord, name: e.target.value.substring(0, 255) };
																setKeyBooleanMap(keyBooleanMap.set('landlordsName' + idx, true));
																form.updateValues('landlords',
																	form.values.landlords!.map((v: AgreementClientDTO, i: number) => idx === i ? updatedLandlord : v)
																);

															} else {
																setKeyBooleanMap(keyBooleanMap.set('landlordsName' + idx, false));
																const updatedLandlord = { ...landlord, name: e.target.value };
																form.updateValues('landlords',
																	form.values.landlords!.map((v: AgreementClientDTO, i: number) => idx === i ? updatedLandlord : v)
																);
															}
														}}
													/>
													<HandleFormHelperText
														isError={keyBooleanMap.get('landlordsName' + idx)}
														errorMessage={lang.textOverLimit}
													/>
												</Grid>
												<Grid
													item
													md={2}
													xs={12}
												>
													<TextField
														fullWidth
														label={langTenancyAgreement.identityType}
														margin="dense"
														required
														select
														variant="outlined"
														disabled={!canEditLandlordFields}
														error={hasErrorForClientInfo('LANDLORD', idx, landlord.identityType)}
														value={landlord.identityType}
														onChange={(e) => {
															const updatedLandlord = { ...landlord, identityType: e.target.value };
															form.updateValues('landlords',
																form.values.landlords!.map((v: AgreementClientDTO, i: number) => idx === i ? updatedLandlord : v)
															);
														}}
													>
														{
															Object.keys(identityTypeOptions).map((key) =>
																<MenuItem key={key} value={key}>{identityTypeOptions[key]}</MenuItem>)
														}
													</TextField>
												</Grid>
												<Grid
													item
													md={2}
													xs={12}
												>
													<TextField
														fullWidth
														label={langTenancyAgreement.identityNumber}
														margin="dense"
														required
														variant="outlined"
														value={landlord.identityNumber}
														disabled={!canEditLandlordFields}
														error={hasErrorForClientInfo('LANDLORD', idx, landlord.identityNumber)}
														onChange={(e) => {
															if (e.target.value.length > 255) {
																e.preventDefault();
																const updatedLandlord = { ...landlord, identityNumber: e.target.value.substring(0, 255) };
																setKeyBooleanMap(keyBooleanMap.set('landlordsIdentityNo' + idx, true));
																form.updateValues('landlords',
																	form.values.landlords!.map((v: AgreementClientDTO, i: number) => idx === i ? updatedLandlord : v)
																);

															} else {
																setKeyBooleanMap(keyBooleanMap.set('landlordsIdentityNo' + idx, false));
																const updatedLandlord = { ...landlord, identityNumber: e.target.value };
																form.updateValues('landlords',
																	form.values.landlords!.map((v: AgreementClientDTO, i: number) => idx === i ? updatedLandlord : v)
																);
															}
														}}
														InputProps={{
															endAdornment: landlord.identityNumber && landlord.identityType === 'H' && !checkIsHKID(landlord.identityNumber ?? '') ?
																<InputAdornment position="end">
																	<Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{`${langSignDialog.msgNonHKID}`}</span>} aria-label='hot-pick-expiry'>
																		<IconButton
																			style={{ color: '#FFBF00' }}
																			aria-label="hkid-indicator"
																			edge="end"
																		>
																			<ErrorIcon />
																		</IconButton>
																	</Tooltip>
																</InputAdornment> : null
														}}
													/>
													<HandleFormHelperText
														isError={keyBooleanMap.get('landlordsIdentityNo' + idx)}
														errorMessage={lang.textOverLimit}
													/>
												</Grid>
												<Grid
													item
													md={5}
													xs={12}
												>
													<TextField
														fullWidth
														label={langTenancyAgreement.address}
														margin="dense"
														variant="outlined"
														value={landlord.address}
														disabled={!canEditLandlordFields}
														// error={hasErrorForClientInfo('LANDLORD', idx, landlord.address)}
														onChange={(e) => {
															if (e.target.value.length > 255) {
																e.preventDefault();
																const updatedLandlord = { ...landlord, address: e.target.value.substring(0, 255) };
																setKeyBooleanMap(keyBooleanMap.set('landlordsAddress' + idx, true));
																form.updateValues('landlords',
																	form.values.landlords!.map((v: AgreementClientDTO, i: number) => idx === i ? updatedLandlord : v)
																);

															} else {
																setKeyBooleanMap(keyBooleanMap.set('landlordsAddress' + idx, false));
																const updatedLandlord = { ...landlord, address: e.target.value };
																form.updateValues('landlords',
																	form.values.landlords!.map((v: AgreementClientDTO, i: number) => idx === i ? updatedLandlord : v)
																);
															}
														}}
													/>
													<HandleFormHelperText
														isError={keyBooleanMap.get('landlordsAddress' + idx)}
														errorMessage={lang.textOverLimit}
													/>
												</Grid>
												<Grid item md={12} xs={12}>
													<Typography>{langTenancyAgreement.idCardImage}</Typography>

													<div style={{ display: 'flex', alignItems: 'center' }}>
														<FormControl margin="dense">
															<input
																style={{ display: 'none' }}
																id={`input-file-landlord-id-card-${idx}`}
																type="file"
																ref={fileRef}
																onChange={(ev) => handleLandlordIdCardUpload('ID_CARD_IMAGE', idx)(ev)}
																disabled={landlord.idCardImage?.length >= UPLOAD_LIMITS.ID_CARD_IMAGE || !canEditLandlordFields}
															/>
															<label htmlFor={`input-file-landlord-id-card-${idx}`}>
																<Button disabled={landlord.idCardImage?.length >= UPLOAD_LIMITS.ID_CARD_IMAGE || !canEditLandlordFields} {...{ component: "span" } as any} variant="contained"
																	// TODO: tooltip={landlord.idCardImage?.length ?? 0 >= 4 ? langPropertyStock.msgPhotoLimit.replace('%d', UPLOAD_LIMITS.PHOTO.toString()) : undefined}
																	// TODO: disabled={landlord.idCardImage?.length ?? 0 >= 4}
																	color="primary">{lang.actionUpload} ({`${landlord.idCardImage?.length}/${UPLOAD_LIMITS.ID_CARD_IMAGE}`})</Button>
															</label>

														</FormControl>
														<Typography style={{ marginLeft: 8 }}>
															{/* TODO: {langPropertyStock.msgPhotoHint}*/} <a onClick={openPopup('ID_CARD_IMAGE')} href="javascript:">{/*TODO: langPropertyStock.msgUploadRequirement*/}</a>
														</Typography>
														<Popover
															open={reqPopupOpened.ID_CARD_IMAGE}
															anchorEl={popupAnchor}
															onClose={closePopup('ID_CARD_IMAGE')}
															anchorOrigin={{
																vertical: 'bottom',
																horizontal: 'center',
															}}
															transformOrigin={{
																vertical: 'top',
																horizontal: 'center',
															}}
														>
															{/* TODO: <Typography style={{ margin: 8 }}>{langPropertyStock.msgUploadRequirementPhoto}</Typography> */}
														</Popover>
													</div>

													<div style={{ marginTop: 8, width: '100%', height: 150, background: '#fafafa', borderRadius: 5, overflowX: 'scroll', overflowY: 'hidden' }}>
														<div style={{ width: 'auto', height: 150, display: 'flex', alignItems: 'center', }}>
															{landlord.idCardImage?.map(({ filename, agentUserId }) => agentUserId?.toString() === currentUid ? `${BASE_URL}/files/${filename}` : 'images/masked-id-image.png')
																.map((src, i) => <div
																	key={src} className={classes.fileItem}

																	draggable
																	onDragOver={(e) => e.preventDefault()}
																	onDragStart={(e) => e.dataTransfer.setData(`landlord-id-card-${idx}`, i.toString())}
																	onDrop={(e) => {
																		if (e.dataTransfer.getData(`landlord-id-card-${idx}`) === '') {
																			return;
																		}

																		const i1 = +e.dataTransfer.getData(`landlord-id-card-${idx}`);
																		const newList = [...landlord.idCardImage ?? []];
																		const tmp = newList[i];
																		newList[i] = newList[i1];
																		newList[i1] = tmp;
																		setLandlordIdCardImage(idx, newList);
																	}}
																	onClick={() => {
																		window.open(src, 'Download');
																	}}
																	style={{ display: 'flex', cursor: 'pointer', justifyContent: 'space-between', alignItems: 'start', marginLeft: 8, width: 260, minWidth: 260, height: 130, backgroundImage: `url("${src}")`, backgroundSize: 'cover', borderRadius: 5, boxShadow: theme.shadows[5] }}
																>
																	{/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'start' }}> */}
																	<div
																		onClick={(e) => {
																			e.stopPropagation();
																			const newList = [...landlord.idCardImage ?? []];
																			newList.unshift(newList.splice(i, 1)[0]);
																			setLandlordIdCardImage(idx, newList);
																		}}
																		style={{ userSelect: 'none', cursor: 'pointer', display: 'inline-block', background: 'rgba(0,0,0,0.8)', color: '#fff', padding: 4, margin: 4, borderRadius: 8, fontSize: theme.typography.caption.fontSize }}>
																		{/* {i === 0 ? langPropertyStock.captionCover : langPropertyStock.actionSetCover} */}
																	</div>

																	{/* <div style={{justifyContent: 'center', alignItems: 'center', margin: 'auto' }}>
																		<Button variant="contained" color="secondary" size="small" {...{component: "a", target: "_blank"}} href={src}>??</Button>
																	</div> */}

																	<div
																		onClick={(e) => {
																			if (!canEditLandlordFields) return;
																			e.stopPropagation();
																			const newList = [...landlord.idCardImage ?? []];
																			newList.splice(i, 1);
																			setLandlordIdCardImage(idx, newList);
																			// TODO: confirmDialog.confirm('???????', '??', '??').then((confirmed) => {
																			// 	if (confirmed) {
																			// 		setPhotos(newList);
																			// 	}
																			// });
																		}}
																		style={{ width: 23, height: 23, fontSize: 21, display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', fontWeight: 'bolder', userSelect: 'none', cursor: 'pointer', background: 'rgba(200,0,0,0.8)', color: '#fff', padding: 4, margin: 4, borderRadius: 800 }}>
																		<div>{'\u2013'}</div>
																	</div>
																	{/* </div> */}

																</div>)}
														</div>
													</div>
												</Grid>
											</Grid>
										)
									})
								}
							</CardContent>
						</Card>
					</Grid>
					{/*------------------- Tenant --------------------------*/}
					<Grid
						item
						md={12}
						xs={12}
					>
						<Card>
							<CardHeader className={classes.cardHeaderBackground}
								title={<div>
									<div className={classes.column0 + ' ' + classes.cardHeaderElement}>
										1.2</div><div className={classes.cardHeaderElement}>{langTenancyAgreement.titleTenant}
										{canEditTenantFields ? null : <div className={classes.cardHeaderElement + ' ' + classes.cardHeaderTips}>{
											format(langPropertyStock.msgSpecifiedRoleCanFillIn, langTenancyAgreement.titleTenantAgent)
										}</div>}
									</div>
								</div>}
							/>
							<Divider />
							<CardContent>
								<Grid
									item
									md={12}
									xs={12}
								>
									<Typography>
										{langTenancyAgreement.tenant}
										{!canEditTenantFields ? null : <Tooltip title={lang.actionAdd}>
											<IconButton color="primary" onClick={() => addTenant()}>
												<AddIcon />
											</IconButton>
										</Tooltip>}
									</Typography>
									{form.hasError('tenants') ? <FormHelperText data-key={'field-tenancy-tenants'} style={{ color: form.hasError('tenants') ? errorColor : 'inherit' }}>{form.hasError('tenants')}</FormHelperText> : null}
									{form.hasError('invalidTenants') ? <FormHelperText data-key={'field-tenancy-invalidTenants'} style={{ color: form.hasError('invalidTenants') ? errorColor : 'inherit' }}>{langTenancyAgreement.msgInputRequiredInfo}</FormHelperText> : null}
								</Grid>
								{
									form.values.tenants?.map((tenant: AgreementClientDTO, idx: number) => {
										return (
											<Grid item container md={12} xs={12} spacing={1}>
												<Grid
													item
													md={1}
													xs={12}
												>
													{!canEditTenantFields ? null : <Typography style={{ display: 'flex', alignItems: 'center', flexGrow: 2, width: 200 }}>
														<IconButton key={idx} onClick={() => removeTenant(idx)}>
															<DeleteIcon />
														</IconButton>
													</Typography>}
												</Grid>
												<Grid
													item
													md={2}
													xs={12}
												>
													<TextField
														fullWidth
														label={langTenancyAgreement.name}
														margin="dense"
														required
														variant="outlined"
														value={tenant.name}
														error={hasErrorForClientInfo('TENANT', idx, tenant.name)}
														disabled={!canEditTenantFields}
														onChange={(e) => {
															if (e.target.value.length > 255) {
																e.preventDefault();
																const updatedLandlord = { ...tenant, name: e.target.value.substring(0, 255) };
																setKeyBooleanMap(keyBooleanMap.set('tenantsName' + idx, true));
																form.updateValues('tenants',
																	form.values.tenants!.map((v: AgreementClientDTO, i: number) => idx === i ? updatedLandlord : v)
																);

															} else {
																setKeyBooleanMap(keyBooleanMap.set('tenantsName' + idx, false));
																const updatedTenant = { ...tenant, name: e.target.value };
																form.updateValues('tenants',
																	form.values.tenants!.map((v: AgreementClientDTO, i: number) => idx === i ? updatedTenant : v)
																);
															}
														}}
													/>
													<HandleFormHelperText
														isError={keyBooleanMap.get('tenantsName' + idx)}
														errorMessage={lang.textOverLimit}
													/>
												</Grid>
												<Grid
													item
													md={2}
													xs={12}
												>
													<TextField
														fullWidth
														label={langTenancyAgreement.identityType}
														margin="dense"
														required
														select
														variant="outlined"
														value={tenant.identityType}
														error={hasErrorForClientInfo('TENANT', idx, tenant.identityType)}
														disabled={!canEditTenantFields}
														onChange={(e) => {
															const updatedTenant = { ...tenant, identityType: e.target.value };
															form.updateValues('tenants',
																form.values.tenants!.map((v: AgreementClientDTO, i: number) => idx === i ? updatedTenant : v)
															);
														}}
													>
														{
															Object.keys(identityTypeOptions).map((key) =>
																<MenuItem key={key} value={key}>{identityTypeOptions[key]}</MenuItem>)
														}
													</TextField>
												</Grid>
												<Grid
													item
													md={2}
													xs={12}
												>
													<TextField
														fullWidth
														label={langTenancyAgreement.identityNumber}
														margin="dense"
														required
														variant="outlined"
														error={hasErrorForClientInfo('TENANT', idx, tenant.identityNumber)}
														disabled={!canEditTenantFields}
														value={tenant.identityNumber}
														onChange={(e) => {
															if (e.target.value.length > 255) {
																e.preventDefault();
																const updatedLandlord = { ...tenant, identityNumber: e.target.value.substring(0, 255) };
																setKeyBooleanMap(keyBooleanMap.set('tenantsIdentityNo' + idx, true));
																form.updateValues('tenants',
																	form.values.tenants!.map((v: AgreementClientDTO, i: number) => idx === i ? updatedLandlord : v)
																);

															} else {
																const updatedTenant = { ...tenant, identityNumber: e.target.value };
																setKeyBooleanMap(keyBooleanMap.set('tenantsIdentityNo' + idx, false));
																form.updateValues('tenants',
																	form.values.tenants!.map((v: AgreementClientDTO, i: number) => idx === i ? updatedTenant : v)
																);
															}
														}}
														InputProps={{
															endAdornment: tenant.identityNumber && tenant.identityType === 'H' && !checkIsHKID(tenant.identityNumber ?? '') ?
																<InputAdornment position="end">
																	<Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{`${langSignDialog.msgNonHKID}`}</span>} aria-label='hot-pick-expiry'>
																		<IconButton
																			style={{ color: '#FFBF00' }}
																			aria-label="hkid-indicator"
																			edge="end"
																		>
																			<ErrorIcon />
																		</IconButton>
																	</Tooltip>
																</InputAdornment> : null
														}}
													/>
													<HandleFormHelperText
														isError={keyBooleanMap.get('tenantsIdentityNo' + idx)}
														errorMessage={lang.textOverLimit}
													/>
												</Grid>
												<Grid
													item
													md={5}
													xs={12}
												>
													<TextField
														fullWidth
														label={langTenancyAgreement.address}
														margin="dense"
														variant="outlined"
														// error={hasErrorForClientInfo('TENANT', idx, tenant.address)}
														value={tenant.address}
														disabled={!canEditTenantFields}
														onChange={(e) => {
															if (e.target.value.length > 255) {
																e.preventDefault();
																const updatedLandlord = { ...tenant, address: e.target.value.substring(0, 255) };
																setKeyBooleanMap(keyBooleanMap.set('tenantsAddress' + idx, true));
																form.updateValues('tenants',
																	form.values.tenants!.map((v: AgreementClientDTO, i: number) => idx === i ? updatedLandlord : v)
																);

															} else {
																const updatedTenant = { ...tenant, address: e.target.value };
																setKeyBooleanMap(keyBooleanMap.set('tenantsAddress' + idx, false));
																form.updateValues('tenants',
																	form.values.tenants!.map((v: AgreementClientDTO, i: number) => idx === i ? updatedTenant : v)
																);
															}
														}}
													/>
													<HandleFormHelperText
														isError={keyBooleanMap.get('tenantsAddress' + idx)}
														errorMessage={lang.textOverLimit}
													/>
												</Grid>
												<Grid item md={12} xs={12}>
													<Typography>{langTenancyAgreement.idCardImage}</Typography>

													<div style={{ display: 'flex', alignItems: 'center' }}>
														<FormControl margin="dense">
															<input
																style={{ display: 'none' }}
																id={`input-file-tenant-id-card-${idx}`}
																type="file"
																ref={fileRef}
																onChange={(ev) => handleTenantIdCardUpload('ID_CARD_IMAGE', idx)(ev)}
																disabled={!canEditTenantFields || tenant.idCardImage?.length >= UPLOAD_LIMITS.ID_CARD_IMAGE}
															/>
															<label htmlFor={`input-file-tenant-id-card-${idx}`}>
																<Button {...{ component: "span" } as any} variant="contained"
																	// TODO: tooltip={tenant.idCardImage?.length ?? 0 >= 4 ? langPropertyStock.msgPhotoLimit.replace('%d', UPLOAD_LIMITS.PHOTO.toString()) : undefined}
																	disabled={!canEditTenantFields || tenant.idCardImage?.length >= UPLOAD_LIMITS.ID_CARD_IMAGE}
																	color="primary">{lang.actionUpload} ({`${tenant.idCardImage?.length}/${UPLOAD_LIMITS.ID_CARD_IMAGE}`})</Button>
															</label>

														</FormControl>
														<Typography style={{ marginLeft: 8 }}>
															{/* TODO: {langPropertyStock.msgPhotoHint}*/} <a onClick={openPopup('ID_CARD_IMAGE')} href="javascript:">{/*TODO: langPropertyStock.msgUploadRequirement*/}</a>
														</Typography>
														<Popover
															open={reqPopupOpened.ID_CARD_IMAGE}
															anchorEl={popupAnchor}
															onClose={closePopup('ID_CARD_IMAGE')}
															anchorOrigin={{
																vertical: 'bottom',
																horizontal: 'center',
															}}
															transformOrigin={{
																vertical: 'top',
																horizontal: 'center',
															}}
														>
															{/* TODO: <Typography style={{ margin: 8 }}>{langPropertyStock.msgUploadRequirementPhoto}</Typography> */}
														</Popover>
													</div>

													<div style={{ marginTop: 8, width: '100%', height: 150, background: '#fafafa', borderRadius: 5, overflowX: 'scroll', overflowY: 'hidden' }}>
														<div style={{ width: 'auto', height: 150, display: 'flex', alignItems: 'center', }}>
															{tenant.idCardImage?.map(({ filename, agentUserId }) => agentUserId?.toString() === currentUid ? `${BASE_URL}/files/${filename}` : 'images/masked-id-image.png')
																.map((src, i) => <div
																	key={src} className={classes.fileItem}

																	draggable
																	onDragOver={(e) => e.preventDefault()}
																	onDragStart={(e) => e.dataTransfer.setData(`tenant-id-card-${idx}`, i.toString())}
																	onDrop={(e) => {
																		if (e.dataTransfer.getData(`tenant-id-card-${idx}`) === '') {
																			return;
																		}

																		const i1 = +e.dataTransfer.getData(`tenant-id-card-${idx}`);
																		const newList = [...tenant.idCardImage ?? []];
																		const tmp = newList[i];
																		newList[i] = newList[i1];
																		newList[i1] = tmp;
																		setTenantIdCardImage(idx, newList);
																	}}
																	onClick={() => {
																		window.open(src, 'Download');
																	}}
																	style={{ display: 'flex', cursor: 'pointer', justifyContent: 'space-between', alignItems: 'start', marginLeft: 8, width: 260, minWidth: 260, height: 130, backgroundImage: `url("${src}")`, backgroundSize: 'cover', borderRadius: 5, boxShadow: theme.shadows[5] }}
																>
																	{/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'start' }}> */}
																	<div
																		onClick={(e) => {
																			e.stopPropagation();
																			const newList = [...tenant.idCardImage ?? []];
																			newList.unshift(newList.splice(i, 1)[0]);
																			setTenantIdCardImage(idx, newList);
																		}}
																		style={{ userSelect: 'none', cursor: 'pointer', display: 'inline-block', background: 'rgba(0,0,0,0.8)', color: '#fff', padding: 4, margin: 4, borderRadius: 8, fontSize: theme.typography.caption.fontSize }}>
																		{/* {i === 0 ? langPropertyStock.captionCover : langPropertyStock.actionSetCover} */}
																	</div>

																	{/* <div style={{justifyContent: 'center', alignItems: 'center', margin: 'auto' }}>
																		<Button variant="contained" color="secondary" size="small" {...{component: "a", target: "_blank"}} href={src}>??</Button>
																	</div> */}

																	<div
																		onClick={(e) => {
																			if (!canEditTenantFields) return;
																			e.stopPropagation();
																			const newList = [...tenant.idCardImage ?? []];
																			newList.splice(i, 1);
																			setTenantIdCardImage(idx, newList);
																			// TODO: confirmDialog.confirm('???????', '??', '??').then((confirmed) => {
																			// 	if (confirmed) {
																			// 		setPhotos(newList);
																			// 	}
																			// });
																		}}
																		style={{ width: 23, height: 23, fontSize: 21, display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', fontWeight: 'bolder', userSelect: 'none', cursor: 'pointer', background: 'rgba(200,0,0,0.8)', color: '#fff', padding: 4, margin: 4, borderRadius: 800 }}>
																		<div>{'\u2013'}</div>
																	</div>
																	{/* </div> */}

																</div>)}
														</div>
													</div>
												</Grid>
											</Grid>
										)
									})
								}
							</CardContent>
						</Card>
					</Grid>
					{/*---------------------------Agent----------------------*/}
					<Grid
						item
						md={12}
						xs={12}
					>
						<TableContainer component={Paper}>
							<Table className={classes.table} size="small" aria-label="B3 table">
								<TableHead>
									<TableRow>
										<TableCell className={classes.column0}>1.3</TableCell>
										<TableCell colSpan={8} align="left">{langTenancyAgreement.titleAgent}</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{/*----------- Appointment of Agent --------*/}
									<TableRow >
										<TableCell component="th" scope="row"></TableCell>
										<TableCell className={classes.column20}>{langTenancyAgreement.agentFor}</TableCell>
										<TableCell colSpan={2}>
											<TextField
												data-key={'field-tenancy-agentFor'}
												className={classes.textField}
												margin="dense"
												variant="outlined"
												disabled={true}
												value={agentAppointmentOptionsTenancy[form.values.agentFor!]}
											// {...form.values.agentFor = "BOTH"}
											/>
										</TableCell>
									</TableRow>
									{/*----------- name ---------- */}
									<TableRow >
										<TableCell component="th" scope="row"></TableCell>
										<TableCell className={classes.column20}>
											{langTenancyAgreement.agentName}
										</TableCell>
										<TableCell colSpan={2}>
											<TextField
												className={classes.textField}
												margin="dense"
												variant="outlined"
												disabled={true}
												{...bind('agentName')}
											/>
										</TableCell>
									</TableRow>
									{/*----------- business registration no. ---------- */}
									<TableRow >
										<TableCell component="th" scope="row"></TableCell>
										<TableCell>{langTenancyAgreement.agentBusinessRegNo}</TableCell>
										<TableCell colSpan={4}>
											<TextField
												className={classes.textField}
												margin="dense"
												disabled={true}
												variant="outlined"
												{...bind('agentBusinessRegNo')}
											/>
										</TableCell>
									</TableRow>

									{/*----------- licence no. ---------- */}
									<TableRow >
										<TableCell component="th" scope="row"></TableCell>
										<TableCell className={classes.column20}>
											{langTenancyAgreement.agentLicenceNo}
										</TableCell>
										<TableCell colSpan={2}>
											<TextField
												className={classes.textField}
												disabled={true}
												margin="dense"
												variant="outlined"
												{...bind('agentLicenceNo')}
											/>
										</TableCell>
									</TableRow>
									{/*---------- address ----------- */}
									<TableRow >
										<TableCell component="th" scope="row"></TableCell>
										<TableCell className={classes.column20}>
											{langTenancyAgreement.agentAddress}
										</TableCell>
										<TableCell colSpan={2}>
											<TextareaAutosize
												disabled={true}
												className={classes.textArea}
												rowsMin={2}
												rowsMax={2}
												{...bind('agentAddress')}
											/>
											{form.hasError('agentAddress') ? <FormHelperText style={{ color: form.hasError('agentAddress') ? errorColor : 'inherit' }}>{form.hasError('agentAddress')}</FormHelperText> : null}
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>

					<Grid item xs={12}>
						<Divider />
					</Grid>

					<Grid container xs={12} spacing={1}>
						<Grid
							item
							md={12}
							xs={12}
						>
							<Typography className={classes.partTitle}>{langTenancyAgreement.titleStatements}</Typography>
						</Grid>
						{/*-----------------premises--------------*/}
						<Grid
							item
							md={12}
							xs={12}
						>
							<TableContainer component={Paper}>
								<Table className={classes.table} size="small" aria-label="B3 table">
									<TableHead>
										<TableRow>
											<TableCell className={classes.column0}>2.</TableCell>
											<TableCell colSpan={8} align="left">{langTenancyAgreement.titlePremises}</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow>
											<TableCell component="th" scope="row"></TableCell>
											{/* <TableCell className={classes.column20}>
												{langTenancyAgreement.agentAddress}
											</TableCell> */}
											<TableCell colSpan={2}>
												<TextareaAutosize
													className={classes.textArea}
													rowsMin={2}
													rowsMax={2}
													{...bind('premises')}
													onChange={(e) => {
														limitTextFieldLength(e, 255, 'premises', form, keyBooleanMap, setKeyBooleanMap);
													}}
												/>
												{form.hasError('premises') ? <FormHelperText style={{ color: form.hasError('premises') ? errorColor : 'inherit' }}>{form.hasError('premises')}</FormHelperText> : null}
												<FormHelperText>{keyBooleanMap.get('premises') ? lang.textOverLimit : ''}</FormHelperText>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>

						{/*-------------monthly rental-------------*/}
						<Grid
							item
							md={12}
							xs={12}
						>
							<TableContainer component={Paper}>
								<Table className={classes.table} size="small" aria-label="B3 table">
									<TableHead>
										<TableRow>
											<TableCell className={classes.column0}>3.</TableCell>
											<TableCell colSpan={8} align="left">{langTenancyAgreement.titleMonthlyRental}</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{/*-------------rental----------*/}
										<TableRow>
											<TableCell data-key={'field-tenancy-monthlyRental'} component="th" scope="row"></TableCell>
											{/* <TableCell className={classes.column20}>
												{langTenancyAgreement.rental}
											</TableCell> */}
											<TableCell data-key={'field-tenancy-monthlyRental'} colSpan={2}>
												<NumberField
													className={classes.dollarField}
													margin="dense"
													variant="outlined"
													InputProps={{
														startAdornment: <InputAdornment position="start">$</InputAdornment>,
														// endAdornment: <InputAdornment position="start">{locale === 'en' ? lang.u1M : lang.u10k}</InputAdornment>,
													}}
													{...bind('monthlyRental')}
													onChange={(e) => {
														limitNumberFieldLength(e, 'monthlyRental', form);
													}}
												/>
												{/* <TextField
													data-key={'field-tenancy-monthlyRental'}
													className={classes.dollarField}
													margin="dense"
													variant="outlined"
													error={!!form.hasError('monthlyRental')}
													helperText={form.hasError('monthlyRental')}
													InputProps={{
														startAdornment: <InputAdornment position="start">$</InputAdornment>,
														inputComponent: MaskedInput as any,
														inputProps: {
															value: bind('monthlyRental').value?.toString(),
															onAccept: (value: any) => {
																bind('monthlyRental').onChange({ target: { value: value ? Number(value) : value } });
															}
														}
													}}
												/>*/}
											</TableCell>
										</TableRow>
										{/*----------- fees inclusive ---------- */}
										<TableRow >
											<TableCell component="th" scope="row"></TableCell>
											<TableCell colSpan={2}>
												<Checkbox
													checked={form.values.feesInclusive ?? false}
													onChange={() => {
														form.updateValues('feesInclusive', !form.values.feesInclusive);
													}}
												/>
												{langTenancyAgreement.inclusive}
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
						{/*-------------Deposit-------------*/}
						<Grid
							item
							md={12}
							xs={12}
						>
							<TableContainer component={Paper}>
								<Table className={classes.table} size="small" aria-label="B3 table">
									<TableHead>
										<TableRow>
											<TableCell className={classes.column0}>4.</TableCell>
											<TableCell colSpan={8} align="left">{langTenancyAgreement.titleDeposit}</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{/*-------------deposit----------*/}
										<TableRow>
											<TableCell component="th" scope="row"></TableCell>
											{/* <TableCell className={classes.column20}>
												{langTenancyAgreement.rental}
											</TableCell> */}
											<TableCell colSpan={2}>
												<NumberField
													className={classes.dollarField}
													margin="dense"
													variant="outlined"
													InputProps={{
														startAdornment: <InputAdornment position="start">$</InputAdornment>,
														// endAdornment: <InputAdornment position="start">{locale === 'en' ? lang.u1M : lang.u10k}</InputAdornment>,
													}}
													{...bind('deposit')}
													onChange={(e) => {
														limitNumberFieldLength(e, 'deposit', form);
													}}
												/>
												{/* <TextField
													data-key={'field-tenancy-deposit'}
													className={classes.dollarField}
													margin="dense"
													variant="outlined"
													error={!!form.hasError('deposit')}
													helperText={form.hasError('deposit')}
													InputProps={{
														startAdornment: <InputAdornment position="start">$</InputAdornment>,
														inputComponent: MaskedInput as any,
														inputProps: {
															value: bind('deposit').value?.toString(),
															onAccept: (value: any) => {
																bind('deposit').onChange({ target: { value: value ? Number(value) : value } });
															}
														}
													}}
												/>*/}
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
						{/*-------------initial deposit-------------*/}
						<Grid
							item
							md={12}
							xs={12}
						>
							<TableContainer component={Paper}>
								<Table className={classes.table} size="small" aria-label="B3 table">
									<TableHead>
										<TableRow>
											<TableCell className={classes.column0}>5.</TableCell>
											<TableCell colSpan={8} align="left">{langTenancyAgreement.titleInitialDeposit}</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{/*-------------initial deposit----------*/}
										<TableRow>
											<TableCell component="th" scope="row"></TableCell>
											{/* <TableCell className={classes.column20}>
												{langTenancyAgreement.rental}
											</TableCell> */}
											<TableCell colSpan={2}>
												<NumberField
													className={classes.dollarField}
													margin="dense"
													variant="outlined"
													InputProps={{
														startAdornment: <InputAdornment position="start">$</InputAdornment>,
														// endAdornment: <InputAdornment position="start">{locale === 'en' ? lang.u1M : lang.u10k}</InputAdornment>,
													}}
													{...bind('initialDeposit')}
													onChange={(e) => {
														limitNumberFieldLength(e, 'initialDeposit', form);
													}}
												/>
												{/* <TextField
													data-key={'field-tenancy-initialDeposit'}
													className={classes.dollarField}
													margin="dense"
													variant="outlined"
													error={!!form.hasError('initialDeposit')}
													helperText={form.hasError('initialDeposit')}
													InputProps={{
														startAdornment: <InputAdornment position="start">$</InputAdornment>,
														inputComponent: MaskedInput as any,
														inputProps: {
															value: bind('initialDeposit').value?.toString(),
															onAccept: (value: any) => {
																bind('initialDeposit').onChange({ target: { value: value ? Number(value) : value } });
															}
														}
													}}
												/>*/}
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
						{/*----------------tenancy---------------*/}
						<Grid
							item
							md={12}
							xs={12}
						>
							<TableContainer component={Paper}>
								<Table className={classes.table} size="small" aria-label="B3 table">
									<TableHead>
										<TableRow>
											<TableCell className={classes.column0}>6.</TableCell>
											<TableCell colSpan={8} align="left">{langTenancyAgreement.titleTenancy}</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{/*----------- tenancy period ---------- */}
										<TableRow >
											<TableCell component="th" scope="row"></TableCell>
											{/* <TableCell className={classes.column20} >{langTenancyAgreement.tenancy}</TableCell> */}
											<TableCell>
												<KeyboardDatePicker data-key={'field-tenancy-tenancyFrom'} autoOk={true}
													className={classes.datePicker}
													variant="inline"
													inputVariant="outlined"
													format={DISPLAY_DATE_FORMAT}
													margin="dense"
													label={langTenancyAgreement.from}
													error={!!form.hasError('tenancyFrom')}
													helperText={form.hasError('tenancyFrom')}
													value={form.values.tenancyFrom ? moment(form.values.tenancyFrom) : null}
													onChange={(value) => {
														form.setValues({
															...form.values,
															tenancyFrom: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined
														});
														if (value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString()?.localeCompare(form.values.tenancyTo ?? "") === 1) {
															form.updateValues('tenancyTo', "");
														}
													}}
													KeyboardButtonProps={{
														'aria-label': 'change date',
													}}
												/>
												{' '}
												<KeyboardDatePicker data-key={'field-tenancy-tenancyTo'} autoOk={true}
													className={classes.datePicker}
													variant="inline"
													inputVariant="outlined"
													format={DISPLAY_DATE_FORMAT}
													margin="dense"
													shouldDisableDate={disablePrevDates(form.values.tenancyFrom)}
													label={langTenancyAgreement.to}
													error={!!form.hasError('tenancyTo')}
													helperText={form.hasError('tenancyTo')}
													value={form.values.tenancyTo ? moment(form.values.tenancyTo) : null}
													onChange={(value) => {
														form.setValues({
															...form.values,
															tenancyTo: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined
														});
														if (value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString()?.localeCompare(form.values.tenancyFrom ?? "") === -1) {
															form.updateValues('tenancyFrom', "");
														}
													}}
													KeyboardButtonProps={{
														'aria-label': 'change date',
													}}
												/>
												{/* <div>
														<div style={{display: 'inline-block', width:'30%'}}>
															{form3.hasError('validityPeriodStart') ? <FormHelperText style={{color: form3.hasError('validityPeriodStart') ? errorColor : 'inherit'}}>{langForm3.msgInputValidityPeriodStart}</FormHelperText> : null}
														</div>
														<div style={{display: 'inline-block', width:'30%'}}>
															{form3.hasError('validityPeriodEnd') ? <FormHelperText style={{color: form3.hasError('validityPeriodEnd') ? errorColor : 'inherit'}}>{langForm3.msgInputvalidityPeriodEnd}</FormHelperText> : null}
														</div>
													</div> */}
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>

						{/*---------------------------break clause----------------------*/}
						<Grid
							item
							md={12}
							xs={12}
						>
							<TableContainer component={Paper}>
								<Table className={classes.table} size="small" aria-label="B3 table">
									<TableHead>
										<TableRow>
											<TableCell className={classes.column0}>7.</TableCell>
											<TableCell colSpan={8} align="left">{langTenancyAgreement.titleBreakClause}</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{/*----------- month in advance ---------- */}
										<TableRow >
											<TableCell component="th" scope="row"></TableCell>
											<TableCell className={classes.column30}>
												{langTenancyAgreement.monthInAdvance}
											</TableCell>
											<TableCell colSpan={2}>
												<NumberField
													className={classes.textField}
													margin="dense"
													variant="outlined"
													{...bind('breakClauseMonthInAdvance')}
													onChange={(e) => {
														limitNumberFieldLength(e, 'breakClauseMonthInAdvance', form);
													}}
												/>
											</TableCell>
										</TableRow>

										{/*----------- rent in lieu ---------- */}
										<TableRow >
											<TableCell component="th" scope="row"></TableCell>
											<TableCell className={classes.column30}>
												{langTenancyAgreement.rentInLieu}
											</TableCell>
											<TableCell colSpan={2}>
												<NumberField
													className={classes.textField}
													margin="dense"
													variant="outlined"
													{...bind('breakClauseRentInLieu')}
													onChange={(e) => {
														limitNumberFieldLength(e, 'breakClauseRentInLieu', form);
													}}
												/>
											</TableCell>
										</TableRow>

										{/*----------- rent for at least ---------- */}
										<TableRow >
											<TableCell component="th" scope="row"></TableCell>
											<TableCell className={classes.column30}>
												{langTenancyAgreement.rentForAtleast}
											</TableCell>
											<TableCell colSpan={2}>
												<NumberField
													className={classes.textField}
													margin="dense"
													variant="outlined"
													{...bind('breakClauseRentForAtLeast')}
													onChange={(e) => {
														limitNumberFieldLength(e, 'breakClauseRentForAtLeast', form);
													}}
												/>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>

						{/* ------------------------- Vacant Possession Delivery ----------------------------- */}
						<Grid
							item
							md={12}
							xs={12}
						>
							<TableContainer component={Paper}>
								<Table className={classes.table} size="small" aria-label="A1 table">
									<TableHead>
										<TableRow>
											<TableCell className={classes.column0}>8.</TableCell>
											<TableCell colSpan={8} align="left">{langTenancyAgreement.titleVacantPossessionDelivery}</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{/*----------- Date ---------- */}
										<TableRow >
											<TableCell component="th" scope="row"></TableCell>
											<TableCell className={classes.column30}>{langTenancyAgreement.titleDeliveryBefore}</TableCell>
											<TableCell>
												<KeyboardDatePicker data-key={'field-tenancy-vacantPossessionDeliveryDate'} autoOk={true}
													className={classes.datePicker}
													variant="inline"
													inputVariant="outlined"
													format={DISPLAY_DATE_FORMAT}
													margin="dense"
													error={!!form.hasError('vacantPossessionDeliveryDate')}
													helperText={form.hasError('vacantPossessionDeliveryDate')}
													value={form.values.vacantPossessionDeliveryDate ? moment(form.values.vacantPossessionDeliveryDate) : null}
													onChange={(value) => {
														form.setValues({
															...form.values,
															vacantPossessionDeliveryDate: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined
														});
													}}
													KeyboardButtonProps={{
														'aria-label': 'change date',
													}}
												/>
												{/* {form.hasError('agreementDate') ? <FormHelperText style={{color: form.hasError('agreementDate') ? errorColor : 'inherit'}}>{langTenancyAgreement.msgSelectDateOfObtainingInfo}</FormHelperText> : null}   */}

											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>

						{/* ------------------------- premises usage ----------------------------- */}
						<Grid
							item
							md={12}
							xs={12}
						>
							<TableContainer component={Paper}>
								<Table className={classes.table} size="small" aria-label="B3 table">
									<TableHead>
										<TableRow>
											<TableCell className={classes.column0}>9.</TableCell>
											<TableCell colSpan={8} align="left">{langTenancyAgreement.titlePremisesUsage}</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{/*-------------deposit----------*/}
										<TableRow>
											<TableCell component="th" scope="row"></TableCell>
											{/* <TableCell className={classes.column20}>
												{langTenancyAgreement.rental}
											</TableCell> */}
											<TableCell colSpan={2}>
												<TextareaAutosize
													data-key={'field-tenancy-premisesUsage'}
													className={classes.textArea}
													rowsMin={2}
													rowsMax={2}
													{...bind('premisesUsage')}
													onChange={(e) => {
														limitTextFieldLength(e, 255, 'premisesUsage', form, keyBooleanMap, setKeyBooleanMap);
													}}
												/>
												{form.hasError('premisesUsage') ? <FormHelperText style={{ color: form.hasError('premisesUsage') ? errorColor : 'inherit' }}>{form.hasError('premisesUsage')}</FormHelperText> : null}
												<FormHelperText>{keyBooleanMap.get('premisesUsage') ? lang.textOverLimit : ''}</FormHelperText>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>

						{/* ------------------------- signing ----------------------------- */}
						<Grid
							item
							md={12}
							xs={12}
						>
							<TableContainer component={Paper}>
								<Table className={classes.table} size="small" aria-label="A1 table">
									<TableHead>
										<TableRow>
											<TableCell className={classes.column0}>12.</TableCell>
											<TableCell colSpan={2} align="left">{langTenancyAgreement.titleSigning}</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{/*----------- sign date ---------- */}
										<TableRow >
											<TableCell component="th" scope="row"></TableCell>
											<TableCell className={classes.column20}>{langTenancyAgreement.formalAgreementSignDeadline}</TableCell>
											<TableCell colSpan={2}>
												<KeyboardDatePicker data-key={'field-tenancy-formalAgreementSignDeadline'} autoOk={true}
													className={classes.datePicker}
													variant="inline"
													inputVariant="outlined"
													format={DISPLAY_DATE_FORMAT}
													margin="dense"
													error={!!form.hasError('formalAgreementSignDeadline')}
													helperText={form.hasError('formalAgreementSignDeadline')}
													value={form.values.formalAgreementSignDeadline ? moment(form.values.formalAgreementSignDeadline) : null}
													onChange={(value) => {
														form.setValues({
															...form.values,
															formalAgreementSignDeadline: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined
														});
													}}
													KeyboardButtonProps={{
														'aria-label': 'change date',
													}}
												/>
												{/* {form.hasError('agreementDate') ? <FormHelperText style={{color: form.hasError('agreementDate') ? errorColor : 'inherit'}}>{langTenancyAgreement.msgSelectDateOfObtainingInfo}</FormHelperText> : null}   */}

											</TableCell>
										</TableRow>
										{/*----------- sign at ---------- */}
										<TableRow >
											<TableCell component="th" scope="row"></TableCell>
											<TableCell className={classes.column20}>{langTenancyAgreement.signAt}</TableCell>
											<TableCell className={classes.column23}>
												<TextField
													data-key={'field-tenancy-signAt'}
													margin="dense"
													// required
													fullWidth
													variant="outlined"
													{...bind('signAt')}
													onChange={(e) => {
														limitTextFieldLength(e, 255, 'signAt', form, keyBooleanMap, setKeyBooleanMap);
													}}
													error={!!form.hasError('signAt')}
													helperText={form.hasError('signAt')}
												/>
												<FormHelperText>{keyBooleanMap.get('signAt') ? lang.textOverLimit : ''}</FormHelperText>
											</TableCell>
											<TableCell>{langTenancyAgreement.solicitorOffice}</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>

						{/*-------------agent commission-------------*/}
						<Grid
							item
							md={12}
							xs={12}
						>
							<TableContainer component={Paper}>
								<Table className={classes.table} size="small" aria-label="B3 table">
									<TableHead>
										<TableRow>
											<TableCell className={classes.column0}>16.</TableCell>
											<TableCell colSpan={8} align="left">{langTenancyAgreement.titleAgentCommission}</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{/*-------------landlord commission----------*/}
										<TableRow>
											<TableCell component="th" scope="row"></TableCell>
											<TableCell className={classes.column20}>
												{langTenancyAgreement.landlordCommission}
											</TableCell>
											<TableCell colSpan={2}>
												<NumberField
													className={classes.dollarField}
													margin="dense"
													variant="outlined"
													InputProps={{
														startAdornment: <InputAdornment position="start">$</InputAdornment>,
													}}
													{...bind('landlordCommission')}
													onChange={(e) => {
														limitNumberFieldLength(e, 'landlordCommission', form);
													}}
													error={!!(bind('landlordCommission') as any).error}
													helperText={(bind('landlordCommission') as any).helperText}
												/>
												{/* <TextField
													className={classes.dollarField}
													margin="dense"
													variant="outlined"
													error={!!(bind('landlordCommission') as any).error}
													helperText={(bind('landlordCommission') as any).helperText}
													InputProps={{
														startAdornment: <InputAdornment position="start">$</InputAdornment>,
														inputComponent: MaskedInput as any,
														inputProps: {
															value: bind('landlordCommission').value?.toString(),
															onAccept: (value: any) => {
																bind('landlordCommission').onChange({ target: { value: value ? Number(value) : value } });
															}
														}
													}}
												/>*/}
											</TableCell>
										</TableRow>
										{/*-------------tenant commission----------*/}
										<TableRow>
											<TableCell component="th" scope="row"></TableCell>
											<TableCell className={classes.column20}>
												{langTenancyAgreement.tenantCommission}
											</TableCell>
											<TableCell colSpan={2}>
												<NumberField
													className={classes.dollarField}
													margin="dense"
													variant="outlined"
													InputProps={{
														startAdornment: <InputAdornment position="start">$</InputAdornment>,
														// endAdornment: <InputAdornment position="start">{locale === 'en' ? lang.u1M : lang.u10k}</InputAdornment>,
													}}
													{...bind('tenantCommission')}
													onChange={(e) => {
														limitNumberFieldLength(e, 'tenantCommission', form);
													}}
													error={!!form.hasError('tenantCommission')}
													helperText={form.hasError('tenantCommission')}
												/>
												{/* <TextField
													data-key={'field-tenancy-tenantCommission'}
													className={classes.dollarField}
													margin="dense"
													variant="outlined"
													error={!!form.hasError('tenantCommission')}
													helperText={form.hasError('tenantCommission')}
													InputProps={{
														startAdornment: <InputAdornment position="start">$</InputAdornment>,
														inputComponent: MaskedInput as any,
														inputProps: {
															value: bind('tenantCommission').value?.toString(),
															onAccept: (value: any) => {
																bind('tenantCommission').onChange({ target: { value: value ? Number(value) : value } });
															}
														}
													}}
												/>*/}
											</TableCell>
										</TableRow>
										{/*-------------commission deadline date----------*/}
										<TableRow>
											<TableCell component="th" scope="row"></TableCell>
											<TableCell className={classes.column20}>
												{langTenancyAgreement.commissionPaymentDeadline}
											</TableCell>
											<TableCell colSpan={2}>
												<KeyboardDatePicker data-key={'field-tenancy-commissionPaymentDeadline'} autoOk={true}
													className={classes.datePicker}
													variant="inline"
													inputVariant="outlined"
													format={DISPLAY_DATE_FORMAT}
													margin="dense"
													error={!!form.hasError('commissionPaymentDeadline')}
													helperText={form.hasError('commissionPaymentDeadline')}
													value={form.values.commissionPaymentDeadline ? moment(form.values.commissionPaymentDeadline) : null}
													onChange={(value) => {
														form.setValues({
															...form.values,
															commissionPaymentDeadline: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined
														});
													}}
													KeyboardButtonProps={{
														'aria-label': 'change date',
													}}
												/>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
						{/* -----------compensation to agent ------------- */}
						<Grid
							item
							md={12}
							xs={12}
						>
							<TableContainer component={Paper}>
								<Table className={classes.table} size="small" aria-label="A1 table">
									<TableHead>
										<TableRow>
											<TableCell className={classes.column0}>17.</TableCell>
											<TableCell colSpan={2} align="left">{langTenancyAgreement.titleCompensationToAgent}</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{/*----------- compensation---------- */}
										<TableRow >
											<TableCell component="th" scope="row"></TableCell>
											<TableCell className={classes.column20}>{langTenancyAgreement.compensation}</TableCell>
											<TableCell colSpan={2}>
												<NumberField
													className={classes.dollarField}
													margin="dense"
													variant="outlined"
													InputProps={{
														startAdornment: <InputAdornment position="start">$</InputAdornment>,
														// endAdornment: <InputAdornment position="start">{locale === 'en' ? lang.u1M : lang.u10k}</InputAdornment>,
													}}
													{...bind('compensationToAgent')}
													onChange={(e) => {
														limitNumberFieldLength(e, 'compensationToAgent', form);
													}}
													error={!!form.hasError('compensationToAgent')}
													helperText={form.hasError('compensationToAgent')}
												/>
												{/*<TextField
													data-key={'field-tenancy-compensationToAgent'}
													className={classes.dollarField}
													margin="dense"
													variant="outlined"
													onChange={(e) => {
														limitNumberFieldLength(e, 'compensationToAgent', form);
													}}
													error={!!form.hasError('compensationToAgent')}
													helperText={form.hasError('compensationToAgent')}
													InputProps={{
														startAdornment: <InputAdornment position="start">$</InputAdornment>,
														inputComponent: MaskedInput as any,
														inputProps: {
															value: bind('compensationToAgent').value?.toString(),
															onAccept: (value: any) => {
																bind('compensationToAgent').onChange({ target: { value: value ? Number(value) : value } });
															}
														}
													}}
												/>*/}
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>

						{/* ------------- prevailing version ------------- */}
						{/* <Grid
							item
							md={12}
							xs={12}
						>
							<TableContainer component={Paper}>
								<Table className={classes.table} size="small"  aria-label="part 2 table">
									<TableHead>
										<TableRow>
											<TableCell className={classes.column0}>13.</TableCell>
											<TableCell colSpan={2} align="left" >{langTenancyAgreement.titlePrevailingVersion}</TableCell>
											 <TableCell></TableCell> 
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow >
											<TableCell component="th" scope="row"></TableCell>
											<TableCell className={classes.column20}>{langTenancyAgreement.version}</TableCell>
											<TableCell colSpan={2}>
												<TextField
													className={classes.textField}
													margin="dense"
													variant="outlined"
													select
													{...bind('prevailingVersion')}
												>
													{
														Object.keys(prevailingVersionOptions).map((key)=> 
															<MenuItem key={key} value={key}>{prevailingVersionOptions[key]}</MenuItem>)
													}
												</TextField>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</Grid> */}

						{/* remarks */}
						<Grid
							item
							md={12}
							xs={12}
						>
							<TableContainer component={Paper}>
								<Table className={classes.table} size="small" aria-label="A1 table">
									<TableHead>
										<TableRow>
											<TableCell className={classes.column0}>20.</TableCell>
											<TableCell colSpan={2} align="left">{langTenancyAgreement.remarks}</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{/*----------- remarks ---------- */}
										<TableRow >
											<TableCell component="th" scope="row"></TableCell>
											{/* <TableCell>{langTenancyAgreement.date}</TableCell> */}
											<TableCell>
												<TextareaAutosize
													className={classes.textArea}
													rowsMin={2}
													rowsMax={2}
													{...bind('remarks')}
													onChange={(e) => {
														limitTextFieldLength(e, 255, 'remarks', form, keyBooleanMap, setKeyBooleanMap);
													}}
												/>
												<FormHelperText>{keyBooleanMap.get('remarks') ? lang.textOverLimit : ''}</FormHelperText>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>

						{/* cheque */}
						<Grid
							item
							md={12}
							xs={12}
						>
							<TableContainer component={Paper}>
								<Table className={classes.table} size="small" aria-label="A1 table">
									<TableHead>
										<TableRow>
											<TableCell className={classes.column0}>*</TableCell>
											<TableCell colSpan={2} align="left">{langTenancyAgreement.titleInitialDepositPaymentMethod}</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{/* options: cash or cheque */}
										<TableRow >
											<TableCell component="th" scope="row"></TableCell>
											{/* <TableCell className={classes.column20}>{langTenancyAgreement.chequeNo}</TableCell> */}
											<TableCell colSpan={2}>
												<FormControl component="fieldset">
													<RadioGroup aria-label="initial deposit payment method" name="customized-radios"
														value={!!form.values.isCheque ? "cheque" : "cash"}
														onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
															const val = (event.target as HTMLInputElement).value;
															// form.updateValues('isCheque', val === "cheque" ? true : val === "cash" ? false : false);
															form.setValues({
																...form.values,
																isCheque: val === "cheque" ? true : val === "cash" ? false : false,
																chequeNo: val === "cheque" ? form.values.chequeNo : undefined,
																chequeBank: val === "cheque" ? form.values.chequeBank : undefined
															});
														}}
													>
														<FormControlLabel
															value="cash"
															control={<Radio size="small" />}
															label={langTenancyAgreement.cash}
														/>
														<FormControlLabel
															value="cheque"
															control={<Radio size="small" />}
															label={langTenancyAgreement.cheque}
														/>
														<FormControlLabel value="" control={<Radio size="small" />} label='' style={{ display: 'none' }} />
													</RadioGroup>
												</FormControl>
											</TableCell>
										</TableRow>
										{/*----------- cheque no ---------- */}
										{
											!!form.values.isCheque ?
												<TableRow >
													<TableCell component="th" scope="row"></TableCell>
													<TableCell className={classes.column20}>{langTenancyAgreement.chequeNo}</TableCell>
													<TableCell colSpan={2}>
														<TextField
															className={classes.textField}
															margin="dense"
															variant="outlined"
															{...bind('chequeNo')}
															onChange={(e) => {
																limitTextFieldLength(e, 255, 'chequeNo', form, keyBooleanMap, setKeyBooleanMap);
															}}
														/>
														<FormHelperText>{keyBooleanMap.get('chequeNo') ? lang.textOverLimit : ''}</FormHelperText>
													</TableCell>
												</TableRow> : null
										}
										{/*---------- cheque bank ---------*/}
										{
											!!form.values.isCheque ?
												<TableRow >
													<TableCell component="th" scope="row"></TableCell>
													<TableCell className={classes.column20}>{langTenancyAgreement.chequeBank}</TableCell>
													<TableCell colSpan={2}>
														<TextField
															className={classes.textField}
															margin="dense"
															variant="outlined"
															{...bind('chequeBank')}
															onChange={(e) => {
																limitTextFieldLength(e, 255, 'chequeBank', form, keyBooleanMap, setKeyBooleanMap);
															}}
														/>
														<FormHelperText>{keyBooleanMap.get('chequeBank') ? lang.textOverLimit : ''}</FormHelperText>
													</TableCell>
												</TableRow> : null
										}
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Divider />
					</Grid>

				</Grid>

			</CardContent>

			<CardActions style={{ justifyContent: 'flex-end', display: 'none' }}>
				<Button
					variant="contained"
					color="primary"
				// onClick={()=>{
				//   if (form.values.id != undefined && form.values.id != null){
				//     dispatch({type: 'Form2.UpdateRequested', payload: {
				//       ...form.values,
				//     }});
				//   }else{
				//     dispatch({type: 'Form2.CreationRequested', payload: {
				//       ...form.values,
				//       propertyStockId: propertyStockId,
				//     }});
				//   }
				// }}
				>{lang.actionSaveForm}</Button>
				<Button variant="contained" color="primary">{lang.actionGenerate}</Button>
			</CardActions>
		</Card>
	)
};

export default TenancyDialog;
