import { makeStyles } from '@material-ui/core';
import usePageTitle from 'common/hooks';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState, PASDispatch } from 'reducers';
import UsersSearch, { UsersSearchForm } from './components/UsersSearch';
import UsersTable from './components/UsersTable';
import UsersToolbar from './components/UsersToolbar';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  search: {
    marginBottom: theme.spacing(2)
  }
}));

let initialPage = 0;
   
const UserList = () => {
	const classes = useStyles(0);

	const dispatch = useDispatch() as PASDispatch;
	const users = useSelector((state: IRootState) => state.user.currentList);
	const {langUser} = useSelector((state: IRootState) => state.locale);
  const { totalPages, totalElements } = useSelector((state: IRootState) => state.user);
	const [ page, setPage ] = useState(initialPage);
	initialPage = page;
  const [ limit ] = useState(10);
	const [ sortOrders, setSortOrders ] = useState<{ [k: string]: 'desc' | 'asc' }>({

	});
	const [ values, setValues ] = useState<Partial<UsersSearchForm>>({});
	usePageTitle(langUser.titlePageHeading);
  const handleSearch = (values: Partial<UsersSearchForm>) => {
		setValues(values);
		setPage(0);
		
    // dispatch({ type: 'UserList.FetchRequested', payload: {
    //   page, size: limit, sort: sortOrders, ...values }
    // });
	};
	
	const handleSort = (key: string | null) => {
    if (key === null) {
      setSortOrders({});
      // dispatch({ 
			// 	type: 'UserList.FetchRequested', 
			// 	payload: { page, size: limit, sort: {}, ...values }
      // });
      return;
    }
    const newSortOrders = { [key]: sortOrders[key] === 'asc' ? 'desc' : 'asc' };
    setSortOrders(newSortOrders as any);
    // dispatch({ type: 'UserList.FetchRequested', payload: {
		// 	page, size: limit, sort: newSortOrders, ...values
		// }});
	}
	
	useEffect(() => {
    // dispatch({ type: 'UserList.FetchRequested', payload: { page, size: limit } });
    dispatch({ type: 'UserList.FetchRequested', payload: {
			page: initialPage, size: limit, sort: sortOrders, ...values
		} });
	}, [ page, limit, sortOrders, values ]);
	
	return (
		<div className={classes.root}>
			<UsersToolbar/>
			<div className={classes.content}>				
				<UsersSearch onSearch={handleSearch} className={classes.search}/>
				<UsersTable
					page={page}
					limit={limit}
					totalPages={totalPages}
					totalElements={totalElements}
					setPage={(page) => {
						setPage(page);
						// dispatch({ 
						// 	type: 'UserList.FetchRequested', 
						// 	payload: { page, size: limit }
            // });
					}}
					users={users}
					sortOrders={sortOrders}
					handleSort={handleSort}
				/>
			</div>
		</div>
	);
};

export default UserList;