import {
  Button as MuiButton,
  makeStyles, useMediaQuery, useTheme
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import MenuIcon from '@material-ui/icons/Menu';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import SaveIcon from '@material-ui/icons/Save';
import systemSettingApi from 'api/systemSettingApi';
import { OptionDTO } from 'common/dto';
import usePageTitle from 'common/hooks';
import { tooltipHoc } from 'common/ui';
import deepEqual from 'deep-equal';
import NavigationBar from 'layouts/Main/components/Topbar/NavigationBar';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState, PASDispatch } from 'reducers';



let uuid = 0;

const mapLocaleDisplay = (locale: string, option: OptionDTO) => {
  return ({
    'zh_HK': option.displayTC,
    'zh_CN': option.displaySC,
    'en': option.displayEN,
  } as Record<string, string>)[locale];
}

const Button = tooltipHoc(MuiButton);

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    paddingTop: 48 + 8, // NavigationBar.height + spacing(1)
  },
  content: {
    flexGrow: 1,
  }
}));

export interface OptionSettingProps {
}

const OptionSetting = ({ }: OptionSettingProps) => {
  const classes = useStyles();
  const { langSettings, lang, locale } = useSelector((state: IRootState) => state.locale);
  const { token } = useSelector((state: IRootState) => state.login);
  const { iosStatusBarHeight } = useSelector((state: IRootState) => state.layout);
  const drawerPaddingTop = 48 + 8 + iosStatusBarHeight; // NavigationBar.height + spacing(1) + iosStatusBarHeight
  const theme = useTheme();
  const staticDrawer = useMediaQuery(theme.breakpoints.up('sm'));

  usePageTitle(langSettings.titleOptionSetting);
  
  const dispatch = useDispatch() as PASDispatch;
  const [editableOptions, setEditableOptions] = useState<{ [parentName: string]: Partial<OptionDTO>[] }>({});
  const [initialEditableOptions, setInitialEditableOptions] = useState<{ [parentName: string]: Partial<OptionDTO>[] }>({});
  const [sections, setSections] = useState<{ [name: string]: OptionDTO }>({});
  const [displayingType, setDisplayingType] = useState<any>('');
  const [dummyID, setDummyID] = useState(99999);
  const buildOptionMap = (options: OptionDTO[]) => {
    const newMap: { [parentName: string]: OptionDTO[] } = {};
    options.forEach(option => {
      if (!newMap[option.parentName]) {
        newMap[option.parentName] = [];
      }
      newMap[option.parentName].push(option);
    });

    return newMap;
  }
  useEffect(() => {
    dispatch({ type: 'Layout.MaskPresentRequested' });
    systemSettingApi.getEditableOptions(token ?? '').then(({ data, error }) => {
      if (data) {
        const { options, sections } = data;
        const optionMap = buildOptionMap(options);
        setEditableOptions(optionMap);
        setInitialEditableOptions(optionMap);

        const sectionMap: { [name: string]: OptionDTO } = {};
        sections.forEach(sec => {
          sectionMap[sec.name] = sec;
        });
        setSections(sectionMap);
        if (!displayingType) {
          setDisplayingType(sections[0].name);
        }
      } else if (error) {
        throw new Error(error);
      }
      dispatch({ type: 'Layout.MaskDismissRequested' });
    }).catch((err: Error) => {
      console.error(err);
      if (err.message === 'TypeError: Failed to fetch') {
        dispatch({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgFailedToConnect } });
      } else {
        dispatch({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgNetworkError } });
      }
      dispatch({ type: 'Layout.MaskDismissRequested' });
    });
  }, []);



  const validateOption = (options: Partial<OptionDTO>[]) => (option: Partial<OptionDTO>, key: keyof OptionDTO): [boolean, string | undefined] => {
    if (!option[key]) {
      return [false, undefined];
    }

    if (key === 'name') {
      const name = option.name;
      const duplicated = options.filter(opt => opt.name === name).length > 1;
      return [!duplicated, duplicated ? langSettings.msgDuplicatedOptionCode : undefined];
    }

    return [true, undefined];
  }

  const onSave = () => {
    const updatedOptions: OptionDTO[] = Object.keys(editableOptions)
      .map(parentName => editableOptions[parentName] as OptionDTO[])
      .reduce((a, b) => a.concat(b), []);

    const valid = Object.keys(editableOptions)
      .every(parentName => {
        return editableOptions[parentName].every(option => {
          return ['name', 'displayEN', 'displaySC', 'displayTC'].every(key => {
            const validateResult = validateOption(editableOptions[parentName])(option, key as any);
            // 
            return validateResult[0];
          })
        });
      });
    if (!valid) {
      return;
    }


    dispatch({ type: 'Layout.MaskPresentRequested' });

    systemSettingApi.updateEditableOptions(updatedOptions, token ?? '').then(({ data, error }) => {
      if (data) {
        const newMap = buildOptionMap(data ?? []);
        setEditableOptions(newMap);
        setInitialEditableOptions(newMap);
      } else if (error) {
        throw new Error(error);
      }
      dispatch({ type: 'Layout.DataReloadRequested' });
      dispatch({ type: 'Layout.MaskDismissRequested' });
    }).catch(err => {
      console.error(err);
      dispatch({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgNetworkError } });
      dispatch({ type: 'Layout.MaskDismissRequested' });
    });
  }

  const unsaved = !deepEqual(editableOptions, initialEditableOptions, { strict: true });

  //  Drawer Open State //
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <div className={classes.root}>
      <NavigationBar title={
        staticDrawer ? langSettings.titleOptionSetting : <Button variant="text" color="primary" startIcon={<MenuIcon />} onClick={() => setDrawerOpen(true)}>{langSettings.titleOptionSetting}</Button>
      }>
        <Grid item container
          lg={6}
          md={6}
          xl={6}
          xs={6}
          justify="flex-end">
          <Grid item >
            <Button style={{ fontWeight: unsaved ? 'bold' : 'unset' }} startIcon={<SaveIcon />} color="primary" variant="text" onClick={onSave}>{lang.actionSave}{unsaved ? ' *' : ''}</Button>
          </Grid>
        </Grid>
      </NavigationBar>

      <Drawer
        className={classes.drawer}
        // variant="permanent"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        variant={staticDrawer ? 'permanent' : 'temporary'}
        classes={{
          paper: classes.drawerPaper,
        }}
        style={{ paddingTop: drawerPaddingTop }}
        anchor="left"
      >
        <List>
          {Object.keys(sections).map(name => {
            return <ListItem key={name} selected={name === displayingType} button
              onClick={() => { setDisplayingType(name); setDrawerOpen(false); }}>
              {mapLocaleDisplay(locale, sections[name])}
            </ListItem>
          })}
        </List>
      </Drawer>

      {/* <Grid className={classes.content}
        container
        md={12}
        xs={12}
        spacing={1}
      > */}
      <Grid className={classes.content}
        container
        spacing={1}
      >
        {Object.keys(editableOptions)
          .filter(n => n === displayingType)
          .map(parentName => {
            return <OptionEdit
              key={parentName}
              title={mapLocaleDisplay(locale, sections[parentName]) ?? parentName}
              options={editableOptions[parentName]}
              onChangeOption={(option, oldOption) => {
                option.updated = true;
                const newList = editableOptions[parentName].map(o => o === oldOption ? option : o);
                setEditableOptions({ ...editableOptions, [parentName]: newList });
              }}
              onAddOption={() => {
                setDummyID(dummyID + 1);
                setEditableOptions({
                  ...editableOptions, [parentName]: [
                    { updated: true, parentName, parentId: sections[parentName].id, sortOrder: 0, id: dummyID },
                    ...editableOptions[parentName].map((opt, i) => ({ ...opt, sortOrder: i + 1, updated: true })),
                  ]
                });
              }}
              onDeleteOption={(option) => {
                const newList = editableOptions[parentName].filter(o => o !== option);
                setEditableOptions({ ...editableOptions, [parentName]: newList });
              }}
              onSwap={(i0, i1) => {
                const newList = [...editableOptions[parentName]];
                const tmp = newList[i0];
                newList[i0] = newList[i1];
                newList[i1] = tmp;
                setEditableOptions({
                  ...editableOptions,
                  [parentName]: newList.map((opt, i) => ({ ...opt, updated: true, sortOrder: i + 1 }))
                });
              }}
              validateOption={validateOption(editableOptions[parentName])}
            />
          })}
      </Grid>

    </div>
  );
};

export default OptionSetting;




const useOptionEditStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
  },
}));

export interface OptionEditProps {
  title: string;
  options: Partial<OptionDTO>[];
  onChangeOption: (option: Partial<OptionDTO>, old: Partial<OptionDTO>) => any;
  onAddOption: () => any;
  onDeleteOption: (option: Partial<OptionDTO>) => any;
  onSwap: (i0: number, i1: number) => any;
  validateOption: (option: Partial<OptionDTO>, key: keyof OptionDTO) => [boolean, string | undefined];
}

export function OptionEdit(props: OptionEditProps) {

  const classes = useOptionEditStyles();
  const { langSettings } = useSelector((state: IRootState) => state.locale);

  const disabledDrag = useCallback((e: React.DragEvent<any>) => {
    e.stopPropagation();
    e.preventDefault();
  }, []);




  const optionRow = (option: Partial<OptionDTO>, i: number) => {
    const bind = (key: keyof OptionDTO) => {
      const [valid, errorMsg] = props.validateOption(option, key);
      return {
        value: option[key],
        onChange: (e: React.ChangeEvent<any>) => {
          // if(isNonEmpty(option.id)){
          if (e.target.value.length > 255) {
            e.preventDefault();
          }
          else {
            const newOption = { ...option, [key]: e.target.value };
            props.onChangeOption(newOption, option);
          }

          // }

        },
        error: !valid,
        helperText: errorMsg,
      }
    };

    return <Grid key={option.id ? `${i}:id-${option.id}` : `${i}:new-${uuid++}`} container spacing={1} direction="row" alignItems="center"
      style={{ background: (i % 2 === 0) ? '#f9f9f9' : '#fffff' }}
      draggable
      onDragOver={(e) => e.preventDefault()}
      onDragStart={(e) => e.dataTransfer.setData('option-row', i.toString())}
      onDrop={(e) => {
        if (e.dataTransfer.getData('option-row') === '') {
          return;
        }

        const i1 = +e.dataTransfer.getData('option-row');
        props.onSwap(i, i1);

      }}
    >
      <Grid item container md={10} xs={9} spacing={1}
        onDrag={disabledDrag}
        onDragStart={disabledDrag}
        onDragOver={disabledDrag}
        onMouseMove={disabledDrag}
      >
        <Grid item md={3} xs={12}>
          <TextField fullWidth required margin="dense" variant="outlined" label={langSettings.captionOptionCode} {...bind('name')}

          />
        </Grid>

        <Grid item md={3} xs={12}>
          <TextField fullWidth required margin="dense" variant="outlined" label="English" {...bind('displayEN')}

          />
        </Grid>

        <Grid item md={3} xs={12}>
          <TextField fullWidth required margin="dense" variant="outlined" label="繁體中文" {...bind('displayTC')}

          />
        </Grid>

        <Grid item md={3} xs={12}>
          <TextField fullWidth required margin="dense" variant="outlined" label="简体中文" {...bind('displaySC')}
          />
        </Grid>
      </Grid>

      <Grid item container md={2} xs={3} spacing={1} alignItems="center">
        <Grid item xs={6}>
          <IconButton onClick={() => props.onDeleteOption(option)}>
            <DeleteIcon />
          </IconButton>
        </Grid>
        <Grid item xs={6}>
          <IconButton style={{ cursor: 'move' }}>
            <OpenWithIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>;
  }

  return (
    <Card className={classes.root}>
      <Grid container direction="row" alignItems="center" spacing={1}>
        <Grid item>
          <Typography>{props.title}</Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={props.onAddOption} color="primary">
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid>

      {props.options
        .sort((a, b) => Math.sign((a.sortOrder ?? 0) - (b.sortOrder ?? 0)))
        .map(optionRow)}
    </Card>
  );
}