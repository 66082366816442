// Native App Settings
import { FCM } from "@capacitor-community/fcm";
import { Capacitor } from "@capacitor/core";
import { Device, DeviceId, DeviceInfo } from "@capacitor/device";
import { PermissionStatus, PushNotifications } from "@capacitor/push-notifications";
import { loadOptions, registerDevice, renew } from "api";
import notificationApi from 'api/notificationApi';
import systemSettingApi from 'api/systemSettingApi';
import { IRootState, LayoutActions, LocaleActions, UserActions } from "reducers";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { PASSagaContext } from "sagas";





const unprotectedUrlPrefixes = [
  '/reset-password',
  '/form-client-sign',
  '/not-found'
];

const envUrl = process.env['PUBLIC_URL'].replace("/", "");

export function* watchInitRequest(context: PASSagaContext) {
  yield takeLatest(
    'Layout.Initialize',
    init,
    context
  );
}

export function* watchDataReloadRequest(context: PASSagaContext) {
  yield takeLatest(
    'Layout.DataReloadRequested',
    reloadData,
    context
  );
}

function* init(context: PASSagaContext, action: Extract<LayoutActions, { type: 'Layout.Initialize' }>) {
  yield put({ type: 'Layout.InitialStatusChanged', payload: { initialized: false, initializationFailed: false } });

  const { token, rowCountPreference } = yield select((state: IRootState) => state.login);

  const deviceInfo: DeviceInfo = yield call(() => Device.getInfo());

  const deviceType = ({
    'android': 'ANDROID',
    'ios': 'IOS',
    'web': 'WEB',
  } as { [key: string]: string })[deviceInfo.platform] ?? 'ANDROID';

  let fcmAvailable = false;

  yield FCM.getToken().then(() => fcmAvailable = true).catch(() => fcmAvailable = false);

  if (Capacitor.isNativePlatform() && fcmAvailable) {

    const fcm = FCM;
    const deviceInfo: DeviceInfo = yield call(() => Device.getInfo());

    const deviceIdObj: DeviceId = yield call(() => Device.getId());
    const deviceId = deviceIdObj.uuid;
    const pnResult: PermissionStatus = yield call(() => PushNotifications.requestPermissions());

    // register the device with fcm token if no matter pnResult.receive is 'granted' or others 
    if (pnResult.receive) {
      // Register with Apple / Google to receive push via APNS/FCM
      yield call(() => PushNotifications.register());
      const tokenResult: { token: string } = yield call(fcm.getToken);
      const fcmRegToken: string = tokenResult.token;
      //console.info("Registering device to PAS", deviceId, 'FCM_REG_TOKEN', fcmRegToken);
      yield call(registerDevice, deviceId, deviceType, fcmRegToken);
      yield put({ type: 'Login.DeviceReady', payload: { deviceId } });
      console.info("Device registered successfully");
    } else {
      // Show some error
      yield call(registerDevice, deviceId, deviceType, '');
      yield put({ type: 'Login.DeviceReady', payload: { deviceId } });
      console.error("Error: cannot register to FCM");
    }
    // else {
    //   // yield call(registerDevice, deviceId, deviceType, '');
    //   // yield put({ type: 'Login.DeviceReady', payload: { deviceId } });
    //   yield put<LayoutActions>({ type: 'Layout.Initialize' });
    //   console.error("Error: cannot register to FCM");
    // }

    // PushNotifications.addListener('registration', (token) => {
    //   console.info('pnToken', token.value);
    // });
  } else {
    const deviceIdResult: DeviceId = yield call(Device.getId);
    const deviceId = deviceIdResult.uuid;

    yield call(registerDevice, deviceId, deviceType, '');

    yield put({ type: 'Login.DeviceReady', payload: { deviceId } });
  }

  // Load options & languages
  const { data: optionData, error: optionLoadError } = yield call(loadOptions, token);

  if (optionLoadError) {
    yield put({ type: 'Layout.InitialStatusChanged', payload: { initialized: false, initializationFailed: true } });
    return;
  }

  yield put({ type: 'Locale.RemoteBundleLoaded', payload: optionData });

  // Check Login
  const { data: renewData, error: loginError } = yield call(renew, token);
  if (renewData?.token) {
    yield put({ type: 'Login.Success', payload: { ...renewData, rowCountPreference } });
    yield put<UserActions>({ type: 'User.FetchRequested', payload: { id: renewData?.uid } });
    const { data: notifications, error: loadError } = yield call(notificationApi.getList, token);
    if (loadError) return;
    yield put({ type: 'Notifications.Loaded', payload: notifications });
  } else {
    localStorage.removeItem('impersonateUserToken');
    yield put({ type: 'Login.LoggedOut' });
  }


  // load system settings
  const { data: systemSettingData, error: systemSettingError } = yield call(systemSettingApi.getListForFrontend, renewData?.token);
  if (systemSettingData) {
    yield put({ type: 'SystemSetting.Loaded', payload: { data: systemSettingData } });
  } else {
    yield put({ type: 'Layout.InitialStatusChanged', payload: { initialized: false, initializationFailed: true } });
    return;
  }

  const { DEFAULT_DISPLAY_LANGUAGE } = yield select((state: IRootState) => state.systemSettings?.System?.SYSTEM_PARAM);
  const { PROFILE } = yield select((state: IRootState) => state.systemSettings?.BuildInfo?.ENV?.PROFILE) ?? null;
  if (renewData?.token) {
    window.SCBeacon?.trackEvent("Agent contact event", {
      xagentId: (PROFILE ? `pas-app-${PROFILE.toLowerCase()}-` : "") + renewData?.uid,
      xfirstName: renewData?.englishName ?? renewData?.chineseName,
      xlastName: '',
      xemail: renewData?.email,
      xlanguage: renewData?.language
    });
  }

  yield put<LocaleActions>({ type: 'Locale.SwitchLocale', locale: renewData?.language ?? DEFAULT_DISPLAY_LANGUAGE ?? 'zh_HK' });

  // if (loginError) {
  //   yield put({ type: 'Layout.InitialStatusChanged', payload: { initialized: false, initializationFailed: true } });
  //   return;
  // }



  const isUnprotectedRoute = unprotectedUrlPrefixes.some(prefix =>
    context.browserHistory.location.pathname.startsWith(prefix)
  );



  if (renewData?.token || isUnprotectedRoute) {
    // Do Nothing
    // context.browserHistory.replace('/dashboard');

    // Force change password
    // if (renewData?.passwordExpired){
    //   context.browserHistory.replace('/force-change-password');
    // }
  } else {
    context.browserHistory.replace('/sign-in');
  }

  yield put({ type: 'Layout.InitialStatusChanged', payload: { initialized: true, initializationFailed: false } });
}

function* reloadData(context: PASSagaContext, action: Extract<LayoutActions, { type: 'Layout.DataReloadRequested' }>) {

  const { token } = yield select((state: IRootState) => state.login);

  // renew login state
  const { data: renewData } = yield call(renew, token);
  if (renewData?.token) {
    yield put({ type: 'Login.Success', payload: renewData });
    // yield put<UserActions>({ type: 'User.FetchRequested', payload: { id: renewData?.uid }});  
  } else {
    localStorage.removeItem('impersonateUserToken');
    return;
  }

  // load data
  const { data: optionData, error: optionLoadError } = yield call(loadOptions, token);

  if (optionLoadError) {
    return;
  }

  yield put({ type: 'Locale.RemoteBundleLoaded', payload: optionData });

  // load system settings
  const { data: systemSettingData } = yield call(systemSettingApi.getListForFrontend, token);
  if (systemSettingData) {
    yield put({ type: 'SystemSetting.Loaded', payload: { data: systemSettingData } });
  } else {
    return;
  }

}
