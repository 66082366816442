import {
  Button, Card, CardContent, Collapse, Grid, IconButton, makeStyles, Tooltip, Typography, useMediaQuery, useTheme
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMore from '@material-ui/icons/ExpandMore';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import clsx from 'clsx';
import { hasPermission } from 'common/access-control';
import { useConfirmDialog } from 'common/ConfirmDialog';
import { ClientFileMap, PropertyFileMap, PropertyMultiValueMap, PropertyStockDTO } from 'common/dto';
import { FormPresenter } from 'common/hooks';
import { AddToCartIcon, HotPickIcon, KeyIcon } from 'common/ui';
import { genAddress, handlePriceDisplay, handlePriceDisplayUnit, handlePriceLength, isNonEmpty, multiLang, numberWithCommas, priceFromView } from 'common/utils';
import NavigationBar from 'layouts/Main/components/Topbar/NavigationBar';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CloseTransactionActions, IRootState, PASDispatch } from 'reducers';
import { MapDialog } from 'views/BuildingDetail/components/MapDialog';
import AddLeadAgentDialog from 'views/PropertyDetail/components/AddLeadAgentDialog';
import Documents from 'views/PropertyDetail/components/Documents';
import MultiClientWishlistDialog from 'views/PropertyDetail/components/MultiClientWishlistDialog';
import { FeatureTile, LeadAgentNameCard, PhotoGallery } from './components';

const defaultGray = '#F4F4F4';
const salesInfoYellow = "#FFFFCC";
const featureRed = "#FFE6CC";
const roomBlue = "#DAE8FC";
const gardenGreen = "#D5E8D4";
const shopPurple = "#E1D5E7";
const schoolNetRed = "#F8CECC";
const otherFeatureYellow = "#FFFF88";
const clubHouseFacilitiesGreen = "#CCFFCC";
const othersBlue = "#CCFFFF";

const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  active: {
    color: theme.palette.common.white,
    background: theme.palette.primary.dark,
    '&:hover': {
      background: theme.palette.primary.dark,
    }
  },
  mgtFeeDetailContainer: {
    padding: '0px 10px',
  },
  netColor: {
    color: '#2D8B4E',
  },
  grossColor: {
    color: ' #ff7417',
  },
  priceColor: {
    color: 'red',
  },
  grayColor: {
    color: 'rgb(0,0,0, .4)',
  },
  priceLogo: {
    height: '24px',
    // width:'24px',
    paddingLeft: '5px',
    paddingRight: '5px',
    backgroundColor: '#1994FC',
    color: 'white',
    borderRadius: '4px',
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  rentLogo: {
    height: '24px',
    // width:'24px',
    paddingLeft: '5px',
    paddingRight: '5px',
    backgroundColor: '#62BF01',
    color: 'white',
    borderRadius: '4px',
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  textHighlight: {
    fontWeight: 'bold',
  },
  otherAreas: {
    fontSize: '10px',
    fontWeight: 'bold',
  },
  smallerText: { //address, lot number, bottom price(rent), price(rent) per net, punchline
    fontSize: '14px',
  },
  remarksTextAreaContainer: {
    backgroundColor: defaultGray,
    borderRadius: '5px',
    padding: '8px 8px',
    width: '100%',
    height: '89px',
  },
  remarksTextArea: {
    backgroundColor: defaultGray,

    textAlign: 'left',
    overflowY: 'scroll',
    whiteSpace: 'pre-wrap',

    width: '100%',
    height: '73px',

    fontSize: '13px',
  },
  companyStockIcon: {
    height: '20px',
    backgroundColor: 'gray',
    color: 'white',
    borderRadius: '4px',
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '11px',
    padding: '0px 3px',
    margin: '0px 3px',
  },
  hasKeyIcon: {
    color: '#fcba03',
    marginLeft: '5px',
    fontSize: '16px',
  },
  hotPickIconBtn: {
    width: '16px',
    height: '16px',
  },
  hotPickIcon: {
    color: '#cc6633',
    marginLeft: '5px',
  },
  valueOnlyTile: {
    width: 'auto',
    minWidth: '60px',
    height: '20px',
    padding: '0px 2px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',

    border: '1px solid #F4F4F4',
    backgroundColor: '#F4F4F4',
    borderRadius: '3px',
  },
  featureTileContainer: {
    padding: '4px 4px',
  },
  valueOnlyTileContainer: {
    padding: '4px 4px',
  },
  sectionLabel: {
    fontSize: '12px',
  },
  sectionContainer: {
  },
  propertyTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 'fit-content',
    flexWrap: 'wrap',
  },
  fadeContainer: {
    display: 'flex',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  approvalStageUnapproved: {
    backgroundColor: '#FFFFFF'
  },
  approvalStageMatching: {
    backgroundColor: '#FED8B1'
  },
  approvalStageClientApp: {
    backgroundColor: '#00FFFF'
  },
  // collapse:{
  //   transform: 'translateX(100px)'
  // }
}));

const When = ({ test, children }: { test?: boolean, children: JSX.Element }): JSX.Element => test ? children : <span />;

export interface PreviewCardProps {

  form: FormPresenter<PropertyStockDTO>;
  tagForm: FormPresenter<PropertyMultiValueMap>;
  fileForm: FormPresenter<PropertyFileMap>;
  hideFloor?: boolean;
  clientFileForm?: FormPresenter<ClientFileMap>;
  saveDisabled: boolean;
  floorUnitOptions?: any;
}

const PreivewCard = (props: PreviewCardProps) => {
  const dispatch = useDispatch() as PASDispatch;
  const { form, tagForm, fileForm, clientFileForm, saveDisabled } = props;
  const classes = useStyles();
  const { lang, locale, langPropertyStock, levelOptions, propertyStockStatusOptions, districtHkiOptions, districtNtOptions, districtKltOptions,
    usageOptions, vacantOptions, symbolOptions, decoOptions, otherFeatureOptions, viewOptions, facingOptions, otherOptions,
    primarySchoolNetOptions, secondarySchoolNetOptions, clubHouseFacilitiesOptions, mgtFeeInclOptions, profileOptions,
  } = useSelector((state: IRootState) => state.locale);
  const { uid, privileges } = useSelector((state: IRootState) => state.login);
  const { isLeadAgent, canReadTeamProperty, canReadUnowned, canReadCompanyStock } = form.values;
  const shouldCollapseMgtFee = useMediaQuery('(max-width: 400px)');
  const showAgentsInNewLine = useMediaQuery('(max-width: 600px)');

  const districtOptions = {
    ...districtHkiOptions,
    ...districtKltOptions,
    ...districtNtOptions,
  };

  // const isNew = false;
  // const showFloor = isLeadAgent || canReadTeamProperty || canReadUnowned || (form.values.companyStock === true && canReadCompanyStock) || isNew;
  // const showUnit = isLeadAgent || canReadTeamProperty || canReadUnowned || (form.values.companyStock === true && canReadCompanyStock) || isNew || isNonEmpty(form.values.floor);
  const showFloor = true; // FIXME: Pending removal: field masking is handled by backend
  const showUnit = true; // FIXME: Pending removal: field masking is handled by backend
  const showStreet = true; // FIXME: Pending removal: field masking is handled by backend
  const showLot = true; // FIXME: Pending removal: field masking is handled by backend
  // const showStreet = isLeadAgent || canReadTeamProperty || (canReadUnowned && !(form.values.usage === 'V')) || (form.values.companyStock === true && canReadCompanyStock) || isNew || isNonEmpty(form.values.floor) || isNonEmpty(form.values.unit);
  // const showStreetLot = isNew || !(form.values.usage === 'V' && !form.values.companyStock && !isLeadAgent && !canReadTeamProperty && !canReadUnowned);
  const showStreetLot = true;
  // const showLot = isLeadAgent || canReadTeamProperty || (canReadUnowned && !(form.values.usage === 'V')) || (form.values.companyStock === true && canReadCompanyStock) || isNew || !isNonEmpty(form.values.lot);

  // ---------- documents ------------
  const documentOptions: { [key: string]: string } = {
    DUMMY: lang.actionUpload,
    LAND_SEARCH: langPropertyStock.landSearch,
    SA_SEARCH: langPropertyStock.saSearch,
    // OP: langPropertyStock.occPermit,
    COMPANY_SEARCH: langPropertyStock.businessReg,
    OTHERS: langPropertyStock.otherDoc,
  };
  const creating = false;

  const closeTransactionDto = useSelector((state: IRootState) => state.closeTransaction.fetchedDetails[form.values.id ?? '']);

  const currentSalePurchaseAgreement = useSelector((state: IRootState) => state.property.salePurchaseAgreement ?? {});
  const currentTenancyAgreement = useSelector((state: IRootState) => state.property.tenancyAgreement ?? {});
  const isBuyerAgent = currentSalePurchaseAgreement?.buyerAgentUserId?.toString() === uid;
  const isTenantAgent = currentTenancyAgreement?.tenantAgentUserId?.toString() === uid;
  const canEditForm = form.values.companyStock === true ? (canReadCompanyStock || !saveDisabled) : !saveDisabled;
  const canEditPa = creating || form.values.companyStock === true ? true : ((isLeadAgent || isBuyerAgent || isTenantAgent) && (form.values.status !== 'SOLD' && form.values.status !== 'LEASED'));
  const isPaAgent = isBuyerAgent || isTenantAgent;

  const role = useSelector((state: IRootState) => state.user.fetchedDetails);
  const isAdminOrOfficeAdmin = Object.values(role).filter(r => r.roles.filter(r => r === "ADMIN" || r === "OFFICE_ADMIN").length > 0).length > 0;

  const canUpdateUnown = !isLeadAgent && hasPermission(privileges, 'UPDATE', 'UNOWNED_PROPERTY_STOCK')
  const canDownloadOrDeleteFormFiles = form.values.companyStock === true ? canEditForm : isLeadAgent;
  const canDownloadPaFiles = form.values.companyStock === true ? true : (isLeadAgent || isBuyerAgent || isTenantAgent);
  const canUploadPropertyDocFiles = creating ? true : (form.values.companyStock === true ? canEditForm : (isLeadAgent || canUpdateUnown));

  const grossOrNet = form.values.net ?? form.values.gross;
  const areaTypeDisplay = form.values.net ? langPropertyStock.captionPerNet : langPropertyStock.captionPerGross;
  const pricePerArea = (form.values.price && grossOrNet) ? (Math.round(priceFromView(form.values.price, locale) / grossOrNet)) : 0;
  const rentPerArea = form.values.rent && grossOrNet ? Math.round((form.values.rent / grossOrNet)) : 0;
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const width = window.innerWidth
  // console.log('theme', width, isBigScreen, useMediaQuery(theme.breakpoints.down('sm')), useMediaQuery(theme.breakpoints.down('md')), useMediaQuery(theme.breakpoints.up('md')))
  const getPriceString = (price: number | string | undefined, unit: string, label = '') => {
    return isNonEmpty(price) ?
      (isNonEmpty(label) ? label + ' ' : '') + '$' + numberWithCommas(price) + unit : '';
  }

  const getAreaString = (area: number | string | undefined, label = '') => {
    return isNonEmpty(area) ?
      (isNonEmpty(label) ? label + ' ' : '') + numberWithCommas(area) + lang.uFt : '';
  }

  const getPropertyTitle = () => {
    const floors = form.values.floor?.filter(f => isNonEmpty(f));
    const units = form.values.unit?.filter(u => isNonEmpty(u));

    const buildingName = multiLang(locale, form.values.building, form.values.buildingEn);
    const floorName = isNonEmpty(floors) ? (locale === 'en' ? (floors?.join(',') + '/F') : (floors?.join(',') + '樓')) : '';
    const levelName = form.values.level?.map(l => levelOptions[l.trim()]).join(',') ?? '';
    const unitName = isNonEmpty(units) ? (locale === 'en' ? 'Unit ' + units?.join(',') : units?.join(',') + '室') : '';

    let resultArray = [];
    resultArray.push(buildingName);
    resultArray.push(isNonEmpty(floorName)? floorName : " " + levelName);
    if (showUnit) {
      resultArray.push(unitName);
    }

    resultArray = resultArray.filter(v => isNonEmpty(v));

    return locale === 'en' ? resultArray.filter(element=>element!=" ").join(', ') : resultArray.filter(element=>element!=" ").join('');
  }

  const propertyAddress = genAddress(locale,
    '', //form.values.unit?.join(',') ?? '',
    '', //form.values.floor?.join(',') ?? '',
    form.values.block ?? '', form.values.blockEn ?? '',
    form.values.building ?? '', form.values.buildingEn ?? '',
    (showStreetLot && showStreet && isNonEmpty(form.values.street) ? (form.values.street ?? '') : ''),
    (showStreetLot && showStreet && isNonEmpty(form.values.streetEn) ? (form.values.streetEn ?? '') : ''),
    districtOptions[form.values.district ?? '']);


  const getHeading = () => {
    switch (locale) {
      case 'en': return form.values.headingEN;
      case 'zh_HK': return form.values.headingTC;
      case 'zh_CN': return form.values.headingSC;
    }
  }

  const getPunchline = () => {
    switch (locale) {
      case 'en': return form.values.punchlineEN;
      case 'zh_HK': return form.values.punchlineTC;
      case 'zh_CN': return form.values.punchlineSC;
    }
  }

  const printFeatureTile = (caption: string, value: string | number | undefined, backgroundColor = defaultGray) => {
    return isNonEmpty(value) ? (
      <div className={classes.featureTileContainer}>
        <FeatureTile caption={caption} value={value?.toString() ?? ''} backgroundColor={backgroundColor} />
      </div>
    ) : null;
  }

  const printValueOnlyTile = (value: string | number | undefined, backgroundColor = defaultGray) => {
    return isNonEmpty(value) ? (
      <div className={classes.valueOnlyTileContainer}>
        <div className={classes.valueOnlyTile}
          style={{ backgroundColor: backgroundColor, border: '1px solid ' + backgroundColor, }}
        >
          {value}
        </div>
      </div>
    ) : null;
  }

  const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);

  const getLeaseCommencementString = () => {
    const leaseCommencementFmtStr = form.values.leaseCommencement ? moment(form.values.leaseCommencement).format(DISPLAY_DATE_FORMAT) : null;
    const leaseExpiryFmtStr = form.values.leaseExpiry ? moment(form.values.leaseExpiry).format(DISPLAY_DATE_FORMAT) : null;
    if (isNonEmpty(leaseCommencementFmtStr) && isNonEmpty(leaseExpiryFmtStr)) {
      return leaseCommencementFmtStr + ' - ' + leaseExpiryFmtStr;
    } else if (isNonEmpty(leaseCommencementFmtStr)) {
      return leaseCommencementFmtStr + ' - ' + langPropertyStock.notSpecified;
    } else if (isNonEmpty(leaseExpiryFmtStr)) {
      return langPropertyStock.notSpecified + ' - ' + leaseExpiryFmtStr;
    } else return '';
  }

  const getRoomString = () => {
    const roomStr = form.values.room ? form.values.room + (locale === 'en' ? ' ' : '') + langPropertyStock.room : undefined;
    const suiteStr = form.values.suite ? form.values.suite + (locale === 'en' ? ' ' : '') + langPropertyStock.suite : undefined;
    if (isNonEmpty(roomStr) && isNonEmpty(suiteStr)) {
      return roomStr + '(' + suiteStr + ')';
    } else if (isNonEmpty(roomStr)) {
      return roomStr;
    } else if (isNonEmpty(suiteStr)) {
      return suiteStr;
    } else return '';
  }

  const monthlyMgtFeePerNet = (form.values.monthlyMgtFee && form.values.net) ? (form.values.monthlyMgtFee / form.values.net).toFixed(2) : 0;
  const monthlyMgtFeePerGross = (form.values.monthlyMgtFee && form.values.gross) ? (form.values.monthlyMgtFee / form.values.gross).toFixed(2) : 0;

  const photoFilenames = fileForm.values.PHOTO?.filter(attachment => attachment.dataStatus !== 'I').map(attachment => `${BASE_URL}/files/${attachment.filename}`) ?? [];
  const videoLink = form.values.videoLink ? form.values.videoLink : undefined;
  const floorPlanFilenames = fileForm.values.FLOOR_PLAN?.filter(attachment => attachment.dataStatus !== 'I')?.map(attachment => `${BASE_URL}/files/${attachment.filename}`) ?? [];

  const [openMapDialog, setOpenMapDialog] = React.useState(false);

  //------ edit button related
  const canGoToEditPage = form.values.isLeadAgent || (form.values.approvalStage ?? 0) >= 1 || canReadTeamProperty || canReadUnowned || isBuyerAgent || isTenantAgent || Boolean(closeTransactionDto);
  const editWarningDialog = useConfirmDialog();
  const [openAddLeadAgentDialog, setOpenAddLeadAgentDialog] = useState(false);
  const history = useHistory();
  // const location = useLocation();

  // if (location.state?.from === `/notifications-centre` && canGoToEditPage && Boolean(form.values.propertyNo)) {
  //   history.push(`/properties-edit/${form.values.propertyNo}`);
  // }

  const handleClickEdit = () => {
    if (canGoToEditPage) {
      history.push(`/properties-edit/${form.values.propertyNo}`);
    } else {
      editWarningDialog.confirm(langPropertyStock.msgWarningNoOwnerInfo, lang.actionContinue, lang.actionGoBack, langPropertyStock.waringDialogTitle).then((confirmed: boolean) => {
        if (confirmed) {
          setOpenAddLeadAgentDialog(true);
        }
      });
    }
  }

  const [showMorePrice, setShowMorePrice] = useState(false);
  const [showMoreArea, setShowMoreArea] = useState(false);
  const hasMoreArea = (form.values.usage == 'S')
    && (isNonEmpty(form.values.baseFloorGross)
      || isNonEmpty(form.values.baseFloorNet)
      || isNonEmpty(form.values.groundFloorGross)
      || isNonEmpty(form.values.groundFloorNet)
      || isNonEmpty(form.values.mezzanineFloorGross)
      || isNonEmpty(form.values.mezzanineFloorNet)
      || isNonEmpty(form.values.otherAreaGross)
      || isNonEmpty(form.values.otherAreaNet)
    );

  const hasMorePrice = isNonEmpty(form.values.bottomPrice)
    || isNonEmpty(pricePerArea)
    || isNonEmpty(form.values.bottomRent)
    || isNonEmpty(rentPerArea);
  let [expanded, setExpanded] = useState({
    price: false,
    shopArea: false,
  });

  //--- hot pick
  const hotpickExpiryDateStr = form.values.hotpickExpiry ? `${langPropertyStock.captionHotPickValidUntil}: ${moment(form.values.hotpickExpiry).format(DISPLAY_DATE_FORMAT)}` : '';
  const canReadHotPick = hasPermission(privileges, 'READ', 'PREMIUM_PROPERTY');

  // ---multi client wishlist dialog open
  const [wishlistDialogOpen, setWishlistDialogOpen] = useState(false);

  // Request API again when the id param changed
  useEffect(() => {
    if (isNonEmpty(form.values.id)) {
      dispatch({ type: 'SalePurchaseAgreement.FetchRequested', payload: { propertyStockId: form.values.id ?? '' } });
      dispatch({ type: 'TenancyAgreement.FetchRequested', payload: { propertyStockId: form.values.id ?? '' } });
      dispatch({ type: 'CloseTransaction.FetchRequested', payload: { pid: form.values.id } } as CloseTransactionActions);
    }
  }, [form.values.id]);

  const navBarStyle = () => {
    switch (form.values.approvalStage) {
      case 0: return classes.approvalStageUnapproved;
      case 1: return classes.approvalStageMatching;
      case 2: return classes.approvalStageClientApp;
      default: return classes.approvalStageUnapproved;
    }
  }

  return <div className={classes.root}>
    <NavigationBar title={langPropertyStock.titlePropertyDetail}>
      {form.values.status != 'PENDING' && form.values.status != 'SOLD' && form.values.status != 'LEASED' ? <Button startIcon={<AddToCartIcon />} style={{ marginRight: 5, marginBottom: 2 }} color="default" variant="text" onClick={() => setWishlistDialogOpen(true)}>{langPropertyStock.actionAddToMultiWishlists}</Button> : null}
      {canGoToEditPage ? <Button startIcon={<EditIcon />} style={{ marginRight: 5, marginBottom: 2 }} color="default" variant="text" onClick={handleClickEdit}>{lang.actionEdit}</Button> : null}
    </NavigationBar>

    {/* --------- dialogs ----------*/}
    {editWarningDialog.render()}
    <MapDialog
      allowClick={false}
      allowSave={false}
      onSave={() => { return; }}
      open={openMapDialog}
      onClose={() => { setOpenMapDialog(false) }}
      selectedValue={[form.values.latitude ?? 0, form.values.longitude ?? 0]}
      useDefaultCenter={!(isNonEmpty(form.values.latitude) && isNonEmpty(form.values.longitude))} />
    <AddLeadAgentDialog
      isCreatePage={true}
      isPreviewPage={true}
      hasAssociatedClients={(form.values.leadAgents?.length ?? 0) > 0}
      hideOpenButton
      propertyStockId={form.values.id}
      open={openAddLeadAgentDialog} handleClickOpen={() => { setOpenAddLeadAgentDialog(true) }} handleClose={() => { setOpenAddLeadAgentDialog(false) }} stockForm={form}
    />
    <MultiClientWishlistDialog
      pid={form.values.id ?? ''}
      open={wishlistDialogOpen}
      close={() => setWishlistDialogOpen(false)}
    />
    <Card>

      <CardContent>

        <Grid
          item container spacing={2}
          md={12}
          sm={12}
          xs={12}
        >
          {/* ------------ photo gallery section ----------- */}
          <When test={isNonEmpty(photoFilenames) || isNonEmpty(floorPlanFilenames) || isNonEmpty(videoLink)}>
            <Grid
              item
              md={6}
              sm={12}
              xs={12}
            >
              <PhotoGallery
                photos={photoFilenames}
                video={videoLink}
                floorPlan={floorPlanFilenames}
              />
            </Grid>
          </When>
          {/* ------------ Basic info and price  ----------- */}
          <Grid
            item container spacing={1}
            md={6}
            sm={12}
            xs={12}
          >
            <Grid
              item container spacing={1}
              md={12}
              sm={12}
              xs={12}
              style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
            >
              {printFeatureTile(langPropertyStock.propertyNo, form.values.propertyNo)}
              {printFeatureTile(langPropertyStock.usage, usageOptions[form.values.usage ?? ''])}
              {printFeatureTile(langPropertyStock.status, propertyStockStatusOptions[form.values.status ?? 'PENDING'])}
              {printFeatureTile(langPropertyStock.district, districtOptions[form.values.district ?? 'UNKNOWN'])}

            </Grid>

            {/* ------------ title & address ----------- */}
            <Grid
              item container
              md={12}
              sm={12}
              xs={12}
              style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
            >
              {/* ------------ title----------- */}
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Grid item>
                  <Typography className={classes.propertyTitle}>
                    {getPropertyTitle()}
                  </Typography>
                </Grid>
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ display: canReadHotPick && form.values.isHotPick && form.values.hotPickNonExpired ? 'flex' : 'none' }}>
                    <Tooltip title={hotpickExpiryDateStr} aria-label='hot-pick-expiry'>
                      <IconButton className={classes.hotPickIconBtn} aria-label="hot-pick-icon-btn">
                        <HotPickIcon className={classes.hotPickIcon} />
                      </IconButton>
                    </Tooltip>
                  </div>
                  {isNonEmpty(form.values.keyNo) && <KeyIcon className={classes.hasKeyIcon} />}
                  {form.values.companyStock && <div className={classes.companyStockIcon}>{langPropertyStock.companyStock}</div>}
                </Grid>
              </Grid>

              {/* ------------ address----------- */}
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
              // style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
              >
                <div onClick={() => setOpenMapDialog(true)} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }}>
                  <LocationOnIcon className={classes.grayColor} />
                  <Typography className={clsx(classes.grayColor, classes.smallerText)}>{propertyAddress}</Typography>
                </div>
              </Grid>

              {/* ------------ Lot number----------- */}
              <When test={showStreetLot && showLot && isNonEmpty(form.values.lot)}>
                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                >
                  <Typography style={{ paddingLeft: '20px' }} className={clsx(classes.grayColor, classes.smallerText)}>{form.values.lot}</Typography>
                </Grid>
              </When>
            </Grid>


            {/* ------------ price & rent----------- */}
            <Grid
              item container
              spacing={1}
              md={12}
              sm={12}
              xs={12}
            >
              {/* ------------ price ----------- */}
              <When test={isNonEmpty(form.values.price)
                || isNonEmpty(form.values.bottomPrice)
                || isNonEmpty(pricePerArea)
              }>

                <Grid
                  item container
                  spacing={1}
                  md={5}
                  sm={5}
                  xs={10}
                >
                  <When test={isNonEmpty(form.values.price)}>
                    <Grid
                      item
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                    >
                      <div className={classes.priceLogo}>{langPropertyStock.saleAbbr}</div>
                      <Typography style={{ paddingLeft: '5px', fontSize: '18px', fontWeight: 'bold' }} className={clsx(classes.priceColor)}>{getPriceString(handlePriceLength(handlePriceDisplay(priceFromView(form.values.price ?? 0, locale), locale)), handlePriceDisplayUnit(priceFromView(form.values.price ?? 0, locale), locale, lang))}</Typography>
                    </Grid>
                  </When>

                  <Collapse in={expanded.price}>
                    <Grid item container spacing={1} style={{ paddingLeft: '4px' }}>
                      <When test={isNonEmpty(form.values.bottomPrice)}>
                        <Grid
                          item
                          md={12}
                          sm={12}
                          xs={12}
                        >
                          <Typography className={clsx(classes.grayColor, classes.smallerText)}>{getPriceString(handlePriceLength(handlePriceDisplay(priceFromView(form.values.bottomPrice ?? 0, locale), locale)), handlePriceDisplayUnit(priceFromView(form.values.bottomPrice ?? 0, locale), locale, lang), langPropertyStock.bottomPrice)}</Typography>
                          {/* form.values.bottomPrice, locale === 'en' ? lang.u1M : lang.u10k, langPropertyStock.bottomPrice */}
                        </Grid>
                      </When>

                      <When test={isNonEmpty(pricePerArea)}>
                        <Grid
                          item
                          md={12}
                          sm={12}
                          xs={12}
                        >
                          <Typography className={clsx(classes.grayColor, classes.smallerText)}>{getPriceString(pricePerArea, ' / ' + areaTypeDisplay, '@')}</Typography>
                        </Grid>
                      </When>

                    </Grid>
                  </Collapse >

                </Grid>
              </When>
              {/* ------------ rent ----------- */}
              <When test={isNonEmpty(form.values.rent)
                || isNonEmpty(form.values.bottomRent)
                || isNonEmpty(rentPerArea)
              }>
                <Grid
                  item container spacing={1}
                  md={5}
                  sm={5}
                  xs={10}
                >
                  <When test={isNonEmpty(form.values.rent)}>
                    <Grid
                      item
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                    >
                      <div className={classes.rentLogo}>{langPropertyStock.rentAbbr}</div>
                      <Typography style={{ paddingLeft: '5px', fontSize: '18px', fontWeight: 'bold' }} >{getPriceString(form.values.rent, '')}</Typography>
                    </Grid>
                  </When>


                  <Collapse in={expanded.price}>
                    <Grid item container spacing={1} style={{ paddingLeft: '4px' }}>
                      <When test={isNonEmpty(form.values.bottomRent)}>
                        <Grid
                          item
                          md={12}
                          sm={12}
                          xs={12}
                        >
                          <Typography className={clsx(classes.grayColor, classes.smallerText)}>{getPriceString(form.values.bottomRent, '', langPropertyStock.bottomRent)}</Typography>
                        </Grid>
                      </When>

                      <When test={isNonEmpty(rentPerArea)}>
                        <Grid
                          item
                          md={12}
                          sm={12}
                          xs={12}
                        >
                          <Typography className={clsx(classes.grayColor, classes.smallerText)}>{getPriceString(rentPerArea, ' / ' + areaTypeDisplay, '@')}</Typography>
                        </Grid>
                      </When>
                    </Grid>
                  </Collapse >
                </Grid>
              </When>


              <When test={hasMorePrice}>
                <Grid
                  item container spacing={1}
                  md={2}
                  sm={2}
                  xs={1}
                  alignItems="flex-start"

                >

                  <IconButton
                    style={{ padding: '8px 0px', height: '30.99px', transform: !isBigScreen ? 'translateX(70px)' : '' }}
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: true,
                    })}
                    onClick={() => setExpanded({ ...expanded, price: !expanded.price })}
                    aria-expanded={expanded.price}
                    aria-label="show more">
                    <ExpandMore />
                  </IconButton>

                </Grid>
              </When>

            </Grid>

            {/* ------------ net & gross & shop areas----------- */}
            <Grid
              item container spacing={1}
              md={12}
              sm={12}
              xs={12}
              alignItems="center"
            >
              {/* ------------ net ----------- */}
              <When test={isNonEmpty(form.values.net)}>
                {isMobileScreen ?
                  <Grid
                    item container spacing={1}
                    md={5}
                    sm={5}
                    xs={5}
                  >
                    <Grid
                      item
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ display: 'flex', flexDirection: 'row' }}
                    >
                      <Typography style={{ fontSize: '18px', fontWeight: 'bold' }} className={clsx(classes.netColor)}>{langPropertyStock.captionNetAbbr}</Typography>
                    </Grid>
                    <Grid
                      item
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ display: 'flex', flexDirection: 'row' }}
                    >
                      <Typography style={{ fontSize: '18px', fontWeight: 'bold' }} className={clsx(classes.netColor)}>{numberWithCommas(form.values.net) + lang.uFt}</Typography>
                    </Grid>

                  </Grid>
                  : <Grid
                    item container spacing={1}
                    md={5}
                    sm={5}
                    xs={5}
                  >
                    <Grid
                      item
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ display: 'flex', flexDirection: 'row' }}
                    >
                      <Typography style={{ fontSize: '18px', fontWeight: 'bold' }} className={clsx(classes.netColor)}>{getAreaString(form.values.net, langPropertyStock.captionNetAbbr + ' ')}</Typography>
                    </Grid>


                  </Grid>}
              </When>

              {/* ------------ gross ----------- */}
              <When test={isNonEmpty(form.values.gross)}>
                {isMobileScreen ?
                  <Grid
                    item container spacing={1}
                    md={5}
                    sm={5}
                    xs={5}
                  >
                    <Grid
                      item
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ display: 'flex', flexDirection: 'row' }}
                    >
                      <Typography style={{ display: 'flex', fontSize: '18px', fontWeight: 'bold' }} className={clsx(classes.grossColor)}>{langPropertyStock.captionGrossAbrr}</Typography>

                    </Grid>
                    <Grid
                      item
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ display: 'flex', flexDirection: 'row' }}
                    >
                      <Typography style={{ display: 'flex', fontSize: '18px', fontWeight: 'bold' }} className={clsx(classes.grossColor)}>{numberWithCommas(form.values.gross) + lang.uFt}</Typography>

                    </Grid>
                  </Grid> : <Grid
                    item container spacing={1}
                    md={5}
                    sm={5}
                    xs={5}
                  >
                    <Grid
                      item
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ display: 'flex', flexDirection: 'row' }}
                    >
                      <Typography style={{ display: 'flex', fontSize: '18px', fontWeight: 'bold' }} className={clsx(classes.grossColor)}>{getAreaString(form.values.gross, langPropertyStock.captionGrossAbrr + ' ')}</Typography>

                    </Grid>
                  </Grid>}
              </When>

              <When test={hasMoreArea}>
                <Grid
                  item container spacing={1}
                  md={2}
                  sm={2}
                  xs={2}
                >
                  <IconButton
                    style={{ padding: '5px' }}
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: true,
                    })}
                    onClick={() => setExpanded({ ...expanded, shopArea: !expanded.shopArea })}
                    aria-expanded={expanded.shopArea}
                    aria-label="show more">
                    <ExpandMore />
                  </IconButton>
                </Grid>
              </When>

              {/* ------------ area for shop ----------- */}
              <When test={hasMoreArea}>
                <Grid
                  item container spacing={1}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <Collapse in={expanded.shopArea}>
                    <Grid
                      item container spacing={1}
                      md={12}
                      sm={12}
                      xs={10}
                      style={{ paddingLeft: '4px' }}
                    >
                      <When test={isNonEmpty(form.values.baseFloorNet)}>
                        <Grid
                          item
                          md={3}
                          sm={3}
                          xs={6}
                        >
                          <Typography className={clsx(classes.otherAreas, classes.netColor)}>{getAreaString(form.values.baseFloorNet, `${langPropertyStock.baseFloor} (${langPropertyStock.captionNetAbbr})`)}</Typography>
                        </Grid>
                      </When>

                      <When test={isNonEmpty(form.values.baseFloorGross)}>
                        <Grid
                          item
                          md={3}
                          sm={3}
                          xs={6}
                        >
                          <Typography className={clsx(classes.otherAreas, classes.grossColor)}>{getAreaString(form.values.baseFloorGross, `${langPropertyStock.baseFloor} (${langPropertyStock.captionGrossAbrr})`)}</Typography>
                        </Grid>
                      </When>

                      <When test={isNonEmpty(form.values.groundFloorNet)}>
                        <Grid
                          item
                          md={3}
                          sm={3}
                          xs={6}
                        >
                          <Typography className={clsx(classes.otherAreas, classes.netColor)}>{getAreaString(form.values.groundFloorNet, `${langPropertyStock.groundFloor} (${langPropertyStock.captionNetAbbr})`)}</Typography>
                        </Grid>
                      </When>

                      <When test={isNonEmpty(form.values.groundFloorGross)}>
                        <Grid
                          item
                          md={3}
                          sm={3}
                          xs={6}
                        >
                          <Typography className={clsx(classes.otherAreas, classes.grossColor)}>{getAreaString(form.values.groundFloorGross, `${langPropertyStock.groundFloor} (${langPropertyStock.captionGrossAbrr})`)}</Typography>
                        </Grid>
                      </When>

                      <When test={isNonEmpty(form.values.mezzanineFloorNet)}>
                        <Grid
                          item
                          md={3}
                          sm={3}
                          xs={6}
                        >
                          <Typography className={clsx(classes.otherAreas, classes.netColor)}>{getAreaString(form.values.mezzanineFloorNet, `${langPropertyStock.mezzanineFloor} (${langPropertyStock.captionNetAbbr})`)}</Typography>
                        </Grid>
                      </When>

                      <When test={isNonEmpty(form.values.mezzanineFloorGross)}>
                        <Grid
                          item
                          md={3}
                          sm={3}
                          xs={6}
                        >
                          <Typography className={clsx(classes.otherAreas, classes.grossColor)}>{getAreaString(form.values.mezzanineFloorGross, `${langPropertyStock.mezzanineFloor} (${langPropertyStock.captionGrossAbrr})`)}</Typography>
                        </Grid>
                      </When>

                      <When test={isNonEmpty(form.values.otherAreaNet)}>
                        <Grid
                          item
                          md={3}
                          sm={3}
                          xs={6}
                        >
                          <Typography className={clsx(classes.otherAreas, classes.netColor)}>{getAreaString(form.values.otherAreaNet, `${langPropertyStock.otherArea} (${langPropertyStock.captionNetAbbr})`)}</Typography>
                        </Grid>
                      </When>

                      <When test={isNonEmpty(form.values.otherAreaGross)}>
                        <Grid
                          item
                          md={3}
                          sm={3}
                          xs={6}
                        >
                          <Typography className={clsx(classes.otherAreas, classes.grossColor)}>{getAreaString(form.values.otherAreaGross, `${langPropertyStock.otherArea} (${langPropertyStock.captionGrossAbrr})`)}</Typography>
                        </Grid>
                      </When>

                    </Grid>
                  </Collapse >
                </Grid>
              </When>

            </Grid>
            {/* ------------ heading and punchline  ----------- */}
            <When test={isNonEmpty(getHeading())
              || isNonEmpty(getPunchline())
            }>
              <Grid
                item container spacing={1}
                md={12}
                sm={12}
                xs={12}
              >
                <When test={isNonEmpty(getHeading())}>
                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>{getHeading()}</Typography>
                  </Grid>
                </When>

                <When test={isNonEmpty(getPunchline())}>
                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <Typography style={{ whiteSpace: 'pre-line' }} className={classes.smallerText}>{getPunchline()}</Typography>
                  </Grid>
                </When>

              </Grid>
            </When>


          </Grid>

          {/* ------------ owner section ----------- */}
          <When test={((isLeadAgent || canReadTeamProperty || canReadUnowned || (form.values.companyStock === true && canReadCompanyStock)) && isNonEmpty(form.values.owner))
            || (!form.values.companyStock && ((form.values.leadAgents?.length ?? 0) > 0))
          }>
            <Grid
              item container spacing={1}
            >
              <When test={(isLeadAgent || canReadTeamProperty || canReadUnowned || (form.values.companyStock === true && canReadCompanyStock)) && isNonEmpty(form.values.owner)}>
                <Grid
                  item container spacing={2}
                  md={4}
                  sm={5}
                  xs={12}
                  className={classes.sectionContainer}
                >
                  <Grid
                    item
                  // md={4}
                  // sm={4}
                  // xs={12}
                  >
                    <Typography className={classes.sectionLabel}>{langPropertyStock.owner}</Typography>
                  </Grid>

                  <Grid
                    item
                    // md={8}
                    // sm={8}
                    // xs={12}
                    style={{ display: 'flex', flexDirection: 'row' }}
                  >
                    <Typography style={{ fontSize: '13px' }}>{form.values.owner}</Typography>
                  </Grid>
                </Grid>
              </When>
              {/* ------------ lead agents section ----------- */}
              <When test={!form.values.companyStock && ((form.values.leadAgents?.length ?? 0) > 0)}>
                <Grid
                  item container spacing={1}
                  md={8}
                  sm={7}
                  xs={12}
                  className={classes.sectionContainer}
                  style={{ display: 'flex', flexDirection: 'row' }}
                >
                  {showAgentsInNewLine ?
                    <Grid
                      item
                      xs={12}
                    >
                      <Typography className={classes.sectionLabel}>{langPropertyStock.captionPropertyStockAgent}</Typography>
                    </Grid>
                    : null}

                  <Grid
                    item
                    // md={11} 
                    // sm={11}
                    // xs={12}
                    container
                    spacing={2}
                  >
                    {!showAgentsInNewLine ?
                      <Grid
                        item
                      >
                        <Typography className={classes.sectionLabel}>{langPropertyStock.captionPropertyStockAgent}</Typography>
                      </Grid>
                      : null}

                    {form.values.leadAgents?.map((agent: PropertyStockDTO['leadAgents'][0], i: number) => {
                      return (
                        <Grid
                          item
                          key={`${agent.userId}-name-card`}
                        >
                          <LeadAgentNameCard
                            propertyStockId={0}
                            clientId={0}
                            agentPhotoFilename={agent.photoFilename}
                            agentNameZh={agent.chineseName}
                            agentNameEn={agent.englishName}
                            agentContactType={agent.contacts[0]?.type ?? ''}
                            agentContactValue={agent.contacts[0]?.value ?? ''}

                            showClientContact={agent.userId?.toString() === uid}
                            clientNameEn={form.values.sellerEn}
                            clientNameZh={form.values.sellerZh}
                            clientContactValue={form.values.mainContact}
                            clientContactRemarks={form.values.mainContactRemarks}
                            clientContactType={form.values.mainContactType}
                          />
                        </Grid>
                      )

                    })}
                  </Grid>
                </Grid>
              </When>

            </Grid>
          </When>
          {/* ------------ sales info ----------- */}
          <When test={isNonEmpty(form.values.expiryYear)
            || isNonEmpty(form.values.occupancyPermitDate)
            || isNonEmpty(form.values.vacant)
            || isNonEmpty(form.values.keyNo)
            || ((tagForm.values['SYMBOL']?.length ?? 0) > 0)
            || isNonEmpty(form.values.rentFreePeriod)
            || isNonEmpty(form.values._yield)
            || isNonEmpty(getLeaseCommencementString())
            || isNonEmpty(form.values.currentRent)
          }>
            <Grid
              item container spacing={1}
              md={12}
              sm={12}
              xs={12}
              className={classes.sectionContainer}
            >
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
              >
                <Typography className={classes.sectionLabel}>{langPropertyStock.titleSaleInfo}</Typography>
              </Grid>

              <Grid
                item container spacing={1}
                md={12} sm={12}
                xs={12}
              >
                {printFeatureTile(langPropertyStock.expiryYear, form.values.expiryYear, salesInfoYellow)}
                {printFeatureTile(langPropertyStock.occupancyPermitDate, form.values.occupancyPermitDate ? moment(form.values.occupancyPermitDate).format(DISPLAY_DATE_FORMAT) : '', salesInfoYellow)}
                {printFeatureTile(langPropertyStock.vacant, vacantOptions[form.values.vacant ?? ''], salesInfoYellow)}
                {printFeatureTile(langPropertyStock.captionKeyNo, form.values.keyNo, salesInfoYellow)}
                {tagForm.values['SYMBOL']?.map(symbol => (
                  printFeatureTile(langPropertyStock.symbol, symbolOptions[symbol], salesInfoYellow)
                ))}
                {printFeatureTile(langPropertyStock.rentFreePeriod, form.values.rentFreePeriod)}
                {printFeatureTile(langPropertyStock._yield, form.values._yield)}
                {printFeatureTile(langPropertyStock.leaseCommencement, getLeaseCommencementString())}
                {printFeatureTile(langPropertyStock.currentRent, getPriceString(form.values.currentRent, ''))}
                {printFeatureTile(langPropertyStock.mgtProfile, profileOptions[form.values.mgtProfile ?? ''])}
                {printFeatureTile(langPropertyStock.mgtCommission, form.values.mgtCommission)}

              </Grid>
            </Grid>
          </When>
          {/* ------------ property features----------- */}
          <When test={isNonEmpty(form.values.facing)
            || isNonEmpty(form.values.deco)
            || ((tagForm.values['VIEW']?.length ?? 0) > 0)
            || isNonEmpty(getRoomString())
            || isNonEmpty(form.values.bathroom)
            || isNonEmpty(form.values.helperRoom)
            || isNonEmpty(form.values.gardenArea)
            || isNonEmpty(form.values.rooftopArea)
            || ((form.values.balconySizes?.length ?? 0) > 0)
            || isNonEmpty(form.values.primarySchoolNet)
            || isNonEmpty(form.values.secondarySchoolNet)
            || (form.values.usage === 'S'
              && (isNonEmpty(form.values.toilet)
                || isNonEmpty(form.values.backDoor)
                || isNonEmpty(form.values.mezzanineFloorArea)
                || isNonEmpty(form.values.storeFrontWidth)
                || isNonEmpty(form.values.storeFrontHeight)
                || isNonEmpty(form.values.shopDepth)
              ) || isNonEmpty(form.values.mgtProfile)
              || isNonEmpty(form.values.mgtCommission)
            )
          }>
            <Grid
              item container spacing={1}
              md={12}
              sm={12}
              xs={12}
              className={classes.sectionContainer}
            >
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
              >
                <Typography className={classes.sectionLabel}>{langPropertyStock.titlePropertyFeatures}</Typography>
              </Grid>

              <Grid
                item container spacing={1}
                md={12} sm={12}
                xs={12}
              >
                {printFeatureTile(langPropertyStock.facing, facingOptions[form.values.facing ?? ''], featureRed)}
                {printFeatureTile(langPropertyStock.deco, decoOptions[form.values.deco ?? ''], featureRed)}
                {tagForm.values['VIEW']?.map(view => (
                  printFeatureTile(langPropertyStock.view, viewOptions[view], featureRed)
                ))}
                {printFeatureTile(langPropertyStock.room, getRoomString(), roomBlue)}
                {printFeatureTile(langPropertyStock.bathroom, form.values.bathroom, roomBlue)}
                {form.values.usage == 'S' && printFeatureTile(langPropertyStock.toilet, form.values.toilet, roomBlue)}
                {printFeatureTile(langPropertyStock.helperRoom, form.values.helperRoom, roomBlue)}
                {printFeatureTile(langPropertyStock.garden, getAreaString(form.values.gardenArea), gardenGreen)}
                {printFeatureTile(langPropertyStock.rooftop, getAreaString(form.values.rooftopArea), gardenGreen)}
                {form.values.balconySizes?.map(size => (
                  printFeatureTile(langPropertyStock.balcony, getAreaString(size), gardenGreen)
                ))}

                {/* for shops only */}
                {form.values.usage == 'S' && printFeatureTile(langPropertyStock.backDoor, form.values.backDoor, shopPurple)}
                {form.values.usage == 'S' && printFeatureTile(langPropertyStock.mezzanineFloorArea, getAreaString(form.values.mezzanineFloorArea), shopPurple)}
                {form.values.usage == 'S' && printFeatureTile(langPropertyStock.storeFrontWidth, getAreaString(form.values.storeFrontWidth), shopPurple)}
                {form.values.usage == 'S' && printFeatureTile(langPropertyStock.storeFrontHeight, getAreaString(form.values.storeFrontHeight), shopPurple)}
                {form.values.usage == 'S' && printFeatureTile(langPropertyStock.shopDepth, getAreaString(form.values.shopDepth), shopPurple)}
                {/* end - for shops only */}

                {printFeatureTile(langPropertyStock.primarySchoolNet, primarySchoolNetOptions[form.values.primarySchoolNet ?? ''], schoolNetRed)}
                {printFeatureTile(langPropertyStock.secondarySchoolNet, secondarySchoolNetOptions[form.values.secondarySchoolNet ?? ''], schoolNetRed)}

              </Grid>
            </Grid>
          </When>
          {/* ------------ other features/club house facilities ----------- */}
          <When test={((tagForm.values['OTHER_FEATURES']?.length ?? 0) > 0)
            || ((tagForm.values['CLUB_HOUSE_FACILITIES']?.length ?? 0) > 0)
          }>
            <Grid
              item container spacing={1}
              md={12}
              sm={12}
              xs={12}
              className={classes.sectionContainer}
            >
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
              >
                <Typography className={classes.sectionLabel}>{langPropertyStock.otherFeature + '/' + langPropertyStock.clubHouseFacilities}</Typography>
              </Grid>

              <Grid
                item container spacing={1}
                md={12} sm={12}
                xs={12}
              >
                {tagForm.values['OTHER_FEATURES']?.map(key => (
                  printValueOnlyTile(otherFeatureOptions[key], otherFeatureYellow)
                ))}

                {tagForm.values['CLUB_HOUSE_FACILITIES']?.map(key => (
                  printValueOnlyTile(clubHouseFacilitiesOptions[key], clubHouseFacilitiesGreen)
                ))}

              </Grid>
            </Grid>
          </When>
          {/* ------------ others ----------- */}
          <When test={((tagForm.values['OTHERS']?.length ?? 0) > 0)
          }>
            <Grid
              item container spacing={1}
              md={12}
              sm={12}
              xs={12}
              className={classes.sectionContainer}
            >
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
              >
                <Typography className={classes.sectionLabel}>{langPropertyStock.others}</Typography>
              </Grid>

              <Grid
                item container spacing={1}
                md={12} sm={12}
                xs={12}
              >
                {tagForm.values['OTHERS']?.map(key => (
                  printValueOnlyTile(otherOptions[key], othersBlue)
                ))}
              </Grid>
            </Grid>
          </When>
          {/* ------------ remarks section ----------- */}
          <When test={isNonEmpty(form.values.remarks)
            || isNonEmpty(form.values.tenancyRemarks)
          }>
            <Grid
              item container spacing={1}
              md={12}
              sm={12}
              xs={12}
              className={classes.sectionContainer}
            >
              <When test={isNonEmpty(form.values.remarks)}>

                <Grid
                  item
                  md={6}
                  sm={6}
                  xs={12}
                >
                  <Typography className={classes.sectionLabel}>{langPropertyStock.remarks}</Typography>
                  <div className={classes.remarksTextAreaContainer}>
                    <div
                      className={classes.remarksTextArea}
                    >
                      {form.values.remarks ?? ''}
                    </div>
                  </div>

                </Grid>
              </When>
              <When test={isNonEmpty(form.values.tenancyRemarks)}>

                <Grid
                  item
                  md={6}
                  sm={6}
                  xs={12}
                >
                  <Typography className={classes.sectionLabel}>{langPropertyStock.tenancyRemarks}</Typography>
                  <div className={classes.remarksTextAreaContainer}>
                    <div
                      className={classes.remarksTextArea}
                    >
                      {form.values.tenancyRemarks ?? ''}
                    </div>
                  </div>
                </Grid>
              </When>
            </Grid>
          </When>
          {/* ------------ Property management info ----------- */}
          <When test={isNonEmpty(form.values.developer)
            || isNonEmpty(form.values.assessmentNum)
            || isNonEmpty(form.values.prn)
            || isNonEmpty(form.values.mgtCompanyRegNum)
            || isNonEmpty(form.values.quarterlyRates)
            || isNonEmpty(form.values.annualGovRent)
            || isNonEmpty(form.values.ratesPropertyAddress)
          }>
            <Grid
              item container spacing={1}
              md={12}
              sm={12}
              xs={12}
              className={classes.sectionContainer}
            >
              <Grid
                item
                md={1}
                sm={2}
                xs={12}
              >
                <Typography className={classes.sectionLabel}>{langPropertyStock.titleMgtInfo}</Typography>
              </Grid>

              <Grid
                item container spacing={1}
                md={12} sm={12}
                xs={12}
              >
                {printFeatureTile(langPropertyStock.developer, form.values.developer)}
                {printFeatureTile(langPropertyStock.assessmentNum, form.values.assessmentNum)}
                {printFeatureTile(langPropertyStock.prn, form.values.prn)}
                {printFeatureTile(langPropertyStock.mgtCompanyRegNum, form.values.mgtCompanyRegNum)}
                {printFeatureTile(langPropertyStock.quarterlyRates, getPriceString(form.values.quarterlyRates, ''))}
                {printFeatureTile(langPropertyStock.annualGovRent, getPriceString(form.values.annualGovRent, ''))}
                {printFeatureTile(langPropertyStock.ratesPropertyAddress, form.values.ratesPropertyAddress)}


              </Grid>

            </Grid>
          </When>
          {/* ------------ Management fee section ----------- */}
          <When test={isNonEmpty(form.values.monthlyMgtFee)
            || isNonEmpty(monthlyMgtFeePerNet)
            || isNonEmpty(monthlyMgtFeePerGross)
            || isNonEmpty(form.values.mgtFeeIncl)
          }>
            <Grid
              item container spacing={1}
              md={12}
              sm={12}
              xs={12}
              className={classes.sectionContainer}
            >
              <Grid
                item
                md={1}
                sm={2}
                xs={12}
              >
                <Typography className={classes.sectionLabel}>{langPropertyStock.monthlyMgtFee}</Typography>
              </Grid>

              <Grid
                item container spacing={shouldCollapseMgtFee ? 1 : undefined}
                md={11}
                sm={10}
                xs={12}
                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
              >
                {isNonEmpty(form.values.monthlyMgtFee) &&
                  <Grid item xs={shouldCollapseMgtFee ? 12 : undefined}>
                    <Typography style={{ fontSize: '20px', fontWeight: 'bold' }} className={clsx(classes.mgtFeeDetailContainer, classes.priceColor)}>{getPriceString(form.values.monthlyMgtFee, '')}</Typography>
                  </Grid>
                }
                {isNonEmpty(monthlyMgtFeePerNet) &&
                  <Grid item>
                    <Typography style={{ fontSize: '14px' }} className={clsx(classes.mgtFeeDetailContainer, classes.netColor)}>{getPriceString(monthlyMgtFeePerNet, langPropertyStock.monthlyMgtFeePerNet)}</Typography>
                  </Grid>
                }
                {isNonEmpty(monthlyMgtFeePerGross) &&
                  <Grid item>
                    <Typography style={{ fontSize: '14px' }} className={clsx(classes.mgtFeeDetailContainer, classes.grossColor)}>{getPriceString(monthlyMgtFeePerGross, langPropertyStock.monthlyMgtFeePerGross)}</Typography>
                  </Grid>
                }
                {isNonEmpty(form.values.mgtFeeIncl) &&
                  <Grid item>
                    <div className={classes.mgtFeeDetailContainer}>
                      {printValueOnlyTile(mgtFeeInclOptions[form.values.mgtFeeIncl ?? ''])}
                    </div>
                  </Grid>
                }
              </Grid>

            </Grid>
          </When>
          {/* ------------ documents section ----------- */}
          <When test={isNonEmpty(fileForm.values.LAND_SEARCH)
            || isNonEmpty(fileForm.values.SA_SEARCH)
            || isNonEmpty(fileForm.values.COMPANY_SEARCH)
            || isNonEmpty(fileForm.values.OTHERS)
          }>
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
            >
              <Typography className={classes.sectionLabel}>{langPropertyStock.titleFile}</Typography>

              {/* <Grid item md={12} sm={12} xs={12} spacing={1}> */}
              <Grid item md={12} sm={12} xs={12}>
                <Documents isPreviewPropertyStockPage={true} fileForm={fileForm} clientFileForm={clientFileForm} stockForm={form} canDownloadOrDeleteFormFiles={canDownloadOrDeleteFormFiles} canDownloadPaFiles={canDownloadPaFiles} canUploadPropertyDocFiles={canUploadPropertyDocFiles}
                  canEditPa={canEditPa} canEditForm={canEditForm} docTypeOptions={documentOptions} isPaAgent={isPaAgent} sectionTitle={langPropertyStock.titleFile} elementIdPrefix={'ps-doc-section'} />
              </Grid>
            </Grid>
          </When>
        </Grid>
      </CardContent>
    </Card>
  </div>;
};

export default PreivewCard; 