import MomentUtils from '@date-io/moment';
import { Grow } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
// import { createBrowserHistory } from 'history';
import Chart from 'chart.js';
import ClientMergeDialog from 'common/elements/ClientMergeDialog';
import { ScrollToTop } from 'common/ui';
import { isNonEmpty } from 'common/utils';
// const browserHistory = createBrowserHistory({ basename: process.env.PUBLIC_URL });
import moment from 'moment';
import "moment/locale/zh-cn";
import "moment/locale/zh-hk";
import React, { useEffect, useState } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { Router } from 'react-router-dom';
import { IRootState, PASDispatch } from 'reducers';
import { format } from 'util';
import validate from 'validate.js';
import './assets/scss/index.scss';
import validators from './common/validators';
import { chartjs } from './helpers';
import Routes from './Routes';
import { browserHistory } from './store';
import theme from './theme';




(Chart as any).helpers.extend((Chart as any).elements.Rectangle.prototype, {
  draw: chartjs.draw
});

validate.validators = {
  ...validate.validators,
  ...validators
};

const BLANK = {};

export default function App() {
  const { initialized, initializationFailed } = useSelector((state: IRootState) => state.layout);
  const { GIT, ENV } = useSelector((state: IRootState) => state.systemSettings?.BuildInfo) ?? BLANK;
  const dispatch = useDispatch() as PASDispatch;
  const { langUser, locale } = useSelector((state: IRootState) => state.locale);
  const { impersonationId, username, impersonateUsername } = useSelector((state: IRootState) => state.login);
  const impersonating = isNonEmpty(impersonationId);

  const [logoAppear, setLogoAppear] = useState(false);
  const [logoAppeared, setLogoAppeared] = useState(false);

  const initialize = () => dispatch({ type: 'Layout.Initialize' });

  useEffect(() => {
    initialize();
    setTimeout(() => setLogoAppear(true), 100);
    setTimeout(() => setLogoAppeared(true), 900);
  }, []);

  useEffect(() => {
    if (locale === 'en') {
      moment.locale('en');
    } else if (locale === 'zh_HK') {
      moment.locale('zh-hk');
    } else if (locale === 'zh_CN') {
      moment.locale('zh-cn');
    }
  }, [locale])

  const main = (
    <Router history={browserHistory}>
      {/* impersonating warning */}
      {impersonating ?
        <div style={{ color: 'red', userSelect: 'none', pointerEvents: 'none', fontSize: 20, fontWeight: 'bolder', position: 'fixed', top: '0%', width: '100%', margin: 'auto', textAlign: "center", zIndex: 10000 }}>
          {format(langUser.impersonating, impersonateUsername, username)}
        </div>
        : null}
      <ScrollToTop />
      {/* DEV_ENV */}
      <div style={{ color: 'rgba(0,0,0,0.3)', userSelect: 'none', pointerEvents: 'none', fontWeight: 'bolder', position: 'fixed', bottom: '5%', left: '5%', zIndex: 10000 }}>
        {(ENV?.PROFILE === 'prd') ? '' :
          `${ENV?.PROFILE?.toUpperCase()} (ver: ${GIT?.DESCRIPTION})`
        }
      </div>
      <Routes />
      <ClientMergeDialog />
    </Router>
  );

  const splash = (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Grow timeout={500} in={logoAppear}>
        <img style={{ width: 250 }} src="images/logo-1.png" />
      </Grow>
      <div style={{ color: 'red', marginTop: 20, fontSize: 17, }}>
        {initializationFailed ? <span>無法連接伺服器, 請<a href="#" onClick={initialize}>重試</a></span> : null}
      </div>
    </div>
  );

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} locale={locale}>
        {initialized && logoAppeared ? main : splash}
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}
