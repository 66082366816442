import { hasPermission } from "common/access-control";
import { useConfirmDialog } from 'common/ConfirmDialog';
import { ClientDetailDTO, ClientFileMap, ClientPreferencesDTO } from "common/dto";
import { BLANK_RANGE } from "common/elements/MinMaxField";
import NavigationBlockerDialog from "common/elements/NavigationBlockerDialog";
import usePageTitle, { useForm } from "common/hooks";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { IRootState, PASDispatch } from "reducers";
import { isNullOrUndefined } from "util";
import ClientDetail from './ClientDetail';
import { ClientDetailFormValidations, PreferenceFormValidations } from "./components/client-validations";
import CreateClientWarningDialog from "./components/CreateClientWarningDialog";

export default function ClientCreatePage() {
  const dispatch = useDispatch() as PASDispatch;

  const cid = '0';
  const { langClientDetail, lang } = useSelector((state: IRootState) => state.locale);

  const clientDetailDto = useSelector((state: IRootState) => state.clients.fetchedDetails[cid]) ?? {} as Partial<ClientDetailDTO>;
  const { privileges } = useSelector((state: IRootState) => state.login);
  const canCreateOwnedClient = hasPermission(privileges, 'CREATE', 'OWN_CLIENT');
  const canCreateUnownedClient = hasPermission(privileges, 'CREATE', 'UNOWNED_CLIENT');
  const canAssignUnownedAgentClient = hasPermission(privileges, 'UPDATE', 'ASSIGN_UNOWNED_AGENT_CLIENT');
  const [duplicatedContact, setDuplicatedContact] = useState(false);
  const confirmCreateDialog = useConfirmDialog();

  usePageTitle(langClientDetail.titleNewClientDetail);
  
  const clientDetailForm = useForm<ClientDetailDTO>({
    ...clientDetailDto,
    clientStatus: 'UNKNOWN',
  }, {
    validations: ClientDetailFormValidations(langClientDetail), scrollToErrorOnValidate: true, dataKeyPrefix: 'clientdetails'
  });

  const agentContactsForm = useForm<any>({
    agentId: '',
    agentNameEn: '',
    agentNameZh: '',
    agentContact: {}
  });

  const otherContactsForm = useForm<any>({
    otherContacts: []
  });

  const tagForm = useForm<{ [type: string]: string[] }>({
    view: [],
    otherFeatures: [],
    symbol: [],
    clientType: [],
    usage: [],
    facing: [],
    deco: [],
    livingRoom: [],

    propertyStatus: [],
    clubHouseFacilities: [],
    others: [],
    secondarySchoolNet: [],
    primarySchoolNet: [],

  }, {
    validations: [],
  });

  const multiSelectForm = useForm<{ [type: string]: string[] }>({
    room: [],
    suite: [],
    livingRoom: [],
    helperRoom: [],
    balcony: [],
    bathroom: [],
    // propertyStatus: [],
    district: [],
    level: [],
  }, {
    validations: [],
  });

  const preferenceForm = useForm<ClientPreferencesDTO>({
    //TODO: remove later
    gross: BLANK_RANGE,
    net: BLANK_RANGE,
    price: BLANK_RANGE,
    rent: BLANK_RANGE,

    room: undefined,
    suite: undefined,
    helperRoom: undefined,
    balcony: undefined,
    bathroom: undefined,

    // //supply min/max value
    // netAreaFrom: 0,
    // netAreaTo: 3000,
    // buyBudgetFrom: 3000000,
    // buyBudgetTo: 200000000,
    // rentBudgetFrom: 3000,
    // rentBudgetTo: 15000,


    // facingArray: [],
  }, {
    validations: PreferenceFormValidations(langClientDetail),
  });

  const fileForm = useForm<ClientFileMap>({
    FORM_4: [],
    FORM_6: [],
  } as ClientFileMap);


  // Request API again when the id param chenged
  // useEffect(() => {
  //   dispatch({ type: 'Property.FetchRequested', payload: { id: id ?? '' } });
  // }, [ id ]);

  useEffect(() => {
    dispatch({ type: 'ClientDetail.FetchRequested', payload: { cid: cid ?? '' } });
  }, []);


  useEffect(() => {
    clientDetailForm.setValues({
      ...clientDetailDto,
      hasAddress: false,
      clientPreferences: {
        ...clientDetailForm.values.clientPreferences,
        // //supply initial min/max value
        // netAreaFrom: 0,
        // netAreaTo: 3000,
        // buyBudgetFrom: 3000000,
        // buyBudgetTo: 200000000,
        // rentBudgetFrom: 3000,
        // rentBudgetTo: 15000,
      }
    });
    preferenceForm.setValues({
      // netAreaFrom: 0,
      // netAreaTo: 3000,
      // buyBudgetFrom: 3000000,
      // buyBudgetTo: 200000000,
      // rentBudgetFrom: 3000,
      // rentBudgetTo: 15000,
      gross: BLANK_RANGE,
      net: BLANK_RANGE,
      price: BLANK_RANGE,
      rent: BLANK_RANGE,
    })

    const fileMap: ClientFileMap = {
      FORM_4: [],
      FORM_6: [],
    };

    clientDetailDto.form4Attachments?.forEach(attachment => {
      const type = 'FORM_4';
      if (fileMap[type]) {
        fileMap[type].push(attachment);
      }
    });

    clientDetailDto.form6Attachments?.forEach(attachment => {
      const type = 'FORM_6';
      if (fileMap[type]) {
        fileMap[type].push(attachment);
      }
    });

    fileForm.setValues(fileMap);

  }, [clientDetailDto?.cid]);

  //update ClientDetailForm.clientPreference 
  useEffect(() => {
    clientDetailForm.setValues({
      ...clientDetailForm.values,
      clientPreferences: preferenceForm.values,
    });
  }, [preferenceForm.values]);

  //update ClientDetailForm.agentContacts
  useEffect(() => {
    clientDetailForm.setValues({
      ...clientDetailForm.values,
      agentId: agentContactsForm.values.agentId,
      agentNameEn: agentContactsForm.values.agentNameEn,
      agentNameZh: agentContactsForm.values.agentNameZh,
      agentContact: agentContactsForm.values.agentContact,
    });
  }, [agentContactsForm.values]);

  //update clientDetailForm.otherContacts 
  useEffect(() => {
    clientDetailForm.setValues({
      ...clientDetailForm.values,
      otherContacts: otherContactsForm.values.otherContacts,
    });
  }, [otherContactsForm.values]);

  //update client type and usage (for sorting)
  useEffect(() => {
    clientDetailForm.setValues({
      ...clientDetailForm.values,
      // clientPreferences: {
      //   ...clientDetailForm.values.clientPreferences,
      //   usage: tagForm.values.usage?.join(','),
      //   clientType: tagForm.values.clientType?.join(','),
      // },
    });
    preferenceForm.setValues({
      ...preferenceForm.values,
      usage: tagForm.values.usage?.join(','),
      clientType: tagForm.values.clientType?.join(','),
    })
  }, [tagForm.values.usage, tagForm.values.clientType]);

  const trimSpace = (array: string[] | undefined) => {
    const joinStr = array?.filter(v => v != '').join(',');
    if (joinStr == '') {
      return undefined;
    }
    return joinStr;
  }

  //handle multiSelectForm
  useEffect(() => {
    clientDetailForm.setValues({
      ...clientDetailForm.values,
      clientPreferences: {
        ...clientDetailForm.values.clientPreferences,
        room: trimSpace(multiSelectForm.values.room),
        suite: trimSpace(multiSelectForm.values.suite),
        livingRoom: trimSpace(multiSelectForm.values.livingRoom),
        helperRoom: trimSpace(multiSelectForm.values.helperRoom),
        balcony: trimSpace(multiSelectForm.values.balcony),
        bathroom: trimSpace(multiSelectForm.values.bathroom),
        // propertyStatus: trimSpace(multiSelectForm.values.propertyStatus),
        // district: trimSpace(multiSelectForm.values.district),
        level: trimSpace(multiSelectForm.values.level),
      },
    });
  }, [multiSelectForm.values]);

  useEffect(() => {
    preferenceForm.updateValues('level',
      // ...preferenceForm.values,
      // district: trimSpace(multiSelectForm.values.district),
      trimSpace(multiSelectForm.values.level)
    );
  }, [multiSelectForm.values.level])

  //---------- warning dialog

  const [warningDialogOpen, setWarningDialogOpen] = React.useState(false);

  const showWarningDialog = () => {
    if (isNullOrUndefined(clientDetailForm.values.agentId) && !canCreateOwnedClient && canCreateUnownedClient && canAssignUnownedAgentClient) {
      return true;
    }
    return false;
  }

  //---------- save action
  const doSave = () => {
    if (duplicatedContact) {
      confirmCreateDialog.confirm(langClientDetail.msgDuplicatedContactOnSave, lang.actionConfirm, lang.actionCancel).then(

        (confirm) => {
          if (confirm) {
            const assembleFormToDto = () => {
              const updatedResult = {
                ...clientDetailForm.values,
                //TODO: unit transformation for budget??
                clientFeatures: Object.keys(tagForm.values)
                  .map(type => tagForm.values[type]?.map(value => ({ type, value })))
                  .reduce((a, b) => (a ?? []).concat(b ?? []), []),

                form4Attachments: (fileForm.values["FORM_4"] ?? [])
                  .reduce((a: any, b: any) => (a ?? []).concat(b ?? []), []),
                //isManualUpload, purchaserName will be handled in backend

                form6Attachments: (fileForm.values["FORM_6"] ?? [])
                  .reduce((a: any, b: any) => (a ?? []).concat(b ?? []), []),
                //isManualUpload, tenantName will be handled in backend

                agentId: clientDetailForm.values.agentId,
              } as Record<string, any>;
              Object.keys(updatedResult).forEach(key => {
                if (typeof updatedResult[key as keyof ClientDetailDTO] === "string" && updatedResult[key as keyof ClientDetailDTO].trim() === "") {
                  updatedResult[key as keyof ClientDetailDTO] = null;
                }
                if (key === 'otherContacts') {
                  updatedResult["otherContacts"].forEach((contact: any) => {
                    Object.keys(contact).forEach((key2: any) => {
                      if (typeof contact[key2] === "string" && contact[key2].trim() === "") {
                        contact[key2] = null;
                      }
                    })
                  })
                } else if (key === 'clientPreferences') {
                  Object.keys(updatedResult["clientPreferences"]).forEach(key2 => {
                          if (typeof updatedResult["clientPreferences"][key2 as keyof ClientPreferencesDTO] === "string" && updatedResult["clientPreferences"][key2 as keyof ClientPreferencesDTO].trim() === "") {
                      updatedResult["clientPreferences"][key2 as keyof ClientPreferencesDTO] = null;
                    }
                  })
                }
              });
              return (updatedResult as ClientDetailDTO);

            }
            dispatch({
              type: 'ClientDetail.CreationRequested', payload: assembleFormToDto()
            });
          }
        })
    } else {
      const assembleFormToDto = () => {
        const updatedResult = {
          ...clientDetailForm.values,
          //TODO: unit transformation for budget??
          clientFeatures: Object.keys(tagForm.values)
            .map(type => tagForm.values[type]?.map(value => ({ type, value })))
            .reduce((a, b) => (a ?? []).concat(b ?? []), []),

          form4Attachments: (fileForm.values["FORM_4"] ?? [])
            .reduce((a: any, b: any) => (a ?? []).concat(b ?? []), []),
          //isManualUpload, purchaserName will be handled in backend

          form6Attachments: (fileForm.values["FORM_6"] ?? [])
            .reduce((a: any, b: any) => (a ?? []).concat(b ?? []), []),
          //isManualUpload, tenantName will be handled in backend

          agentId: clientDetailForm.values.agentId,
        } as Record<string, any>;
        Object.keys(updatedResult).forEach(key => {
          if (typeof updatedResult[key as keyof ClientDetailDTO] === "string" && updatedResult[key as keyof ClientDetailDTO].trim() === "") {
            updatedResult[key as keyof ClientDetailDTO] = null;
          }
          if (key === 'otherContacts') {
            updatedResult["otherContacts"].forEach((contact: any) => {
              Object.keys(contact).forEach((key2: any) => {
                if (typeof contact[key2] === "string" && contact[key2].trim() === "") {
                  contact[key2] = null;
                }
              })
            })
          } else if (key === 'clientPreferences') {
            Object.keys(updatedResult["clientPreferences"]).forEach(key2 => {
              if (typeof updatedResult["clientPreferences"][key2 as keyof ClientPreferencesDTO] === "string" && updatedResult["clientPreferences"][key2 as keyof ClientPreferencesDTO].trim() === "") {
                updatedResult["clientPreferences"][key2 as keyof ClientPreferencesDTO] = null;
              }
            })
          }
        });
        return (updatedResult as ClientDetailDTO);
      }

      dispatch({
        type: 'ClientDetail.CreationRequested', payload: assembleFormToDto()
      });
    }
  }

  const [shoudBlockNaigation, setShoudBlockNaigation] = useState(true);

  return <div>
    <NavigationBlockerDialog matchesUrl={`/client-detail/new`} unsaved={shoudBlockNaigation} goBack />
    <CreateClientWarningDialog preferenceForm={preferenceForm} open={warningDialogOpen} handleClose={() => setWarningDialogOpen(false)}
      handleConfirm={() => {
        // if ( (!preferenceForm.values.clientType?.includes('B') || !preferenceForm.values.clientType?.includes('T') )&& !preferenceForm.validate()){
        //   return;
        // }
        doSave()
      }} />
    <ClientDetail onSave={() => {
      if (!clientDetailForm.validate()) {
        return;
      }
      if (showWarningDialog()) {
        // confirmCreateDialog.confirm(langClientDetail.msgWarningCreateDormantClient, lang.actionConfirm, lang.actionCancel).then((confirmed) => {
        //   if (confirmed) {
        //     doSave();
        //   }
        // })
        setWarningDialogOpen(true);
        setShoudBlockNaigation(false);
      } else {
        setShoudBlockNaigation(false);
        doSave();
      }
    }} form={clientDetailForm} creating={true} canEdit={true} unsaved={true}
      confirmCreateDialog={confirmCreateDialog}
      tagForm={tagForm}
      preferenceForm={preferenceForm}
      agentContactsForm={agentContactsForm}
      otherContactsForm={otherContactsForm}
      multiSelectForm={multiSelectForm}
      fileForm={fileForm}
      setDuplicatedContact={setDuplicatedContact} /></div>;
}