import { hasPermission } from 'common/access-control';
import { ClientFileMap, PropertyFileMap, PropertyMultiValueMap, PropertyStockDTO } from "common/dto";
import usePageTitle, { useForm } from "common/hooks";
import { isNonEmpty, priceToView } from 'common/utils';
import deepEqual from 'deep-equal';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router";
import { IRootState, PASDispatch } from "reducers";
import PreivewCard from "./PreviewCard";

const EMPTY = {};

export default function PropertyPreviewPage() {
  const dispatch = useDispatch() as PASDispatch;
  // const [ isNew, setIsNew ] = useState(true);
  // const pid = 'PS10000';
  const { propertyNo }:any = useParams();
  const propertyStockDto = useSelector((state: IRootState) => state.property.fetchedDetails[propertyNo!]) ?? EMPTY as Partial<PropertyStockDTO>;
  const { langPropertyStock,locale } = useSelector((state: IRootState) => state.locale);
  const { privileges } = useSelector((state: IRootState) => state.login); 
  const propertyStockOpen = propertyStockDto.status !== 'SOLD' && propertyStockDto.status !== 'LEASED';

  usePageTitle(langPropertyStock.titlePropertyDetail);
  // Permissions
  // const canRead = propertyStockDto.companyStock ? 
  //   (propertyStockDto.canReadCompanyStock && hasPermission(privileges, 'READ', 'COMPANY_STOCK'))
  //    :
  //   (propertyStockDto.isLeadAgent && hasPermission(privileges, 'READ', 'OWN_PROPERTY_STOCK')) ||
  //   (propertyStockDto.isTeamHead && hasPermission(privileges, 'READ', 'TEAM_PROPERTY_STOCK')) ||
  //   (
  //     hasPermission(privileges, 'READ', 'UNOWNED_PROPERTY_STOCK')
  //   )
  //   ||
  //   (propertyStockDto.canReadCompanyStock && hasPermission(privileges, 'READ', 'COMPANY_STOCK'))
  // ;
  const canUpdateOwn = propertyStockDto.isLeadAgent && hasPermission(privileges, 'UPDATE', 'OWN_PROPERTY_STOCK');
  const canUpdate = propertyStockDto.companyStock ? 
    (hasPermission(privileges, 'UPDATE', 'COMPANY_STOCK') && propertyStockOpen)
    : (
      canUpdateOwn ||
      (propertyStockDto.isTeamHead && hasPermission(privileges, 'UPDATE', 'TEAM_PROPERTY_STOCK')) ||
      (!propertyStockDto.isTeamHead &&
        !propertyStockDto.isLeadAgent &&
        hasPermission(privileges, 'UPDATE', 'UNOWNED_PROPERTY_STOCK')
      )
    )
  && propertyStockOpen;
  // const hideFloor = !hasPermission(privileges, 'READ', 'UNOWNED_PROPERTY_STOCK') &&
  //   !canUpdateOwn;
  const hideFloor = false; // FIXME: Pending removal: field masking is handled by backend

  // 
  
  // Form
  const stockForm = useForm<PropertyStockDTO>({
    ...propertyStockDto,
    // Price unit transformation
    price: propertyStockDto.price ? priceToView(propertyStockDto.price, locale) : 0,
    bottomPrice: propertyStockDto.bottomPrice ? priceToView(propertyStockDto.bottomPrice, locale) : 0,
  }, {
    getDisabledProps: () => ({ disabled: true }),
    allDisabled: !canUpdate,
  });

  useEffect(() => {
    stockForm.setValues({
      ...propertyStockDto,
      // Price unit transformation
      price: propertyStockDto.price ? priceToView(propertyStockDto.price, locale) : 0,
      bottomPrice: propertyStockDto.bottomPrice ? priceToView(propertyStockDto.bottomPrice, locale) : 0,
    });
  }, [ locale, propertyStockDto ]);
  
  const tagForm = useForm<PropertyMultiValueMap>({
    VIEW: [],
    OTHER_FEATURES: [],
    SYMBOL: [],
    LIVING_ROOM: [],
    CLUB_HOUSE_FACILITIES: [],
    OTHERS: [],
    BALCONY_SIZES: [],
  } as PropertyMultiValueMap, {
    getDisabledProps: () => ({ disabled: true }),
    allDisabled: !canUpdate,
  });

  const fileForm = useForm<PropertyFileMap>({
    PHOTO: [],
    FLOOR_PLAN: [],
    LAND_SEARCH: [],
    SA_SEARCH: [],
    OP: [],
    OTHERS: [],
    COMPANY_SEARCH: [],
    FORM_1: [],
    FORM_2: [],
    FORM_3: [],
    FORM_5: [],
    PROVISIONAL_AGREEMENT: [],
    SUPPLEMENTAL_DOCUMENT: [],
    CLOSE_TRNX_SUPP: []
  } as PropertyFileMap, {
    allDisabled: !canUpdate,
  });


  const clientFileForm = useForm<ClientFileMap>({
    FORM_4: [],
    FORM_6: [],
  } as ClientFileMap);

  // useEffect(() => {
  //   window.SCBeacon.trackGoal("Browse Property", { data: "propertyStockId", dataKey: id });
  // }, [ id ]);

  // Request API again when the id param changed
  useEffect(() => {
    dispatch({ type: 'Property.FetchByPropertyNoRequested', payload: { propertyNo: propertyNo ?? '' } });
  }, [ propertyNo ]);

  // Refresh form object when propertyStockDto changed
  useEffect(() => {
    

    const stockFormValues = {
      ...propertyStockDto,
      // Price unit transformation
      price: propertyStockDto.price ? priceToView(propertyStockDto.price, locale) : 0,
      bottomPrice: propertyStockDto.bottomPrice ? priceToView(propertyStockDto.bottomPrice, locale) : 0,
    };
    stockForm.setValues(stockFormValues);

    // Transform features to view model
    let featureMap: { [ type: string ]: string[] } = {};
    propertyStockDto.features?.filter(f=> isNonEmpty(f.value))?.forEach(f => {
      if (!featureMap[f.type]) {
        featureMap[f.type] = [];
      }

      featureMap[f.type].push(f.value);
    });

    const tagFormValues = {
      ...tagForm.values,
      ...featureMap,
    };

    tagForm.setValues(tagFormValues);

    // Transform property attachment to view model
    const fileMap: PropertyFileMap = {
      PHOTO: [],
      FLOOR_PLAN: [],
      LAND_SEARCH: [],
      SA_SEARCH: [],
      OP: [],
      COMPANY_SEARCH: [],
      OTHERS: [],
      FORM_1: [],
      FORM_2: [],
      FORM_3: [],
      FORM_5: [],
      PROVISIONAL_AGREEMENT: [],
      SUPPLEMENTAL_DOCUMENT: [],
      CLOSE_TRNX_SUPP: []
    };
    propertyStockDto.attachments?.forEach(attachment => {
      const type = attachment.type as keyof PropertyFileMap;
      if (fileMap[type]) {
        fileMap[type].push(attachment);
      }
    });
    fileForm.setValues(fileMap);

    setInitialFormValues([ stockFormValues, tagFormValues, fileMap ]);

    //form4 and form6 ready only
    const clientFileMap: ClientFileMap = {
      FORM_4: [],
      FORM_6: [],
    };

    propertyStockDto.form4Attachments?.forEach(attachment => {
      const type = 'FORM_4';
      if (clientFileMap[type]) {
        clientFileMap[type].push(attachment);
      }
    });

    propertyStockDto.form6Attachments?.forEach(attachment => {
      const type = 'FORM_6';
      if (clientFileMap[type]) {
        clientFileMap[type].push(attachment);
      }
    });
    clientFileForm.setValues(clientFileMap);

  }, [ propertyStockDto ]);


  // Unsaved Check
  const [ initialFormValues, setInitialFormValues ] = useState<any>({});
  

  // const [ shouldUpdateApprovalStage, setShouldUpdateApprovalStage ] = useState(false);

  useEffect(() => {
    // 
    // 
    // Object.keys(stockForm.values).forEach((key) => {
    //   
    //   if (stockForm.values?.[key as keyof Partial<PropertyStockDTO>] !== initialFormValues['0']?.[key]) {
    //     
    //   }
    // });
    // 
    //   [ 
    //     fileForm.values?.CLOSE_TRNX_SUPP, 
    //     fileForm.values?.COMPANY_SEARCH, 
    //     fileForm.values?.FLOOR_PLAN,
    //     fileForm.values?.LAND_SEARCH, 
    //     fileForm.values?.OP, 
    //     fileForm.values?.OTHERS,
    //     fileForm.values?.PHOTO,
    //     fileForm.values?.SA_SEARCH,
    //     fileForm.values?.SUPPLEMENTAL_DOCUMENT 
    //   ],
    //   [
    //     initialFormValues['2']?.CLOSE_TRNX_SUPP,
    //     initialFormValues['2']?.COMPANY_SEARCH,
    //     initialFormValues['2']?.FLOOR_PLAN,
    //     initialFormValues['2']?.LAND_SEARCH,
    //     initialFormValues['2']?.OP,
    //     initialFormValues['2']?.OTHERS,
    //     initialFormValues['2']?.PHOTO,
    //     initialFormValues['2']?.SA_SEARCH,
    //     initialFormValues['2']?.SUPPLEMENTAL_DOCUMENT
    // ], { strict: true }));
    const shouldSetApprovalStage = !deepEqual([ stockForm.values ], [ initialFormValues['0'] ], { strict: true }) 
     || !deepEqual([ tagForm.values ], [ initialFormValues['1'] ], { strict: true })
     || !deepEqual(
      [ 
        // fileForm.values?.CLOSE_TRNX_SUPP, 
        fileForm.values?.COMPANY_SEARCH, 
        fileForm.values?.FLOOR_PLAN,
        fileForm.values?.LAND_SEARCH, 
        fileForm.values?.OP, 
        fileForm.values?.OTHERS,
        fileForm.values?.PHOTO,
        fileForm.values?.SA_SEARCH,
        // fileForm.values?.SUPPLEMENTAL_DOCUMENT 
      ],
      [
        // initialFormValues['2']?.CLOSE_TRNX_SUPP,
        initialFormValues['2']?.COMPANY_SEARCH,
        initialFormValues['2']?.FLOOR_PLAN,
        initialFormValues['2']?.LAND_SEARCH,
        initialFormValues['2']?.OP,
        initialFormValues['2']?.OTHERS,
        initialFormValues['2']?.PHOTO,
        initialFormValues['2']?.SA_SEARCH,
        // initialFormValues['2']?.SUPPLEMENTAL_DOCUMENT
    ], { strict: true });

    // 
    // setShouldUpdateApprovalStage(shouldSetApprovalStage);

  }, [ stockForm.values, tagForm.values, fileForm ]);

return <PreivewCard saveDisabled={true} form={stockForm} tagForm={tagForm} fileForm={fileForm} hideFloor={hideFloor} clientFileForm={clientFileForm}/>;
}