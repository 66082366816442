import { makeStyles } from '@material-ui/core';
import { TransactionSearchDTO } from 'common/dto';
import usePageTitle from 'common/hooks';
import React, { useEffect, useState } from 'react';
import { IMaskInput } from 'react-imask';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState, PASDispatch } from 'reducers';
import { TransactionsSearch, TransactionsTable, TransactionsToolbar } from './components';

const DEFAULT_SIZE = 10;

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  search: {
    marginBottom: theme.spacing(2)
  }
}));


function toCriteria(values: any) {
  return values;
}

function MaskedInput(props: any) {
  return (
    <IMaskInput
      {...props}
      mask={Number}
      thousandsSeparator=","
    />
  );
}

let initialPage = 0 as number | undefined;

let currentTransactionRow = 0;

const TransactionList = () => {
  const classes = useStyles();

  const dispatch = useDispatch() as PASDispatch;
  const { langTransaction, rowsCountPrefOptions } = useSelector((state: IRootState) => state.locale);

  const transactions = useSelector((state: IRootState) => state.transaction.currentList);
  // const rowsCountPreference = useSelector((state: IRootState) => state.login.rowsCountPreference) ?? EMPTY_STRING;
  const limit = useSelector((state: IRootState) => state.login.rowsCountPreference?.TRANSACTION) ?? DEFAULT_SIZE;
  const [currentRowsPerPage, setCurrentRowsPerPage] = useState(currentTransactionRow === 0 ? limit : currentTransactionRow);
  currentTransactionRow = currentRowsPerPage;
  // const [ limit, setLimit ] = useState(10);
  const handleRowsCount = (count: number) => {
    dispatch({ type: 'Login.RowCountPrefUpdate', payload: { TRANSACTION: count } });
  }

  usePageTitle(langTransaction.titleTransactionSearch);
  
  const {
    totalPages,
    totalElements,
    criteria: {
      page, sort: sortOrders, size: _prevLimit, ...values
    }
  } = useSelector((state: IRootState) => state.transaction);

  initialPage = page;
  // useEffect(() => {
  //   setLimit(JSON.parse(rowsCountPreference)?.TRANSACTION);
  // }, [ rowsCountPreference]);
  // const query = useQuery();
  // 

  // useEffect(() => {
  //   dispatch({ type: 'TransactionList.CriteriaUpdated', payload: {
  //     $replaced: true,
  //     page: 0,
  //     sort: {
  //       transactionDate: 'desc',
  //     },
  //     street: query.get('street') ?? EMPTY_STRING,
  //     building: query.get('building') ?? EMPTY_STRING,
  //     block: query.get('block') ?? EMPTY_STRING,
  //     level: query.get('level') ? [ query.get('level')! ] : [],
  //   }});
  // }, [ query.get('street') ?? EMPTY_STRING, query.get('building') ?? EMPTY_STRING, query.get('block') ?? EMPTY_STRING, query.get('level') ?? EMPTY_ARRAY ]);


  const handleSearch = (values: Partial<TransactionSearchDTO>) => {
    dispatch({
      type: 'TransactionList.CriteriaUpdated', payload: {
        page: 0,
        ...toCriteria(values as any),
      }
    });

    dispatch({ type: 'TransactionList.FetchRequested' });
  };

  const handleSort = (key: string | null) => {
    if (key === null) {
      dispatch({
        type: 'TransactionList.CriteriaUpdated', payload: {
          sort: {},
        }
      });
    } else {
      let newSortOrders;
      if (key != 'transactionDate') {
        newSortOrders = {
          [key]: sortOrders?.[key] === 'asc' ? 'desc' : 'asc',
          ['transactionDate']: 'desc'
        };
      } else {
        newSortOrders = { [key]: sortOrders?.[key] === 'asc' ? 'desc' : 'asc' };
      }
      dispatch({
        type: 'TransactionList.CriteriaUpdated', payload: {
          sort: newSortOrders,
        }
      });
    }

    dispatch({ type: 'TransactionList.FetchRequested' });
  }

  // Initial Fetch
  useEffect(() => {
    dispatch({
      type: 'TransactionList.CriteriaUpdated', payload: {
        page: initialPage,
        size: currentRowsPerPage,
        ...values,
      }
    });
    dispatch({ type: 'TransactionList.FetchRequested' });
    // setLimit(rowsCountPreference?.BUILDING_MASTER);
  }, [currentRowsPerPage]);

  // useEffect(() => {
  //   setValues({
  //     ...values,
  //     ...(lastSearchCriteria ?? {}),
  //     street: query.get('street') ?? lastSearchCriteria?.street ?? undefined,
  //     building: query.get('building') ?? lastSearchCriteria?.building ?? undefined,
  //     block: query.get('block') ?? lastSearchCriteria?.block ?? undefined,
  //     level: query.get('level') ? [ query.get('level')! ] : (lastSearchCriteria?.level ?? []),
  //   });
  // }, [ query.get('street') ?? EMPTY_STRING, query.get('building') ?? EMPTY_STRING, query.get('block') ?? EMPTY_STRING, query.get('level') ?? EMPTY_ARRAY, lastSearchCriteria ]);

  // const MaskedInput = <IMaskInput
  //   mask={Number}
  //   radix="."
  //   value="123"
  //   thousandsSeparator=","
  //   unmask={true} // true|false|'typed'
  //   inputRef={(el: any) => setDummyValue(el)}  // access to nested input
  //   // DO NOT USE onChange TO HANDLE CHANGES!
  //   // USE onAccept INSTEAD
  //   onAccept={
  //     // depending on prop above first argument is
  //     // `value` if `unmask=false`,
  //     // `unmaskedValue` if `unmask=true`,
  //     // `typedValue` if `unmask='typed'`
  //     (value: any, mask: any) => {
  //       
  //       
  //     }
  //   }
  // />
  return (
    <div className={classes.root}>
      <TransactionsToolbar />
      {/* <TextField value={dummyValue} onChange={(ev) => setDummyValue(+ev.target.value)}/> */}
      <div className={classes.content}>
        <TransactionsSearch onsearch={handleSearch} initialcriteria={values} className={classes.search}></TransactionsSearch>
        <TransactionsTable
          page={page ?? 0}
          limit={currentRowsPerPage}
          rowsCountOptions={Object.keys(rowsCountPrefOptions).map(Number)}
          handleRowsCount={(ev) => { setCurrentRowsPerPage(ev); handleRowsCount(ev); }}
          totalPages={totalPages}
          totalElements={totalElements}
          setPage={(page: any) => {
            dispatch({
              type: 'TransactionList.CriteriaUpdated', payload: {
                page,
              }
            });
            dispatch({ type: 'TransactionList.FetchRequested' });
          }}
          transactions={transactions}
          sortOrders={(sortOrders as any) ?? {}}
          handleSort={handleSort}
        />
      </div>
    </div>
  );
};

export default TransactionList;