import {
	Button as MuiButton, Card, CardActions, CardContent, CardHeader, Checkbox, Divider, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, InputAdornment, makeStyles, MenuItem, Popover, Radio, RadioGroup, TextareaAutosize, TextField, TextFieldProps,
	Tooltip, Typography, withStyles
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ErrorIcon from '@material-ui/icons/Error';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker } from '@material-ui/pickers';
import fileApi from 'api/fileApi';
import userApi from 'api/userApi';
import clsx from 'clsx';
import { AgreementClientDTO, PropertyAttachmentDTO, PropertyStockDTO, SalePurchaseAgreementDTO, UserListItemDTO } from 'common/dto';
import { FormPresenter } from "common/hooks";
import { HandleFormHelperText, tooltipHoc } from 'common/ui';
import { checkIsHKID, genAddress, initFormKeysAndBooleanMap, isNonEmpty, limitNumberFieldLength, limitTextFieldLength, multiLang } from 'common/utils';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { IMaskInput } from 'react-imask';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';
import theme from 'theme';
import { format } from 'util';
import { SalePaAccessControls } from '../provisionalAgreement-access-controls';
const Button = tooltipHoc(MuiButton);

const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];

const regex = new RegExp(/(^\d*\.?\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/);

const NumberField = withStyles({
	root: {
		'& input': {
			textAlign: 'right',
			'-moz-appearance': 'textfield',
			'&::-webkit-outer-spin-button': {
				'-webkit-appearance': 'none',
			},
			'&::-webkit-inner-spin-button': {
				'-webkit-appearance': 'none',
			},
		},
	},
},
)((props: TextFieldProps) => {
	return <TextField type={"text"}{...props} inputProps={{
		...props.inputProps,
		min: 0,
		max: Math.max(),
		inputMode: 'decimal',
		pattern: "([^0-9]*)",
		step: 0.01
	}}

		onKeyDown={e => {
			// If the key pressed is not a number or a period, nothing is printed
			if (!e.key.match(regex)) {

				e.preventDefault();

			}
			// if (!e.key.match(regex) || (e.key === '.' && String(props.value).indexOf('.') > 0) || (e.key === '.' && String(props.value).length === 0) || e.keyCode === 229 || e.key === 'Unidentified') {

			//   e.preventDefault();

			// }

		}}

		onKeyPress={e => {
			// If the key pressed is not a number or a period, nothing is printed
			if (!e.key.match(regex)) {

				e.preventDefault();

			}
		}}


	/>;
});

const MaskedInput = (props: TextFieldProps) => {
	return (
		<IMaskInput
			{...props}
			mask={Number}
			unmask={true}
			thousandsSeparator=","
		/>
	);
}

const useStyles = makeStyles(() => ({
	root: {},
	fileItem: {
		'& .fileControl': {
			display: 'none',
		},
		'&:hover .fileControl': {
			display: 'inherit',
		},
	},
	partTitle: {
		fontWeight: 'bold',
	},
	textArea: {
		width: '100%',
		margin: 'dense',
		padding: '8px',
		fontSize: '14px'
	},
	table: {
		minWidth: 650,
	},
	dollarField: {
		width: '30%',
		'& input': {
			textAlign: 'right',
		},
	},
	textField: {
		width: '30%',
	},
	datePicker: {
		width: '30%',
	},
	column0: {
		width: '5%',
	},
	partB_column1: {
		width: '20%',
	},
	partC_column1: {
		width: '20%',
	},
	numberFields: {
		width: '30%',
	},
	part2_column1: {
		width: '7%',
	},
	part2_description: {
		width: '100%',
	},
	part2_textAreaDiv: {
		paddingTop: '4px'
	},
	subTableCell: {
		borderBottomColor: '#FFFFFF',
	},
	divider: {
		backgroundColor: '#1565c0',
	},
	cardHeaderBackground: {
		height: '35px',
		backgroundColor: '#fafafa',
	},
	cardHeaderElement: {
		display: 'inline-block',
	},
	cardHeaderTips: {
		fontSize: '12px',
		color: '#546e7a',
		paddingLeft: 8,
	},
	patchOutlineAutocomplete: {
		'& .MuiAutocomplete-inputRoot': {
			padding: 0,
			paddingLeft: 8,
		}
	},
}));

const UPLOAD_LIMITS = {
	ID_CARD_IMAGE: 1,
	SUPPLEMENTAL_DOCUMENT: 4
};

const errorColor = '#e53935';


export const salePurchaseInitial: Partial<SalePurchaseAgreementDTO> = {
	isResidential: true,
	vendorAsConfirmor: false,
	isCheque: false,
	vendors: [],
	purchasers: [],
	supplementalDoc: [],
	buyerAgentType: 'I',
	remainingSum: 0,
};

interface SalePurchaseAgreementProps {
	className?: string;
	propertyStockId: string;
	form: FormPresenter<SalePurchaseAgreementDTO>;
	// isBuyerAgent: boolean;
	// isCompanyStock: boolean;
	// isSellerAgent: boolean;
	// isSalePaSigner: boolean;

	accessControls: SalePaAccessControls;
}

const SalePurchaseDialog = (props: SalePurchaseAgreementProps) => {
	const { className, propertyStockId, form, accessControls,
		...rest
	} = props;
	const {
		canEditSellerFields,
		canEditBuyerFields,
		canEditSalePaAssignees,
	} = accessControls;
	const classes = useStyles();
	const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);
	const { lang, langSalePurchaseAgreement, langPropertyStock, identityTypeOptions, agentAppointmentOptionsSale, langSignDialog, locale, formLocaleOptions, agentTypeOptions } = useSelector((state: IRootState) => state.locale);

	const agencyCompanyInfo = useSelector((state: IRootState) => state.systemSettings.Company.AGENCY_COMPANY_INFO);

	const bind = form.bind;
	//console.log("compensationToAgent", form.values.compensationToAgent, form.values.compensationToAgent?.toString(), "isEmpty:", isEmpty(form.values.compensationToAgent), form.values.compensationToAgent?.toString() == " ");
	const [agents, setAgents] = useState<UserListItemDTO[]>([]);
	const [agentListLoading, setAgentListLoading] = useState(false);

	const token: string = useSelector((state: IRootState) => state.login.token ?? '');
	const currentUid = useSelector((state: IRootState) => state.login.uid);

	const canReadEditSellerHKIDImage = true;
	const canReadEditBuyerHKIDImage = true;
	const [keyBooleanMap, setKeyBooleanMap] = useState(initFormKeysAndBooleanMap(form));

	// useEffect(() => {
	// 	
	// 	
	// }, [ form.values ])

	// useEffect(() => {
	// 	if (form.values.sellerAgentUserId === null) {
	// 		form.setValues({
	// 			...form.values,
	// 			sellerAgentUserId: loginInfo.uid,
	// 			sellerAgentNameEn: loginInfo.englishName,
	// 			sellerAgentNameZh: loginInfo.chineseName
	// 		});
	// 	}
	// }, [ loginInfo ]);

	const fetchAgents = (keyword: string) => {
		if (keyword.length < 2) { return; }
		setAgentListLoading(true);
		userApi.getListForAutocomplete({ name: keyword, onlyHasCreateOwnClientPrivilege: true }, token).then(result => {
			if (result.data?.content) {
				setAgents(result.data!.content);
				setAgentListLoading(false);
				setAgentUsername(result.data!.content.filter(agent => agent.username != 'companyStockAgent').map((agent) => agent.username));

			}
		});
	}

	const [agentUsername, setAgentUsername] = useState(agents.filter(agent => agent.username != 'companyStockAgent').map((agent) => agent.username));
	const agentMap: { [name: string]: UserListItemDTO } = {};
	agents.forEach((agent) => {
		agentMap[agent.username] = agent;
	})

	const [keywordOfAgent, setKeywordOfAgent] = useState('')


	useEffect(() => {
		setKeyBooleanMap(initFormKeysAndBooleanMap(form));
	}, [Object.keys(form.values).length])

	useEffect(() => {
		const timer = setTimeout(() => {
			fetchAgents(keywordOfAgent);
		}, 1000);
		return () => clearTimeout(timer);
	}, [keywordOfAgent]);

	const addVendor = () => {
		form.updateValues('vendors',
			[...form.values.vendors ?? [], { agentUserId: Number(currentUid), type: 'SELLER', name: '', identityType: '', identityNumber: '', address: '', identityFilename: null, idCardImage: [] } as AgreementClientDTO])
	}

	const addPurchaser = () => {
		form.updateValues('purchasers',
			[...form.values.purchasers ?? [], { agentUserId: Number(currentUid), type: 'BUYER', name: '', identityType: '', identityNumber: '', address: '', identityFilename: null, idCardImage: [] } as AgreementClientDTO])
	}

	const removeVendor = (i: number) => {
		form.updateValues('vendors',
			form.values.vendors!.filter((_: any, _i: number) => i !== _i)
		);
	}

	const removePurchaser = (i: number) => {
		form.updateValues('purchasers',
			form.values.purchasers!.filter((_: any, _i: number) => i !== _i)
		);
	}

	const setVendorIdCardImage = (i: number, newList: PropertyAttachmentDTO[]) =>
		form.updateValues('vendors',
			form.values.vendors!.map((v: AgreementClientDTO, idx: number) => idx === i ?
				{ ...v, identityFilename: newList!.length < 1 ? null : v.identityFilename, idCardImage: newList } : v));

	const setPurchaserIdCardImage = (i: number, newList: PropertyAttachmentDTO[]) =>
		form.updateValues('purchasers',
			form.values.purchasers!.map((v: AgreementClientDTO, idx: number) => idx === i ?
				{ ...v, identityFilename: newList!.length < 1 ? null : v.identityFilename, idCardImage: newList } : v));


	const handleVendorIdCardUpload = (type: string, i: number) => (ev: React.ChangeEvent<HTMLInputElement>) => {
		let files = Array.from(ev.target.files ?? []);
		const currentLength = form.values.vendors![i]!.idCardImage?.length ?? 0;
		const limit = UPLOAD_LIMITS[type as keyof typeof UPLOAD_LIMITS] ?? 0;
		// 
		let remainLength = limit ?? 0 - currentLength > 0 ? limit ?? 0 - currentLength : 0;
		// 
		if (remainLength === 0) return;

		Promise.all(files.slice(0, remainLength).map(file => {
			return fileApi.addFile(file, true, 'HKID').then(result => result.data as string)
		})).then(paths => {
			paths = paths.filter(path => form.values.vendors![i]!.idCardImage?.map(f => f.filename).indexOf(path) < 0);

			form.updateValues('vendors',
				form.values.vendors!.map((v: AgreementClientDTO, idx: number) => idx === i ?
					{ ...v, identityFilename: paths![0], idCardImage: [...v.idCardImage ?? [], ...paths.map(path => ({ filename: path, type, uploadDate: (new Date).toISOString(), remarks: '', agentUserId: Number(currentUid) }))] }
					: v
				)
			);
		});
	}

	const handlePurchaserIdCardUpload = (type: string, i: number) => (ev: React.ChangeEvent<HTMLInputElement>) => {
		let files = Array.from(ev.target.files ?? []);
		const currentLength = form.values.purchasers![i]!.idCardImage?.length ?? 0;
		const limit = UPLOAD_LIMITS[type as keyof typeof UPLOAD_LIMITS] ?? 0;
		let remainLength = limit ?? 0 - currentLength > 0 ? limit ?? 0 - currentLength : 0;
		if (remainLength === 0) return;

		Promise.all(files.slice(0, remainLength).map(file => {
			return fileApi.addFile(file, true, 'HKID').then(result => result.data as string)
		})).then(paths => {
			paths = paths.filter(path => form.values.purchasers![i]!.idCardImage?.map(f => f.filename).indexOf(path) < 0);

			form.updateValues('purchasers',
				form.values.purchasers!.map((v: AgreementClientDTO, idx: number) => idx === i ?
					{ ...v, identityFilename: paths![0], idCardImage: [...v.idCardImage ?? [], ...paths.map(path => ({ filename: path, type, uploadDate: (new Date).toISOString(), remarks: '', agentUserId: Number(currentUid) }))] }
					: v
				)
			);
		});
	}

	const fileRef = React.createRef<HTMLInputElement>();
	// upload req popup
	// const [ reqPopupOpened, setReqPopupOpened ] = useState(false);
	const [reqPopupOpened, setReqPopupOpened] = useState<{ [key: string]: boolean }>({});
	const [popupAnchor, setPopAnchor] = useState<HTMLAnchorElement>();
	// const refPhotoUploadRequirement = React.createRef<HTMLAnchorElement>();
	const openPopup = (key: string) => (ev: React.MouseEvent<HTMLAnchorElement>) => {
		setPopAnchor(ev.currentTarget);
		setReqPopupOpened(prev => ({ ...prev, [key]: true }));
	};
	const closePopup = (key: string) => () => setReqPopupOpened(prev => ({ ...prev, [key]: false }));

	const invalidVendorIndexArray = form.hasError('invalidVendor')?.split('_') ?? [];
	const invalidPurchaserIndexArray = form.hasError('invalidPurchasers')?.split('_') ?? [];

	const hasErrorForClientInfo = (clientType: string, idx: number, value: string) => {
		const indexArray = clientType === 'VENDOR' ? invalidVendorIndexArray : invalidPurchaserIndexArray;
		if (!indexArray.includes(idx.toString())) {
			return false;
		} else {
			if (isNonEmpty(value)) {
				return false;
			}
			return true;
		}
	}

	//auto complete for remaining sum
	useEffect(() => {
		form.updateValues('remainingSum',
			((form.values.purchasePrice ?? 0) - (form.values.initialDeposit ?? 0) - (form.values.fullDeposit ?? 0)) < 0 ? 0 : (form.values.purchasePrice ?? 0) - (form.values.initialDeposit ?? 0) - (form.values.fullDeposit ?? 0)
		);

	}, [form.values.purchasePrice,
	form.values.initialDeposit,
	form.values.fullDeposit]);

	const propertyStockDto = useSelector((state: IRootState) => state.property.fetchedDetailsByPropertyStockId[props.propertyStockId]) ?? {} as Partial<PropertyStockDTO>;
	const { districtHkiOptions: districtHkiOptionsEn, districtNtOptions: districtNtOptionsEn, districtKltOptions: districtKltOptionsEn } = useSelector((state: IRootState) => state.locale._bundle['en']);
	const { districtHkiOptions: districtHkiOptionsZhHK, districtNtOptions: districtNtOptionsZhHK, districtKltOptions: districtKltOptionsZhHK } = useSelector((state: IRootState) => state.locale._bundle['zh_HK']);

	const districtEnOptions = {
		...districtHkiOptionsEn,
		...districtNtOptionsEn,
		...districtKltOptionsEn,
	};
	const districtZhHkOptions = {
		...districtHkiOptionsZhHK,
		...districtNtOptionsZhHK,
		...districtKltOptionsZhHK,
	};

	const companyDetailsLocale = (locale: string) => {
		return (locale === 'en') ? { name: agencyCompanyInfo['COMPANY_NAME_EN'], address: agencyCompanyInfo['ADDRESS_EN'], businessReg: agencyCompanyInfo['BUSINESS_REG_NO'], licence: agencyCompanyInfo['LICENCE_NO'] } : { name: agencyCompanyInfo['COMPANY_NAME_CN'], address: agencyCompanyInfo['ADDRESS_CN'], businessReg: agencyCompanyInfo['BUSINESS_REG_NO'], licence: agencyCompanyInfo['LICENCE_NO'] };
	};
	const propertyAddress = (locale: string) => {

		const address = genAddress(locale,
			propertyStockDto.unit?.join(',') ?? '',
			propertyStockDto.floor?.join(',') ?? '',
			propertyStockDto.block ?? '', propertyStockDto.blockEn ?? '',
			propertyStockDto.building ?? '', propertyStockDto.buildingEn ?? '',
			propertyStockDto.street ?? '', propertyStockDto.streetEn ?? '',
			(locale === 'en') ? districtEnOptions[propertyStockDto.district ?? ''] : districtZhHkOptions[propertyStockDto.district ?? '']);
		return address;
	};
	const updateCompanyCurrentLocaleDetails = (locale: string) => {
		let details = companyDetailsLocale(locale);
		form.updateValues('agentName', details.name);
		form.updateValues('agentBusinessRegNo', details.businessReg);
		form.updateValues('agentLicenceNo', details.licence);
		form.updateValues('agentAddress', details.address);
		form.updateValues('premises', propertyAddress(locale));
	};

	return (
		<Card
			{...rest}
			className={clsx(classes.root, className)}>
			<CardContent>


				<Grid container xs={12} spacing={1}>
					{/*---------------------------agreement language----------------------*/}
					<Grid
						item
						sm={2}
						md={2}
						xs={12}
					>
						<Typography>{langPropertyStock.captionAgreementLocale}</Typography>
						<TextField select
							fullWidth
							margin="dense"
							{...bind('agreementLocale')}
							required
							variant="outlined"
							onChange={(event) => {
								bind('agreementLocale').onChange(event);
								updateCompanyCurrentLocaleDetails(event.target.value);
							}}>
							{
								Object.keys(formLocaleOptions).map((opt) => <MenuItem value={opt}>{formLocaleOptions[opt]}</MenuItem>)
							}
						</TextField>
					</Grid>
					{/*---------------------------Seller Agent----------------------*/}
					<Grid
						item
						md={12}
						xs={12}
					>
						<Typography style={{ paddingTop: theme.spacing(1) }}>
							{langSalePurchaseAgreement.titleSellerAgent}
							<br />
							<FormHelperText>{langSalePurchaseAgreement.sellerAgentHelpText}</FormHelperText>
						</Typography>
					</Grid>

					<Grid
						item
						md={12}
						xs={12}
						container
						spacing={1}
					>
						<Grid item md={4} xs={12}>
							<TextField
								fullWidth
								label={langSalePurchaseAgreement.agentType}
								margin="dense"
								required
								select
								variant="outlined"
								// {...bind('sellerAgentType')}
								value={form.values.sellerAgentType}
								error={!!form.hasError('sellerAgentType') || !!form.hasError('invalidBuyerOrSellerAgentType')}
								helperText={form.hasError('sellerAgentType') || form.hasError('invalidBuyerOrSellerAgentType')}
								disabled={!canEditSalePaAssignees}
								onChange={(e) => {
									if (form.values.sellerAgentType !== e.target.value) {
										form.setValues({
											...form.values,
											sellerAgentType: e.target.value,
											sellerAgentUserId: undefined,
											sellerAgentName: undefined,
											sellerAgentNameEn: undefined,
											sellerAgentNameZh: undefined,
											sellerAgentLicenseNumber: undefined,
											agentFor: e.target.value === 'I' ? 'BOTH' : 'PURCHASER_ONLY',
										});
									} else {
										form.updateValues('agentFor', e.target.value === 'I' ? 'BOTH' : 'PURCHASER_ONLY');
									}
								}}

							>
								{
									Object.keys(agentTypeOptions ?? {}).map(key => (
										<MenuItem key={key} value={key}>{agentTypeOptions[key]}</MenuItem>
									))
								}
							</TextField>

						</Grid>
						<Grid item md={4} xs={12}>
							{/* {isBuyerAgent ? 
						<TextField
							className={classes.patchOutlineAutocomplete}
							fullWidth
							label={langSalePurchaseAgreement.buyerAgentName}
							margin="dense"
							required
							variant="outlined"
							value={multiLang(locale, form.values.buyerAgentNameZh, form.values.buyerAgentNameEn)}
						/>
					: */}
							{form.values.sellerAgentType === 'I' ?
								<Autocomplete
									popupIcon={""}
									options={form.values.sellerAgentType === 'I' ? agentUsername : []}
									noOptionsText={''}
									loading={agentListLoading}
									loadingText={lang.msgLoading}
									filterOptions={(option) => option}
									renderOption={(option) => <div>
										<div><Typography >{`${agents.filter(agent => agent.username === option)?.[0]?.englishName ?? ''}${agents.filter(agent => agent.username === option)?.[0]?.chineseName ? '/' : ''}${agents.filter(agent => agent.username === option)?.[0]?.chineseName ?? ''}`}</Typography></div>
										<div><Typography variant="caption">{agents.filter(agent => agent.username === option)?.[0]?.mainContact}</Typography></div>
									</div>}
									disabled={!canEditSalePaAssignees}
									renderInput={(params: any) => (
										<TextField
											{...params}
											className={classes.patchOutlineAutocomplete}
											fullWidth
											label={langSalePurchaseAgreement.sellerAgentName}
											margin="dense"
											required
											variant="outlined"
											onFocus={(ev) => {
												setAgentUsername([]);
												setKeywordOfAgent(ev.target.value);
											}}
											onChange={(ev) => {

												setAgents([]);
												setAgentUsername([]);
												setKeywordOfAgent(ev.target.value);
												setAgentListLoading(true);
												if (ev.target.value.length < 2 || !isNonEmpty(ev.target.value)) {
													setAgentListLoading(false);
												}
											}
											}
											error={!!form.hasError('sellerAgentName')}
											helperText={form.hasError('sellerAgentName')}
										/>
									)}
									value={form.values.sellerAgentName ?? ''}

									onChange={(_: any, val: any) => {
										if (agentMap[val]) {
											const { id, chineseName, englishName, licenseNumber } = agentMap[val];
											form.setValues({
												...form.values,
												sellerAgentUserId: id,
												sellerAgentName: multiLang(locale, chineseName, englishName),
												sellerAgentNameEn: englishName,
												sellerAgentNameZh: chineseName,
												sellerAgentLicenseNumber: licenseNumber,
											});

										} else {
											form.setValues({
												...form.values,
												sellerAgentUserId: undefined,
												sellerAgentName: undefined,
												sellerAgentNameEn: undefined,
												sellerAgentNameZh: undefined,
												sellerAgentLicenseNumber: undefined,
											});

										}
									}}
									getOptionLabel={() => multiLang(locale, form.values.sellerAgentNameZh, form.values.sellerAgentNameEn) ?? ''}
								/>
								:
								<TextField
									fullWidth
									label={langSalePurchaseAgreement.sellerAgentName}
									margin="dense"
									variant="outlined"
									required
									{...bind('sellerAgentName')}
									onChange={(e) => {
										limitTextFieldLength(e, 255, 'sellerAgentName', form, keyBooleanMap, setKeyBooleanMap);
									}}
									disabled={!canEditSalePaAssignees}
								// value={agent.name}
								// error={!!getAgentValidationMsg('SELLER', 'name', agent.name)}
								// helperText={getAgentValidationMsg('SELLER', 'name', agent.name)}
								// onChange={(e) => {
								//   const updatedAgent = { ...agent, name: e.target.value };
								//   form.updateValues('sellerAgents',
								//     form.values.sellerAgents?.map((a: any, i: number) => idx === i ? updatedAgent : a)
								//   );
								// }}
								/>

							}
							<FormHelperText>{keyBooleanMap.get('sellerAgentName') && form.values.sellerAgentType !== 'I' && isNonEmpty(form.values.sellerAgentName) ? lang.textOverLimit : ''}</FormHelperText>
							{/* } */}

						</Grid>

						<Grid item md={4} xs={12}>
							<TextField
								fullWidth
								label={langSalePurchaseAgreement.agentLicenseNumber}
								margin="dense"
								variant="outlined"
								required
								{...bind('sellerAgentLicenseNumber')}
								onChange={(e) => {
									limitTextFieldLength(e, 255, 'sellerAgentLicenseNumber', form, keyBooleanMap, setKeyBooleanMap);
								}}
								disabled={!canEditSalePaAssignees}
							// value={agent.name}
							// error={!!getAgentValidationMsg('SELLER', 'name', agent.name)}
							// helperText={getAgentValidationMsg('SELLER', 'name', agent.name)}
							// onChange={(e) => {
							//   const updatedAgent = { ...agent, name: e.target.value };
							//   form.updateValues('sellerAgents',
							//     form.values.sellerAgents?.map((a: any, i: number) => idx === i ? updatedAgent : a)
							//   );
							// }}
							/>
							<FormHelperText>{keyBooleanMap.get('sellerAgentLicenseNumber') && isNonEmpty(form.values.sellerAgentLicenseNumber) ? lang.textOverLimit : ''}</FormHelperText>
						</Grid>
					</Grid>
					{/*---------------------------Buyer Agent----------------------*/}
					<Grid
						item
						md={12}
						xs={12}
					>
						<Typography style={{ paddingTop: theme.spacing(1) }}>
							{langSalePurchaseAgreement.titleBuyerAgent}
							<br />
							<FormHelperText>{langSalePurchaseAgreement.internalAgentHelpText}</FormHelperText>
							<FormHelperText>{langSalePurchaseAgreement.externalAgentHelpText}</FormHelperText>
						</Typography>
					</Grid>

					<Grid
						item
						md={12}
						xs={12}
						container
						spacing={1}
					>

						<Grid item md={4} xs={12}>
							<TextField
								fullWidth
								label={langSalePurchaseAgreement.agentType}
								margin="dense"
								required
								select
								variant="outlined"
								// {...bind('buyerAgentType')}
								disabled={true}
								value={form.values.buyerAgentType}
								error={!!form.hasError('buyerAgentType') || !!form.hasError('invalidBuyerOrSellerAgentType')}
								helperText={form.hasError('buyerAgentType') || form.hasError('invalidBuyerOrSellerAgentType')}
								onChange={(e) => {

									form.setValues({
										...form.values,
										buyerAgentType: e.target.value,
										buyerAgentUserId: undefined,
										buyerAgentName: undefined,
										buyerAgentNameEn: undefined,
										buyerAgentNameZh: undefined,
										buyerAgentLicenseNumber: undefined,
									});

								}}

							>
								{
									Object.keys(agentTypeOptions ?? {}).map(key => (
										<MenuItem key={key} value={key}>{agentTypeOptions[key]}</MenuItem>
									))
								}
							</TextField>

						</Grid>

						<Grid item md={4} xs={12}>

							{/* {disableVendorFields ? 
            // buyer agent cannot edit this info
						<TextField
							className={classes.patchOutlineAutocomplete}
							fullWidth
							label={langSalePurchaseAgreement.buyerAgentName}
							margin="dense"
							required
							variant="outlined"
							value={multiLang(locale, form.values.buyerAgentNameZh, form.values.buyerAgentNameEn)}
						/>
          : */}
							{form.values.buyerAgentType === 'I' ?
								<Autocomplete
									popupIcon={""}
									options={form.values.buyerAgentType === 'I' ? agentUsername : []}
									noOptionsText={''}
									loading={agentListLoading}
									loadingText={lang.msgLoading}
									filterOptions={(option) => option}
									renderOption={(option) => <div>
										<div><Typography >{`${agents.filter(agent => agent.username === option)?.[0]?.englishName ?? ''}${agents.filter(agent => agent.username === option)?.[0]?.chineseName ? '/' : ''}${agents.filter(agent => agent.username === option)?.[0]?.chineseName ?? ''}`}</Typography></div>
										<div><Typography variant="caption">{agents.filter(agent => agent.username === option)?.[0]?.mainContact}</Typography></div>
									</div>}
									disabled={!canEditSalePaAssignees}
									renderInput={(params: any) => (
										<TextField
											data-key={'field-salepurchase-buyerAgentName'}
											{...params}
											className={classes.patchOutlineAutocomplete}
											fullWidth
											label={langSalePurchaseAgreement.buyerAgentName}
											margin="dense"
											required
											variant="outlined"
											error={!!form.hasError('buyerAgentName')}
											helperText={form.hasError('buyerAgentName')}
											onFocus={(ev) => {
												setAgentUsername([]);
												setKeywordOfAgent(ev.target.value);
											}}
											onChange={(ev) => {

												setAgents([]);
												setAgentUsername([]);
												setKeywordOfAgent(ev.target.value);
												setAgentListLoading(true);
												if (ev.target.value.length < 2 || !isNonEmpty(ev.target.value)) {
													setAgentListLoading(false);
												}
											}
											}
										/>
									)}

									value={form.values.buyerAgentName ?? ''}

									onChange={(_: any, val: any) => {
										if (agentMap[val]) {
											const { id, chineseName, englishName, licenseNumber } = agentMap[val];
											form.setValues({
												...form.values,
												buyerAgentUserId: id,
												buyerAgentName: multiLang(locale, chineseName, englishName),
												buyerAgentNameEn: englishName,
												buyerAgentNameZh: chineseName,
												buyerAgentLicenseNumber: licenseNumber,
											});

										} else {
											form.setValues({
												...form.values,
												buyerAgentUserId: undefined,
												buyerAgentName: undefined,
												buyerAgentNameEn: undefined,
												buyerAgentNameZh: undefined,
												buyerAgentLicenseNumber: undefined,
											});

										}
									}}
									getOptionLabel={() => multiLang(locale, form.values.buyerAgentNameZh, form.values.buyerAgentNameEn) ?? ''}
								/> :
								<TextField
									data-key={'field-salepurchase-buyerAgentName'}
									fullWidth
									label={langSalePurchaseAgreement.buyerAgentName}
									margin="dense"
									variant="outlined"
									required
									{...bind('buyerAgentName')}
									onChange={(e) => {
										limitTextFieldLength(e, 255, 'buyerAgentName', form, keyBooleanMap, setKeyBooleanMap);
									}}
									disabled={!canEditSalePaAssignees}
								// value={agent.name}
								// error={!!getAgentValidationMsg('SELLER', 'name', agent.name)}
								// helperText={getAgentValidationMsg('SELLER', 'name', agent.name)}
								// onChange={(e) => {
								//   const updatedAgent = { ...agent, name: e.target.value };
								//   form.updateValues('buyerAgents',
								//     form.values.buyerAgents?.map((a: any, i: number) => idx === i ? updatedAgent : a)
								//   );
								// }}
								/>
							}
							<FormHelperText>{keyBooleanMap.get('buyerAgentName') ? lang.textOverLimit : ''}</FormHelperText>
						</Grid>
						<Grid item md={4} xs={12}>
							<TextField
								fullWidth
								label={langSalePurchaseAgreement.agentLicenseNumber}
								margin="dense"
								variant="outlined"
								required
								{...bind('buyerAgentLicenseNumber')}
								onChange={(e) => {
									limitTextFieldLength(e, 255, 'buyerAgentLicenseNumber', form, keyBooleanMap, setKeyBooleanMap);
								}}
								disabled={!canEditSalePaAssignees}

							// value={agent.name}
							// error={!!getAgentValidationMsg('SELLER', 'name', agent.name)}
							// helperText={getAgentValidationMsg('SELLER', 'name', agent.name)}
							// onChange={(e) => {
							//   const updatedAgent = { ...agent, name: e.target.value };
							//   form.updateValues('buyerAgents',
							//     form.values.buyerAgents?.map((a: any, i: number) => idx === i ? updatedAgent : a)
							//   );
							// }}
							/>
							<FormHelperText>{keyBooleanMap.get('buyerAgentLicenseNumber') && isNonEmpty(form.values.buyerAgentLicenseNumber) ? lang.textOverLimit : ''}</FormHelperText>
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={12} spacing={2}>
					<br /><br />
				</Grid>

				<Grid container xs={12} spacing={1}>
					<Grid
						item
						md={12}
						xs={12}
					>
						<Typography style={{ fontSize: '16px' }} className={classes.partTitle} >{langSalePurchaseAgreement.formName}</Typography>
					</Grid>

				</Grid>

				<Grid item xs={12} spacing={1}>

				</Grid>

				{/* ------------------------- Agreement Date ----------------------------- */}
				<Grid container xs={12} spacing={1}>
					<Grid
						item
						md={12}
						xs={12}
					>
						<TableContainer component={Paper}>
							<Table className={classes.table} size="small" aria-label="Agreement Date">
								<TableHead>
									<TableRow>
										{/* <TableCell className={classes.column0}>1.</TableCell> */}
										<TableCell colSpan={8} align="left">{langSalePurchaseAgreement.titleAgreementDate}</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{/*----------- Date ---------- */}
									<TableRow >
										<TableCell component="th" scope="row"></TableCell>
										<TableCell>{langSalePurchaseAgreement.date}</TableCell>
										<TableCell>
											<KeyboardDatePicker data-key={'field-salepurchase-agreementDate'} autoOk={true}
												className={classes.datePicker}
												variant="inline"
												inputVariant="outlined"
												format={DISPLAY_DATE_FORMAT}
												margin="dense"
												// maxDate={new Date()}
												error={!!form.hasError('agreementDate')}
												helperText={form.hasError('agreementDate')}
												value={form.values.agreementDate ? moment(form.values.agreementDate) : null}
												onChange={(value) => {
													form.setValues({
														...form.values,
														agreementDate: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined
													});
												}}
												KeyboardButtonProps={{
													'aria-label': 'change date',
												}}
											/>
											{/* {form.hasError('agreementDate') ? <FormHelperText data-key={'field-salepurchase-agreementDate'} style={{color: form.hasError('agreementDate') ? errorColor : 'inherit'}}>{langSalePurchaseAgreement.msgSelectDateOfObtainingInfo}</FormHelperText> : null}   */}

										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
				</Grid>

				<Grid item xs={12} spacing={1}>

				</Grid>

				{/* -------------------------- Involved Parties ----------------------*/}
				<Grid container xs={12} spacing={1}>
					<Grid
						item
						md={12}
						xs={12}
					>
						<Typography className={classes.partTitle}>{langSalePurchaseAgreement.titleParties}</Typography>
					</Grid>


					{/* ------------------------- Vendor --------------------------- */}
					<Grid
						item
						md={12}
						xs={12}
					>
						<Card>
							<CardHeader className={classes.cardHeaderBackground}
								title={<div>
									<div className={classes.column0 + ' ' + classes.cardHeaderElement}>1.</div><div className={classes.cardHeaderElement}>{langSalePurchaseAgreement.titleVendor}
										{canEditSellerFields ? null : <div className={classes.cardHeaderElement + ' ' + classes.cardHeaderTips}>{
											format(langPropertyStock.msgSpecifiedRoleCanFillIn, langSalePurchaseAgreement.titleSellerAgent)
										}</div>}
									</div>
								</div>}
							/>
							<Divider />
							<CardContent>
								<Grid
									item
									md={12}
									xs={12}
								>
									<Typography>
										{langSalePurchaseAgreement.vendor}
										{!canEditSellerFields ? null :
											<Tooltip title={lang.actionAdd}>
												<IconButton color="primary" onClick={() => addVendor()}>
													<AddIcon />
												</IconButton>
											</Tooltip>
										}
									</Typography>
									{form.hasError('vendors') ? <FormHelperText style={{ color: form.hasError('vendors') ? errorColor : 'inherit' }}>{form.hasError('vendors')}</FormHelperText> : null}
									{form.hasError('invalidVendor') ? <FormHelperText style={{ color: form.hasError('invalidVendor') ? errorColor : 'inherit' }}>{langSalePurchaseAgreement.msgInputRequiredInfo}</FormHelperText> : null}

								</Grid>

								{
									form.values.vendors?.map((vendor: AgreementClientDTO, idx: number) => {
										return (
											<Grid item container md={12} xs={12} spacing={1}>
												{!canEditSellerFields ? null :
													<Grid
														item
														md={1}
														xs={12}
													>
														<Typography style={{ display: 'flex', alignItems: 'center', flexGrow: 2, width: 200 }}>

															<IconButton key={idx} onClick={() => removeVendor(idx)}>
																<DeleteIcon />
															</IconButton>

														</Typography>
													</Grid>
												}
												<Grid
													item
													md={2}
													xs={12}
												>
													<TextField
														fullWidth
														label={langSalePurchaseAgreement.name}
														margin="dense"
														required
														variant="outlined"
														value={vendor.name}
														error={hasErrorForClientInfo('VENDOR', idx, vendor.name)}
														disabled={!canEditSellerFields}
														onChange={(e) => {
															const updatedVendor = { ...vendor, name: e.target.value.substring(0, 255) };
															if (e.target.value.length > 255) {
																e.preventDefault();
																setKeyBooleanMap(keyBooleanMap.set('vendorsName' + idx, true));
																form.updateValues('vendors',
																	form.values.vendors!.map((v: AgreementClientDTO, i: number) => idx === i ? updatedVendor : v)
																);

															} else {
																setKeyBooleanMap(keyBooleanMap.set('vendorsName' + idx, false));
																form.updateValues('vendors',
																	form.values.vendors!.map((v: AgreementClientDTO, i: number) => idx === i ? updatedVendor : v)
																);
															}
														}}
													/>
													<HandleFormHelperText
														isError={keyBooleanMap.get('vendorsName' + idx)}
														errorMessage={lang.textOverLimit}
													/>
												</Grid>
												<Grid
													item
													md={2}
													xs={12}
												>
													<TextField
														fullWidth
														label={langSalePurchaseAgreement.identityType}
														margin="dense"
														required
														select
														variant="outlined"
														disabled={!canEditSellerFields}
														error={hasErrorForClientInfo('VENDOR', idx, vendor.identityType)}
														value={vendor.identityType}
														onChange={(e) => {
															const updatedVendor = { ...vendor, identityType: e.target.value };
															form.updateValues('vendors',
																form.values.vendors!.map((v: AgreementClientDTO, i: number) => idx === i ? updatedVendor : v)
															);
														}}
													>

														{
															Object.keys(identityTypeOptions).map((key) =>
																<MenuItem key={key} value={key}>{identityTypeOptions[key]}</MenuItem>)
														}
													</TextField>
												</Grid>
												<Grid
													item
													md={2}
													xs={12}
												>
													<TextField
														fullWidth
														label={langSalePurchaseAgreement.identityNumber}
														margin="dense"
														required
														variant="outlined"
														disabled={!canEditSellerFields}
														error={hasErrorForClientInfo('VENDOR', idx, vendor.identityNumber)}
														value={vendor.identityNumber}
														onChange={(e) => {
															const updatedVendor = { ...vendor, identityNumber: e.target.value.substring(0, 255) };
															if (e.target.value.length > 255) {
																e.preventDefault();
																setKeyBooleanMap(keyBooleanMap.set('vendorsIdentityNo' + idx, true));
																form.updateValues('vendors',
																	form.values.vendors!.map((v: AgreementClientDTO, i: number) => idx === i ? updatedVendor : v)
																);
															} else {
																setKeyBooleanMap(keyBooleanMap.set('vendorsIdentityNo' + idx, false));
																form.updateValues('vendors',
																	form.values.vendors!.map((v: AgreementClientDTO, i: number) => idx === i ? updatedVendor : v)
																);
															}
														}}
														InputProps={{
															endAdornment: vendor.identityNumber && vendor.identityType === 'H' && !checkIsHKID(vendor.identityNumber ?? '') ?
																<InputAdornment position="end">
																	<Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{`${langSignDialog.msgNonHKID}`}</span>} aria-label='hot-pick-expiry'>
																		<IconButton
																			style={{ color: '#FFBF00' }}
																			aria-label="hkid-indicator"
																			edge="end"
																		>
																			<ErrorIcon />
																		</IconButton>
																	</Tooltip>
																</InputAdornment> : null
														}}
													/>
													<HandleFormHelperText
														isError={keyBooleanMap.get('vendorsIdentityNo' + idx)}
														errorMessage={lang.textOverLimit}
													/>
												</Grid>
												<Grid
													item
													md={5}
													xs={12}
												>
													<TextField
														fullWidth
														label={langSalePurchaseAgreement.address}
														margin="dense"
														variant="outlined"
														value={vendor.address}
														disabled={!canEditSellerFields}
														// error={hasErrorForClientInfo('VENDOR', idx, vendor.address)}
														onChange={(e) => {
															const updatedVendor = { ...vendor, address: e.target.value.substring(0, 255) };
															if (e.target.value.length > 255) {
																e.preventDefault();

																setKeyBooleanMap(keyBooleanMap.set('vendorsAddress' + idx, true));
																form.updateValues('vendors',
																	form.values.vendors!.map((v: AgreementClientDTO, i: number) => idx === i ? updatedVendor : v)
																);
															} else {
																setKeyBooleanMap(keyBooleanMap.set('vendorsAddress' + idx, false));
																form.updateValues('vendors',
																	form.values.vendors!.map((v: AgreementClientDTO, i: number) => idx === i ? updatedVendor : v)
																);
															}
														}}
													/>
													<HandleFormHelperText
														isError={keyBooleanMap.get('vendorsAddress' + idx)}
														errorMessage={lang.textOverLimit}
													/>
												</Grid>

												{canReadEditSellerHKIDImage ? <Grid item md={12} xs={12}>
													<Typography>{langSalePurchaseAgreement.idCardImage}</Typography>

													<div style={{ display: 'flex', alignItems: 'center' }}>
														<FormControl margin="dense">
															<input
																style={{ display: 'none' }}
																id={`input-file-vendor-id-card-${idx}`}
																type="file"
																ref={fileRef}
																onChange={(ev) => handleVendorIdCardUpload('ID_CARD_IMAGE', idx)(ev)}
																disabled={vendor.idCardImage?.length >= UPLOAD_LIMITS.ID_CARD_IMAGE || !canEditSellerFields}
															/>
															<label htmlFor={`input-file-vendor-id-card-${idx}`}>
																<Button disabled={(vendor.idCardImage?.length >= UPLOAD_LIMITS.ID_CARD_IMAGE || !canEditSellerFields)} {...{ component: "span" } as any} variant="contained"
																	// TODO: tooltip={vendor.idCardImage?.length ?? 0 >= 4 ? langPropertyStock.msgPhotoLimit.replace('%d', UPLOAD_LIMITS.PHOTO.toString()) : undefined}
																	// TODO: disabled={vendor.idCardImage?.length ?? 0 >= 4}
																	color="primary">{lang.actionUpload} ({`${vendor.idCardImage?.length}/${UPLOAD_LIMITS.ID_CARD_IMAGE}`})</Button>
															</label>

														</FormControl>
														<Typography style={{ marginLeft: 8 }}>
															{/* TODO: {langPropertyStock.msgPhotoHint}*/} <a onClick={openPopup('ID_CARD_IMAGE')} href="javascript:">{/*TODO: langPropertyStock.msgUploadRequirement*/}</a>
														</Typography>
														<Popover
															open={reqPopupOpened.ID_CARD_IMAGE}
															anchorEl={popupAnchor}
															onClose={closePopup('ID_CARD_IMAGE')}
															anchorOrigin={{
																vertical: 'bottom',
																horizontal: 'center',
															}}
															transformOrigin={{
																vertical: 'top',
																horizontal: 'center',
															}}
														>
															{/* TODO: <Typography style={{ margin: 8 }}>{langPropertyStock.msgUploadRequirementPhoto}</Typography> */}
														</Popover>
													</div>

													<div style={{ marginTop: 8, width: '100%', height: 150, background: '#fafafa', borderRadius: 5, overflowX: 'scroll', overflowY: 'hidden' }}>
														<div style={{ width: 'auto', height: 150, display: 'flex', alignItems: 'center', }}>
															{vendor.idCardImage?.map(({ filename, agentUserId }) => agentUserId?.toString() === currentUid ? `${BASE_URL}/files/${filename}` : 'images/masked-id-image.png')
																.map((src, i) => <div
																	key={src} className={classes.fileItem}

																	draggable
																	onDragOver={(e) => e.preventDefault()}
																	onDragStart={(e) => e.dataTransfer.setData(`vendor-id-card-${idx}`, i.toString())}
																	onDrop={(e) => {
																		if (e.dataTransfer.getData(`vendor-id-card-${idx}`) === '') {
																			return;
																		}

																		const i1 = +e.dataTransfer.getData(`vendor-id-card-${idx}`);
																		const newList = [...vendor.idCardImage ?? []];
																		const tmp = newList[i];
																		newList[i] = newList[i1];
																		newList[i1] = tmp;
																		setVendorIdCardImage(idx, newList);
																	}}
																	onClick={() => {
																		window.open(src, 'Download');
																	}}
																	style={{ display: 'flex', cursor: 'pointer', justifyContent: 'space-between', alignItems: 'start', marginLeft: 8, width: 260, minWidth: 260, height: 130, backgroundImage: `url("${src}")`, backgroundSize: 'cover', borderRadius: 5, boxShadow: theme.shadows[5] }}
																>
																	{/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'start' }}> */}
																	<div
																		onClick={(e) => {
																			e.stopPropagation();
																			const newList = [...vendor.idCardImage ?? []];
																			newList.unshift(newList.splice(i, 1)[0]);
																			setVendorIdCardImage(idx, newList);
																		}}
																		style={{ userSelect: 'none', cursor: 'pointer', display: 'inline-block', background: 'rgba(0,0,0,0.8)', color: '#fff', padding: 4, margin: 4, borderRadius: 8, fontSize: theme.typography.caption.fontSize }}>
																		{/* {i === 0 ? langPropertyStock.captionCover : langPropertyStock.actionSetCover} */}
																	</div>

																	{/* <div style={{justifyContent: 'center', alignItems: 'center', margin: 'auto' }}>
																<Button variant="contained" color="secondary" size="small" {...{component: "a", target: "_blank"}} href={src}>??</Button>
															</div> */}

																	<div
																		onClick={(e) => {
																			if (!canEditSellerFields) return;
																			e.stopPropagation();
																			const newList = [...vendor.idCardImage ?? []];
																			newList.splice(i, 1);
																			setVendorIdCardImage(idx, newList);
																			// TODO: confirmDialog.confirm('???????', '??', '??').then((confirmed) => {
																			// 	if (confirmed) {
																			// 		setPhotos(newList);
																			// 	}
																			// });
																		}}
																		style={{ width: 23, height: 23, fontSize: 21, display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', fontWeight: 'bolder', userSelect: 'none', cursor: 'pointer', background: 'rgba(200,0,0,0.8)', color: '#fff', padding: 4, margin: 4, borderRadius: 800 }}>
																		<div>{'\u2013'}</div>
																	</div>
																	{/* </div> */}

																</div>)}
														</div>
													</div>
												</Grid> : null}
											</Grid>
										)
									})
								}
							</CardContent>
						</Card>

					</Grid>
					{/*-------------------Purchaser --------------------------*/}
					<Grid
						item
						md={12}
						xs={12}
					>

						<Card>
							<CardHeader
								className={classes.cardHeaderBackground}
								title={<div>
									<div className={classes.column0 + ' ' + classes.cardHeaderElement}>2.</div><div className={classes.cardHeaderElement}>{langSalePurchaseAgreement.titlePurchaser}
										{canEditBuyerFields ? null : <div className={classes.cardHeaderElement + ' ' + classes.cardHeaderTips}>{
											format(langPropertyStock.msgSpecifiedRoleCanFillIn, langSalePurchaseAgreement.titleBuyerAgent)
										}</div>}
									</div>
								</div>}
							/>
							<Divider />
							<CardContent>
								<Grid
									item
									md={12}
									xs={12}
								>
									<Typography>
										{langSalePurchaseAgreement.purchaser}
										{!canEditBuyerFields ? null : <Tooltip title={lang.actionAdd}>
											<IconButton color="primary" onClick={() => addPurchaser()}>
												<AddIcon />
											</IconButton>
										</Tooltip>}
									</Typography>
									{form.hasError('purchasers') ? <FormHelperText data-key={'field-salepurchase-purchasers'} style={{ color: form.hasError('purchasers') ? errorColor : 'inherit' }}>{form.hasError('purchasers')}</FormHelperText> : null}
									{form.hasError('invalidPurchasers') ? <FormHelperText data-key={'field-salepurchase-invalidPurchasers'} style={{ color: form.hasError('invalidPurchasers') ? errorColor : 'inherit' }}>{langSalePurchaseAgreement.msgInputRequiredInfo}</FormHelperText> : null}
								</Grid>
								{
									form.values.purchasers?.map((purchaser: AgreementClientDTO, idx: number) => {
										return (
											<Grid item container md={12} xs={12} spacing={1}>
												<Grid
													item
													md={1}
													xs={12}
												>
													<Typography style={{ display: 'flex', alignItems: 'center', flexGrow: 2, width: 200 }}>
														{!canEditBuyerFields ? null : <IconButton key={idx} onClick={() => removePurchaser(idx)}>
															<DeleteIcon />
														</IconButton>}
													</Typography>
												</Grid>
												<Grid
													item
													md={2}
													xs={12}
												>
													<TextField
														fullWidth
														label={langSalePurchaseAgreement.name}
														margin="dense"
														required
														variant="outlined"
														error={hasErrorForClientInfo('PURCHASER', idx, purchaser.name)}
														disabled={!canEditBuyerFields}
														value={purchaser.name}
														onChange={(e) => {
															const updatedPurchaser = { ...purchaser, name: e.target.value.substring(0, 255) };
															if (e.target.value.length > 255) {
																e.preventDefault();
																setKeyBooleanMap(keyBooleanMap.set('purchasersName' + idx, true));
																form.updateValues('purchasers',
																	form.values.purchasers!.map((v: AgreementClientDTO, i: number) => idx === i ? updatedPurchaser : v)
																);
															} else {
																setKeyBooleanMap(keyBooleanMap.set('purchasersName' + idx, false));
																form.updateValues('purchasers',
																	form.values.purchasers!.map((v: AgreementClientDTO, i: number) => idx === i ? updatedPurchaser : v)
																);
															}
														}}
													/>
													<HandleFormHelperText
														isError={keyBooleanMap.get('purchasersName' + idx)}
														errorMessage={lang.textOverLimit}
													/>
												</Grid>
												<Grid
													item
													md={2}
													xs={12}
												>
													<TextField
														fullWidth
														label={langSalePurchaseAgreement.identityType}
														margin="dense"
														select
														required
														variant="outlined"
														error={hasErrorForClientInfo('PURCHASER', idx, purchaser.identityType)}
														disabled={!canEditBuyerFields}
														value={purchaser.identityType}
														onChange={(e) => {
															const updatedPurchaser = { ...purchaser, identityType: e.target.value };
															form.updateValues('purchasers',
																form.values.purchasers!.map((v: AgreementClientDTO, i: number) => idx === i ? updatedPurchaser : v)
															);
														}}
													>
														{
															Object.keys(identityTypeOptions).map((key) =>
																<MenuItem key={key} value={key}>{identityTypeOptions[key]}</MenuItem>)
														}
													</TextField>
												</Grid>
												<Grid
													item
													md={2}
													xs={12}
												>
													<TextField
														fullWidth
														label={langSalePurchaseAgreement.identityNumber}
														margin="dense"
														required
														variant="outlined"
														error={hasErrorForClientInfo('PURCHASER', idx, purchaser.identityNumber)}
														disabled={!canEditBuyerFields}
														value={purchaser.identityNumber}
														onChange={(e) => {
															if (e.target.value.length > 255) {
																e.preventDefault();
																const updatedPurchaser = { ...purchaser, identityNumber: e.target.value.substring(0, 255) };
																setKeyBooleanMap(keyBooleanMap.set('purchasersIdentityNo' + idx, true));
																form.updateValues('purchasers',
																	form.values.purchasers!.map((v: AgreementClientDTO, i: number) => idx === i ? updatedPurchaser : v)
																);
															} else {
																setKeyBooleanMap(keyBooleanMap.set('purchasersIdentityNo' + idx, false));
																const updatedPurchaser = { ...purchaser, identityNumber: e.target.value };
																form.updateValues('purchasers',
																	form.values.purchasers!.map((v: AgreementClientDTO, i: number) => idx === i ? updatedPurchaser : v)
																);
															}
														}}
														InputProps={{
															endAdornment: purchaser.identityNumber && purchaser.identityType === 'H' && !checkIsHKID(purchaser.identityNumber ?? '') ?
																<InputAdornment position="end">
																	<Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{`${langSignDialog.msgNonHKID}`}</span>} aria-label='hot-pick-expiry'>
																		<IconButton
																			style={{ color: '#FFBF00' }}
																			aria-label="hkid-indicator"
																			edge="end"
																		>
																			<ErrorIcon />
																		</IconButton>
																	</Tooltip>
																</InputAdornment> : null
														}}
													/>
													<HandleFormHelperText
														isError={keyBooleanMap.get('purchasersIdentityNo' + idx)}
														errorMessage={lang.textOverLimit}
													/>
												</Grid>
												<Grid
													item
													md={5}
													xs={12}
												>
													<TextField
														fullWidth
														label={langSalePurchaseAgreement.address}
														margin="dense"
														variant="outlined"
														// error={hasErrorForClientInfo('PURCHASER', idx, purchaser.address)}
														disabled={!canEditBuyerFields}
														value={purchaser.address}
														onChange={(e) => {
															if (e.target.value.length > 255) {
																e.preventDefault();
																const updatedPurchaser = { ...purchaser, address: e.target.value.substring(0, 255) };
																setKeyBooleanMap(keyBooleanMap.set('purchasersAddress' + idx, true));
																form.updateValues('purchasers',
																	form.values.purchasers!.map((v: AgreementClientDTO, i: number) => idx === i ? updatedPurchaser : v)
																);
															} else {
																setKeyBooleanMap(keyBooleanMap.set('purchasersAddress' + idx, false));
																const updatedPurchaser = { ...purchaser, address: e.target.value };
																form.updateValues('purchasers',
																	form.values.purchasers!.map((v: AgreementClientDTO, i: number) => idx === i ? updatedPurchaser : v)
																);
															}
														}}
													/>
													<HandleFormHelperText
														isError={keyBooleanMap.get('purchasersAddress' + idx)}
														errorMessage={lang.textOverLimit}
													/>
												</Grid>

												{canReadEditBuyerHKIDImage ? <Grid item md={12} xs={12}>
													<Typography>{langSalePurchaseAgreement.idCardImage}</Typography>

													<div style={{ display: 'flex', alignItems: 'center' }}>
														<FormControl margin="dense">
															<input
																style={{ display: 'none' }}
																id={`input-file-purchaser-id-card-${idx}`}
																type="file"
																ref={fileRef}
																onChange={(ev) => handlePurchaserIdCardUpload('ID_CARD_IMAGE', idx)(ev)}
																disabled={!canEditBuyerFields || purchaser.idCardImage?.length >= UPLOAD_LIMITS.ID_CARD_IMAGE}
															/>
															<label htmlFor={`input-file-purchaser-id-card-${idx}`}>
																<Button {...{ component: "span" } as any} variant="contained"
																	// TODO: tooltip={vendor.idCardImage?.length ?? 0 >= 4 ? langPropertyStock.msgPhotoLimit.replace('%d', UPLOAD_LIMITS.PHOTO.toString()) : undefined}
																	disabled={!canEditBuyerFields || purchaser.idCardImage?.length >= UPLOAD_LIMITS.ID_CARD_IMAGE}
																	color="primary">{lang.actionUpload} ({`${purchaser.idCardImage?.length}/${UPLOAD_LIMITS.ID_CARD_IMAGE}`})</Button>
															</label>

														</FormControl>
														<Typography style={{ marginLeft: 8 }}>
															{/* TODO: {langPropertyStock.msgPhotoHint}*/} <a onClick={openPopup('ID_CARD_IMAGE')} href="javascript:">{/*TODO: langPropertyStock.msgUploadRequirement*/}</a>
														</Typography>
														<Popover
															open={reqPopupOpened.ID_CARD_IMAGE}
															anchorEl={popupAnchor}
															onClose={closePopup('ID_CARD_IMAGE')}
															anchorOrigin={{
																vertical: 'bottom',
																horizontal: 'center',
															}}
															transformOrigin={{
																vertical: 'top',
																horizontal: 'center',
															}}
														>
															{/* TODO: <Typography style={{ margin: 8 }}>{langPropertyStock.msgUploadRequirementPhoto}</Typography> */}
														</Popover>
													</div>

													<div style={{ marginTop: 8, width: '100%', height: 150, background: '#fafafa', borderRadius: 5, overflowX: 'scroll', overflowY: 'hidden' }}>
														<div style={{ width: 'auto', height: 150, display: 'flex', alignItems: 'center', }}>
															{purchaser.idCardImage?.map(({ filename, agentUserId }) => agentUserId?.toString() === currentUid ? `${BASE_URL}/files/${filename}` : `images/masked-id-image.png`)
																.map((src, i) => <div
																	key={src} className={classes.fileItem}

																	draggable
																	onDragOver={(e) => e.preventDefault()}
																	onDragStart={(e) => e.dataTransfer.setData(`purchaser-id-card-${idx}`, i.toString())}
																	onDrop={(e) => {
																		if (e.dataTransfer.getData(`purchaser-id-card-${idx}`) === '') {
																			return;
																		}

																		const i1 = +e.dataTransfer.getData(`purchaser-id-card-${idx}`);
																		const newList = [...purchaser.idCardImage ?? []];
																		const tmp = newList[i];
																		newList[i] = newList[i1];
																		newList[i1] = tmp;
																		setPurchaserIdCardImage(idx, newList);
																	}}
																	onClick={() => {
																		window.open(src, 'Download');
																	}}
																	style={{ display: 'flex', cursor: 'pointer', justifyContent: 'space-between', alignItems: 'start', marginLeft: 8, width: 260, minWidth: 260, height: 130, backgroundImage: `url("${src}")`, backgroundSize: 'cover', borderRadius: 5, boxShadow: theme.shadows[5] }}
																>
																	{/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'start' }}> */}
																	<div
																		onClick={(e) => {
																			if (!canEditBuyerFields) return;
																			e.stopPropagation();
																			const newList = [...purchaser.idCardImage ?? []];
																			newList.unshift(newList.splice(i, 1)[0]);
																			setPurchaserIdCardImage(idx, newList);
																		}}
																		style={{ userSelect: 'none', cursor: 'pointer', display: 'inline-block', background: 'rgba(0,0,0,0.8)', color: '#fff', padding: 4, margin: 4, borderRadius: 8, fontSize: theme.typography.caption.fontSize }}>
																		{/* {i === 0 ? langPropertyStock.captionCover : langPropertyStock.actionSetCover} */}
																	</div>

																	{/* <div style={{justifyContent: 'center', alignItems: 'center', margin: 'auto' }}>
																<Button variant="contained" color="secondary" size="small" {...{component: "a", target: "_blank"}} href={src}>??</Button>
															</div> */}

																	<div
																		onClick={(e) => {
																			if (!canEditBuyerFields) return;
																			e.stopPropagation();
																			const newList = [...purchaser.idCardImage ?? []];
																			newList.splice(i, 1);
																			setPurchaserIdCardImage(idx, newList);
																			// TODO: confirmDialog.confirm('???????', '??', '??').then((confirmed) => {
																			// 	if (confirmed) {
																			// 		setPhotos(newList);
																			// 	}
																			// });
																		}}
																		style={{ width: 23, height: 23, fontSize: 21, display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', fontWeight: 'bolder', userSelect: 'none', cursor: 'pointer', background: 'rgba(200,0,0,0.8)', color: '#fff', padding: 4, margin: 4, borderRadius: 800 }}>
																		<div>{'\u2013'}</div>
																	</div>
																	{/* </div> */}

																</div>)}
														</div>
													</div>
												</Grid> : null}
											</Grid>
										)
									})
								}
							</CardContent>
						</Card>

					</Grid>

					{/*---------------------------Agent----------------------*/}
					<Grid
						item
						md={12}
						xs={12}
					>
						<TableContainer component={Paper}>
							<Table className={classes.table} size="small" aria-label="B3 table">
								<TableHead>
									<TableRow>
										<TableCell className={classes.column0}>3.</TableCell>
										<TableCell colSpan={8} align="left">{langSalePurchaseAgreement.titleAgent}</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{/*----------- Appointment of Agent ---------- */}
									<TableRow >
										<TableCell component="th" scope="row"></TableCell>
										<TableCell className={classes.partB_column1}>{langSalePurchaseAgreement.agentFor}</TableCell>
										<TableCell colSpan={2}>
											<TextField
												data-key={'field-salepurchase-agentFor'}
												className={classes.textField}
												margin="dense"
												variant="outlined"
												disabled={true}
												value={agentAppointmentOptionsSale[form.values.agentFor!]}
											// {...form.values.agentFor = "BOTH"}
											/>

										</TableCell>
									</TableRow>

									{/*----------- name ---------- */}
									<TableRow >
										<TableCell component="th" scope="row"></TableCell>
										<TableCell className={classes.partB_column1}>
											{langSalePurchaseAgreement.agentName}
										</TableCell>
										<TableCell colSpan={2}>
											<TextField
												className={classes.textField}
												disabled={true}
												margin="dense"
												variant="outlined"
												{...bind('agentName')}
											/>
										</TableCell>
									</TableRow>
									{/*----------- business registration no. ---------- */}
									<TableRow >
										<TableCell component="th" scope="row"></TableCell>
										<TableCell>{langSalePurchaseAgreement.agentBusinessRegNo}</TableCell>
										<TableCell colSpan={4}>
											<TextField
												className={classes.textField}
												disabled={true}
												margin="dense"
												variant="outlined"
												{...bind('agentBusinessRegNo')}
											/>
										</TableCell>
									</TableRow>

									{/*----------- licence no. ---------- */}
									<TableRow >
										<TableCell component="th" scope="row"></TableCell>
										<TableCell className={classes.partB_column1}>
											{langSalePurchaseAgreement.agentLicenceNo}
										</TableCell>
										<TableCell colSpan={2}>
											<TextField
												className={classes.textField}
												disabled={true}
												margin="dense"
												variant="outlined"
												{...bind('agentLicenceNo')}
											/>
										</TableCell>
									</TableRow>
									{/*---------- address ----------- */}
									<TableRow >
										<TableCell component="th" scope="row"></TableCell>
										<TableCell className={classes.partB_column1}>
											{langSalePurchaseAgreement.agentAddress}
										</TableCell>
										<TableCell colSpan={2}>
											<TextareaAutosize
												className={classes.textArea}
												disabled={true}
												rowsMin={2}
												rowsMax={2}
												{...bind('agentAddress')}
											/>
											{form.hasError('agentAddress') ? <FormHelperText style={{ color: form.hasError('agentAddress') ? errorColor : 'inherit' }}>{form.hasError('agentAddress')}</FormHelperText> : null}
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>

					<Grid item xs={12}>
						<Divider />
					</Grid>

					<Grid container xs={12} spacing={1}>
						<Grid
							item
							md={12}
							xs={12}
						>
							<Typography className={classes.partTitle}>{langSalePurchaseAgreement.titleStatements}</Typography>
						</Grid>
						{/*-----------------premises--------------*/}
						<Grid
							item
							md={12}
							xs={12}
						>
							<TableContainer component={Paper}>
								<Table className={classes.table} size="small" aria-label="B3 table">
									<TableHead>
										<TableRow>
											<TableCell className={classes.column0}>1.</TableCell>
											<TableCell colSpan={8} align="left">{langSalePurchaseAgreement.titlePremises}</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow>
											<TableCell component="th" scope="row"></TableCell>
											{/* <TableCell className={classes.partB_column1}>
										{langSalePurchaseAgreement.agentAddress}
									</TableCell> */}
											<TableCell colSpan={2}>
												<TextareaAutosize
													className={classes.textArea}
													rowsMin={2}
													rowsMax={2}
													{...bind('premises')}
													onChange={(e) => {
														limitTextFieldLength(e, 255, 'premises', form, keyBooleanMap, setKeyBooleanMap);
													}}
												/>
												<FormHelperText>{keyBooleanMap.get('premises') ? lang.textOverLimit : ''}</FormHelperText>
												{form.hasError('premises') ? <FormHelperText style={{ color: form.hasError('premises') ? errorColor : 'inherit' }}>{form.hasError('premises')}</FormHelperText> : null}
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
						{/*-------------consideration and payment-------------*/}
						<Grid
							item
							md={12}
							xs={12}
						>
							<TableContainer component={Paper}>
								<Table className={classes.table} size="small" aria-label="B3 table">
									<TableHead>
										<TableRow>
											<TableCell className={classes.column0}>2.1.</TableCell>
											<TableCell colSpan={8} align="left">{langSalePurchaseAgreement.titleConsiderationPayment}</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{/*-------------purchase price----------*/}
										<TableRow>
											<TableCell component="th" scope="row"></TableCell>
											<TableCell className={classes.partB_column1}>
												{langSalePurchaseAgreement.purchasePrice}
											</TableCell>
											<TableCell colSpan={2}>

												<NumberField
													className={classes.dollarField}
													margin="dense"
													variant="outlined"
													InputProps={{
														startAdornment: <InputAdornment position="start">$</InputAdornment>,
														// endAdornment: <InputAdornment position="start">{locale === 'en' ? lang.u1M : lang.u10k}</InputAdornment>,
													}}
													{...bind('purchasePrice')}
													error={!!form.hasError('purchasePrice')}
													helperText={form.hasError('purchasePrice')}
													onChange={(e) => {
														e.target.value.length > 12 ? e.preventDefault() : form.updateValues('purchasePrice', (!isNonEmpty(e.target.value) || isNaN(parseInt(e.target.value))) ? '' : parseInt(e.target.value))
													}}
												/>
												{/*<TextField
													className={classes.dollarField}
													margin="dense"
													variant="outlined"
													{...bind('purchasePrice')} onChange={undefined} value={undefined}
													InputProps={{
														startAdornment: <InputAdornment position="start">$</InputAdornment>,
														inputComponent: MaskedInput as any,
														inputProps: {
															value: bind('purchasePrice').value?.toString(),
															onAccept: (value: any) => {
																bind('purchasePrice').onChange({ target: { value: value ? Number(value) : value } });
															}
														}
													}}
													onKeyPress={(e) => {
														if (e.key == ".") {
															e.preventDefault();
														}
													}}
													error={!!form.hasError('purchasePrice')}
													helperText={form.hasError('purchasePrice')}
												/>*/}
											</TableCell>
										</TableRow>
										{/*-------------initial deposit----------*/}
										<TableRow>
											<TableCell component="th" scope="row"></TableCell>
											<TableCell className={classes.partB_column1}>
												{langSalePurchaseAgreement.initialDeposit}
											</TableCell>
											<TableCell colSpan={2}>
												<NumberField
													className={classes.dollarField}
													margin="dense"
													variant="outlined"
													InputProps={{
														startAdornment: <InputAdornment position="start">$</InputAdornment>,
														// endAdornment: <InputAdornment position="start">{locale === 'en' ? lang.u1M : lang.u10k}</InputAdornment>,
													}}
													{...bind('initialDeposit')}
													onChange={(e) => {
														e.target.value.length > 12 ? e.preventDefault() : form.updateValues('initialDeposit', (!isNonEmpty(e.target.value) || isNaN(parseInt(e.target.value))) ? '' : parseInt(e.target.value))
													}}
													error={!!form.hasError('initialDeposit')}
													helperText={form.hasError('initialDeposit')}
												/>
												{/* <TextField
													className={classes.dollarField}
													margin="dense"
													variant="outlined"
													{...bind('initialDeposit')} onChange={undefined} value={undefined}
													InputProps={{
														startAdornment: <InputAdornment position="start">$</InputAdornment>,
														inputComponent: MaskedInput as any,
														inputProps: {
															value: bind('initialDeposit').value?.toString(),
															onAccept: (value: any) => {
																// setMaskValues({ ...maskValues, initialDeposit: value });
																bind('initialDeposit').onChange({ target: { value: value ? Number(value) : value } });
															}
														}
													}}
													onKeyPress={(e) => {
														if (e.key == ".") {
															e.preventDefault();
														}
													}}
													error={!!form.hasError('initialDeposit')}
													helperText={form.hasError('initialDeposit')}
												/> */}
											</TableCell>
										</TableRow>
										{/*-------------sign date----------*/}
										<TableRow>
											<TableCell component="th" scope="row"></TableCell>
											<TableCell className={classes.partB_column1}>
												{langSalePurchaseAgreement.formalAgreementSignDeadline}
											</TableCell>
											<TableCell colSpan={2}>
												<KeyboardDatePicker autoOk={true}
													data-key={'field-salepurchase-formalAgreementSignDeadline'}
													className={classes.datePicker}
													variant="inline"
													inputVariant="outlined"
													format={DISPLAY_DATE_FORMAT}
													margin="dense"
													error={!!form.hasError('formalAgreementSignDeadline')}
													helperText={form.hasError('formalAgreementSignDeadline')}
													value={form.values.formalAgreementSignDeadline ? moment(form.values.formalAgreementSignDeadline) : null}
													onChange={(value) => {
														form.setValues({
															...form.values,
															formalAgreementSignDeadline: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined
														});
													}}
													KeyboardButtonProps={{
														'aria-label': 'change date',
													}}
												/>
											</TableCell>
										</TableRow>
										{/*-------------full Deposit----------*/}
										<TableRow>
											<TableCell component="th" scope="row"></TableCell>
											<TableCell className={classes.partB_column1}>
												{langSalePurchaseAgreement.fullDeposit}
											</TableCell>
											<TableCell colSpan={2}>
												<NumberField
													className={classes.dollarField}
													margin="dense"
													variant="outlined"
													InputProps={{
														startAdornment: <InputAdornment position="start">$</InputAdornment>,
														// endAdornment: <InputAdornment position="start">{locale === 'en' ? lang.u1M : lang.u10k}</InputAdornment>,
													}}
													{...bind('fullDeposit')}
													onChange={(e) => {
														e.target.value.length > 12 ? e.preventDefault() : form.updateValues('fullDeposit', (!isNonEmpty(e.target.value) || isNaN(parseInt(e.target.value))) ? '' : parseInt(e.target.value))
													}}
													error={!!form.hasError('fullDeposit')}
													helperText={form.hasError('fullDeposit')}
												/>
												{/* <TextField
													className={classes.dollarField}
													margin="dense"
													variant="outlined"
													{...bind('fullDeposit')} onChange={undefined} value={undefined}
													InputProps={{
														startAdornment: <InputAdornment position="start">$</InputAdornment>,
														inputComponent: MaskedInput as any,
														inputProps: {
															value: bind('fullDeposit').value?.toString(),
															onAccept: (value: any) => {
																bind('fullDeposit').onChange({ target: { value: value ? Number(value) : value } });
															}
														}
													}}
													onKeyPress={(e) => {
														if (e.key == ".") {
															e.preventDefault();
														}
													}}
													error={!!form.hasError('fullDeposit')}
													helperText={form.hasError('fullDeposit')}
												/> */}
											</TableCell>
										</TableRow>
										{/*-------------full deposit date----------*/}
										<TableRow>
											<TableCell component="th" scope="row"></TableCell>
											<TableCell className={classes.partB_column1}>
												{langSalePurchaseAgreement.fullDepositDate}
											</TableCell>
											<TableCell colSpan={2}>
												<KeyboardDatePicker autoOk={true}
													data-key={'field-salepurchase-fullDepositDate'}
													className={classes.datePicker}
													variant="inline"
													inputVariant="outlined"
													format={DISPLAY_DATE_FORMAT}
													margin="dense"
													error={!!form.hasError('fullDepositDate')}
													helperText={form.hasError('fullDepositDate')}
													value={form.values.fullDepositDate ? moment(form.values.fullDepositDate) : null}
													onChange={(value) => {
														form.setValues({
															...form.values,
															fullDepositDate: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined
														});
													}}
													KeyboardButtonProps={{
														'aria-label': 'change date',
													}}
												/>
											</TableCell>
										</TableRow>
										{/*-------------remaining sum----------*/}
										<TableRow>
											<TableCell component="th" scope="row"></TableCell>
											<TableCell className={classes.partB_column1}>
												{langSalePurchaseAgreement.remainingSum}
											</TableCell>
											<TableCell colSpan={2}>
												{/* <NumberField
											className={classes.textField}
											margin="dense"
											variant="outlined"
											InputProps={{
												readOnly: true,
												startAdornment: <InputAdornment position="start">$</InputAdornment>,
												// endAdornment: <InputAdornment position="start">{locale === 'en' ? lang.u1M : lang.u10k}</InputAdornment>,
											}}
											{...bind('remainingSum')}
										/> */}
												<TextField
													className={classes.dollarField}
													margin="dense"
													variant="outlined"
													disabled={true}
													{...bind('remainingSum')} onChange={undefined} value={undefined}
													InputProps={{
														readOnly: true,
														startAdornment: <InputAdornment position="start">$</InputAdornment>,
														inputComponent: MaskedInput as any,
														inputProps: {
															value: bind('remainingSum').value?.toString(),
															onAccept: (value: any) => {
																// setMaskValues({ ...maskValues, remainingSum: value });
																bind('remainingSum').onChange({ target: { value: value ? Number(value) : value } });
															}
														}
													}}
												/>
											</TableCell>
										</TableRow>
										{/*-------------remaining sum----------*/}
										<TableRow>
											<TableCell component="th" scope="row"></TableCell>
											<TableCell className={classes.partB_column1}>
												{langSalePurchaseAgreement.paymentCompletionDate}
											</TableCell>
											<TableCell colSpan={2}>
												<KeyboardDatePicker autoOk={true}
													data-key={'field-salepurchase-paymentCompletionDate'}
													className={classes.datePicker}
													variant="inline"
													inputVariant="outlined"
													format={DISPLAY_DATE_FORMAT}
													margin="dense"
													error={!!form.hasError('paymentCompletionDate')}
													helperText={form.hasError('paymentCompletionDate')}
													value={form.values.paymentCompletionDate ? moment(form.values.paymentCompletionDate) : null}
													onChange={(value) => {
														form.setValues({
															...form.values,
															paymentCompletionDate: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined
														});
													}}
													KeyboardButtonProps={{
														'aria-label': 'change date',
													}}
												/>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
						{/*----------------vacant possession term---------------*/}
						<Grid
							item
							md={12}
							xs={12}
						>
							<TableContainer component={Paper}>
								<Table className={classes.table} size="small" aria-label="B3 table">
									<TableHead>
										<TableRow>
											<TableCell className={classes.column0}>2.2.</TableCell>
											<TableCell colSpan={8} align="left">{langSalePurchaseAgreement.titleVacantPossessionTerm}</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow>
											<TableCell component="th" scope="row"></TableCell>
											<TableCell align="left" colSpan={2}>
												<FormControl component="fieldset" data-key={'field-salepurchase-vacantPossessionTerm'}>
													<RadioGroup aria-label="vendor acknowledgement" name="customized-radios"
														value={form.values.vacantPossessionTerm ?? ''}
														onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
															const val = (event.target as HTMLInputElement).value;
															form.updateValues('vacantPossessionTerm', val);
														}}
													>
														<FormControlLabel
															value="option1"
															control={<Radio size="small" />}
															label={langSalePurchaseAgreement.vacantPossessionTerm1}
														/>
														<FormControlLabel
															value="option2"
															control={<Radio size="small" />}
															label={langSalePurchaseAgreement.vacantPossessionTerm2}
														/>
														<FormControlLabel value="" control={<Radio size="small" />} label='' style={{ display: 'none' }} />
													</RadioGroup>
												</FormControl>
												{form.hasError('vacantPossessionTerm') ? <FormHelperText style={{ color: form.hasError('vacantPossessionTerm') ? errorColor : 'inherit' }}>{form.hasError('vacantPossessionTerm')}</FormHelperText> : null}

											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>

						{/*-------------vendor as confirmor-------------*/}
						<Grid
							item
							md={12}
							xs={12}
						>
							<TableContainer component={Paper}>
								<Table className={classes.table} size="small" aria-label="vendor as confirmor">
									<TableHead>
										<TableRow>
											<TableCell className={classes.column0}>5.</TableCell>
											<TableCell colSpan={8} align="left">{langSalePurchaseAgreement.titleVendorAsConfirmor}</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>

										{/*----------- vendorAsConfirmor ---------- */}
										<TableRow >
											<TableCell component="th" scope="row"></TableCell>
											<TableCell colSpan={2}>
												<Checkbox
													checked={form.values.vendorAsConfirmor ?? false}
													onChange={() => {
														form.updateValues('vendorAsConfirmor', !form.values.vendorAsConfirmor);
													}}
												/>
												{langSalePurchaseAgreement.vendorIsSellingAsConfirmor}
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
						{/*--------------------solicitor-------------------*/}
						<Grid
							item
							md={12}
							xs={12}
						>
							<TableContainer component={Paper}>
								<Table className={classes.table} size="small" aria-label="B3 table">
									<TableHead>
										<TableRow>
											<TableCell className={classes.column0}>6.</TableCell>
											<TableCell colSpan={8} align="left">{langSalePurchaseAgreement.titleSolicitor}</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{/*----------- vendor solicitor ---------- */}
										<TableRow >
											<TableCell component="th" scope="row"></TableCell>
											<TableCell className={classes.partB_column1}>
												{langSalePurchaseAgreement.vendorSolicitor}
											</TableCell>
											<TableCell colSpan={2}>
												<TextField
													className={classes.textField}
													margin="dense"
													variant="outlined"
													{...bind('vendorSolicitor')}
													onChange={(e) => {
														limitTextFieldLength(e, 255, 'vendorSolicitor', form, keyBooleanMap, setKeyBooleanMap);
													}}
												/>
												<FormHelperText>{keyBooleanMap.get('vendorSolicitor') ? lang.textOverLimit : ''}</FormHelperText>
											</TableCell>
										</TableRow>

										{/*------------ purchaser solicitor ---------- */}
										<TableRow >
											<TableCell component="th" scope="row"></TableCell>
											<TableCell>{langSalePurchaseAgreement.purchaserSolicitor}</TableCell>
											<TableCell colSpan={4}>
												<TextField
													className={classes.textField}
													margin="dense"
													variant="outlined"
													{...bind('purchaserSolicitor')}
													onChange={(e) => {
														limitTextFieldLength(e, 255, 'purchaserSolicitor', form, keyBooleanMap, setKeyBooleanMap);
													}}
												/>
												<FormHelperText>{keyBooleanMap.get('purchaserSolicitor') ? lang.textOverLimit : ''}</FormHelperText>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
						{/*-------------agent commission-------------*/}
						<Grid
							item
							md={12}
							xs={12}
						>
							<TableContainer component={Paper}>
								<Table className={classes.table} size="small" aria-label="B3 table">
									<TableHead>
										<TableRow>
											<TableCell className={classes.column0}>9.</TableCell>
											<TableCell colSpan={8} align="left">{langSalePurchaseAgreement.titleAgentCommission}</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{/*-------------vendor commission----------*/}
										<TableRow>
											<TableCell component="th" scope="row"></TableCell>
											<TableCell className={classes.partB_column1}>
												{langSalePurchaseAgreement.vendorCommission}
											</TableCell>
											<TableCell colSpan={2}>
												{/* <TextField
													className={classes.dollarField}
													margin="dense"
													variant="outlined"
													{...bind('vendorCommission')} value={undefined}
													InputProps={{
														startAdornment: <InputAdornment position="start">$</InputAdornment>,
														inputComponent: MaskedInput as any,
														inputProps: {
															value: bind('vendorCommission').value?.toString(),
															onAccept: (value: any) => {
																bind('vendorCommission').onChange({ target: { value: value ? Number(value) : value } });
															}
														}
													}}
												/>*/}
												<NumberField
													className={classes.dollarField}
													margin="dense"
													variant="outlined"
													InputProps={{
														startAdornment: <InputAdornment position="start">$</InputAdornment>,
														// endAdornment: <InputAdornment position="start">{locale === 'en' ? lang.u1M : lang.u10k}</InputAdornment>,
													}}
													{...bind('vendorCommission')}
													onChange={(e) => {
														limitNumberFieldLength(e, 'vendorCommission', form);
													}}
												/>
											</TableCell>
										</TableRow>
										{/*-------------purchaser commission----------*/}
										<TableRow>
											<TableCell component="th" scope="row"></TableCell>
											<TableCell className={classes.partB_column1}>
												{langSalePurchaseAgreement.purchaserCommission}
											</TableCell>
											<TableCell colSpan={2}>
												{/* <TextField
													className={classes.dollarField}
													margin="dense"
													variant="outlined"
													{...bind('purchaserCommission')} value={undefined}
													InputProps={{
														startAdornment: <InputAdornment position="start">$</InputAdornment>,
														inputComponent: MaskedInput as any,
														inputProps: {
															value: bind('purchaserCommission').value?.toString(),
															onAccept: (value: any) => {
																bind('purchaserCommission').onChange({ target: { value: value ? Number(value) : value } });
															}
														}
													}}
												/> */}
												<NumberField
													className={classes.dollarField}
													margin="dense"
													variant="outlined"
													InputProps={{
														startAdornment: <InputAdornment position="start">$</InputAdornment>,
														// endAdornment: <InputAdornment position="start">{locale === 'en' ? lang.u1M : lang.u10k}</InputAdornment>,
													}}
													{...bind('purchaserCommission')}
													onChange={(e) => {
														limitNumberFieldLength(e, 'purchaserCommission', form);
													}}
												/>
											</TableCell>
										</TableRow>
										{/*-------------commission deadline date----------*/}
										<TableRow>
											<TableCell component="th" scope="row"></TableCell>
											<TableCell className={classes.partB_column1}>
												{langSalePurchaseAgreement.commissionPaymentDeadline}
											</TableCell>
											<TableCell colSpan={2}>
												<KeyboardDatePicker autoOk={true}
													data-key={'field-salepurchase-commissionPaymentDeadline'}
													className={classes.datePicker}
													variant="inline"
													inputVariant="outlined"
													format={DISPLAY_DATE_FORMAT}
													margin="dense"
													error={!!form.hasError('commissionPaymentDeadline')}
													helperText={form.hasError('commissionPaymentDeadline')}
													value={form.values.commissionPaymentDeadline ? moment(form.values.commissionPaymentDeadline) : null}
													onChange={(value) => {
														form.setValues({
															...form.values,
															commissionPaymentDeadline: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined
														});
													}}
													KeyboardButtonProps={{
														'aria-label': 'change date',
													}}
												/>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
						{/* -----------compensation to agent ------------- */}
						<Grid
							item
							md={12}
							xs={12}
						>
							<TableContainer component={Paper}>
								<Table className={classes.table} size="small" aria-label="A1 table">
									<TableHead>
										<TableRow>
											<TableCell className={classes.column0}>10.</TableCell>
											<TableCell colSpan={2} align="left">{langSalePurchaseAgreement.titleCompensationToAgent}</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{/*----------- compensation---------- */}
										<TableRow >
											<TableCell component="th" scope="row"></TableCell>
											<TableCell className={classes.partB_column1}>{langSalePurchaseAgreement.compensation}</TableCell>
											<TableCell colSpan={2}>
												{/*<TextField
													className={classes.dollarField}
													margin="dense"
													variant="outlined"
													{...bind('compensationToAgent')} value={undefined}
													InputProps={{
														startAdornment: <InputAdornment position="start">$</InputAdornment>,
														inputComponent: MaskedInput as any,
														inputProps: {
															value: bind('compensationToAgent').value?.toString(),
															onAccept: (value: any) => {
																bind('compensationToAgent').onChange({ target: { value: value ? Number(value) : value } });
															}
														}
													}}
												/> */}
												<NumberField
													className={classes.dollarField}
													margin="dense"
													variant="outlined"
													InputProps={{
														startAdornment: <InputAdornment position="start">$</InputAdornment>,
														// endAdornment: <InputAdornment position="start">{locale === 'en' ? lang.u1M : lang.u10k}</InputAdornment>,
													}}
													{...bind('compensationToAgent')}
													onChange={(e) => {

														limitNumberFieldLength(e, 'compensationToAgent', form);
													}}
												/>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>

						{/* ------------- residential or not ------------- */}
						<Grid
							item
							md={12}
							xs={12}
						>
							<TableContainer component={Paper}>
								<Table className={classes.table} size="small" aria-label="part 2 table">
									<TableHead>
										<TableRow>
											<TableCell className={classes.column0}>14.</TableCell>
											<TableCell colSpan={2} align="left" >{langSalePurchaseAgreement.residentialOrNot}</TableCell>
											{/* <TableCell></TableCell> */}
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow>
											<TableCell component="th" scope="row"></TableCell>
											{/* <TableCell className={classes.column1}>{langForm3.relationship}</TableCell> */}
											<TableCell>
												<div >
													<FormControl component="fieldset" style={{}}>
														{/* <FormLabel component="legend">Gender</FormLabel> */}
														<RadioGroup aria-label="Relationships" name="customized-radios" row
															value={form.values.isResidential ?? true ? 'Y' : 'N'}
															onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
																const val = (event.target as HTMLInputElement).value;
																form.updateValues('isResidential', val === 'Y' ? true : false);
															}}
														>
															<FormControlLabel
																value='Y'
																control={<Radio size="small" />}
																label={langSalePurchaseAgreement.residential}
															/>
															<FormControlLabel
																value='N'
																control={<Radio size="small" />}
																label={langSalePurchaseAgreement.nonResidential}
															/>
														</RadioGroup>
													</FormControl>
												</div>
												{/* {form3.hasError('relationship') ? <FormHelperText style={{color: form3.hasError('relationship') ? errorColor : 'inherit'}}>{langForm3.msgSelectRelationship}</FormHelperText> : null} */}
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>


						{/* ------------- prevailing version ------------- */}
						{/* <Grid
					item
					md={12}
					xs={12}
				>
					<TableContainer component={Paper}>
						<Table className={classes.table} size="small"  aria-label="part 2 table">
							<TableHead>
								<TableRow>
									<TableCell className={classes.column0}>9.</TableCell>
									<TableCell colSpan={2} align="left" >{langSalePurchaseAgreement.titlePrevailingVersion}</TableCell>
									<TableCell></TableCell> 
								</TableRow>
							</TableHead>
							<TableBody>
								<TableRow >
									<TableCell component="th" scope="row"></TableCell>
									<TableCell className={classes.partB_column1}>{langSalePurchaseAgreement.version}</TableCell>
									<TableCell colSpan={2}>
										<TextField
											className={classes.textField}
											margin="dense"
											variant="outlined"
											select
											{...bind('prevailingVersion')}
										>
											{
												Object.keys(prevailingVersionOptions).map((key)=> 
													<MenuItem key={key} value={key}>{prevailingVersionOptions[key]}</MenuItem>)
											}
										</TextField>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</Grid> */}
						{/* certificate of value */}
						<Grid
							item
							md={12}
							xs={12}
						>
							<TableContainer component={Paper}>
								<Table className={classes.table} size="small" aria-label="A1 table">
									<TableHead>
										<TableRow>
											<TableCell className={classes.column0}>15.</TableCell>
											<TableCell colSpan={2} align="left">{langSalePurchaseAgreement.certificateOfValue}</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{/*----------- certification of value ---------- */}
										<TableRow >
											<TableCell component="th" scope="row"></TableCell>
											{/* <TableCell>{langSalePurchaseAgreement.date}</TableCell> */}
											<TableCell>
												<NumberField
													className={classes.dollarField}
													margin="dense"
													variant="outlined"
													InputProps={{
														startAdornment: <InputAdornment position="start">$</InputAdornment>,
														// endAdornment: <InputAdornment position="start">{locale === 'en' ? lang.u1M : lang.u10k}</InputAdornment>,
													}}
													{...bind('certificateOfValue')}
													onChange={(e) => {
														limitNumberFieldLength(e, 'certificateOfValue', form);
													}}
												/>
												{/* <TextField
													className={classes.dollarField}
													margin="dense"
													variant="outlined"
													{...bind('certificateOfValue')} value={undefined}
													InputProps={{
														startAdornment: <InputAdornment position="start">$</InputAdornment>,
														inputComponent: MaskedInput as any,
														inputProps: {
															value: bind('certificateOfValue').value?.toString(),
															onAccept: (value: any) => {
																bind('certificateOfValue').onChange({ target: { value: value ? Number(value) : value } });
															}
														}
													}}
												/> */}
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>

						{/* remarks */}
						<Grid
							item
							md={12}
							xs={12}
						>
							<TableContainer component={Paper}>
								<Table className={classes.table} size="small" aria-label="A1 table">
									<TableHead>
										<TableRow>
											<TableCell className={classes.column0}>18.</TableCell>
											<TableCell colSpan={2} align="left">{langSalePurchaseAgreement.remarks}</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{/*----------- remarks ---------- */}
										<TableRow >
											<TableCell component="th" scope="row"></TableCell>
											{/* <TableCell>{langSalePurchaseAgreement.date}</TableCell> */}
											<TableCell>
												<TextareaAutosize
													className={classes.textArea}
													rowsMin={2}
													rowsMax={2}
													{...bind('remarks')}
													onChange={(e) => {
														limitTextFieldLength(e, 255, 'remarks', form, keyBooleanMap, setKeyBooleanMap);
													}}
												/>
												<FormHelperText>{keyBooleanMap.get('remarks') ? lang.textOverLimit : ''}</FormHelperText>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>

						{/* cheque */}
						<Grid
							item
							md={12}
							xs={12}
						>
							<TableContainer component={Paper}>
								<Table className={classes.table} size="small" aria-label="A1 table">
									<TableHead>
										<TableRow>
											<TableCell className={classes.column0}>*</TableCell>
											<TableCell colSpan={2} align="left">{langSalePurchaseAgreement.titleInitialDepositPaymentMethod}</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{/* options: cash or cheque */}
										<TableRow >
											<TableCell component="th" scope="row"></TableCell>
											{/* <TableCell className={classes.column20}>{langTenancyAgreement.chequeNo}</TableCell> */}
											<TableCell colSpan={2}>
												<FormControl component="fieldset">
													<RadioGroup aria-label="initial deposit payment method" name="customized-radios"
														value={!!form.values.isCheque ? "cheque" : "cash"}
														onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
															const val = (event.target as HTMLInputElement).value;
															// form.updateValues('isCheque', val === "cheque" ? true : val === "cash" ? false : false);
															form.setValues({
																...form.values,
																isCheque: val === "cheque" ? true : val === "cash" ? false : false,
																chequeNo: val === "cheque" ? form.values.chequeNo : undefined,
																chequeBank: val === "cheque" ? form.values.chequeBank : undefined
															});
														}}
													>
														<FormControlLabel
															value="cash"
															control={<Radio size="small" />}
															label={langSalePurchaseAgreement.cash}
														/>
														<FormControlLabel
															value="cheque"
															control={<Radio size="small" />}
															label={langSalePurchaseAgreement.cheque}
														/>
														<FormControlLabel value="" control={<Radio size="small" />} label='' style={{ display: 'none' }} />
													</RadioGroup>
												</FormControl>
											</TableCell>
										</TableRow>
										{/*----------- cheque no ---------- */}
										{
											!!form.values.isCheque ?
												<TableRow >
													<TableCell component="th" scope="row"></TableCell>
													<TableCell className={classes.partB_column1}>{langSalePurchaseAgreement.chequeNo}</TableCell>
													<TableCell colSpan={2}>
														<TextField
															className={classes.textField}
															margin="dense"
															variant="outlined"
															{...bind('chequeNo')}
															onChange={(e) => {
																limitTextFieldLength(e, 255, 'chequeNo', form, keyBooleanMap, setKeyBooleanMap);
															}}
														/>
														<FormHelperText>{keyBooleanMap.get('chequeNo') ? lang.textOverLimit : ''}</FormHelperText>
													</TableCell>
												</TableRow> : null
										}
										{/*---------- cheque bank ---------*/}
										{
											!!form.values.isCheque ?
												<TableRow >
													<TableCell component="th" scope="row"></TableCell>
													<TableCell className={classes.partB_column1}>{langSalePurchaseAgreement.chequeBank}</TableCell>
													<TableCell colSpan={2}>
														<TextField
															className={classes.textField}
															margin="dense"
															variant="outlined"
															{...bind('chequeBank')}
															onChange={(e) => {
																limitTextFieldLength(e, 255, 'chequeBank', form, keyBooleanMap, setKeyBooleanMap);
															}}
														/>
														<FormHelperText>{keyBooleanMap.get('chequeBank') ? lang.textOverLimit : ''}</FormHelperText>
													</TableCell>
												</TableRow> : null
										}
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Divider />
					</Grid>

				</Grid>

			</CardContent>

			<CardActions style={{ justifyContent: 'flex-end', display: 'none' }}>
				<Button
					variant="contained"
					color="primary"
				// onClick={()=>{
				//   if (form.values.id != undefined && form.values.id != null){
				//     dispatch({type: 'Form2.UpdateRequested', payload: {
				//       ...form.values,
				//     }});
				//   }else{
				//     dispatch({type: 'Form2.CreationRequested', payload: {
				//       ...form.values,
				//       propertyStockId: propertyStockId,
				//     }});
				//   }
				// }}
				>{lang.actionSaveForm}</Button>
				<Button variant="contained" color="primary">生成PDF檔案</Button>
			</CardActions>
		</Card>
	)
};

export default SalePurchaseDialog;
