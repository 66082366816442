import { makeStyles } from '@material-ui/core';
import { hasPermission } from "common/access-control";
import { DEFAULT_FROM, DEFAULT_TO } from 'common/elements/MinMaxField';
import usePageTitle from 'common/hooks';
import { priceFromView, priceToView } from 'common/utils';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { IRootState, PASDispatch } from 'reducers';
import { ClientsTable, ClientsToolbar } from './components';
import ClientsSearch, { SearchFormValues } from './components/ClientsSearch';

const DEFAULT_SIZE = 10;

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  search: {
    marginBottom: theme.spacing(2)
  }
}));

function toCriteria(values: any, locale: string) {
  if (Array.isArray(values.price)) {
    values = {
      ...values,
      price: [
        values.price[0] === DEFAULT_FROM ? DEFAULT_FROM : priceFromView(values.price[0], locale),
        values.price[1] === DEFAULT_TO ? DEFAULT_TO : priceFromView(values.price[1], locale),
      ],
      // price: [
      //   values.price[0] === priceSlider[0] ? 0 : values.price[0],
      //   values.price[1] === priceSlider[priceSlider.length - 1] ? Number.MAX_SAFE_INTEGER : values.price[1],
      // ],
    };
  }

  if (Array.isArray(values.rent)) {
    values = {
      ...values,
      // rent: [
      //   values.rent[0] === rentSlider[0] ? 0 : values.rent[0],
      //   values.rent[1] === rentSlider[rentSlider.length - 1] ? Number.MAX_SAFE_INTEGER : values.rent[1],
      // ],
    };
  }

  if (Array.isArray(values.net)) {
    values = {
      ...values,
      // net: [
      //   values.net[0] === netSlider[0] ? 0 : values.net[0],
      //   values.net[1] === netSlider[netSlider.length - 1] ? Number.MAX_SAFE_INTEGER : values.net[1],
      // ],
    };
  }

  if (Array.isArray(values.gross)) {
    values = {
      ...values,
      // net: [
      //   values.gross[0] === netSlider[0] ? 0 : values.gross[0],
      //   values.gross[1] === netSlider[netSlider.length - 1] ? Number.MAX_SAFE_INTEGER : values.gross[1],
      // ],
    };
  }

  return values;
}
let initialPage = 0;
let currentClientRow = 0;

export let initialSortOrder: { [k: string]: 'desc' | 'asc' } = {
  dateModified: 'desc',
};
const ClientList = () => {
  const classes = useStyles();

  const history = useHistory();

  const dispatch = useDispatch() as PASDispatch;
  const propertyMatching = useSelector((state: IRootState) => state.propertyMatching);
  const properties = useSelector((state: IRootState) => state.property.currentList);
  // const { totalPages, totalElements } = useSelector((state: IRootState) => state.property);

  const clients = useSelector((state: IRootState) => state.clients.currentList);
  const { lastSearchCriteria, totalPages, totalElements } = useSelector((state: IRootState) => state.clients);
  const { langClientDetail, locale, rowsCountPrefOptions } = useSelector((state: IRootState) => state.locale);
  const { privileges } = useSelector((state: IRootState) => state.login);
  const canReadUnownedClient = hasPermission(privileges, 'READ', 'UNOWNED_CLIENT');
  const canReadTeamClient = hasPermission(privileges, 'READ', 'TEAM_CLIENT');
  const canCreateOwnClient = hasPermission(privileges, 'CREATE', 'OWN_CLIENT');
  // const rowsCountPreference = useSelector((state: IRootState) => state.login.rowsCountPreference) ?? EMPTY_STRING;

  usePageTitle(langClientDetail.clientSearch);
  
  const [page, setPage] = useState(initialPage);
  initialPage = page;
  // const [ limit, setLimit ] = useState(10);
  const limit = useSelector((state: IRootState) => state.login.rowsCountPreference?.CLIENT) ?? DEFAULT_SIZE;
  const [currentRowsPerPage, setCurrentRowsPerPage] = useState(currentClientRow === 0 ? limit : currentClientRow);
  currentClientRow = currentRowsPerPage;
  const handleRowsCount = (count: number) => {
    dispatch({ type: 'Login.RowCountPrefUpdate', payload: { CLIENT: count } });
  }

  const [sortOrders, setSortOrders] = useState<{ [k: string]: 'desc' | 'asc' }>(initialSortOrder);
  // Work-around solution (same as page)
  initialSortOrder = sortOrders;

  const [values, setValues] = useState<Partial<SearchFormValues>>({
    ...lastSearchCriteria ?? {},
    price: [
      // clientPreference?.price?.[0] ? priceToView(clientPreference.price[0], locale) : DEFAULT_FROM,
      // clientPreference?.price?.[1] ? priceToView(clientPreference.price[1], locale) : DEFAULT_TO,
      (lastSearchCriteria?.price?.[0] ?? DEFAULT_FROM) === DEFAULT_FROM ? DEFAULT_FROM : priceToView(lastSearchCriteria?.price?.[0] ?? 0, locale),
      (lastSearchCriteria?.price?.[1] ?? DEFAULT_TO) === DEFAULT_TO ? DEFAULT_TO : priceToView(lastSearchCriteria?.price?.[1] ?? 0, locale),
    ] as [number, number]

  });

  const handleSearch = (values: Partial<SearchFormValues>) => {
    setValues(values);
    setPage(0)
    dispatch({
      type: 'ClientList.FetchRequested', payload: {
        page: 0, size: currentRowsPerPage, ...toCriteria(values as any, locale),
        sort: Object.keys(sortOrders)[0] === 'clientPreferences.buyBudgetFrom' && values.isRent ? {
          ['clientPreferences.rentBudgetFrom']: sortOrders['clientPreferences.buyBudgetFrom'],
          ['clientPreferences.rentBudgetTo']: sortOrders['clientPreferences.buyBudgetTo'],
          ['dateModified']: 'desc'
          // ['clientPreferences.buyBudgetFrom']: sortOrders['clientPreferences.buyBudgetFrom'],
          // ['clientPreferences.buyBudgetTo']: sortOrders['clientPreferences.buyBudgetTo']
        } : Object.keys(sortOrders)[0] === 'clientPreferences.rentBudgetFrom' && !values.isRent ? {
          ['clientPreferences.buyBudgetFrom']: sortOrders['clientPreferences.rentBudgetFrom'],
          ['clientPreferences.buyBudgetTo']: sortOrders['clientPreferences.rentBudgetTo'],
          ['dateModified']: 'desc'
          // ['clientPreferences.rentBudgetFrom']: sortOrders['clientPreferences.rentBudgetFrom'],
          // ['clientPreferences.rentBudgetTo']: sortOrders['clientPreferences.rentBudgetTo']

        } : sortOrders
      }
    });

    setSortOrders(orders => {
      if (orders?.['clientPreferences.buyBudgetFrom'] || orders?.['clientPreferences.rentBudgetFrom']) {
        if (values.isRent) {
          return {
            ...orders,
            ['clientPreferences.rentBudgetFrom']: orders['clientPreferences.buyBudgetFrom'],
            ['clientPreferences.rentBudgetTo']: orders['clientPreferences.buyBudgetTo'],
          }
        }
        return {
          ...orders,
          ['clientPreferences.buyBudgetFrom']: orders['clientPreferences.rentBudgetFrom'],
          ['clientPreferences.buyBudgetTo']: orders['clientPreferences.rentBudgetTo'],
        }
      }
      return orders;
    })
  };

  const handleSort = (key: string | null) => {
    if (key === null) {
      setSortOrders({});
      dispatch({
        type: 'ClientList.FetchRequested', payload: {
          ...toCriteria(values as any, locale),
          page, size: currentRowsPerPage, sort: {},
        }
      });
      return;
    }
    let newSortOrders;
    if (key === 'clientPreferences.rentBudgetFrom') {
      newSortOrders = {
        [key]: sortOrders[key] === 'asc' ? 'desc' : 'asc',
        ['clientPreferences.rentBudgetTo']: sortOrders[key] === 'asc' ? 'desc' : 'asc',
        ['dateModified']: 'desc'
        // ['clientPreferences.buyBudgetFrom']: sortOrders[key] === 'asc' ? 'desc' : 'asc',
        // ['clientPreferences.buyBudgetTo']: sortOrders[key] === 'asc' ? 'desc' : 'asc'
      };
    } else if (key === 'clientPreferences.buyBudgetFrom') {
      newSortOrders = {
        [key]: sortOrders[key] === 'asc' ? 'desc' : 'asc',
        ['clientPreferences.buyBudgetTo']: sortOrders[key] === 'asc' ? 'desc' : 'asc',
        ['dateModified']: 'desc'
        // ['clientPreferences.rentBudgetFrom']: sortOrders[key] === 'asc' ? 'desc' : 'asc',
        // ['clientPreferences.rentBudgetTo']: sortOrders[key] === 'asc' ? 'desc' : 'asc'
      };
    } else if (key != 'dateModified') {
      newSortOrders = {
        [key]: sortOrders[key] === 'asc' ? 'desc' : 'asc',
        ['dateModified']: 'desc'
      };
    } else {
      newSortOrders = { [key]: sortOrders[key] === 'asc' ? 'desc' : 'asc' };
    }

    //special handling for sorting by client name (order by client.name_zh, client.name_en)
    // if (key == 'chineseName'){
    //   newSortOrders = {
    //     ...newSortOrders, ['englishName']: sortOrders[key] === 'asc' ? 'desc' : 'asc'  ,
    //   };
    // } 
    setSortOrders(newSortOrders as any);
    dispatch({
      type: 'ClientList.FetchRequested', payload: {
        ...toCriteria(values as any, locale),
        isCurrentUser: lastSearchCriteria?.isCurrentUser,
        page, size: currentRowsPerPage, sort: key === 'price' && lastSearchCriteria?.isRent ? { ['rent']: sortOrders[key] === 'asc' ? 'desc' : 'asc' } : newSortOrders,
      }
    });
  }
  // Initial Fetch
  const currentOwnedClient = lastSearchCriteria?.isCurrentUser;



  useEffect(() => {
    if (typeof currentOwnedClient == 'undefined') {
      dispatch({
        type: 'ClientList.FetchRequested', payload: {
          ...values as any,
          page, size: currentRowsPerPage, sort: sortOrders,
          //this will triger a bug that can not change the rows after exited the page
          //...toCriteria(values as any, locale),

          //initial search result of Owned client is depended on canCreateOwnClient
          isCurrentUser: canCreateOwnClient,
        }
      });
    } else {
      dispatch({
        type: 'ClientList.FetchRequested', payload: {
          ...values as any,
          page, size: currentRowsPerPage, sort: sortOrders,
          isCurrentUser: currentOwnedClient,
        }
      });
    }
  }, [currentRowsPerPage]);

  return (
    <div className={classes.root}>
      <ClientsToolbar />
      <div className={classes.content}>
        <ClientsSearch form={values} onSearch={handleSearch} matchingClient={propertyMatching} wishlistVariant={!!propertyMatching}
          canReadUnownedClient={canReadUnownedClient} canReadTeamClient={canReadTeamClient}
          className={classes.search} canCreateOwnClient={canCreateOwnClient}>
        </ClientsSearch>
        <ClientsTable
          page={page}
          limit={currentRowsPerPage}
          rowsCountOptions={Object.keys(rowsCountPrefOptions).map(Number)}
          handleRowsCount={(ev) => { setCurrentRowsPerPage(ev); handleRowsCount(ev) }}
          totalPages={totalPages}
          totalElements={totalElements}
          setPage={(page) => {
            setPage(page);
            dispatch({
              type: 'ClientList.FetchRequested', payload: {
                ...toCriteria(values as any, locale),
                page, size: currentRowsPerPage, sort: sortOrders,

              }
            });
          }}
          properties={properties}
          clients={clients}
          sortOrders={sortOrders}
          handleSort={handleSort}
          matchingClient={propertyMatching}
          onCancelPropertyMatching={() => {
            dispatch({ type: 'PropertyMatching.StopMatching' });
            dispatch({
              type: 'ClientDetail.Generic' as any,
              payload: { tabIndex: 2 }, // 睇樓清單 tab
            });
            history.goBack();
          }}
          canReadUnownedClient={canReadUnownedClient}
        />
      </div>
    </div>
  );
};

export default ClientList;
