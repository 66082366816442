import {
  Card, CardContent, CardHeader, CardProps, Checkbox, Divider, FormControl, FormControlLabel, FormHelperText, Grid, InputAdornment, InputLabel, ListItemText, MenuItem, Select, Switch, TextField, TextFieldProps,
  Typography, useMediaQuery, useTheme, withStyles
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { hasPermission } from 'common/access-control';
import { PropertyMultiValueMap, PropertyStockDTO } from 'common/dto';
import { FormPresenter } from 'common/hooks';
import { HandleFormHelperText } from 'common/ui';
import { initFormKeysAndBooleanMap, isNonEmpty, limitTextFieldLength, priceFromView, restrictDecimal } from 'common/utils';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';
const errorColor = '#e53935';
const When = ({ test, children }: { test?: boolean, children: JSX.Element }): JSX.Element => test ? children : <span />;
const regex = new RegExp(/(^\d*\.?\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/);

const InputGroup = (props: { children: React.ReactNode[], label?: string }) => {
  const { children } = props;
  return (
    <Grid container xs={12} style={{ display: 'flex' }}>
      <Grid
        item
        style={{ flex: 1 }}
      >
        {children[0]}
      </Grid>

      <Grid item style={{ width: 40, flexShrink: 1 }}>
        <TextField
          fullWidth
          InputProps={{
            style: { borderRadius: 0, borderLeft: 0, borderRight: 0 },
          }}
          inputProps={{
            style: { paddingLeft: 0, paddingRight: 0, textAlign: 'center', borderLeft: 0, borderRight: 0 },
          }}
          disabled value="-"
          variant="outlined"
          margin="dense" />
      </Grid>

      <Grid
        item
        style={{ flex: 1 }}
      // md={5} sm={5}
      // xs={5}
      >
        {children[1]}
      </Grid>
    </Grid>
  );
}

InputGroup.leftItem = () => ({ style: { borderTopRightRadius: 0, borderBottomRightRadius: 0 } });
InputGroup.rightItem = () => ({ style: { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } });

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
  patchOutlineAutocomplete: {
    '& .MuiAutocomplete-inputRoot': {
      padding: 0,
      paddingLeft: 8,
    },
    label: {
      fontSize: '14px',
      paddingTop: '8px'
    },
    filecontainer: {
      paddingLeft: '16px'
    },
    filename: {
      fontSize: '14px',
      paddingTop: '16px',
      paddingLeft: '16px'
    }
  },
}));

const NumberField = withStyles({
  root: {
    '& input': {
      textAlign: 'right',
      '-moz-appearance': 'textfield',
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
      },
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    },
  },
},
)((props: TextFieldProps) => {
  return <TextField type={"text"}{...props} inputProps={{
    ...props.inputProps,
    min: 0,
    max: Math.max(),
    inputMode: 'decimal',
    pattern: "([^0-9]*)",
    step: 0.01
  }}
    onKeyDown={e => {
      // If the key pressed is not a number or a period, nothing is printed
      if (!e.key.match(regex)) {

        e.preventDefault();

      }
      // if (!e.key.match(regex) || (e.key === '.' && String(props.value).indexOf('.') > 0) || (e.key === '.' && String(props.value).length === 0) || e.keyCode === 229 || e.key === 'Unidentified') {

      //   e.preventDefault();

      // }

    }}

    onKeyPress={e => {
      // If the key pressed is not a number or a period, nothing is printed
      if (!e.key.match(regex)) {

        e.preventDefault();

      }
    }}



  />;
});

export interface SaleBasicInfoProps extends CardProps {
  isNew: boolean;
  editDisabled: boolean;
  form: FormPresenter<PropertyStockDTO>;
  tagForm: FormPresenter<PropertyMultiValueMap>;
}


const SaleBasicInfo = (props: SaleBasicInfoProps) => {
  const theme = useTheme();
  const { className, isNew, editDisabled, form, tagForm, ...rest } = props;
  const { locale, lang, langPropertyStock, vacantOptions, symbolOptions, profileOptions, commissionOptions } = useSelector((state: IRootState) => state.locale);
  const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const [isGross, setIsGross] = useState(false);
  const grossOrNet = isGross ? form.values.gross : form.values.net;

  const bind = form.bind;

  const { uid, privileges } = useSelector((state: IRootState) => state.login);
  const canUpdateHotPick = hasPermission(privileges, 'UPDATE', 'PREMIUM_PROPERTY');
  const canReadHotPick = hasPermission(privileges, 'READ', 'PREMIUM_PROPERTY');
  const showCompanyStockBox = !isNew || hasPermission(privileges, 'CREATE', 'COMPANY_STOCK');
  if (form.values.hasKey === undefined) { form.values.hasKey = false; }

  const [keyBooleanMap, setKeyBooleanMap] = useState(initFormKeysAndBooleanMap(form));

  // useEffect(() => {
  //   form.bind("mgtCommission");
  // }, [form.values.mgtCommission])


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
        noValidate
      >

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <CardHeader
            title={langPropertyStock.titleSaleInfo}
          />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: theme.spacing(1) }}>
            <Typography>{langPropertyStock.captionNet}</Typography>
            <Switch
              checked={isGross}
              onChange={(_, val) => setIsGross(val)}
            />
            <Typography>{langPropertyStock.captionGross}</Typography>
          </div>
        </div>
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              md={4} sm={4}
              xs={6}
            >
              <NumberField
                fullWidth
                label={langPropertyStock.price}
                margin="dense"
                required
                variant="outlined"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  endAdornment: <InputAdornment position="start">{locale === 'en' ? lang.u1M : lang.u10k}</InputAdornment>,
                }}
                {...bind('price')}
                //value= {(form.values.price ?? 0 )}

                onChange={(e) => {
                  if (!isNonEmpty(e.target.value)) {
                    form.updateValues('price', '');
                    form.updateValues('bottomPrice', '');
                  } else if (isNaN(parseFloat(e.target.value))) {
                    e.preventDefault();
                  } else if (e.target.value.match(/^\d*\.?\d*$/) == null) {
                    e.preventDefault();
                  } else if (e.target.value.indexOf('.') != -1 && e.target.value.match(/\./g)!.length >= 2) {
                    e.preventDefault()
                  } else {
                    if ((e.target.value.indexOf(".") != -1 && e.target.value.indexOf(".") > (locale === 'en' ? 6 : 8)) || (e.target.value.indexOf(".") == -1 && e.target.value.length > (locale === 'en' ? 6 : 8))) {
                      e.preventDefault();
                      if (e.target.value.substring(13) == "0") {
                        form.updateValues('price', parseFloat(e.target.value).toFixed(12 - e.target.value.indexOf(".")));
                      }
                      //form.updateValues('price', (Math.floor(parseFloat(e.target.value) * 10000) / 10000).toFixed((e.target.value.length - (e.target.value.indexOf(".") + 1) > 4 ? 4 : e.target.value.length - (e.target.value.indexOf(".") + 1))));
                    }
                    else {

                      e.target.value.indexOf('.') != -1 && e.target.value.match(/\./g)!.length === 1 && e.target.value.indexOf('.') + 1 === e.target.value.length ? form.updateValues('price', e.target.value) : form.updateValues('price', restrictDecimal(e.target.value, locale));
                    }
                  }
                }}


              // value={((form.values.price ?? 0) / 10_000).toFixed(2)}
              // onChange={(e) => form.updateValues('price', (+e.target.value || 0) * 10_000)}
              />
              {form.hasError('price') ? <FormHelperText style={{ color: form.hasError('price') ? errorColor : 'inherit' }}>{langPropertyStock.msgInputAtLeastPriceOrRent}</FormHelperText> : null}

            </Grid>
            <Grid
              item
              md={4} sm={4}
              xs={6}
            >
              <NumberField
                fullWidth
                label={langPropertyStock.unitPrice}
                disabled
                margin="dense"
                variant="outlined"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  // endAdornment: <InputAdornment position="start">{lang.u10k}</InputAdornment>,
                  // style: {
                  //   // color: 'grey',
                  //   background: '#fafafa'
                  // }
                }}
                // {...bind('pricePt')}
                value={(form.values.price && grossOrNet) ? Math.round((priceFromView(form.values.price, locale) / grossOrNet)) : 0}
              />
            </Grid>
            <Grid
              item
              md={4} sm={4}
              xs={6}
            >
              <NumberField
                fullWidth
                label={langPropertyStock.bottomPrice}
                disabled={!form.values.price}
                margin="dense"
                variant="outlined"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  endAdornment: <InputAdornment position="start">{locale === 'en' ? lang.u1M : lang.u10k}</InputAdornment>,
                }}
                {...bind('bottomPrice')}



                onChange={(e) => {
                  //console.log("price", e.target.value);
                  if (!isNonEmpty(e.target.value)) {
                    form.updateValues('bottomPrice', '');
                  } else if (isNaN(parseFloat(e.target.value))) {
                    e.preventDefault();
                  } else if (e.target.value.match(/^\d*\.?\d*$/) == null) {
                    e.preventDefault();
                  } else if (e.target.value.indexOf('.') != -1 && e.target.value.match(/\./g)!.length >= 2) {
                    e.preventDefault()
                  } else {
                    if ((e.target.value.indexOf(".") != -1 && e.target.value.indexOf(".") > (locale === 'en' ? 6 : 8)) || (e.target.value.indexOf(".") == -1 && e.target.value.length > (locale === 'en' ? 6 : 8))) {
                      e.preventDefault();
                      if (e.target.value.substring(13) == "0") {
                        form.updateValues('bottomPrice', parseFloat(e.target.value).toFixed(12 - e.target.value.indexOf(".")));
                      }
                      //form.updateValues('price', (Math.floor(parseFloat(e.target.value) * 10000) / 10000).toFixed((e.target.value.length - (e.target.value.indexOf(".") + 1) > 4 ? 4 : e.target.value.length - (e.target.value.indexOf(".") + 1))));
                    }
                    else {

                      e.target.value.indexOf('.') != -1 && e.target.value.match(/\./g)!.length === 1 && e.target.value.indexOf('.') + 1 === e.target.value.length ? form.updateValues('bottomPrice', e.target.value) : form.updateValues('bottomPrice', restrictDecimal(e.target.value, locale));
                    }
                  }
                }}
              />
              {form.hasError('bottomPrice') ? <FormHelperText style={{ color: form.hasError('bottomPrice') ? errorColor : 'inherit' }}>{langPropertyStock.msgBottomPriceExceedsPrice}</FormHelperText> : null}
            </Grid>
            {isSmallScreen ? <Grid
              item
              xs={6}
            ></Grid> : null}
            <Grid
              item
              md={4} sm={4}
              xs={6}
            >
              <NumberField
                fullWidth
                label={langPropertyStock.rent}
                margin="dense"
                required
                variant="outlined"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
                {...bind('rent')}

                onChange={(e) => {
                  if (!isNonEmpty(e.target.value)) {
                    form.updateValues('rent', '');
                    form.updateValues('bottomRent', '');
                  } else if (isNaN(parseFloat(e.target.value))) {
                    e.preventDefault();
                  } else if (e.target.value.indexOf('.') != -1) {
                    e.preventDefault()
                  } else {
                    e.target.value.length > 12 ? e.preventDefault() : form.updateValues('rent', parseInt(e.target.value))
                  }
                }}


              />
              {form.hasError('rent') ? <FormHelperText style={{ color: form.hasError('rent') ? errorColor : 'inherit' }}>{langPropertyStock.msgInputAtLeastPriceOrRent}</FormHelperText> : null}
            </Grid>
            <Grid
              item
              md={4} sm={4}
              xs={6}
            >
              <NumberField
                fullWidth
                label={langPropertyStock.unitRent}
                disabled
                margin="dense"
                variant="outlined"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  // endAdornment: <InputAdornment position="end">/{lang.uFt}</InputAdornment>,
                  // style: {
                  //   // color: 'grey',
                  //   background: '#fafafa',
                  // },
                }}
                value={form.values.rent && grossOrNet ? (form.values.rent / grossOrNet).toFixed(0) : 0}
              // {...bind('rentPt')}
              />
            </Grid>
            <Grid
              item
              md={4} sm={4}
              xs={6}
            >
              <NumberField
                fullWidth
                label={langPropertyStock.bottomRent}
                disabled={!form.values.rent}
                margin="dense"
                variant="outlined"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
                {...bind('bottomRent')}

                onChange={(e) => {
                  if (!isNonEmpty(e.target.value)) {
                    form.updateValues('bottomRent', '');
                  } else if (isNaN(parseFloat(e.target.value))) {
                    e.preventDefault();
                  } else if (e.target.value.indexOf('.') != -1) {
                    e.preventDefault()
                  } else {
                    e.target.value.length > 12 ? e.preventDefault() : form.updateValues('bottomRent', parseInt(e.target.value))
                  }
                }}
              />
              {form.hasError('bottomRent') ? <FormHelperText style={{ color: form.hasError('bottomRent') ? errorColor : 'inherit' }}>{langPropertyStock.msgBottomRentExceedsRent}</FormHelperText> : null}
            </Grid>
            {isSmallScreen ? <Grid
              item
              xs={6}
            ></Grid> : null}

            <Grid
              item
              md={3} sm={3}
              xs={6}
            >
              <TextField
                fullWidth
                select
                label={langPropertyStock.vacant}
                margin="dense"
                variant="outlined"
                {...bind('vacant')}
                inputProps={{ readOnly: editDisabled }}
              >
                <MenuItem key={-1} value={undefined}><option aria-label="None" value="" /></MenuItem>
                {Object.keys(vacantOptions).map((v, idx) => <MenuItem value={v} key={idx}>{vacantOptions[v]}</MenuItem>)}
              </TextField>
            </Grid>

            <Grid item md={3} sm={3} xs={6}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: '6px', paddingTop: '3px', justifyContent: 'center' }}>
                <Typography>{langPropertyStock.captionWithoutKey}</Typography>
                <Switch
                  checked={!!(form.values.hasKey)}
                  disabled={editDisabled}
                  onChange={(_, val) => form.updateValues('hasKey', val)}
                />
                <Typography>{langPropertyStock.captionWithKey}</Typography>
              </div>
            </Grid>
            <Grid item md={3} sm={3} xs={6} style={{ display: !!(form.values.hasKey) ? 'block' : 'none' }}>
              <TextField
                fullWidth
                label={langPropertyStock.captionKeyNo}
                margin="dense"
                // type="text"
                variant="outlined"
                required
                {...bind('keyNo')}
                onChange={(e) => {
                  limitTextFieldLength(e, 255, 'keyNo', form, keyBooleanMap, setKeyBooleanMap);
                }}
              />
              {form.hasError('keyNo') ? <FormHelperText style={{ color: form.hasError('keyNo') ? errorColor : 'inherit' }}>{langPropertyStock.msgInputKeyNo}</FormHelperText> : null}
              <HandleFormHelperText
                isError={keyBooleanMap.get('keyNo')}
                errorMessage={lang.textOverLimit}
              />
            </Grid>

            <Grid
              item
              md={6} sm={6}
              xs={12}
            >
              <FormControl variant="outlined" margin="dense" fullWidth>
                <InputLabel variant="outlined" style={{ background: '#fff' }}>{langPropertyStock.symbol}</InputLabel>
                <Select
                  variant="outlined"
                  multiple
                  {...tagForm.bind('SYMBOL')}
                  renderValue={(selected) => (selected as string[]).map(key => symbolOptions[key]).join(', ')}
                >
                  {Object.keys(symbolOptions).map(symbol => (
                    <MenuItem key={symbol} value={symbol}>
                      <Checkbox checked={(tagForm.values.SYMBOL?.indexOf(symbol) ?? -1) > -1} />
                      <ListItemText primary={symbolOptions[symbol]} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {form.values.usage == 'S' ?
              <Grid
                item
                md={2} sm={2}
                xs={6}
              >
                <NumberField
                  fullWidth
                  label={langPropertyStock.rentFreePeriod}
                  margin="dense"
                  variant="outlined"
                  {...bind('rentFreePeriod')}
                />
              </Grid> : null}
            {form.values.usage == 'S' ? <Grid
              item
              md={2} sm={2}
              xs={6}
            >
              <TextField
                fullWidth
                label={langPropertyStock._yield}
                margin="dense"
                variant="outlined"
                {...bind('_yield')}
                onChange={(e) => {
                  limitTextFieldLength(e, 255, '_yield', form, keyBooleanMap, setKeyBooleanMap);
                }}
              />
              <HandleFormHelperText
                isError={keyBooleanMap.get('_yield')}
                errorMessage={lang.textOverLimit}
              />
            </Grid> : null}
            {form.values.usage == 'S' ?
              <Grid
                item
                md={8} sm={8}
                xs={12}
              >
                <TextField
                  fullWidth
                  label={langPropertyStock.tenancyRemarks}
                  margin="dense"
                  variant="outlined"
                  {...bind('tenancyRemarks')}
                  onChange={(e) => {
                    limitTextFieldLength(e, 255, 'tenancyRemarks', form, keyBooleanMap, setKeyBooleanMap);
                  }}
                />


                <HandleFormHelperText
                  isError={keyBooleanMap.get('tenancyRemarks')}
                  errorMessage={lang.textOverLimit}
                />
              </Grid> : null
            }
            <Grid
              item
              md={6} sm={6}
              xs={6}
            >
              <TextField
                fullWidth
                select
                label={langPropertyStock.mgtProfile}
                margin="dense"
                variant="outlined"
                {...bind('mgtProfile')}
              >
                <MenuItem key={-1} value={undefined}><option aria-label="None" value="" /></MenuItem>
                {Object.keys(profileOptions ?? {}).map((v, idx) => <MenuItem key={idx} value={v}>{profileOptions[v]}</MenuItem>)}
              </TextField>
            </Grid>

            <Grid
              item
              md={6} sm={6}
              xs={6}
            >
              <Autocomplete
                options={Object.values(commissionOptions)}
                noOptionsText={''}
                disabled={form.isDisabled('mgtCommission')}
                // loading={buildingListLoading}
                // loadingText={lang.msgLoading}
                freeSolo={true}
                renderInput={params => (
                  <TextField
                    {...params}
                    className={classes.patchOutlineAutocomplete}
                    // error={!!form.hasError('building')}
                    // helperText={form.hasError('building')}
                    fullWidth
                    label={langPropertyStock.mgtCommission}
                    margin="dense"
                    variant="outlined"
                    disabled={form.isDisabled('mgtCommission')}
                    onChange={(ev) => {

                      //form.updateValues('mgtCommission', ev.target.value)
                      limitTextFieldLength(ev, 255, 'mgtCommission', form, keyBooleanMap, setKeyBooleanMap);
                      form.bind("mgtCommission");
                      //console.log("mgtCommission", ev.target.value.length);
                    }}
                  // value={form.values.building ?? ''}
                  />
                )}
                value={form.values.mgtCommission ?? ''}
                onChange={(_, val) => {
                  if (val) {
                    form.setValues({
                      ...form.values,
                      mgtCommission: val
                    })
                    setKeyBooleanMap(keyBooleanMap.set("mgtCommission", false))
                  }
                }}
              />
              <HandleFormHelperText
                isError={keyBooleanMap.get('mgtCommission')}
                errorMessage={lang.textOverLimit}
              />
            </Grid>

            <Grid item container md={12} sm={12} xs={12} spacing={4}>
              <When test={canReadHotPick}>

                <Grid item style={{ paddingRight: 'inherit' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: '3px', justifyContent: 'flex-start' }}>
                    <Switch
                      checked={!!(form.values.isHotPick)}
                      disabled={editDisabled || !canUpdateHotPick}
                      onChange={(_, val) => {
                        if (editDisabled || !canUpdateHotPick) {
                          return;
                        }
                        form.updateValues('isHotPick', val);
                        if (!val) {
                          form.updateValues('hotpickExpiry', undefined);
                        }
                      }}
                    />
                    <Typography>{langPropertyStock.captionHotPick}</Typography>
                  </div>
                </Grid>
              </When>
              <Grid item md={4} sm={4} xs={7}>

                <KeyboardDatePicker autoOk={true}
                  //disableToolbar
                  fullWidth
                  variant="inline"
                  inputVariant="outlined"
                  error={!!form.hasError('hotpickExpiry')}
                  helperText={form.hasError('hotpickExpiry')}
                  format={DISPLAY_DATE_FORMAT}
                  label={langPropertyStock.captionHotPickValidUntil}
                  margin="dense"
                  disabled={form.values.isHotPick !== true || editDisabled || !canUpdateHotPick}
                  value={form.values.hotpickExpiry ? moment(form.values.hotpickExpiry) : null}
                  onChange={(value) => {
                    form.setValues({
                      ...form.values,
                      hotpickExpiry: value?.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString() ?? undefined
                    });
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>
              {
                showCompanyStockBox &&
                <Grid
                  item
                  md={4} sm={4}
                  xs={12}
                  style={isSmallScreen ? { justifyContent: 'center', paddingTop: '0px' } : { justifyContent: 'center', paddingLeft: 'inherit' }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={form.values.companyStock ?? false}
                        readOnly={true}
                        color="primary"
                      />
                    }
                    label={langPropertyStock.companyStock}
                  />
                </Grid>
              }


            </Grid>
          </Grid>


        </CardContent>
        {/* <Divider /> */}
        {/* <CardActions>
          <Button
            color="primary"
            variant="contained"
          >
            Save details
          </Button>
        </CardActions> */}
      </form>
    </Card>
  );
};

SaleBasicInfo.propTypes = {
  className: PropTypes.string
};

export default SaleBasicInfo;
