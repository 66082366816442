import { RoleDTO, RolePrivilegeMap } from "common/dto";
import usePageTitle, { useForm } from "common/hooks";
import { convertToPrivilegeDtoList, diffUpdateSpec } from "common/utils";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { IRootState, PASDispatch } from "reducers";
import roleFormValidations from "./role-validation";
import RolePrivilege from './RolePrivilege';

const EMPTY = {};

export default function RolePrivilegeUpdatePage() {
  const dispatch = useDispatch() as PASDispatch;
  const { langRolePrivilege} = useSelector((state: IRootState) => state.locale);
  const { roleId }: any = useParams();

  //currentRoleDetail-> role name, description, status
  const currentRoleDetail = useSelector((state: IRootState) => state.rolePrivilege.fetchedDetails[roleId!]) ?? EMPTY as RoleDTO;
  
  //currentRolePrivilegeMap -> privilege for this role
  const currentRolePrivilegeMap = currentRoleDetail.privilegeTable ?? EMPTY as RolePrivilegeMap;
  
  const initialRolePrivilege = useForm<RolePrivilegeMap>({});

  //for edit
  const roleForm = useForm<RoleDTO>({},{validations: roleFormValidations(langRolePrivilege)});
  const rolePrivilegeForm = useForm<RolePrivilegeMap>({});

  useEffect(()=>{
    //for role detail
    roleForm.setValues({...currentRoleDetail, privilegeTable:{}})

    //for privilege
    rolePrivilegeForm.setValues(currentRolePrivilegeMap);
    initialRolePrivilege.setValues(currentRolePrivilegeMap);
  },[currentRoleDetail])

  usePageTitle(langRolePrivilege.titleRoleDetail);

  useEffect(() => {
    dispatch({ type: 'RolePrivilege.FetchRequested', payload: {roleId: roleId } as any});
  }, [ 1 ]);

  const onSave = ()=>{
    if (!roleForm.validate()){
      return;
    }
    const currentList = convertToPrivilegeDtoList(roleId, initialRolePrivilege);
    const updatedList = convertToPrivilegeDtoList(roleId, rolePrivilegeForm);
    // 
    // 
    const result = diffUpdateSpec(currentList, updatedList, (a,b) => {return a.id === b.id});
    // 
    // 
    // 

    dispatch({ type: 'RolePrivilege.UpdateRequested', payload: {...roleForm.values, listUpdate:result } as RoleDTO });
  }


  return <RolePrivilege roleForm={roleForm} rolePrivilegeForm={rolePrivilegeForm} onSave={onSave}/>;
}