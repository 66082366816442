import { Grid, makeStyles, Button as MuiButton } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { hasPermission } from 'common/access-control';
import { RoleDTO, RolePrivilegeMap } from 'common/dto';
import { FormPresenter } from 'common/hooks';
import { tooltipHoc } from 'common/ui';
import NavigationBar from 'layouts/Main/components/Topbar/NavigationBar';
import React from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';
import RolePrivilegeDetail from './components/RolePrivilegeDetail';



// #region Tab Page Common UI 

const Button = tooltipHoc(MuiButton);

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}));

// #endregion Tab Page Common UI


export interface UserDetailProps {
  roleForm: FormPresenter<RoleDTO>; 
  rolePrivilegeForm: FormPresenter<RolePrivilegeMap>; 
  roleName?: string;
  onSave: () => void;
  creating?: boolean;
}

const RolePrivilege = ({creating, roleForm, rolePrivilegeForm, onSave, roleName }: UserDetailProps) => {
  const { lang, langRolePrivilege } = useSelector((state: IRootState) => state.locale);
  const classes = useStyles();

  const { privileges } = useSelector((state: IRootState) => state.login);
  const canUpdate= hasPermission(privileges, 'UPDATE', 'ROLE');

  return (
    <div className={classes.root}>
      <NavigationBar title={langRolePrivilege.titleRoleDetail}>
        <Grid container item lg={6} md={6} xl={6} xs={6} justify="flex-end">
          
          <Grid item>
            <Button disabled={!canUpdate} color="primary" onClick={onSave} variant="text" startIcon={<SaveIcon />} >{lang.actionSave}</Button>
          </Grid>
        </Grid>
      </NavigationBar>

      <Grid container item md={12} xs={12} spacing={1}>
        <Grid
          item
          md={12}
          xs={12}
        >
          <RolePrivilegeDetail creating={creating} roleForm={roleForm} rolePrivilegeForm={rolePrivilegeForm} roleName={roleName ?? ''}/>
        </Grid>

      </Grid>

    </div>
  );
};

export default RolePrivilege;