import { makeStyles } from '@material-ui/core';
import usePageTitle from 'common/hooks';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState, PASDispatch } from 'reducers';
import ActivityAuditingSearch, { ActivityAuditingSearchForm } from './components/ActivityAuditingSearch';
import ActivityAuditingTable from './components/ActivityAuditingTable';
import ActivityAuditingToolbar from './components/ActivityAuditingToolbar';


const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(2)
	},
	content: {
		marginTop: theme.spacing(2)
	},
	search: {
		marginBottom: theme.spacing(2)
	}
}));

const ActivityAuditingList = () => {
	const classes = useStyles(0);

	const dispatch = useDispatch() as PASDispatch;
	const activityAuditingList = useSelector((state: IRootState) => state.activityAuditing.currentList);
	const { totalPages, totalElements } = useSelector((state: IRootState) => state.activityAuditing);
	const { langActivityAuditing } = useSelector((state: IRootState) => state.locale);
	const [ page, setPage ] = useState(0);
	const [ limit, setLimit ] = useState(10);
	const [ sortOrders, setSortOrders ] = useState<{ [k: string]: 'desc' | 'asc' }>({
		dateCreated: 'desc',
	});
	const [ values, setValues ] = useState<Partial<ActivityAuditingSearchForm>>({});

	usePageTitle(langActivityAuditing.titleActivityAuditingList);
	
	const handleSearch = (values: Partial<ActivityAuditingSearchForm>) => {
		setValues(values);
		
		setPage(0);
		dispatch({ type: 'ActivityAuditingList.FetchRequested', payload: {
			page: 0, size: limit, sort: sortOrders, ...values }
		});
	};
	
	const handleSort = (key: string | null) => {
		if (key === null) {
			setSortOrders({});
			dispatch({ 
				type: 'ActivityAuditingList.FetchRequested', 
				payload: { page, size: limit, sort: {}, ...values }
			});
			return;
		}
		const newSortOrders = { [key]: sortOrders[key] === 'asc' ? 'desc' : 'asc' };
		setSortOrders(newSortOrders as any);
		dispatch({ type: 'ActivityAuditingList.FetchRequested', payload: {
			page, size: limit, sort: newSortOrders, ...values
		}});
	}
	
	useEffect(() => {
		dispatch({ type: 'ActivityAuditingList.FetchRequested', payload: { page, size: limit, sort: sortOrders, } });
	}, []);
	
	return (
		<div className={classes.root}>
			<ActivityAuditingToolbar/>
			<div className={classes.content}>				
				<ActivityAuditingSearch onSearch={handleSearch} className={classes.search}/>
				<ActivityAuditingTable
					page={page}
					limit={limit}
					totalPages={totalPages}
					totalElements={totalElements}
					setPage={(page) => {
						setPage(page);
						dispatch({ 
							type: 'ActivityAuditingList.FetchRequested', 
							payload: { page, size: limit, sort: sortOrders, ...values },
						});
					}}
					activityAuditingList={activityAuditingList}
					sortOrders={sortOrders}
					handleSort={handleSort}
				/>
			</div>
		</div>
	);
};

export default ActivityAuditingList;