import { Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { Device } from "@capacitor/device";
import { App } from "@capacitor/app";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));

const Footer = (props) => {
  const { className, ...rest } = props;
  const env = process.env["PUBLIC_URL"];
  const classes = useStyles();
  const [deviceInfo, setDeviceInfo] = useState(null);
  useEffect(() => {
    Device.getInfo().then((info) => setDeviceInfo(info.platform));
  }, []);
  const [buildVersion, setBuildVersion] = useState("");
  useEffect(() => {
    App.getInfo().then((info) => setBuildVersion(info.version));
  }, []);
  const version =
    deviceInfo === "web"
      ? localStorage.getItem(env + "/" + "frontendVersion")
      : buildVersion;

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography variant="body1">
        &copy;{" "}
        <Link
          component="a"
          // href="http://sunshineproperty.hk/index.php"
          target="_blank"
        >
          Sunshine Property Consultant Limited
        </Link>
        . {new Date().getFullYear()} V. {version}
      </Typography>
      {/* <Typography variant="caption">
        Created with love for the environment. By designers and developers who
        love to work together in offices!
      </Typography> */}
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
