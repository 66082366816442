import { Button, Card, CardContent, CardHeader, Divider, Grid, MenuItem, TextField, Typography, useMediaQuery } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';
import usePageTitle from 'common/hooks';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardKpiTypes } from 'reducers';
import {
  ClientPropertyVisits, CommissionTotal, NewClients,
  NewClientUpdates,
  NewProperties,
  NewPropertyUpdates
} from './components';
import LayoutControlDialog from './components/LayoutControlDialog';



const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1)
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  welcomeroot: {
    padding: theme.spacing(4)
  },
  cardHeaderAction: {
    flex: "0 1 auto"
  },
  lastModified: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 0 10px 0',
  },
}));



const Dashboard = () => {
  const classes = useStyles();
  /** @type import('reducers').PASDispatch */
  const dispatch = useDispatch();
  const { username } = useSelector((state) => state.login);
  const { lang, locale, langDashboard, districtHkiOptions, districtNtOptions, districtKltOptions, } = useSelector((state) => state.locale);
  const { districts } = useSelector((state) => state.login);
  const DISPLAY_DATE_FORMAT = useSelector((state) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATETIME_FORMAT);
  // const { locale,  lang,
  //   districtHkiOptions, districtNtOptions, districtKltOptions,
  // } = localeBundle;

  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));

  const [OpenLayoutControlDialog, setOpenLayoutControlDialog] = React.useState(false);

  const handleApplyLayoutControl = (newDashboardPref) => {
    dispatch({ type: 'Dashboard.PrefUpdated', payload: { dashboardPref: newDashboardPref } });
    setOpenLayoutControlDialog(false);
  }

  const handleApplySaveLayoutControl = (newDashboardPref) => {
    dispatch({ type: 'Dashboard.PrefUpdatedAndSaved', payload: { dashboardPref: newDashboardPref } });
    setOpenLayoutControlDialog(false);
  }

  const handleOpenLayoutControlDialog = () => {
    setOpenLayoutControlDialog(true);
  };

  const handleCloseLayoutControlDialog = () => {

    setOpenLayoutControlDialog(false);
  };

  usePageTitle(lang.navDashboard);
  // const [dashboardPref, setDashboardPref] = 
  //  useState(
  //   [
  // { "kpiType": DashboardKpiTypes.NEW_CLIENTS, "isVisible": true, "sortOrder": 1 },
  // { "kpiType": DashboardKpiTypes.NEW_CLIENT_UPDATES, "isVisible": true, "sortOrder": 2 },
  // { "kpiType": DashboardKpiTypes.NEW_PROPERTIES, "isVisible": true, "sortOrder": 3 },
  // { "kpiType": DashboardKpiTypes.NEW_PROPERTY_UPDATES, "isVisible": true, "sortOrder": 4 },
  // { "kpiType": DashboardKpiTypes.TOTAL_COMMISSION, "isVisible": true, "sortOrder": 5 },
  // { "kpiType": DashboardKpiTypes.CLIENT_PROPERTY_VISIT, "isVisible": true, "sortOrder": 6 }
  //   ]
  // );
  const dashboardPref = useSelector((state) => state.dashboard.dashboardPref);




  const {
    region, periodEnd, periodStart,
    graphData, allowedKpiTypeList,
    lastModified,
  } = useSelector(/** @param {import('reducers').IRootState} state */(state) => state.dashboard);

  const setRegion = (newRegion) => {
    dispatch({ type: 'Dashboard.CriteriaUpdated', payload: { region: newRegion } });
    dispatch({ type: 'Dashboard.FetchRequested' });
  };

  const setPeriodStart = (newStart) => {
    dispatch({ type: 'Dashboard.CriteriaUpdated', payload: { periodStart: newStart } });
    dispatch({ type: 'Dashboard.FetchRequested' });
  };

  const setPeriodEnd = (newEnd) => {
    dispatch({ type: 'Dashboard.CriteriaUpdated', payload: { periodEnd: newEnd } });
    dispatch({ type: 'Dashboard.FetchRequested' });
  };

  // const setPref= () =>{};


  // Fetch backend once when navigate to dashboard page
  useEffect(() => {

    let newRegion = '';
    let newStart;
    let newEnd;
    for (const district of districts) {
      let tempRegion;
      // if(newRegion==''){
      if (Object.keys(districtHkiOptions).indexOf(district) > -1
      ) {
        //hki region
        // newRegion= 'HKI';
        tempRegion = 'HKI';
      } else if (Object.keys(districtKltOptions).indexOf(district) > -1
      ) {
        //kln region
        // newRegion= 'KLN';
        tempRegion = 'KLN';
      } else if (Object.keys(districtNtOptions).indexOf(district) > -1
      ) {
        //nt region
        // newRegion= 'NT'; 
        tempRegion = 'NT';
      }
      else {
        newRegion = 'ALL';
        break;
      }
      // }
      // else {
      if (newRegion == '') {
        newRegion = tempRegion;
        // break;
      } else
        if (newRegion != tempRegion) {
          newRegion = 'ALL';
          break;
        }

    }
    if (districts.length == 0) {
      newRegion = 'ALL';
    }
    // }
    // moment().date(1).hours(0).minutes(0).seconds(0).milliseconds(0).toISOString()

    newStart = moment().date(1).hours(0).minutes(0).seconds(0).milliseconds(0).toISOString();

    newEnd = moment().date(1).hours(0).minutes(0).seconds(0).milliseconds(0).toISOString();

    dispatch({ type: 'Dashboard.CriteriaUpdated', payload: { region: newRegion, periodStart: newStart, periodEnd: newEnd } });

    dispatch({ type: 'Dashboard.FetchRequested' });
  }, []);
  useEffect(() => { dispatch({ type: 'Dashboard.FetchRequested' }); }, [locale]);


  if (Object.keys(graphData).length == 0) {
    // return()

    // if (username) {
    // Temp Welcome Message
    return <div className={classes.welcomeroot}>
      {/* <Grid container xs={12}> */}
      <Grid container>
        <Grid item>
          <Typography variant="h2" style={{ display: 'flex', alignItems: 'center' }}>
            {/* <img src="images/logo-compact.png" style={{ height: 28, marginRight: 8 }} /> */}
            {lang.msgWelcome}, {username}.
          </Typography>

        </Grid>
      </Grid>
    </div>;
    // }
  }
  const InputGroup = (props) => {
    const { children } = props;
    return (
      // <Grid container xs={12} style={{ display: 'flex' }}>
      <Grid container style={{ display: 'flex' }}>
        <Grid
          item
          style={{ flex: 1 }}
        >
          {children[0]}
        </Grid>

        <Grid item style={{ width: 40, flexShrink: 1 }} >
          <TextField
            fullWidth
            InputProps={{
              style: { borderRadius: 0, borderLeft: 0, borderRight: 0 },
            }}
            inputProps={{
              style: { paddingLeft: 0, paddingRight: 0, textAlign: 'center', borderLeft: 0, borderRight: 0 },
            }}
            disabled value="to"
            variant="outlined"
            margin="dense" />
        </Grid>

        <Grid
          item
          style={{ flex: 1 }}
        // md={5}
        // xs={5}
        >
          {children[1]}
        </Grid>
      </Grid>

    );
  }

  InputGroup.leftItem = () => ({ style: { borderTopRightRadius: 0, borderBottomRightRadius: 0 } });
  InputGroup.rightItem = () => ({ style: { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } });


  return (
    <div className={classes.root}>
      {/* <Grid container xs={12} spacing={1}> */}
      <Grid container spacing={1}>
        {/* onApply={handleApplyLayoutControl} onApplySave={handleApplySaveLayoutControl} */}
        <LayoutControlDialog open={OpenLayoutControlDialog} onOpen={handleOpenLayoutControlDialog} onClose={handleCloseLayoutControlDialog}
          onApply={handleApplyLayoutControl}
          onApplySave={handleApplySaveLayoutControl}
        />
        <Grid item className={classes.row}>
          <Typography variant="h2" style={{ display: 'flex', alignItems: 'center' }}>
            {langDashboard.welcomeTitle}
          </Typography>
        </Grid>
        <span className={classes.spacer} />
        <div>
          <Button
            color="primary"
            variant="contained"

            onClick={handleOpenLayoutControlDialog}
          >
            {langDashboard.titleConsole}
          </Button>
        </div>
      </Grid>

      {/* <Grid container md={12} xs={12} spacing={1}> */}
      <Grid container spacing={1}>
        <Grid item md={12} xs={12}>
          <Card>
            <CardHeader
              title={isSmallScreen ? null : <Typography variant="body1">
                {langDashboard.captionLastModified}: {lastModified ? moment(lastModified).format(DISPLAY_DATE_FORMAT) : '-'}
              </Typography>}
              classes={{ action: classes.cardHeaderAction }}
              action={
                // <Grid container md={12} xs={12} spacing={1}>
                <Grid container spacing={1}>
                  <Grid item md={8} sm={8} xs={12}>
                    <InputGroup>
                      <KeyboardDatePicker autoOk={true}
                        InputProps={InputGroup.leftItem()}
                        fullWidth
                        variant="inline"
                        inputVariant="outlined"
                        // label="Period From"
                        label={langDashboard.periodFrom}
                        margin="dense"
                        value={moment(periodStart)}
                        disableFuture="true"
                        views={["year", "month"]}
                        openTo={"month"}
                        onChange={(_, value) => {
                          // 
                          // setPeriodStart(value?.date(1).hours(0).minutes(0).seconds(0).milliseconds(0).toISOString());
                          setPeriodStart(moment(value, "MMM YYYY").toISOString());
                          //setPeriodEnd(null);
                          //periodEnd: value? null:periodEnd
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />

                      <KeyboardDatePicker autoOk={true}
                        InputProps={InputGroup.rightItem()}
                        //disableToolbar
                        fullWidth
                        variant="inline"
                        inputVariant="outlined"
                        label={langDashboard.periodTo}
                        margin="dense"
                        value={moment(periodEnd)}
                        disableFuture="true"
                        views={["year", "month"]}
                        openTo={"month"}
                        minDate={Date.parse(periodStart)}
                        onChange={(_, value) => {
                          // setPeriodEnd(value?.date(1).hours(0).minutes(0).seconds(0).milliseconds(0).toISOString());
                          setPeriodEnd(moment(value, "MMM YYYY").toISOString());
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}

                      />
                    </InputGroup>
                  </Grid>
                  <Grid item md={4} sm={4} xs={12}>
                    <TextField select
                      fullWidth
                      margin="dense"
                      variant="outlined"
                      // label="Region"
                      label={langDashboard.region}
                      value={region}
                      onChange={(ev) => {
                        setRegion(ev.target.value);
                      }}>
                      <MenuItem value="ALL">{langDashboard.all}</MenuItem>
                      <MenuItem value='HKI'>{langDashboard.hki}</MenuItem>
                      <MenuItem value='KLN'>{langDashboard.kln}</MenuItem>
                      <MenuItem value='NT'>{langDashboard.nt}</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
              }
            />
            {!isSmallScreen ? null : <div className={classes.lastModified}><Typography variant="body1">
              {langDashboard.captionLastModified}: {lastModified ? moment(lastModified).format(DISPLAY_DATE_FORMAT) : '-'}
            </Typography></div>}
            <Divider />
            <CardContent>
              <Grid container spacing={1}>
                {
                  dashboardPref.filter(d => allowedKpiTypeList.includes(d.kpiType)).filter(d => d.isVisible).map((pref, idx) => (
                    // <Grid item lg={4} md={4} xl={4} sm={6} xs={12}>
                    <Grid key={idx} item lg={3} md={3} xl={3} sm={6} xs={12}>
                      {{

                        [DashboardKpiTypes.NEW_CLIENTS]:
                          graphData[DashboardKpiTypes.NEW_CLIENTS] ?
                            <NewClients graphData={graphData[DashboardKpiTypes.NEW_CLIENTS]
                            } />
                            : null
                        ,
                        [DashboardKpiTypes.NEW_CLIENT_UPDATES]:
                          graphData[DashboardKpiTypes.NEW_CLIENT_UPDATES] ?
                            <NewClientUpdates graphData={graphData[DashboardKpiTypes.NEW_CLIENT_UPDATES]} />
                            : null
                        ,
                        [DashboardKpiTypes.NEW_PROPERTIES]:
                          graphData[DashboardKpiTypes.NEW_PROPERTIES] ?
                            <NewProperties graphData={graphData[DashboardKpiTypes.NEW_PROPERTIES]} />
                            : null
                        ,
                        [DashboardKpiTypes.NEW_PROPERTY_UPDATES]:
                          graphData[DashboardKpiTypes.NEW_PROPERTY_UPDATES] ?
                            <NewPropertyUpdates graphData={graphData[DashboardKpiTypes.NEW_PROPERTY_UPDATES]} />
                            : null
                        ,
                        [DashboardKpiTypes.TOTAL_COMMISSION]:
                          graphData[DashboardKpiTypes.TOTAL_COMMISSION] ?
                            <CommissionTotal graphData={graphData[DashboardKpiTypes.TOTAL_COMMISSION]} />
                            : null
                        ,
                        [DashboardKpiTypes.CLIENT_PROPERTY_VISIT]:
                          graphData[DashboardKpiTypes.CLIENT_PROPERTY_VISIT] ?
                            <ClientPropertyVisits graphData={graphData[DashboardKpiTypes.CLIENT_PROPERTY_VISIT]} />
                            : null

                      }[pref.kpiType]

                      }
                    </Grid>
                  ))}
              </Grid>

              {/* <Grid
                  item
                  lg={6}
                  md={6}
                  xl={6}
                  xs={12}
                >
                {graphData[DashboardKpiTypes.NEW_CLIENTS] ?
                    <NewClients graphData={graphData[DashboardKpiTypes.NEW_CLIENTS]}/> : null 
                  }
                  </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  xl={6}
                  xs={12}
                >
                {graphData[DashboardKpiTypes.NEW_CLIENT_UPDATES] ?
                    <NewClientUpdates graphData={graphData[DashboardKpiTypes.NEW_CLIENT_UPDATES]}/> : null 
                  }
                  </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  xl={6}
                  xs={12}
                >
                {graphData[DashboardKpiTypes.NEW_PROPERTIES] ?
                    <NewProperties graphData={graphData[DashboardKpiTypes.NEW_PROPERTIES]}/> : null 
                  }
                  </Grid>
                  <Grid
                  item
                  lg={6}
                  md={6}
                  xl={6}
                  xs={12}
                >
                {graphData[DashboardKpiTypes.NEW_PROPERTY_UPDATES] ?
                    <NewPropertyUpdates graphData={graphData[DashboardKpiTypes.NEW_PROPERTY_UPDATES]}/> : null 
                  }
                  </Grid> */}
              {/* <Grid
                  item
                  lg={6}
                  md={6}
                  xl={6}
                  xs={12}
                > */}
              {/* Note: Render the commission graph only if the corresponding data exists. */}
              {/* {graphData[DashboardKpiTypes.TOTAL_COMMISSION] ?
                    <CommissionTotal graphData={graphData[DashboardKpiTypes.TOTAL_COMMISSION]}/> : null
                  }
                  
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  xl={6}
                  xs={12}
                >
                {graphData[DashboardKpiTypes.CLIENT_PROPERTY_VISIT] ?
                    <ClientPropertyVisits graphData={graphData[DashboardKpiTypes.CLIENT_PROPERTY_VISIT]}/> : null 
                  }
                  </Grid> */}

              {/* </Grid> */}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
