import {
  Card, CardContent, colors, Dialog,
  DialogActions, DialogContent, DialogContentText,
  DialogTitle, Divider, FormControl, FormControlLabel, Grid, IconButton, ListItemIcon, ListItemSecondaryAction,
  ListItemText, ListSubheader, makeStyles, Menu,
  Button as MuiButton,
  MenuItem as MuiMenuItem, Radio, RadioGroup, Tab, TabProps, Tabs, TabsTypeMap, TextField, Typography, useMediaQuery, withStyles
} from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import ListAltIcon from '@material-ui/icons/ListAlt';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import ReplayIcon from '@material-ui/icons/Replay';
import SaveIcon from '@material-ui/icons/Save';
import WarningIcon from '@material-ui/icons/Warning';
import { transactionApi } from 'api/transactionApi';
import clsx from 'clsx';
import { hasPermission } from 'common/access-control';
import ConfirmDialog, { useConfirmDialog } from 'common/ConfirmDialog';
import { BuildingListItemDTO, CheckDuplicatedPropertyStockResultDTO, ClientFileMap, MemoDTO, PropertyFileMap, PropertyMultiValueMap, PropertyStockDTO, UserDTO } from 'common/dto';
import FixedTabPanel from 'common/elements/FixedTabPanel';
import FixedTabsContainer from 'common/elements/FixedTabsContainer';
import { FormPresenter, useForm, useMenu, useRouteBasedState } from 'common/hooks';
import { useInfoDialog } from 'common/InfoDialog';
import Memo from 'common/Memo';
import MemoNew from 'common/MemoNew';
import { CloseDealIcon, EditPsStatusIcon, InfoIcon, tooltipHoc } from 'common/ui';
import { isNonEmpty, multiLang } from 'common/utils';
import NavigationBar from 'layouts/Main/components/Topbar/NavigationBar';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { IRootState, PASDispatch, PropertyActions } from 'reducers';
import { format } from 'util';
import CloseTransactionDialog from './components/CloseTransactionDialog';
import ContactInfo from './components/ContactInfo';
import Documents from './components/Documents';
import FullScreenDialog from './components/FormFullScreenDialog';
import LeadAgentPanel from './components/LeadAgentPanel';
import PropertyBasicInfo from './components/PropertyBasicInfo';
import PropertyFeatures from './components/PropertyFeatures';
import PropertyManagementInfo from './components/PropertyManagementInfo';
import ProvisionalAgreementDialog from './components/ProvisionalAgreementFullScreenDialog';
import SaleBasicInfo from './components/SaleBasicInfo';
import WarningDialogForCreatingExisingPropertyStock from './components/WarningDialogForCreatingExisingPropertyStock';
import { form35ValidityChecking } from './property-data-funcs';
import { selectSalePaAccessControls, selectTenancyPaAccessControls } from './provisionalAgreement-access-controls';


const EMPTY = {};
const Button = tooltipHoc(MuiButton);
const MenuItem = tooltipHoc(MuiMenuItem);

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  dialogContent: {
    width: 500,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  approvalStageUnapproved: {
    backgroundColor: '#FFFFFF'
  },
  approvalStageMatching: {
    backgroundColor: '#FED8B1'
  },
  approvalStageClientApp: {
    backgroundColor: '#00FFFF'
  },
  unsavedBtn: {
    color: '#FFBF00',
    fontWeight: 'bold'
  },
  paListItemSecAction: {
    right: '2px',
  },
  activePa: {
    fontWeight: 'bold',
    fontStyle: 'italic',
  },
  menuMessage: {
    lineHeight: '20px',
    color: '#000',
    wordBreak: 'break-word',
    padding: '0.5rem 16px',
    maxWidth: '300px',
    backgroundColor: '#fbfbfb',

    '& svg': {
      marginBottom: '-5px',
    }
    // display: 'flex', alignItems: 'center',
  },
}));



const TabPanel = FixedTabPanel;

const StyledTabs: OverridableComponent<TabsTypeMap> = (props: any) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />;

const StyledTab = tooltipHoc(withStyles(() => ({
  // Nothing customised
}))((props: TabProps) => <Tab {...props} />));

const When = ({ test, children }: { test?: boolean, children: JSX.Element }): JSX.Element => test ? children : <span />;

interface DeleteConfirmDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  form: FormPresenter<PropertyStockDTO>;
  isUnsaved: boolean;
}

export const DeleteConfirmDialog = ({ open, onClose, onConfirm, form, isUnsaved }: DeleteConfirmDialogProps) => {
  const { lang, langPropertyStock } = useSelector((state: IRootState) => state.locale);
  const classes = useStyles();
  const [confirmButtonAllowed, setConfirmButtonAllowed] = useState(false);
  const [buildingNames, setBuildingNames] = useState<string[]>([]);
  const [blockNames, setBlockNames] = useState<string[]>([]);
  const [propertyInput, setPropertyInput] = useState<{ [key: string]: any }>({
    building: '',
    block: '',
    floor: [],
    unit: [],
    lot: '',
    developer: form.values.developer ?? ''
  });

  useEffect(() => {
    setBuildingNames(new Array(form.values.building ?? form.values.buildingEn ?? '', form.values.buildingEn ?? form.values.building ?? ''));
    setBlockNames(new Array(form.values.block ?? form.values.blockEn ?? '', form.values.blockEn ?? form.values.block ?? ''));
    // setPropertyInput({
    //   ...propertyInput,
    //   floor: propertyInput.floor.concat(form.values.floor ?? []).map((f: string) => { return ''; }),
    //   unit: propertyInput.unit.concat(form.values.unit ?? []).map((u: string) => { return ''; }),
    // })
  }, [form]);
  //console.log('form', form);

  const validatePropertyInput = () => {
    return (
      (isNonEmpty(buildingNames) ? buildingNames.includes(propertyInput.building) : true) &&
      (isNonEmpty(blockNames) ? blockNames?.includes(propertyInput.block) : !isNonEmpty(propertyInput.block)) &&
      (isNonEmpty(form.values.floor) ? (form.values.floor?.every((f: string) => propertyInput.floor?.includes(f)) &&
        propertyInput.floor?.every((f: string) => form.values.floor?.includes(f))) : true) &&
      (isNonEmpty(form.values.unit) ? (form.values.unit?.every((u: string) => propertyInput.unit?.includes(u)) &&
        propertyInput.unit?.every((u: string) => form.values.unit?.includes(u))) : true) &&
      (isNonEmpty(form.values.lot) ? propertyInput.lot === (form.values.lot ?? '') : !isNonEmpty(propertyInput.lot)) && !isUnsaved
    )
  }
  useEffect(() => {
    validatePropertyInput() ? setConfirmButtonAllowed(true) : setConfirmButtonAllowed(false);
  }, [propertyInput, form]);
  // useEffect(() => {
  //   if (isNonEmpty(buildingInput) && buildingNames.includes(buildingInput)) {
  //     setConfirmButtonAllowed(true);
  //   } else {
  //     setConfirmButtonAllowed(false);
  //   }
  // }, [ propertyInput ]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => onClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className={classes.dialogContent}>
          <DialogContentText id="alert-dialog-description">
            {langPropertyStock.msgInputPropertyInfo}
          </DialogContentText>
          <TextField
            fullWidth margin="dense" variant="outlined"
            label={langPropertyStock.buildingName}
            value={propertyInput.building}
            onChange={(e) => setPropertyInput({ ...propertyInput, building: e.target.value })}
          />
          <TextField
            fullWidth margin="dense" variant="outlined"
            label={langPropertyStock.block}
            value={propertyInput.block}
            onChange={(e) => setPropertyInput({ ...propertyInput, block: e.target.value })}
          />
          <Grid container /*md={12} sm={12} xs={12}*/ spacing={1}>
            {/* <div style={{ display: 'flex', flexDirection: 'row' }}> */}
            <Grid item md={6} sm={6} xs={6}>
              <TextField
                fullWidth margin="dense" variant="outlined"
                label={langPropertyStock.floor}
                value={propertyInput.floor.join(',')}
                onChange={(e) => setPropertyInput({
                  ...propertyInput,
                  floor: e.target.value?.trim().split(',') ?? []
                })}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <TextField
                fullWidth margin="dense" variant="outlined"
                label={langPropertyStock.unit}
                value={propertyInput.unit.join(',')}
                onChange={(e) => setPropertyInput({
                  ...propertyInput,
                  unit: e.target.value?.trim().split(',') ?? []
                })}
              />
            </Grid>
            {/* <Typography>
              {langPropertyStock.floor} {` :`}
            </Typography>
            {
              propertyInput.floor.map((f: string, idx: number) => 
                <div>
                  <Typography>
                    {` ,`}
                  </Typography>
                  <TextField
                    fullWidth margin="dense" variant="outlined"
                    value={f}
                    onChange={(e) => setPropertyInput({ 
                      ...propertyInput, 
                      floor: propertyInput.floor.map((_: string, i: number) => idx === i ? e.target.value : f) 
                    })}
                  />
                </div>
              )
            } */}
          </Grid>
          {/* </div> */}
          {/* <div>
            <Typography>
              {langPropertyStock.unit} {` :`}
            </Typography>
            {
              propertyInput.unit.map((u: string, idx: number) =>
                <div>
                  <Typography>
                    {` ,`}
                  </Typography>
                  <TextField
                    fullWidth margin="dense" variant="outlined"
                    value={u}
                    onChange={(e) => setPropertyInput({ 
                      ...propertyInput, 
                      unit: propertyInput.unit.map((_: string, i: number) => idx === i ? e.target.value : u) 
                    })}
                  />
                </div>
              )
            }
          </div> */}
          <TextField
            fullWidth margin="dense" variant="outlined"
            label={langPropertyStock.lot}
            value={propertyInput.lot}
            onChange={(e) => setPropertyInput({ ...propertyInput, lot: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()} color="primary">
            {lang.actionCancel}
          </Button>
          <Button
            disabled={!confirmButtonAllowed}
            onClick={() => {
              onConfirm();
              onClose();
            }}
            color="primary" variant="contained" autoFocus
          >
            {lang.actionConfirm}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export interface PropertyDetailProps {
  onSave: (noNavigate?: boolean) => void;
  unsaved?: boolean;
  shouldResetApprovalStage?: boolean;
  form: FormPresenter<PropertyStockDTO>;
  tagForm: FormPresenter<PropertyMultiValueMap>;
  fileForm: FormPresenter<PropertyFileMap>;
  memos?: MemoDTO[];
  creating?: boolean;
  saveDisabled?: boolean;
  hideFloor?: boolean;
  hideStreet?: boolean;
  hideLot?: boolean;
  clientFileForm?: FormPresenter<ClientFileMap>;
  floorUnitOptions?: BuildingListItemDTO['floorUnits'];
  getCheckDuplicateResult: () => void;
  checkResult: Partial<CheckDuplicatedPropertyStockResultDTO>;
}

const PropertyDetail = ({ onSave, unsaved, shouldResetApprovalStage, form, tagForm, fileForm, memos, creating, saveDisabled, hideFloor, hideStreet, hideLot, clientFileForm, floorUnitOptions, getCheckDuplicateResult, checkResult }: PropertyDetailProps) => {
  const classes = useStyles();
  const { lang, langPropertyStock, propertyStockStatusOptions, locale } = useSelector((state: IRootState) => state.locale);
  const { privileges } = useSelector((state: IRootState) => state.login);
  const canAddMemo = hasPermission(privileges, 'CREATE', 'OWN_PROPERTY_STOCK_MEMO')
  const myId = useSelector((state: IRootState) => state.login.uid);
  const { isLeadAgent, canReadTeamProperty, canReadUnowned, canReadCompanyStock } = form.values;
  const [tabIndex, setTabIndex] = useState(0);
  const onScrollIn = (idx: number) => setTabIndex(idx);
  const scrollRef0 = React.useRef<() => void>();
  const scrollRef1 = React.useRef<() => void>();
  const scrollRef2 = React.useRef<() => void>();
  const scrollRef3 = React.useRef<() => void>();
  const scrollRef4 = React.useRef<() => void>();
  const isStatusSale = () => form.values.status === 'SALES' || form.values.status === 'BOTH';
  const isStatusRent = () => form.values.status === 'RENT' || form.values.status === 'BOTH';
  const myPrivileges = useSelector((state: IRootState) => state.login.privileges);
  const tabScrolls = [
    scrollRef0,
    scrollRef1,
    scrollRef2,
    scrollRef3,
    scrollRef4,
  ];
  const [memoDialogOpen, setMemoDialogOpen] = useState(false);
  const [ctDialogOpen, setCtDialogOpen] = useRouteBasedState(`/properties-edit/${form.values.propertyNo}/close-transaction-view`);
  const { token } = useSelector((state: IRootState) => state.login);
  const dispatch = useDispatch() as PASDispatch;
  const history = useHistory();
  const memoForm = useForm<MemoDTO>({});
  const selectFormBtnMenuList = [langPropertyStock.titleForm, langPropertyStock.titleForm1, langPropertyStock.titleForm2, langPropertyStock.titleForm3, langPropertyStock.titleForm5];
  const selectPaBtnMenuList = [langPropertyStock.titleProvisionalAgreement, langPropertyStock.titleSaleAndPurchase, langPropertyStock.titleTenancy];
  const tab1HasError = form.hasError('mainContact') ||
    form.hasError('building') ||
    form.hasError('floor') ||
    form.hasError('unit') || form.hasError('price') || form.hasError('net') || form.hasError('gross') || form.hasError('owner');
  // useEffect(()=>{
  //   dispatch({type: 'AllForm.FetchRequested', payload: {propertyStockId: form.values.id ?? ''}});
  // },[ form.values.id ?? '' ]);

  useEffect(() => {
    dispatch({ type: 'SalePurchaseAgreement.FetchRequested', payload: { propertyStockId: form.values.id ?? '' } });
  }, [form.values.id ?? ''])

  useEffect(() => {
    dispatch({ type: 'TenancyAgreement.FetchRequested', payload: { propertyStockId: form.values.id ?? '' } });
  }, [form.values.id ?? ''])

  useEffect(() => {
    dispatch({ type: 'Memo.FetchRequested', payload: { id: form.values.id ?? '' } } as PropertyActions);
  }, [form.values.id ?? '']);

  // Wishlist Granted Agents
  const wishlistGrantedAgents = useSelector((state: IRootState) => state.property.wishlistGrantedAgents);
  const handleWishlistGrantedAgentAdded = (grantedAgentUid: number) => {
    dispatch({
      type: 'Property.WishlistGrantedAgents.UpdateRequested',
      payload: {
        propertyStockId: Number(form.values.id ?? '0'),
        updates: {
          updated: [], deleted: [],
          created: [{
            propertyStockId: Number(form.values.id ?? '0'),
            grantedAgentUserId: grantedAgentUid,
            leadAgentUserId: Number(uid ?? '0'),
          }].filter(wga => !wishlistGrantedAgents.some(w0 => w0.grantedAgentUserId === wga.grantedAgentUserId)),
        }
      },
    });
  };

  const handleWishlistGrantedAgentRemoved = (grantedAgentUid: number) => {
    dispatch({
      type: 'Property.WishlistGrantedAgents.UpdateRequested',
      payload: {
        propertyStockId: Number(form.values.id ?? '0'),
        updates: {
          updated: [], created: [],
          deleted: wishlistGrantedAgents.filter(wga => wga.grantedAgentUserId === grantedAgentUid),
        },
      },
    });
  };

  // handling for group button
  const [openFormButtonMenu, setOpenFormButtonMenu] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  // const [openFormDialog, setOpenFormDialog] = React.useState(false);
  const [openFormDialog, setOpenFormDialog] = useRouteBasedState(`/properties-edit/${form.values.propertyNo}/form-view`, selectedIndex !== 0);

  const handleClickOpenFormDialog = () => {
    setOpenFormDialog(true);
  };

  const handleCloseFormDialog = () => {
    setOpenFormDialog(false);
  };

  const handleFormButtonClick = () => {
    setOpenFormDialog(selectedIndex != 0 ? true : false);
  };

  const LAND_SEARCH_VALIDITY_PERIOD = useSelector((root: IRootState) => root.systemSettings.PropertyStock?.LAND_SEARCH?.VALIDITY_PERIOD) ?? '7';

  const handleFormButtonMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    switch (selectFormBtnMenuList[index]) {
      case langPropertyStock.titleForm3:
      // fallthrough
      case langPropertyStock.titleForm5:
        // Check land search
        if (!fileForm.values.LAND_SEARCH?.filter(file =>
          file.dataStatus === 'A' && file.confirmed && file.id
        ).some((file: any) => {
          const date = file.date as string;
          return moment(new Date).isSameOrBefore(moment(date).add(+LAND_SEARCH_VALIDITY_PERIOD, 'day'));
        })) {
          alertDialog.confirm(
            format(langPropertyStock.msgLandSearchRequiredForForm35, +LAND_SEARCH_VALIDITY_PERIOD),
            lang.actionConfirm, lang.actionConfirm
          );
          return;
        }
      default:
      // nothing
    }

    setSelectedIndex(index);
    setOpenFormButtonMenu(false);
    setOpenFormDialog(true);
  };

  const handleFormButtonToggle = () => {
    setOpenFormButtonMenu(prevOpen => !prevOpen);
  };

  const handleFormButtonClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpenFormButtonMenu(false);
  };

  const showFormButton = (index: number) => {
    switch (form.values.status) {
      case 'SALES': return index === 0 || index === 1 || index === 3;
      case 'RENT': return index === 0 || index === 2 || index === 4;
      default: return true;
    }
  }

  // handling provisional agreement button group
  const [openPaButtonMenu, setOpenPaButtonMenu] = React.useState(false);
  const anchorRefPa = React.useRef<HTMLDivElement>(null);
  const [selectedPaIndex, setSelectedPaIndex] = React.useState(0);
  const [openPaDialog, setOpenPaDialog] = useRouteBasedState(`/properties-edit/${form.values.propertyNo}/agreement-form-view`, selectedPaIndex !== 0);

  const handleClickOpenPaDialog = () => {
    setOpenPaDialog(true);
  };

  const handleClosePaDialog = () => {
    setOpenPaDialog(false);
  };

  const handlePaButtonClick = () => {
    setOpenPaDialog(selectedIndex != 0 ? true : false);
  };

  const handlePaButtonMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedPaIndex(index);
    setOpenPaButtonMenu(false);
    setOpenPaDialog(true);
  };

  const handlePaButtonToggle = () => {
    setOpenPaButtonMenu(prevOpen => !prevOpen);
  };

  const handlePaButtonClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRefPa.current && anchorRefPa.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpenPaButtonMenu(false);
  };


  // handling approval
  const canApprove = hasPermission(privileges, 'APPROVE', 'PROPERTY_STOCK');
  const approvalStage = form.values.approvalStage ?? 0;
  const pendingApprovalStage = form.values.pendingApprovalStage ?? 0;
  const warningToApprovalStage = form.values.approvalStage !== 2 && form.values.published;

  const shouldShowApprovalWarning = warningToApprovalStage || form.values.unpublishedDueToForm35 || form.values.hasUnapprovedForm3 || form.values.hasUnapprovedForm5;

  const approvalStageMap: { [stage: number]: { icon: React.ReactNode, title: React.ReactNode, message: React.ReactNode } } = {
    0: { icon: !shouldShowApprovalWarning ? <RadioButtonUncheckedIcon /> : <WarningIcon />, title: langPropertyStock.captionNotApproved, message: langPropertyStock.captionNotApproved },
    1: { icon: pendingApprovalStage == 0 && !form.values.unpublishedDueToForm35 && !shouldShowApprovalWarning ? <CheckCircleOutlineIcon /> : !shouldShowApprovalWarning ? <RadioButtonUncheckedIcon /> : <WarningIcon />, title: form.values.published ? langPropertyStock.actionApproveStage2 : langPropertyStock.actionApproveStage1, message: langPropertyStock.captionApproved + langPropertyStock.actionApproveStage1 },
    2: { icon: form.values.unpublishedDueToForm35 || form.values.hasUnapprovedForm3 || form.values.hasUnapprovedForm5 ? <WarningIcon /> : <CheckCircleOutlineIcon />, title: langPropertyStock.actionApproveStage2, message: langPropertyStock.captionApproved + langPropertyStock.actionApproveStage2 },
  };
  //console.log("canApprove", canApprove, "approvalStage", approvalStage, "pendingApprovalStage", pendingApprovalStage, "warningToApprovalStage", warningToApprovalStage, "shouldShowApprovalWarning", shouldShowApprovalWarning);
  //

  //console.log("canApprove", canApprove);
  const formMenu = useMenu();
  const paMenu = useMenu();
  const approvalMenu = useMenu();
  const statusMenu = useMenu();

  const alertDialog = useInfoDialog();
  const { missingForm35, form35Valid } = useMemo(() => form35ValidityChecking(form.values.attachments, form.values.status), [form.values.attachments, form.values.status]);
  const readyToStage2Approval =
    (form.values.status === "BOTH" && (form.values.latestValidForm3Id ?? 0) > 0 && (form.values.latestValidForm5Id ?? 0) > 0 ||
    form.values.status === "SALES" && (form.values.latestValidForm3Id ?? 0) > 0 ||
    form.values.status === "RENT" && (form.values.latestValidForm5Id ?? 0) > 0) &&
    // Lag & Lng present
    form.values.longitude && form.values.latitude &&
    // Check form3/5 validity
    form35Valid;



  const handlePaDelete = (type: 'SALE' | 'RENTAL') => {
    confirmDialog.confirm(langPropertyStock.msgConfirmDeleteProvisionalAgreemnt, lang.actionConfirm, lang.actionCancel).then(confirmed => {
      if (confirmed) {
        dispatch({ type: 'ProvisionalAgreement.DeleteRequested', payload: { propertyStockId: form.values.id!, type } });
      }
    });
  }

  const handleOnApprovalClick = (stage: number) => async (): Promise<boolean> => { // return shouldDoFurtherNavigate
    //block approving internal matching for sold/leased property
    const isSoldLeasedProperty = form.values.status === 'SOLD' || form.values.status === 'LEASED';
    if (stage === 1 && isSoldLeasedProperty && canApprove) {
      return alertDialog.confirm(
        <div>
          <Typography>{langPropertyStock.msgCannotApproveApproval}</Typography>
          <br />
          <Typography>{'- '}{format(langPropertyStock.msgStatusNotForInternalMatching, propertyStockStatusOptions[form.values.status ?? 'UNKNOWN'])}</Typography>
        </div>
        , lang.actionYes, lang.actionClose).then(confirmed => {
          return true;
        });
    }
    //check before stage 2 approval
    if (stage === 2 && !readyToStage2Approval) {
      return alertDialog.confirm(
        <div>
          <Typography>{canApprove ? langPropertyStock.msgCannotApproveApproval : langPropertyStock.msgCannotRequestForApprovalStage2}</Typography>
          <br />
          {!(form.values.longitude && form.values.latitude) ?
            <Typography>{'- '}{langPropertyStock.msgLongLatNotDefined}</Typography>
            : null}
          {/* {!form35Valid && missingForm35 ?
            <Typography>{'- '}{format(langPropertyStock.msgNoValidForm35, missingForm35)}</Typography>
            : null} */}
          {!form35Valid && !missingForm35 ?
            <Typography>{'- '}{format(langPropertyStock.msgStatusNotForPublicListing, propertyStockStatusOptions[form.values.status ?? 'UNKNOWN'])}</Typography>
            : null}
          {form.values.status === "BOTH" && ((form.values.latestValidForm3Id ?? 0) === 0 || (form.values.latestValidForm5Id ?? 0) === 0) ?
            <Typography>{'- '}{
              format(langPropertyStock.msgNoValidForm35)
            }</Typography>
            : null}
          {form.values.status === "SALES" && (form.values.latestValidForm3Id ?? 0) === 0 ?
            <Typography>{'- '}{format(langPropertyStock.msgNoValidForm35)}</Typography>
            : null}
          {form.values.status === "RENT" && (form.values.latestValidForm5Id ?? 0) === 0 ?
            <Typography>{'- '}{format(langPropertyStock.msgNoValidForm35)}</Typography>
            : null}
        </div>
        , lang.actionYes, lang.actionClose).then(confirmed => {
          return true;
        });
    }

    if (canApprove) {
      // If have approval right, do approval & rejection
      setWillApproveTo(stage);
      setApprovalDialogOpen(true);
      return false;
      // confirmDialog.confirm(langPropertyStock.msgConfirmApproval, lang.actionYes, lang.actionNo).then(confirmed => {
      //   if (confirmed) {
      //     dispatch({ type: 'Property.ApprovalRequested', payload: {
      //       id: form.values.id!,
      //       stage,
      //       action: 'RESOLVE',
      //     } });
      //   }
      // });
    } else {
      // If not, do pending request
      setWillApproveTo(0);
      await confirmDialog.confirm(langPropertyStock.msgConfirmRequestForApproval, lang.actionConfirm, lang.actionCancel).then(confirmed => {
        if (confirmed) {
          dispatch({
            type: 'Property.ApprovalRequested', payload: {
              id: form.values.id!,
              stage,
              action: 'PENDING',
            }
          });
        }
      });
      return true;

    }
  };

  const [revertedStage, setRevertedStage] = useState((form.values.approvalStage === 2 || form.values.approvalStage === 1 && form.values.published) ? "1" : "0");

  const [sendRevert, setSendRevert] = useState(false);

  useEffect(() => {
    setRevertedStage((form.values.approvalStage === 2 || form.values.approvalStage === 1 && form.values.published) ? "1" : "0");
  }, [form.values.approvalStage, form.values.published]);

  useEffect(() => {
    if (sendRevert) {
      dispatch({
        type: 'Property.ApprovalRequested', payload: {
          id: form.values.id!,
          stage: Number(revertedStage),
          action: 'REVERT'
        }
      });
      setSendRevert(false)
    }
  }, [sendRevert])
  const handleOnApprovalRevertClick = () => {
    return confirmDialog.confirm(
      <div>
        <Typography variant="h5" style={{ paddingTop: '10px' }}>{`${langPropertyStock.msgPleaseChooseToRevert}: `}</Typography>
        <FormControl component="fieldset">
          <RadioGroup name="revert-to-stage" defaultValue={revertedStage} onChange={(_, val) => setRevertedStage(val)}>
            {(form.values.approvalStage === 2 || form.values.approvalStage === 1 && form.values.published) && <FormControlLabel value="1" control={<Radio />} label={langPropertyStock.captionRevertToStage1} />}
            <FormControlLabel value="0" control={<Radio />} label={langPropertyStock.captionRevertToStage0} />
          </RadioGroup>
        </FormControl>
      </div>
      , lang.actionConfirm, lang.actionCancel).then(confirmed => {
        if (confirmed) {
          setSendRevert(true)
        }
      });
  }

  // 

  const [isPendingOrUnknown, setIsPendingOrUnknown] = useState(false);
  //console.log("approvalStage", canApprove);
  useEffect(() => {
    if (form.values.status === 'PENDING' || form.values.status === 'UNKNOWN') {
      setIsPendingOrUnknown(true);
    } else {
      setIsPendingOrUnknown(false);
    }
  }, [form.values.status]);
  //console.log("form", form);
  //console.log("unpublishedDueToForm3", form.values.unpublishedDueToForm3);
  const approvalMenuMessages = (() => {
    const messages: string[] = [];
    switch (form.values.status) {
      case "BOTH":
        // if (form.values.approvalStage === 2) {
        if (form.values.unpublishedDueToForm3 && form.values.unpublishedDueToForm5) {
          messages.push(langPropertyStock.msgUnpublishedDueToForm35Expired);
        }
        else if (form.values.unpublishedDueToForm3) {
          messages.push(langPropertyStock.msgStatusBothChangeToRent);
        }
        else if (form.values.unpublishedDueToForm5) {
          messages.push(langPropertyStock.msgStatusBothChangeToSales);
        }
        // }
        else if (warningToApprovalStage) {
          messages.push(langPropertyStock.msgPublishedInClientAppAsPreviousVersion);
        }
        else if (form.values.hasUnapprovedForm3 && form.values.hasUnapprovedForm5) {
          messages.push(langPropertyStock.msgHasUnapprovedForm35);
        }
        else if (form.values.hasUnapprovedForm3) {
          messages.push(langPropertyStock.msgHasUnapprovedForm3);
        }
        else if (form.values.hasUnapprovedForm5) {
          messages.push(langPropertyStock.msgHasUnapprovedForm5);
        }


        break;

      case "SALES":

        if (form.values.unpublishedDueToForm3) {
          messages.push(langPropertyStock.msgUnpublishedDueToForm3Expired);
        }
        else if (warningToApprovalStage) {
          messages.push(langPropertyStock.msgPublishedInClientAppAsPreviousVersion);
        }
        else if (form.values.unpublishedDueToForm3 && form.values.unpublishedDueToForm5) {
          messages.push(langPropertyStock.msgUnpublishedDueToForm35Expired);
        }
        else if (form.values.hasUnapprovedForm3) {
          messages.push(langPropertyStock.msgHasUnapprovedForm3);
        }
        else if (form.values.hasUnapprovedForm5) {
          messages.push(langPropertyStock.msgHasUnapprovedForm5);
        }

        break;

      case "RENT":
        if (form.values.unpublishedDueToForm5) {
          messages.push(langPropertyStock.msgUnpublishedDueToForm5Expired);
        }
        else if (warningToApprovalStage) {
          messages.push(langPropertyStock.msgPublishedInClientAppAsPreviousVersion);
        }
        else if (form.values.unpublishedDueToForm3 && form.values.unpublishedDueToForm5) {
          messages.push(langPropertyStock.msgUnpublishedDueToForm35Expired);
        }
        else if (form.values.hasUnapprovedForm3) {
          messages.push(langPropertyStock.msgHasUnapprovedForm3);
        }
        else if (form.values.hasUnapprovedForm5) {
          messages.push(langPropertyStock.msgHasUnapprovedForm5);
        }

    }


    // if (form.values.unpublishedDueToForm35) {
    //   messages.push(langPropertyStock.msgUnpublishedDueToForm35);
    // } else if (warningToApprovalStage) {
    //   messages.push(langPropertyStock.msgPublishedInClientAppAsPreviousVersion);
    // }
    return messages.map((msg, i) => <div className={classes.menuMessage} key={i}>
      <span style={{
        color: approvalStage === 0 && pendingApprovalStage === 0 || ((form.values.status === "BOTH" && form.values.unpublishedDueToForm3 && form.values.unpublishedDueToForm5
          || form.values.status === "SALES" && form.values.unpublishedDueToForm3 || form.values.status === "RENT" && form.values.unpublishedDueToForm5) && pendingApprovalStage === 0)
          ? 'unset'
          : (pendingApprovalStage !== 0)
            ? colors.yellow[800]
            : colors.green[800]
      }}><WarningIcon /></span>&nbsp;{msg}
    </div>);
  })();
  // color: form.values.approvalStage == 0 && pendingApprovalStage == 0 ? "unset" : form.values.published && !form.values.unpublishedDueToForm35 ? colors.green[800] : colors.yellow[800]

  const approvalMenuItems = (menu: any) => [
    approvalMenuMessages.length ? <div key="approval-menu-messages">
      {approvalMenuMessages}
    </div> : null,
    <MenuItem key={`approve-1`}
      disabled={approvalStage >= 2 || (approvalStage == 1 && !form.values.hasUnapprovedForm3) && (approvalStage == 1 && !form.values.hasUnapprovedForm5) || !canApprove && form.values.pendingApprovalStage === 1 || (saveDisabled && !canApprove) || (checkResult.hasPendingForApprovalOrIsOpen && form.values.approvalStage === 0) || unsaved}
      onClick={approvalStage <= 0 ? menu.withClosing(handleOnApprovalClick(1)) : ((approvalStage == 1 && form.values.hasUnapprovedForm3) || (approvalStage == 1 && form.values.hasUnapprovedForm5)) ? menu.withClosing(handleOnApprovalClick(1)) : undefined}
      style={{ color: approvalStage >= 1 ? colors.green[800] : pendingApprovalStage >= 1 ? colors.yellow[800] : 'unset' }}>
      <ListItemIcon style={{ color: 'inherit', minWidth: 30 }}>
        {approvalStage <= 0 ? <RadioButtonUncheckedIcon fontSize="small" /> :
          (<CheckCircleOutlineIcon fontSize="small" />)
        }
      </ListItemIcon>
      {langPropertyStock.actionApproveStage1}
    </MenuItem>,
    <MenuItem key={`approve-2`}
      disabled={!form.values.unpublishedDueToForm35 && approvalStage === 2 && !form.values.hasUnapprovedForm3 && !form.values.hasUnapprovedForm5 && pendingApprovalStage === 0 || !canApprove && form.values.pendingApprovalStage === 2 || (saveDisabled && !canApprove) || (checkResult.hasPendingForApprovalOrIsOpen && form.values.approvalStage === 0) || unsaved}
      onClick={form.values.unpublishedDueToForm35 || approvalStage !== 2 || pendingApprovalStage === 2 || form.values.hasUnapprovedForm3 || form.values.hasUnapprovedForm5 ? menu.withClosing(handleOnApprovalClick(2)) : undefined}
      style={{
        color: approvalStage === 0 && pendingApprovalStage === 0 || ((form.values.status === "BOTH" && form.values.unpublishedDueToForm3 && form.values.unpublishedDueToForm5
          || form.values.status === "SALES" && form.values.unpublishedDueToForm3 || form.values.status === "RENT" && form.values.unpublishedDueToForm5) && pendingApprovalStage === 0) || pendingApprovalStage === 1
          || pendingApprovalStage === 0 && approvalStage === 1 && !form.values.published
          ? 'unset'
          : (pendingApprovalStage !== 0)
            ? colors.yellow[800]
            : colors.green[800]
      }}>
      <ListItemIcon style={{ color: 'inherit', minWidth: 30 }}>
        {
          form.values.unpublishedDueToForm35 || form.values.published && (form.values.pendingApprovalStage === 2 || form.values.pendingApprovalStage === 0 && approvalStage !== 2) || form.values.hasUnapprovedForm3 && form.values.published || form.values.hasUnapprovedForm5 && form.values.published ?
            <WarningIcon fontSize="small" />
            :
            form.values.unpublishedDueToForm35 || approvalStage != 2 || pendingApprovalStage >= 1 ? <RadioButtonUncheckedIcon fontSize="small" /> : <CheckCircleOutlineIcon fontSize="small" />}
      </ListItemIcon>
      {langPropertyStock.actionApproveStage2}
    </MenuItem>,

    (form.values.published || !(form.values.approvalStage === 0)) && canApprove && <MenuItem key={`approve-revert`}
      disabled={(approvalStage <= 0) || (saveDisabled && !canApprove) || (checkResult.hasPendingForApprovalOrIsOpen && form.values.approvalStage === 0)}
      onClick={() => handleOnApprovalRevertClick()}
    >
      <ListItemIcon style={{ color: 'inherit', minWidth: 30 }}>
        <ReplayIcon fontSize="small" />
      </ListItemIcon>
      {langPropertyStock.actionApproveRevert}
    </MenuItem>
  ];

  const statusMenuItems = (menu: any) => [
    !isPendingOrUnknown && <MenuItem key={`PENDING`} value={`PENDING`} disabled={(Boolean(closeTransactionDto) && closeTransactionDto.status !== 'A' && isNonEmpty(validForm(fileForm).values.PROVISIONAL_AGREEMENT)) || form.values.status === 'SOLD' || form.values.status === 'LEASED' || unsaved || saveDisabled} onClick={() => onChangeStatusWithPrompt('PENDING')}>{propertyStockStatusOptions['PENDING']}</MenuItem>,
    // !isPendingOrUnknown && <MenuItem key={`UNKNOWN`} value={`UNKNOWN`} onClick={() => onChangeStatusWithPrompt('UNKNOWN')}>{propertyStockStatusOptions['UNKNOWN']}</MenuItem>,
    <MenuItem key={`SOLD`} value={`SOLD`} disabled={(Boolean(closeTransactionDto) && closeTransactionDto.status !== 'A' && isNonEmpty(validForm(fileForm).values.PROVISIONAL_AGREEMENT)) || form.values.status === 'SOLD' || form.values.status === 'LEASED' || unsaved || saveDisabled} onClick={() => onChangeStatusWithPrompt('SOLD')}>{propertyStockStatusOptions['SOLD']}</MenuItem>,
    <MenuItem key={`LEASED`} value={`LEASED`} disabled={(Boolean(closeTransactionDto) && closeTransactionDto.status !== 'A' && isNonEmpty(validForm(fileForm).values.PROVISIONAL_AGREEMENT)) || form.values.status === 'SOLD' || form.values.status === 'LEASED' || unsaved || saveDisabled} onClick={() => onChangeStatusWithPrompt('LEASED')}>{propertyStockStatusOptions['LEASED']}</MenuItem>,
    isPendingOrUnknown && <MenuItem key={`RELEASE`} value={`RELEASE`} disabled={(Boolean(closeTransactionDto) && closeTransactionDto.status !== 'A' && isNonEmpty(validForm(fileForm).values.PROVISIONAL_AGREEMENT)) || form.values.status === 'SOLD' || form.values.status === 'LEASED' || unsaved || saveDisabled} onClick={() => onChangeStatusWithPrompt('RELEASE')}>{`${langPropertyStock.captionReleasePrefix}${propertyStockStatusOptions[form.values.status ?? '']}${langPropertyStock.captionReleaseSuffix}`}</MenuItem>,
  ];

  const formMenuItems = () => selectFormBtnMenuList.filter((_, i) => i !== 0).map((option, index) => (
    <MenuItem key={option}
      //disabled follows the form Menu from fullscreen view
      disabled={!canEditForm || form.values.isOrphan || (form.values.canReadCompanyStock && form.values.companyStock !== true) || (form.values.canReadUnowned && form.values.companyStock !== true) || unsaved}
      style={{ display: showFormButton(index + 1) ? 'block' : 'none' }}
      onClick={event => { handleFormButtonMenuItemClick(event, index + 1); formMenu.onClose() }}
    >
      {option}
    </MenuItem>
  ));

  const paMenuItems = () => selectPaBtnMenuList.filter((_, i) => i !== 0).map((option, index: number) => (
    <MenuItem
      key={option}
      disabled={(index === 0 && !(canEditSalePa && isStatusSale())) || (index === 1 && !(canEditTenancyPa && isStatusRent())) || unsaved}
      onClick={event => { handlePaButtonMenuItemClick(event, index + 1); paMenu.onClose() }}
    >
      <ListItemText>
        <span className={(index === 0 ? currentSalePurchaseAgreement.id : currentTenancyAgreement.id) ? classes.activePa : ''}>
          {option}
        </span>
      </ListItemText>
      {(index === 0 ? salePaAccessControls.canDeleteSalePa : tenancyPaAccessControls.canDeleteTenancyPa) ? <ListItemSecondaryAction className={classes.paListItemSecAction}>
        <IconButton onClick={() => handlePaDelete(index === 0 ? 'SALE' : 'RENTAL')}>
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction> : null}

      {(() => {
        let message: string | undefined;
        if (index === 0) {
          message = !canEditSalePa && !!currentSalePurchaseAgreement?.id && isLeadAgent && isPropertyStockActive ? langPropertyStock.msgSaleAgreementExists : undefined;
        } else if (index === 1) {
          message = !canEditTenancyPa && !!currentTenancyAgreement?.id && isLeadAgent && isPropertyStockActive ? langPropertyStock.msgTenancyAgreementExists : undefined;
        }

        return message ? <ListItemSecondaryAction className={classes.paListItemSecAction}>
          <IconButton onClick={() => alertDialog.confirm(message, lang.actionConfirm, lang.actionConfirm)}>
            <InfoIcon />
          </IconButton>
        </ListItemSecondaryAction> : null;
      })()}
    </MenuItem>
  ));
  // handling collapseMenu
  const collapseMenu = useMenu();
  const shouldCollapse = useMediaQuery('(max-width: 1024px)');
  const confirmDialog = useConfirmDialog();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const onSaveWithPrompt = () => {
    if (approvalStage === 0) {
      onSave();
    } else {
      if (shouldResetApprovalStage && form.validate()) {
        confirmDialog.confirm(langPropertyStock.msgRequireApproveAgain, lang.actionConfirm, lang.actionCancel).then(confirmed => {
          if (confirmed) {
            onSave();

            if ((form.values.approvalStage ?? 0) > 0 && (form.values.leadAgents?.length ?? 0) > 1) {
              dispatch({
                type: 'Property.ApprovalRequested', payload: {
                  id: form.values.id!,
                  stage: (form.values.pendingApprovalStage ?? 0) >= (form.values.approvalStage ?? 0) ? form.values.pendingApprovalStage! : form.values.approvalStage!,
                  action: 'PENDING',
                }
              });
            }
          }
        });
      } else {
        onSave();
      }
    }
  };

  const onChangeStatusWithPrompt = (status: string) => {
    confirmDialog.confirm(langPropertyStock.msgChangeStatus, lang.actionConfirm, lang.actionCancel).then(confirmed => {
      if (confirmed) {
        dispatch({
          type: 'Property.UpdateStatusRequested',
          payload: {
            id: form.values.id ?? '',
            status: status ?? '',
            dateModified: form.values.dateModified ?? '',
          }
        });
      }
    });
  };

  // const onChangeStatusWithPromptWarning = (status: string) => {
  //   let message: string | undefined;
  //   message = "You are not allowed to change the status until you finish the Close Transaction"
  //   confirmDialog.confirm(message, lang.actionConfirm, '')
  // };
  // Approval Dialog State
  const [approvalDialogOpen, setApprovalDialogOpen] = useState(false);
  const [willApproveTo, setWillApproveTo] = useState(0);

  // Close Transaction DTO
  const closeTransactionDto = useSelector((state: IRootState) => state.closeTransaction.fetchedDetails[form.values.id ?? '']);
  const isShouldSignCTBuyerAgent = (isNonEmpty(closeTransactionDto) && isNonEmpty(closeTransactionDto.buyerAgents)) ? closeTransactionDto.buyerAgents!.filter(a => a.shouldSign && a.agentId?.toString() === myId).length > 0 : false;
  const isShouldSignCTSellerAgent = (isNonEmpty(closeTransactionDto) && isNonEmpty(closeTransactionDto.sellerAgents)) ? closeTransactionDto.sellerAgents!.filter(a => a.shouldSign && a.agentId?.toString() === myId).length! > 0 : false;
  const hasCanApprove = isNonEmpty(closeTransactionDto) ? closeTransactionDto.canApprove : false;
  const canOpenCloseTransaction = isNonEmpty(myPrivileges) ? (myPrivileges!.filter(p => (p.role === 'OFFICE_ADMIN' && (hasCanApprove || closeTransactionDto?.status === 'A')) || p.role === 'ADMIN').length > 0 || isShouldSignCTBuyerAgent || isShouldSignCTSellerAgent) : false;
  const isAdminOrOfficeAdmin = isNonEmpty(myPrivileges) ? myPrivileges!.filter(p => (p.role === 'OFFICE_ADMIN' || p.role === 'ADMIN')).length > 0 : false;
  const isCompanyStock = form.values.companyStock === true;
  // provisional agreement
  const { uid } = useSelector((state: IRootState) => state.login);
  const currentSalePurchaseAgreement = useSelector((state: IRootState) => state.property.salePurchaseAgreement ?? {});
  const currentTenancyAgreement = useSelector((state: IRootState) => state.property.tenancyAgreement ?? {});
  const canDeleteUnownedProvisionalAgreement = hasPermission(privileges, 'DELETE', 'UNOWNED_PROVISIONAL_AGREEMENT');
  const salePaAccessControls = useSelector(selectSalePaAccessControls(isLeadAgent ?? false, isCompanyStock, uid ?? '', canDeleteUnownedProvisionalAgreement))
  const tenancyPaAccessControls = useSelector(selectTenancyPaAccessControls(isLeadAgent ?? false, isCompanyStock, uid ?? '', canDeleteUnownedProvisionalAgreement))

  const isPropertyStockActive = (form.values.status !== 'SOLD' && form.values.status !== 'LEASED');
  const canEditSalePa = salePaAccessControls.canEditSalePaData && isPropertyStockActive;
  const canEditTenancyPa = tenancyPaAccessControls.canEditTenancyPaData && isPropertyStockActive;
  const canEditPa = (canEditSalePa || canEditTenancyPa);
  const canEditForm = form.values.companyStock === true ? (canReadCompanyStock || !saveDisabled) : !saveDisabled;

  const isPaAgent = salePaAccessControls.isBuyerAgent || tenancyPaAccessControls.isTenantAgent;

  const canUpdateUnown = !isLeadAgent && hasPermission(privileges, 'UPDATE', 'UNOWNED_PROPERTY_STOCK')
  const canDownloadOrDeleteFormFiles = form.values.companyStock === true ? canEditForm : isLeadAgent;
  const canDownloadPaFiles = (canEditSalePa || canEditTenancyPa);
  const canUploadPropertyDocFiles = creating ? true : (form.values.companyStock === true ? canEditForm : (isLeadAgent || canUpdateUnown));

  const { teamMembers } = useSelector((state: IRootState) => state.user.fetchedDetails[uid!]) ?? EMPTY as Partial<UserDTO>;

  //check ownerName

  //auto-fill and highlight ownerName
  const [hasLastTransaction, setHasLastTransaction] = useState<boolean | undefined>(form.values.hasLatestTransaction);
  const [ownerFromLatestTransactionEn, setOwnerFromLatestTransactionEn] = useState<string | undefined>(form.values.ownerFromLatestTransactionEn);
  const [ownerFromLatestTransactionZh, setOwnerFromLatestTransactionZh] = useState<string | undefined>(form.values.ownerFromLatestTransactionZh)
  const [netFromLatestTransaction, setNetFromLatestTransaction] = useState<number | undefined>(form.values.netFromLatestTransaction);
  const [grossFromLatestTransaction, setGrossFromLatestTransaction] = useState<number | undefined>(form.values.grossFromLatestTransaction);

  const checkSameOwner = (hasTransaction: boolean, formValuesOwner: string | undefined, lastTransactionOwnerEn: string | undefined, lastTransactionOwnerZh: string | undefined) => {
    return hasTransaction ? (formValuesOwner?.toUpperCase().trim() === lastTransactionOwnerEn?.toUpperCase().trim() || formValuesOwner?.toUpperCase().trim() === lastTransactionOwnerZh?.toUpperCase().trim()) : true;
  }

  const checkSameArea = (hasTransaction: boolean, formValuesArea: number | undefined, lastTransactionArea: number | undefined) => {
    return hasTransaction ? (!isNonEmpty(formValuesArea) && !isNonEmpty(lastTransactionArea) ? true : formValuesArea == lastTransactionArea) : true;
  }

  // const isSameOwner = hasLastTransaction ? form.values.owner?.toUpperCase().trim() === ownerFromLatestTransaction?.toUpperCase().trim() : true;
  // const isSameNet = hasLastTransaction ? (!isNonEmpty(form.values.net) && !isNonEmpty(netFromLatestTransaction) ? true : form.values.net  == netFromLatestTransaction) : true;
  // const isSameGross = hasLastTransaction ? (!isNonEmpty(form.values.gross) && !isNonEmpty(grossFromLatestTransaction) ? true : form.values.gross  == grossFromLatestTransaction) : true;

  const isSameOwner = creating ? checkSameOwner(hasLastTransaction ?? false, form.values.owner, ownerFromLatestTransactionEn, ownerFromLatestTransactionZh)
    : checkSameOwner(form.values.hasLatestTransaction ?? false, form.values.owner, form.values.ownerFromLatestTransactionEn, form.values.ownerFromLatestTransactionZh);
  const isSameNet = creating ? checkSameArea(hasLastTransaction ?? false, form.values.net, netFromLatestTransaction)
    : checkSameArea(form.values.hasLatestTransaction ?? false, form.values.net, netFromLatestTransaction);
  const isSameGross = creating ? checkSameArea(hasLastTransaction ?? false, form.values.gross, grossFromLatestTransaction)
    : checkSameArea(form.values.hasLatestTransaction ?? false, form.values.gross, grossFromLatestTransaction);

  const getPropertyPreviousTransactionData = () => {
    if (isNonEmpty(form.values.buildingId) && isNonEmpty(form.values.usage)) {
      // if ((isNonEmpty(form.values.hasFloorDefinitions) && !isNonEmpty(form.values.floor))
      //   || (isNonEmpty(form.values.hasUnitDefinitions) && !isNonEmpty(form.values.unit))
      //   //!(isNonEmpty(form.values.block) || isNonEmpty(form.values.blockEn))
      // ) {
      //   return;
      // }
      setHasLastTransaction(false);
      setOwnerFromLatestTransactionEn(undefined);
      setOwnerFromLatestTransactionZh(undefined);
      setNetFromLatestTransaction(undefined);
      setGrossFromLatestTransaction(undefined);

      dispatch({ type: 'Layout.MaskPresentRequested' });
      transactionApi.getPropertyPreviousTransactionData({
        buildingId: form.values.buildingId,
        usage: form.values.usage,
        district: form.values.district,
        block: form.values.block,
        blockEn: form.values.blockEn,
        street: form.values.street,
        streetEn: form.values.streetEn,
        lot: form.values.lot,
        building: form.values.building,
        buildingEn: form.values.buildingEn,
        floor: form.values.floor?.filter(f => isNonEmpty(f)) ?? [],
        unit: form.values.unit?.filter(u => isNonEmpty(u)) ?? [],
      } as PropertyStockDTO, token ?? '').then(result => {

        if (result.data) {
          if (!isNonEmpty(form.values.owner)) {
            form.updateValues('owner', multiLang(locale, result.data.buyerZh, result.data.buyerEn));
          }
          if (!isNonEmpty(form.values.gross) && !isNonEmpty(form.values.net)) {
            form.updateValues('gross', result.data.gross);
            form.updateValues('net', result.data.net);
          }
          setHasLastTransaction(isNonEmpty(result.data.id) ? true : false);
          setOwnerFromLatestTransactionEn(result.data.buyerEn);
          setOwnerFromLatestTransactionZh(result.data.buyerZh);
          setNetFromLatestTransaction(result.data.net);
          setGrossFromLatestTransaction(result.data.gross);

        } else if (result.error) {
          throw new Error(result.error);
          form.updateValues('owner', null);
          form.updateValues('gross', null);
          form.updateValues('net', null);
        }
        dispatch({ type: 'Layout.MaskDismissRequested' });
      }).catch(() => {
        dispatch({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgNetworkError } });
        dispatch({ type: 'Layout.MaskDismissRequested' });
      });

    }
  }

  const documentOptions: { [key: string]: string } = {
    DUMMY: lang.actionUpload,
    LAND_SEARCH: langPropertyStock.landSearch,
    SA_SEARCH: langPropertyStock.saSearch,
    // OP: langPropertyStock.occPermit,
    COMPANY_SEARCH: langPropertyStock.businessReg,
    OTHERS: langPropertyStock.otherDoc,
  };

  const formsAndPaOptions: { [key: string]: string } = {
    DUMMY: lang.actionUpload,
    FORM_1: langPropertyStock.form1,
    FORM_2: langPropertyStock.form2,
    FORM_3: langPropertyStock.form3,
    FORM_4: langPropertyStock.form4,
    FORM_5: langPropertyStock.form5,
    FORM_6: langPropertyStock.form6,
    PROVISIONAL_AGREEMENT: langPropertyStock.provisionalAgreement,
    SUPPLEMENTAL_DOCUMENT: langPropertyStock.provisionalAgreementSuppDoc,
  };
  // 
  const navBarStyle = () => {

    switch (form.values.approvalStage) {
      case 0: return classes.approvalStageUnapproved;
      case 1: return classes.approvalStageMatching;
      case 2: return classes.approvalStageClientApp;
      default: return classes.approvalStageUnapproved;
    }
  }

  const [selectedApprovalSuggestion, setSelectedApprovalSuggestion] = useState("");
  const [approvalSuggestionDialogOpen, setApprovalSuggestionDialogOpen] = useState(false);
  const handleApprovalSuggestion = async (confimed: boolean) => {
    setApprovalSuggestionDialogOpen(false);
    let shouldDoFurtherNavigate = true;
    if (confimed && Number(selectedApprovalSuggestion)) {
      // dispatch({ type: 'Property.ApprovalRequested', payload: {
      //   id: form.values.id!,
      //   stage: Number(selectedApprovalSuggestion),
      //   action: canApprove ? 'RESOLVE' : 'PENDING',
      // } });
      shouldDoFurtherNavigate = await handleOnApprovalClick(+selectedApprovalSuggestion)();
    }

    if (!form.values.noNavigateAfterApprovalSuggestions && shouldDoFurtherNavigate) {
      history.replace(`/properties/${form.values.propertyNo!}`);
    }
  };

  const suggestionView = <FormControl component="fieldset">
    {/* <FormLabel component="legend">Gender</FormLabel> */}
    <RadioGroup name="approval-suggestion" value={selectedApprovalSuggestion} onChange={(ev) => setSelectedApprovalSuggestion(ev.target.value)}>
      <FormControlLabel name="approval-suggestion" value="" control={<Radio />} label={canApprove ? langPropertyStock.msgApprovalSuggestionNoActionResolve : langPropertyStock.msgApprovalSuggestionNoActionPending} />
      {form.values.approvalSuggestions?.includes(1) ? <FormControlLabel name="approval-suggestion" value="1" control={<Radio />}
        label={canApprove ? langPropertyStock.msgApprovalSuggestionToStage1Resolve : langPropertyStock.msgApprovalSuggestionToStage1Pending} />
        : null}
      {form.values.approvalSuggestions?.includes(2) ? <FormControlLabel name="approval-suggestion" value="2" control={<Radio />}
        label={langPropertyStock[`msgApprovalSuggestionToStage2${canApprove ? 'Resolve' : 'Pending'}${form.values.published ? '' : 'FirstTime'}`]} />
        : null}
    </RadioGroup>
  </FormControl>;

  // useEffect(() => {
  //   const cannotGoToEditPage = form.values.isLeadAgent === false && canReadUnowned === false && isBuyerAgent === false && isTenantAgent === false && Boolean(closeTransactionDto) === false;
  //   if (form.values.propertyNo && cannotGoToEditPage) {
  //     history.replace(`/properties/${form.values.propertyNo!}`);
  //   }
  // }, [ form.values.propertyNo, form.values.isLeadAgent, canReadUnowned, currentSalePurchaseAgreement, currentTenancyAgreement, closeTransactionDto ]);

  useEffect(() => {
    if (form.values.approvalSuggestions?.length) {
      // Load second or more time
      // 
      setApprovalSuggestionDialogOpen(true);
    }

  }, [form.values.approvalSuggestions]);

  useEffect(() => {
    if (form.values.propertyNo) {
      dispatch({ type: 'Property.FetchByPropertyNoRequested', payload: { propertyNo: form.values.propertyNo ?? '' } });
    }
  }, [closeTransactionDto])

  const validForm = (fileForm: FormPresenter<PropertyFileMap>) => {
    let tempForm: FormPresenter<PropertyFileMap> = {
      ...fileForm,

      values: {
        FORM_1: fileForm.values.FORM_1?.filter(f => f.dataStatus === 'A'),
        FORM_2: fileForm.values.FORM_2?.filter(f => f.dataStatus === 'A'),
        FORM_3: fileForm.values.FORM_3?.filter(f => f.dataStatus === 'A'),
        FORM_5: fileForm.values.FORM_5?.filter(f => f.dataStatus === 'A'),
        LAND_SEARCH: fileForm.values.LAND_SEARCH?.filter(f => f.dataStatus === 'A'),
        PROVISIONAL_AGREEMENT: fileForm.values.PROVISIONAL_AGREEMENT?.filter(f => f.dataStatus === 'A'),
      }
    };
    return tempForm;

  }

  const validClientForm = (clientFileForm?: FormPresenter<ClientFileMap>) => {

    if (isNonEmpty(clientFileForm)) {
      let tempForm = {
        ...clientFileForm,
        values: {
          FORM_4: clientFileForm?.values.FORM_4?.filter(f => f.dataStatus === 'A'),
          FORM_6: clientFileForm?.values.FORM_6?.filter(f => f.dataStatus === 'A'),
        }
      };
      return tempForm;
    }
  }
  const fileFormForCloseTrnx = isAdminOrOfficeAdmin ? fileForm : validForm(fileForm);
  const formPaTableForCloseTrnx = <Documents noCardView saveDisabled fileForm={fileFormForCloseTrnx} clientFileForm={validClientForm(clientFileForm)} stockForm={form} canDownloadOrDeleteFormFiles={canDownloadOrDeleteFormFiles} canDownloadPaFiles={canDownloadPaFiles} canUploadPropertyDocFiles={false} isPreviewPropertyStockPage
    showAllFormsDownloadAnyway={isAdminOrOfficeAdmin}
    canEditPa={false} canEditForm={false}
    docTypeOptions={formsAndPaOptions}
    isPaAgent={isPaAgent}
    sectionTitle={langPropertyStock.titleFormAndAgreement}
    elementIdPrefix={'form-and-pa-section'}
    isClientPage={false} hideRemarksInListTable={true}
    canDownloadUnownedPa={canReadUnowned}
    showForm46={false}
    showForm35={false}
    isFormPaTableForCloseTrnx={true}
    teamMemberList={teamMembers}
  />;

  return (
    <div className={classes.root}>
      {confirmDialog.render()}
      {alertDialog.render()}
      <ConfirmDialog
        title={form.values.published ? langPropertyStock.msgApprovalSuggestion : langPropertyStock.msgApprovalSuggestionFirstTime}
        message={suggestionView}
        actionConfirm={lang.actionConfirm}
        actionCancel={lang.actionCancel}
        open={approvalSuggestionDialogOpen && form.values.leadAgents?.length === 1}
        onClose={handleApprovalSuggestion}
      />
      <DeleteConfirmDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={() => {
          dispatch({
            type: 'Property.RemoveRequested',
            payload: {
              id: form.values.id ?? ''
            }
          });
          setDeleteDialogOpen(false);
        }}
        form={form}
        isUnsaved={unsaved ? true : false}
      />
      <ApprovalDialog
        form={form}
        checkResult={checkResult}
        open={approvalDialogOpen}
        onClose={() => setApprovalDialogOpen(false)}
        onResolve={() => {
          dispatch({
            type: 'Property.ApprovalRequested', payload: {
              id: form.values.id!,
              stage: willApproveTo,
              action: 'RESOLVE',
            }
          });
          setApprovalDialogOpen(false);
        }}
        onReject={(rejectionReason) => {
          dispatch({
            type: 'Property.ApprovalRequested', payload: {
              id: form.values.id!,
              stage: willApproveTo,
              action: 'REJECT',
              rejectionReason,
            }
          });
          setApprovalDialogOpen(false);
        }}
      />
      <CloseTransactionDialog propertyNo={form.values.propertyNo ?? ''} propertyStockId={form.values.id ?? ''} open={ctDialogOpen} handleClickOpen={() => setCtDialogOpen(true)} handleClose={() => setCtDialogOpen(false)} formPaTable={formPaTableForCloseTrnx} />
      <FullScreenDialog selectedFormNo={selectedIndex} propertyNo={form.values.propertyNo ?? ''} propertyStockId={form.values.id ?? ''} form={form} open={openFormDialog} handleClickOpen={handleClickOpenFormDialog} handleClose={handleCloseFormDialog} landsearchdate={JSON.stringify(fileForm.values.LAND_SEARCH?.map(x => x.date)).replace(/]|[[]|["]|/g, '')} />
      <ProvisionalAgreementDialog fileForm={fileForm}
        salePaAccessControls={salePaAccessControls} tenanctPsAccessControls={tenancyPaAccessControls}
        selectedAgreementNo={selectedPaIndex} propertyStockId={form.values.id ?? ''} form={form} open={openPaDialog}
        handleClickOpen={handleClickOpenPaDialog} handleClose={handleClosePaDialog} isLeadAgent={isLeadAgent ?? false} canEditPa={canEditPa} />
      <NavigationBar title={creating ? langPropertyStock.actionNewPropertyStock : (langPropertyStock.titlePropertyDetail + (unsaved ? '*' : ''))}>
        {/* <When test={creating}>
          <Button style={{ marginRight: 5 }} variant="contained" disabled={tabIndex === 2} onClick={() => setTabIndex(tabIndex + 1)}>{lang.actionNextStep}</Button>
        </When> */}
        <When test={!shouldCollapse && !creating}>
          <Button startIcon={<DeleteIcon />} style={{ marginRight: 5, marginBottom: 2 }} disabled={!form.values.canDeletePropertyStock || form.values.isOrphan} color="default" variant="text" onClick={() => setDeleteDialogOpen(true)}>{langPropertyStock.actionDeletePropertyStock}</Button>
        </When>
        <When test={!shouldCollapse && !creating}>
          <Button style={{ marginRight: 5, marginBottom: 2 }} startIcon={<CloseDealIcon />} disabled={!Boolean(closeTransactionDto) || !canOpenCloseTransaction || fileForm.values.PROVISIONAL_AGREEMENT?.filter(form => form.dataStatus === 'A').length === 0 || unsaved || form.values.isOrphan} color="default" variant="text" onClick={() => setCtDialogOpen(true)}>{langPropertyStock.actionCloseTransaction}</Button>
        </When>

        {/* form Menu */}
        <When test={!shouldCollapse && !creating}>
          <Button
            style={{ marginRight: 5, marginBottom: 2 }}
            color="default" variant="text"
            onClick={formMenu.open}
            buttonRef={formMenu.anchorEl}
            startIcon={<ListAltIcon />}
            disabled={!canEditForm || form.values.isOrphan || (form.values.canReadCompanyStock && form.values.companyStock !== true) || (form.values.canReadUnowned && form.values.companyStock !== true) || unsaved}
          >{selectFormBtnMenuList[0]}</Button>
        </When>

        <Menu {...formMenu.menuProps()} >
          <ListSubheader>{selectFormBtnMenuList[0]}...</ListSubheader>
          {formMenuItems()}
        </Menu>

        {/* PA Menu */}
        <When test={!shouldCollapse && !creating}>
          <Button
            style={{ marginRight: 5, marginBottom: 2 }}
            color="default" variant="text"
            onClick={paMenu.open}
            buttonRef={paMenu.anchorEl}
            startIcon={<ListAltIcon />}
            disabled={form.values.isOrphan || (!isStatusSale() && !isStatusRent()) || unsaved}
          >{selectPaBtnMenuList[0]}</Button>
        </When>

        <Menu {...paMenu.menuProps()} >
          <ListSubheader>{selectPaBtnMenuList[0]}...</ListSubheader>
          {paMenuItems()}
        </Menu>

        {/* Memo Button */}
        <When test={!shouldCollapse && !creating}>
          <Button disabled={saveDisabled || unsaved || !canAddMemo} startIcon={<NoteAddIcon />} style={{ marginRight: 5, marginBottom: 2 }} color="default" variant="text" onClick={() => setMemoDialogOpen(true)}>{lang.actionAddMemo}</Button>
        </When>

        {/* Status Menu */}
        <When test={!shouldCollapse && !creating}>
          <Button
            style={{ marginRight: 5, marginBottom: 2 }}
            color="default" variant="text"
            onClick={statusMenu.open}
            buttonRef={statusMenu.anchorEl}
            startIcon={<EditPsStatusIcon />}
            disabled={(Boolean(closeTransactionDto) && closeTransactionDto.status !== 'A' && isNonEmpty(validForm(fileForm).values.PROVISIONAL_AGREEMENT)) || form.values.status === 'SOLD' || form.values.status === 'LEASED' || unsaved || saveDisabled}
          >{langPropertyStock.actionChangeStatus}</Button>
        </When>

        <Menu {...statusMenu.menuProps()} >
          <ListSubheader>{langPropertyStock.statusChangeHeading}...</ListSubheader>
          {statusMenuItems(statusMenu)}
        </Menu>

        {/* Approval Menu */}
        <When test={!shouldCollapse && !creating}>
          <Button
            {...{ component: "span" }}
            disabled={(saveDisabled && !canApprove) || form.values.isOrphan || checkResult.hasPendingForApprovalOrIsOpen || unsaved}
            onClick={approvalMenu.open}
            buttonRef={approvalMenu.anchorEl}
            variant="text"
            style={{
              color: (checkResult.hasPendingForApprovalOrIsOpen && form.values.approvalStage === 0) || unsaved
                ? colors.grey[500]
                : approvalStage === 0 && pendingApprovalStage === 0 || ((form.values.status === "BOTH" && form.values.unpublishedDueToForm3 && form.values.unpublishedDueToForm5
                  || form.values.status === "SALES" && form.values.unpublishedDueToForm3 || form.values.status == "RENT" && form.values.unpublishedDueToForm5) && pendingApprovalStage === 0)
                  ? 'unset'
                  : pendingApprovalStage !== 0
                    ? colors.yellow[800]
                    : colors.green[800]
            }}
            color={approvalStage === 0 ? 'default' : 'inherit'}
            startIcon={approvalStageMap[approvalStage].icon}
          >
            {pendingApprovalStage !== 0 ? approvalStageMap[pendingApprovalStage].title : approvalStageMap[approvalStage].title}
          </Button>
        </When>

        <Menu {...approvalMenu.menuProps()} disablePortal>
          <ListSubheader>{canApprove ? langPropertyStock.actionApprove : langPropertyStock.actionRequestForApproval}...</ListSubheader>
          {approvalMenuItems(approvalMenu)}
        </Menu>

        {/* Claim Button. Always display as toolbar icon */}
        {/* <When test={form.values.canClaim}>
          <Button
            // startIcon={<SaveIcon />}
            disabled={form.values.isOrphan}
            color="primary" variant="text"
            onClick={() => dispatch({ type: 'Property.ClaimRequested', payload: { id: form.values.id ?? '' } })}
          >
            {langPropertyStock.actionClaimProperty}
          </Button>
        </When> */}

        {/* Save Button. Always display as toolbar icon */}
        <Button
          startIcon={<SaveIcon />}
          className={clsx(unsaved && classes['unsavedBtn'])}
          style={{ marginBottom: !creating ? 2 : 0, fontWeight: unsaved ? 'bold' : 'normal' }}
          color="primary" disabled={saveDisabled} variant="text"
          onClick={onSaveWithPrompt}
        >
          {lang.actionSave}{unsaved ? '*' : ''}
        </Button>

        <When test={shouldCollapse && !creating}>
          <IconButton {...collapseMenu.buttonProps()}>
            <MoreVertIcon />
          </IconButton>
        </When>

        {/* Collapse Menu */}
        <Menu {...collapseMenu.menuProps()}>
          {/* delete button  */}
          {!creating ?
            <MenuItem disabled={!form.values.canDeletePropertyStock || form.values.isOrphan} onClick={() => setDeleteDialogOpen(true)}>
              <ListItemIcon style={{ minWidth: 30 }}><DeleteIcon /></ListItemIcon>
              {langPropertyStock.actionDeletePropertyStock}
            </MenuItem>
            : null}
          {/* Close Deal Button */}
          <MenuItem disabled={!Boolean(closeTransactionDto) || !canOpenCloseTransaction || fileForm.values.PROVISIONAL_AGREEMENT?.filter(form => form.dataStatus === 'A').length === 0 || unsaved || form.values.isOrphan} onClick={() => setCtDialogOpen(true)}>
            <ListItemIcon style={{ minWidth: 30 }}><CloseDealIcon /></ListItemIcon>
            {langPropertyStock.actionCloseTransaction}
          </MenuItem>

          {/* Memo Button */}
          <MenuItem disabled={saveDisabled || unsaved || !canAddMemo} onClick={collapseMenu.withClosing(() => setMemoDialogOpen(true))}>
            <ListItemIcon style={{ minWidth: 30 }}><NoteAddIcon /></ListItemIcon>
            {lang.actionAddMemo}
          </MenuItem>

          {/* Form Sub-menu */}
          <ListSubheader>{selectFormBtnMenuList[0]}</ListSubheader>
          {formMenuItems()}

          {/* PA Sub-menu */}
          <ListSubheader>{selectPaBtnMenuList[0]}</ListSubheader>
          {paMenuItems()}

          {/* Approval Sub-menu */}
          <ListSubheader>{canApprove ? langPropertyStock.actionApprove : langPropertyStock.actionRequestForApproval}...</ListSubheader>
          {approvalMenuItems(collapseMenu)}

          {/* Status Sub-menu */}
          <ListSubheader>{langPropertyStock.statusChangeHeading}</ListSubheader>
          {statusMenuItems(collapseMenu)}
        </Menu>

      </NavigationBar>

      <MemoNew onClose={() => setMemoDialogOpen(false)} isOpen={memoDialogOpen} form={memoForm} onSave={() => {
        if (memoForm.values?.content) {
          dispatch({
            type: 'Memo.CreateRequested', payload: {
              id: form.values.id ?? '',
              memo: memoForm.values as MemoDTO,
            }
          } as PropertyActions);
          setMemoDialogOpen(false);
          memoForm.setValues({
            ...memoForm.values,
            content: ''
          });
        }
      }} />

      <FixedTabsContainer>
        <StyledTabs
          value={tabIndex} onChange={(_, val) => {
            setTabIndex(val);
            tabScrolls[val]?.current?.();
          }} scrollButtons="auto" variant="scrollable">
          <StyledTab style={{ color: tab1HasError ? 'red' : 'inherit' }} label={langPropertyStock.captionBasicInfo} />
          <StyledTab label={langPropertyStock.titlePropertyFeatures} />
          <StyledTab label={langPropertyStock.titleMgtInfo} />
          <StyledTab label={langPropertyStock.titleUploadFiles} />
          {
            !creating ? <StyledTab label={lang.memos} /> : null
          }
        </StyledTabs>
        <Divider />
      </FixedTabsContainer>

      <TabPanel scrollRef={scrollRef0} index={0} onScrollIn={onScrollIn}>
        <Grid
          container
          // md={12} sm={12}
          // xs={12}
          spacing={1}
        >
          {/* Rejection Remarks */}
          <When test={!!form.values.rejectedApprovalStage && !!form.values.approvalRejectionReason}>
            <Grid item md={12} sm={12} xs={12}>
              <Card style={{}}>
                <CardContent>
                  <Typography color="error">
                    {langPropertyStock.captionRejectionReason}
                    {form.values.approvalRejectionReason}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </When>

          <Grid style={{ height: 'auto' }} // 405
            item
            md={6} sm={12}
            xs={12}
          >
            <PropertyBasicInfo isNew={creating ?? false} form={form} tagForm={tagForm} editDisabled={saveDisabled} hideFloor={hideFloor} floorUnitOptions={floorUnitOptions} getCheckDuplicateResult={getCheckDuplicateResult} getPropertyPreviousTransactionData={getPropertyPreviousTransactionData} isSameNet={isSameNet} isSameGross={isSameGross} />
          </Grid>

          <Grid style={{ height: 'auto' }} // 405
            item
            md={6} sm={12}
            xs={12}
          >
            <SaleBasicInfo isNew={creating ?? false} editDisabled={!!saveDisabled} form={form} tagForm={tagForm} />
          </Grid>
          {
            (isLeadAgent || canReadTeamProperty || canReadUnowned || (form.values.companyStock === true && canReadCompanyStock)) || creating ?
              <Grid item md={6} sm={6} xs={12}>
                <ContactInfo form={form} creating={creating} isSameOwner={isSameOwner} />
              </Grid>
              :
              null
          }
          {creating || form.values.companyStock === true ? null :
            <Grid item md={6} sm={6} xs={12}>
              <LeadAgentPanel
                form={form}
                wishlistGrantedAgents={wishlistGrantedAgents}
                onWishlistGrantedAgentAdded={handleWishlistGrantedAgentAdded}
                onWishlistGrantedAgentRemoved={handleWishlistGrantedAgentRemoved}
              />
            </Grid>
          }
        </Grid>
      </TabPanel>

      <TabPanel title={langPropertyStock.titlePropertyFeatures} scrollRef={scrollRef1} index={1} onScrollIn={onScrollIn}>
        <Grid
          container
          // md={12} sm={12}
          spacing={1}
        >
          <Grid item md={12} sm={12} xs={12} /*spacing={1}*/>
            <PropertyFeatures form={form} tagForm={tagForm} fileForm={fileForm} unsaved={unsaved ?? false} onSave={onSave} saveDisabled={saveDisabled ?? false} isNew={creating ?? false} />
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel title={langPropertyStock.titleMgtInfo} scrollRef={scrollRef2} index={2} onScrollIn={onScrollIn}>
        <Grid
          container
          // md={12} sm={12}
          spacing={1}
        >
          <Grid item md={12} sm={12} xs={12} /*spacing={1}*/>
            <PropertyManagementInfo form={form} tagForm={tagForm} fileForm={fileForm} clientFileForm={clientFileForm} creating={creating} canDownloadOrDeleteFiles={canDownloadOrDeleteFormFiles} />
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel title={langPropertyStock.titleUploadFiles} scrollRef={scrollRef3} index={3} onScrollIn={onScrollIn}>
        <Grid
          container
          // md={12} sm={12}
          spacing={1}
        >
          {/* Search Docs */}
          <Grid item md={12} sm={12} xs={12} /*spacing={1}*/>
            <Documents saveDisabled={saveDisabled} fileForm={fileForm} clientFileForm={clientFileForm} stockForm={form} canDownloadOrDeleteFormFiles={canDownloadOrDeleteFormFiles} canDownloadPaFiles={canDownloadPaFiles} canUploadPropertyDocFiles={canUploadPropertyDocFiles}
              canEditPa={canEditPa} canEditForm={canEditForm} docTypeOptions={documentOptions} isPaAgent={isPaAgent} sectionTitle={langPropertyStock.titleFile} elementIdPrefix={'ps-doc-section'} isClientPage={false} isCreatePage={creating} teamMemberList={teamMembers} />
          </Grid>


          {/* Forms & Agreements */}
          <Grid item md={12} sm={12} xs={12} /*spacing={1}*/>
            <Documents saveDisabled={saveDisabled} fileForm={fileForm} clientFileForm={clientFileForm} stockForm={form} shouldDisableUpload={creating ? true : false} canDownloadOrDeleteFormFiles={canDownloadOrDeleteFormFiles} canDownloadPaFiles={canDownloadPaFiles} canUploadPropertyDocFiles={canUploadPropertyDocFiles}
              canEditPa={canEditPa} canEditForm={canEditForm} docTypeOptions={formsAndPaOptions} isPaAgent={isPaAgent} sectionTitle={langPropertyStock.titleFormAndAgreement} elementIdPrefix={'form-and-pa-section'} isClientPage={false} hideRemarksInListTable={true}
              canDownloadUnownedPa={canReadUnowned} teamMemberList={teamMembers}
            />
          </Grid>
        </Grid>
      </TabPanel>

      <When test={!creating && (form.values.isLeadAgent || form.values.canReadTeamProperty || form.values.canReadCompanyStock || form.values.canReadUnowned)}>
        <TabPanel title={lang.memos} scrollRef={scrollRef4} index={4} onScrollIn={onScrollIn}>
          <Grid
            container
            // md={12} sm={12}
            // xs={12}
            spacing={1}
          >
            <Memo memos={memos} />
          </Grid>
        </TabPanel>
      </When>
    </div>
  );
};

export default PropertyDetail;

interface ApprovalDialogProps {
  onClose: () => any;
  open: boolean;
  onResolve: () => any;
  onReject: (remarks: string) => any;
  checkResult: Partial<CheckDuplicatedPropertyStockResultDTO>;
  form: FormPresenter<PropertyStockDTO>;
}

function ApprovalDialog(props: ApprovalDialogProps) {
  const { lang, langPropertyStock } = useSelector((state: IRootState) => state.locale);
  const { onClose, open, onResolve, onReject } = props;

  const [approvalAction, setApprovalAction] = useState('RESOLVE');
  const [rejectionReason, setRejectionReason] = useState('');

  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const handleConfirm = () => {
    setOpenWarningDialog(false);
  };

  return (
    <div>
      <WarningDialogForCreatingExisingPropertyStock forEditing
        form={props.form}
        openDialog={openWarningDialog}
        handleConfirm={handleConfirm}
        setOpenDialog={setOpenWarningDialog}
        checkResult={props.checkResult}
        fromWarningDialog={true}
      />
      <Dialog onClose={onClose} open={open}>
        <DialogTitle>{langPropertyStock.msgApprovalOrReject}</DialogTitle>
        <DialogContent>
          <Grid container /*md={12} sm={12} xs={12}*/ spacing={1}>
            <Grid item md={12} sm={12} xs={12}>
              <RadioGroup defaultValue="" aria-label="payment time" name="customized-radios" row
                value={approvalAction}
                onChange={(_, val) => setApprovalAction(val)}
              >
                <FormControlLabel value="RESOLVE" control={<Radio />} label={langPropertyStock.actionApprove} />
                <FormControlLabel value="REJECT" control={<Radio />} label={langPropertyStock.actionReject} />
              </RadioGroup>
            </Grid>
            {approvalAction === 'REJECT' ? <Grid item md={12} sm={12} xs={12}>
              <TextField
                label={langPropertyStock.captionPleaseInputRejectionReason}
                fullWidth margin="dense" variant="outlined"
                value={rejectionReason}
                onChange={(ev) => setRejectionReason(ev.target.value)}
              />
            </Grid> : null}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()} color="primary">
            {lang.actionCancel}
          </Button>
          <Button disabled={!isNonEmpty(rejectionReason) && approvalAction === 'REJECT'} onClick={() => {

            if (approvalAction === 'REJECT') {
              onReject(rejectionReason.trim());
            } else if (!props.checkResult.duplicated) {
              onResolve();
            } else {
              setOpenWarningDialog(true);
              onClose();
            }
          }} variant="contained" color="primary">{lang.actionConfirm}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}