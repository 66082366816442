import { Grid, makeStyles, Typography } from '@material-ui/core';
import usePageTitle from 'common/hooks';
import React, { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState, PASDispatch } from 'reducers';
import JobCard, { JobCardProps } from './components/JobCard';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  search: {
    marginBottom: theme.spacing(2)
  },
  title: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
}));

const EXAMPLE_DATA: JobCardProps[] = [
  {
    jobName: 'dataFeedStart',
    display: 'Transaction Importer',
    schedule: '7am @everyday',
    intervalMin: 60 * 24,
    lastStartTime: '2021-07-16T07:00:00',
    lastEndTime: '2021-07-16T07:21:36',
    status: 'FAILED',
  },
  {
    jobName: 'dashboardAggregate',
    display: 'Databoard Aggregator',
    schedule: '6am @everyday',
    intervalMin: 60 * 24,
    lastStartTime: '2021-07-19T06:00:00',
    lastEndTime: undefined,
    status: 'RUNNING',
    expectedMaxRunningMin: 30,
  },
  {
    jobName: 'clientClaim',
    display: 'Client Claim',
    schedule: '@every 5min',
    // intervalMin: 5,
    lastStartTime: '2021-07-16T12:00:00',
    lastEndTime: '2021-07-16T12:00:12',
    status: 'SUCCEED',
  },
];

const JobMonitor = () => {
	
	const classes = useStyles();
  const { langJobMonitor } = useSelector((root: IRootState) => root.locale);
  const dispatch = useDispatch() as PASDispatch;
  const jobs = useSelector((root: IRootState) => root.jobMonitor.jobs);

  usePageTitle(langJobMonitor.titleJobMonitor);
  
  useLayoutEffect(() => {
    dispatch({ type: 'JobMonitor.FetchRequested' });
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      dispatch({ type: 'JobMonitor.FetchRequested' });
    }, 1000 * 10);

    return () => clearInterval(timer);
  }, []);

  const data = jobs;

  return <div className={classes.root}>
    <div className={classes.title} onClick={() => dispatch({ type: 'JobMonitor.FetchRequested' })}>
      <Typography variant="h2">{langJobMonitor.titleJobMonitor ?? 'Job Monitor'}</Typography>
    </div>
    <Grid container spacing={1}>
      {data.map(job => <Grid key={job.jobName} item md={6} xs={12}>
        <JobCard {...job} />
      </Grid>)}
    </Grid>
  </div>

};

export default JobMonitor;