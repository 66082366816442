import { Grid, makeStyles, Button as MuiButton } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import clsx from 'clsx';
import { UserDTO } from 'common/dto';
import usePageTitle, { FormPresenter } from 'common/hooks';
import { ChangePasswordIcon, tooltipHoc } from 'common/ui';
import NavigationBar from 'layouts/Main/components/Topbar/NavigationBar';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';
import UserBasic from './components/UserBasic';
import UserContacts from './components/UserContacts';
import UserPreference from './components/UserPreference';

// #region Tab Page Common UI 

const Button = tooltipHoc(MuiButton);

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  unsavedBtn: {
    color: '#FFBF00',
    fontWeight: 'bold'
  }
}));

// #endregion Tab Page Common UI

export interface UserDetailProps {
  creating: boolean;
  onSave: () => void;
  form: FormPresenter<UserDTO>;
  profileDisabled?: boolean;
  infoDisabled?: boolean;
  unsaved?: boolean;
}

const UserDetail = ({ creating, unsaved, onSave, form, profileDisabled, infoDisabled }: UserDetailProps) => {
  const { langUser, lang } = useSelector((state: IRootState) => state.locale);
  const saveDisabled = false;
  const classes = useStyles();

  usePageTitle(langUser.titleUserDetail);
  // Change Password
  const [passwordChangeDialogOpen, setPasswordChangeDialogOpen] = useState(false);

  return (
    <div className={classes.root}>
      <NavigationBar title={creating ? langUser.actionNewUser : langUser.titleUserDetail}>
        <Grid container lg={6} md={6} xl={6} xs={6} justify="flex-end" style={{ display: 'contents' }}>
          {creating || profileDisabled ? null :
            <Grid item>
              <Button startIcon={<ChangePasswordIcon />} color="primary" variant="text" onClick={() => setPasswordChangeDialogOpen(true)}>{langUser.actionChangePassword}</Button>
            </Grid>
          }
          <Grid item>
            <Button color="primary" className={clsx(unsaved && classes['unsavedBtn'])} style={{ fontWeight: unsaved ? 'bold' : 'normal' }} disabled={saveDisabled} variant="text" startIcon={<SaveIcon />} onClick={onSave}>{lang.actionSave}{unsaved ? '*' : ''}</Button>
          </Grid>
        </Grid>
      </NavigationBar>

      <Grid container md={12} xs={12} spacing={1}>
        <Grid
          item
          md={6}
          xs={12}
        >
          <UserBasic creating={creating} form={form} profileDisabled={profileDisabled} infoDisabled={infoDisabled} passwordChangeDialogOpen={passwordChangeDialogOpen} setPasswordChangeDialogOpen={setPasswordChangeDialogOpen} />
        </Grid>

        <Grid
          item
          md={6}
          xs={12}
        >
          <UserContacts form={form} canEdit={!profileDisabled || !infoDisabled} />
        </Grid>
        {
          !creating && !profileDisabled ?
            <Grid
              item
              md={12}
              xs={12}
            >
              <UserPreference form={form} />
            </Grid> : null
        }

      </Grid>

    </div>
  );
};

export default UserDetail;