import {
  Button as MuiButton, Card,
  CardActions,
  CardContent, CardHeader, Checkbox, colors, Divider, FormControl, IconButton as MuiIconButton, MenuItem, Popover, Table,
  TableBody,
  TableCell,
  TableHead, TableRow, TableSortLabel, TextField,
  // TablePagination,
  Theme, Typography, useMediaQuery
} from '@material-ui/core';
import { CardProps } from '@material-ui/core/Card';
import ViewIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useConfirmDialog } from 'common/ConfirmDialog';
import { PropertyListItemDTO } from 'common/dto';
import DataRow from 'common/elements/DataRow';
import TablePagination from 'common/elements/TablePagination';
import { useInfoDialog } from 'common/InfoDialog';
import { AddIcon, InfoIcon, tooltipHoc } from 'common/ui';
import { handlePriceDisplay, handlePriceDisplayUnit, isNonEmpty, multiLang } from 'common/utils';
// import PropTypes from 'prop-types';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { IRootState, PASDispatch } from 'reducers';
import FullScreenDialogForm4And6 from 'views/ClientDetail/components/FullScreenDialogForm4And6';
import PropertySummaryReportDialog from '../PropertySummaryReportDialog';




const IconButton = tooltipHoc(MuiIconButton);
const Button = tooltipHoc(MuiButton);

// const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];
const PUBLIC_URL = process.env['PUBLIC_URL'];

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-start',
    background: colors.grey[50],
    flexWrap: 'wrap',
    '& > *': {
      wordBreak: 'keep-all',
      // marginBottom: '8px',
    },
    paddingTop: '0.5rem',
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableCell: {
    wordWrap: 'normal',
    wordBreak: 'keep-all',
    lineBreak: 'strict',
    whiteSpace: 'nowrap',
    // minWidth: 128,
  },
  tablePaginationIconButton: {
    '& .MuiIconButton-root': {
      padding: '6px',
    }
  },
  tablePaginationToolbar: {
    paddingRight: '6px',
  },
  clearSortingContainer: {
    padding: '3px',
  },
  approvalStageUnapproved: {
    backgroundColor: '#F0F0F0'
  },
  approvalStageMatching: {
    backgroundColor: '#F9E885'
  },
  approvalStageClientApp: {
    backgroundColor: '#CBF8C7',
  },
  legendGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem'
  },
  legend: {
    height: '1rem',
    width: '1rem',
    border: '1px solid #A0A0A0'
  },
  legendCaption: {
    fontSize: '1rem',
    paddingLeft: '0.4rem'
  }
}));

type Header = {
  title: string | JSX.Element,
  overrideStyle?: any,
};

interface PropertiesMatchingTableProps extends CardProps, PAS.WishlistVariant {
  className?: string;
  properties: PropertyListItemDTO[];
  matchingClient?: any;
  // {
  //   name: string;
  // } | null;
  onCancelPropertyMatching?: () => void;
  handleUpdateStockCart?: (id: string[]) => any;
  handleDeleteStockCart?: (id: string[]) => any;
  formSigned?: boolean;
  setFormSigned?: (v: boolean) => void;

  page: number;
  totalPages: number;
  totalElements: number;
  limit: number;
  setPage: (page: number) => void;
  sortOrders?: { [k: string]: 'desc' | 'asc' };
  handleSort?: (key: string | null) => void;
  rowsCountOptions: number[];
  handleRowsCount: (count: number) => void;
  //for form 4 and 6
  cid?: string;
  fileForm?: any;
  unsaved?: boolean;
  clientuploadform4or6?: boolean;
  isSellerStockPage?: boolean;
  onSave?: () => void;
  //for wishlist
  initialStockCartFilterOption?: string; //'SALES' / 'RENT'
  stockCartFilterOption?: string | undefined;
  setStockCartFilterOption?: React.Dispatch<React.SetStateAction<string | undefined>>;
}

type ColumnName = string; type ColumnPreferenceKey = string;
const columnNameToColumnPreferenceMapping: Record<ColumnName, ColumnPreferenceKey> = {
  id: 'id',
  dateCreated: 'dateCreated',
  dateModified: 'dateModified',
  status: 'status',
  'property.usage': 'usage',
  'property.streetZh': 'street',
  'property.streetEn': 'street',
  'property.buildingNameZh': 'building',
  'property.buildingNameEn': 'building',
  price: 'price',
  'property.blockZh': 'block',
  'property.blockEn': 'block',
  'property.floor': 'floor',
  'property.unit': 'unit',
  gross: 'gross',
  net: 'net',
  pricePerGross: 'pricePerGross',
  pricePerNet: 'pricePerNet',
  owner: 'owner',
  mainContact: 'mainContact',
  'property.lot': 'lot',
  symbol: 'symbol',
  keyNo: 'keyNo',
  vacant: 'vacant',
  occupancyPermitAge: 'occupancyPermitAge',
};



const PropertiesMatchingTable = (props: PropertiesMatchingTableProps) => {
  const { className, properties, matchingClient, onCancelPropertyMatching, handleUpdateStockCart, handleDeleteStockCart,
    wishlistVariant, formSigned, setFormSigned, sortOrders, handleSort, page, totalPages, totalElements, limit, setPage,
    cid, fileForm, isSellerStockPage, rowsCountOptions, handleRowsCount, initialStockCartFilterOption, stockCartFilterOption, setStockCartFilterOption, clientuploadform4or6, unsaved, onSave, ...rest } = props;
  const { locale, levelOptions, usageOptions, propertyStockStatusOptions, dormancyOptions, vacantOptions, symbolOptions, langPropertyStock: lang, lang: langCommon, langClientDetail, langUser } = useSelector((state: IRootState) => state.locale);
  const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);

  const isDormant = useSelector((state: IRootState) => state.property.lastSearchFormValues?.isDormant ?? false);
  const canClaimBy = useSelector((state: IRootState) => state.property.lastSearchFormValues?.canClaimBy ?? false);

  const isRent = useSelector((state: IRootState) => state.property.lastSearchFormValuesForPropertiesMatching?.isRent ?? false);

  const { columnsOrder, disabledColumns } = useSelector((state: IRootState) => state.property);

  const [colOrders, setColOrders] = useState<string[]>([]);

  const classes = useStyles();

  const history = useHistory();

  const dispatch = useDispatch() as PASDispatch;

  const confirmDialog = useConfirmDialog();

  const shouldCollapse = useMediaQuery('(max-width: 900px)');

  useEffect(() => {
    dispatch({ type: 'Property.ColumnPreference.FetchRequested' });
  }, []);

  const [selectedProperties, setSelectedProperties] = useState<number[]>([]);
  const selectedIncludedInCurrentProperties = selectedProperties.length > 0 && selectedProperties.filter(sp => properties.map(p => p.id).includes(sp)).length > 0;
  const selectedIncludesAllCurrentProperties = selectedProperties.length > 0 && selectedProperties.filter(sp => properties.map(p => p.id).includes(sp)).length >= properties.length;
  const selectedIncludesNotGrantedProperties = selectedProperties.length > 0 && !selectedProperties.every(pid => properties.filter(p => p.id === pid)?.[0]?.fullAddressAvailable);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { properties } = props;

    // Filter those not provided checkbox stock record
    const filteredProperties = properties.filter(p => p.status != 'PENDING' && p.status != 'SOLD' && p.status != 'LEASED')

    if (event.target.checked) {
      setSelectedProperties([...selectedProperties, ...filteredProperties.map(property => property.id!).filter(p => !selectedProperties.includes(p))]);
    } else {
      setSelectedProperties(selectedProperties.filter(sp => !properties.map(property => property.id!).includes(sp)));
    }

  };

  const handleSelectOne = (event: React.MouseEvent, id: string) => {
    event.stopPropagation();
    const selectedIndex = selectedProperties.indexOf(+id);
    let newSelectedProperties: number[] = [];

    if (selectedIndex === -1) {
      newSelectedProperties = newSelectedProperties.concat(selectedProperties, +id);
    } else if (selectedIndex === 0) {
      newSelectedProperties = newSelectedProperties.concat(selectedProperties.slice(1));
    } else if (selectedIndex === selectedProperties.length - 1) {
      newSelectedProperties = newSelectedProperties.concat(selectedProperties.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedProperties = newSelectedProperties.concat(
        selectedProperties.slice(0, selectedIndex),
        selectedProperties.slice(selectedIndex + 1)
      );
    }
    // 
    setSelectedProperties(newSelectedProperties);
  };

  const navigationLink = (propertyNo: string) => `/properties/${propertyNo}`;

  const handleNavigateToItem = (propertyNo: string) => () => {
    // if(isCurrentUserStock){
    sessionStorage.setItem('lastClicked', propertyNo);
    history.push(navigationLink(propertyNo));
    // }
  }

  const handlePageChange = (event: React.MouseEvent | null, page: number) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // dispatch( { type: 'Login.RowCountPrefUpdate', payload: { PROPERTY_STOCK: +event.target.value}})
    handleRowsCount(+event.target.value);
  };

  const handleViewTransaction = (property: PropertyListItemDTO) => {
    dispatch({
      type: 'TransactionList.CriteriaUpdated', payload: {
        $replaced: true,
        page: 0,
        sort: {
          transactionDate: 'desc',
        },
        street: multiLang(locale, property.streetZh, property.streetEn) ?? '',
        building: multiLang(locale, property.buildingNameZh ?? property.buildingNameEn, property.buildingNameEn ?? property.buildingNameZh) ?? '',
        block: multiLang(locale, property.blockZh ?? property.blockEn, property.blockEn ?? property.blockZh) ?? '',
        level: property.level ? [property.level] : [],
      }
    });
    history.push(`/transactions`);
  }


  const tableBodyStyle: { [key: string]: React.CSSProperties } = {
    dateCreated: { textAlign: 'right' },
    dateModified: { textAlign: 'right' },
    price: { textAlign: 'right' },
    gross: { textAlign: 'right' },
    net: { textAlign: 'right' },
    pricePerGross: { textAlign: 'right' },
    pricePerNet: { textAlign: 'right' },
  }

  let tableHeaders: Record<any, Header | null> = { // keyof PropertyListItemDTO
    // checkboxes: <Checkbox
    //   checked={selectedProperties.length === properties.length}
    //   color="primary"
    //   indeterminate={
    //     selectedProperties.length > 0 &&
    //     selectedProperties.length < properties.length
    //   }
    //   onChange={handleSelectAll}
    // />,
    // id: null,
    // id: { title: '#' },
    propertyNo: { title: '#' },
    dateCreated: { title: lang.dateCreated },
    dateModified: { title: lang.dateModified },
    status: { title: lang.status },
    'property.usage': { title: lang.usage },
    [multiLang(locale, 'property.streetZh', 'property.streetEn')!]: { title: lang.street },
    [multiLang(locale, 'property.buildingNameZh', 'property.buildingNameEn')!]:
      { title: lang.buildingOrVillage },
    price: { title: (lang.price + ' / ' + lang.rent), overrideStyle: { minWidth: 128 } },
    // rent: { title: lang.rent, overrideStyle: { minWidth: 128 } },
    [multiLang(locale, 'property.blockZh', 'property.blockEn')!]: { title: lang.block },
    'property.floor': { title: lang.floor },
    'property.unit': { title: lang.unit },
    gross: { title: lang.gross },
    net: { title: lang.net },
    pricePerGross: { title: lang.pricePerGross },
    pricePerNet: { title: lang.pricePerNet },
    owner: { title: lang.sellerOrOwner },
    mainContact: { title: lang.mainContact },
    'property.lot': { title: lang.lot },
    symbol: { title: lang.symbol },
    keyNo: { title: lang.key },
    vacant: { title: lang.vacant },
    occupancyPermitAge: { title: lang.occupancyPermitAge }
  };

  // const tableHeadersSorted: Record<any, Header | null> = {};
  const [tableHeadersSorted, setTableHeadersSorted] = useState<Record<any, Header | null>>({});
  const selectColumnOrder = (columnName: string) => colOrders.indexOf(columnNameToColumnPreferenceMapping[columnName]);
  var tmp: any
  useEffect(() => {
    const sortedHeaders: Record<any, Header | null> = {};
    if (colOrders?.length > 0) {
      Object.keys(tableHeaders).sort((colA, colB) =>
        selectColumnOrder(colA) > selectColumnOrder(colB) ? 1 : -1
      ).forEach((colName) => sortedHeaders[colName] = tableHeaders[colName]);
    }

    setTableHeadersSorted(sortedHeaders);
  }, [colOrders ?? [], locale]);

  const overrideCols: { [col: string]: (property: PropertyListItemDTO) => any } = {
    propertyNo: (property) => property.propertyNo + `${property.photoAvailable == true ? "*" : ''}`,
    status: (property) => propertyStockStatusOptions[property.status ?? ''],
    'property.usage': (property) => usageOptions[property.usage],
    'property.floor': (property) => `${property.floor ? property.floor : (property.level?.split(',')?.map(l => levelOptions[l.trim()]).join(',') ?? '')}`, //  (${property.floor})
    [multiLang(locale, 'property.blockZh', 'property.blockEn')!]:
      (property) => multiLang(locale, property.blockZh, property.blockEn),
    [multiLang(locale, 'property.buildingNameZh', 'property.buildingNameEn')!]:
      (property) => multiLang(locale, property.buildingNameZh, property.buildingNameEn),
    [multiLang(locale, 'property.streetZh', 'property.streetEn')!]:
      (property) => multiLang(locale, property.streetZh, property.streetEn),
    'property.unit': (property) => property.unit,
    // gross: (property) => property.grossArea,
    price: (property) => priceRentColumnDisplay(property),
    pricePerGross: (property) => priceRentGrossColumnDisplay(property),
    pricePerNet: (property) => priceRentNetColumnDisplay(property),
    // net: (property) => property.netArea,
    owner: (property) => property.owner,
    'property.lot': (property) => property.lot,
    symbol: (property) => property.symbol?.map(s => symbolOptions[s]).join(','),
    vacant: (property) => vacantOptions[property.vacant],
    dateCreated: (property) => property.dateCreated ? moment(property.dateCreated).format(DISPLAY_DATE_FORMAT) : '',
    dateModified: (property) => property.dateModified ? moment(property.dateModified).format(DISPLAY_DATE_FORMAT) : '',
  }

  const priceRentColumnDisplay = (property: PropertyListItemDTO) => {
    const priceDisplay = !isNonEmpty(property.price) ? '-' : String(handlePriceDisplay(property.price ?? 0, locale)) + handlePriceDisplayUnit(property.price ?? 0, locale, langCommon);
    const rentDisplay = !isNonEmpty(property.rent) ? '-' : property.rent;
    switch (property.status) {
      //case 'SALES': return priceDisplay;
      //case 'SOLD': return priceDisplay + ' / -';
      //case 'RENT': return rentDisplay;
      //case 'LEASED': return '- / ' + rentDisplay;
      default:
        return priceDisplay + ' / ' + rentDisplay;
      // if (isNonEmpty(priceDisplay) && isNonEmpty(rentDisplay)) {
      //   return priceDisplay + ' / ' + rentDisplay;
      // } else if (isNonEmpty(priceDisplay)) {
      //   return priceDisplay;
      // } else if (isNonEmpty(rentDisplay)) {
      //   return rentDisplay;
      // } else return '';
    }
  }

  const priceRentGrossColumnDisplay = (property: PropertyListItemDTO) => {
    const priceDisplay = !isNonEmpty(property.pricePerGross) ? '-' : Math.round(property.pricePerGross);
    const rentDisplay = !isNonEmpty(property.rentPerGross) ? '-' : Math.round(property.rentPerGross);
    switch (property.status) {
      //case 'SALES': return priceDisplay;
      //case 'SOLD': return priceDisplay + ' / -';
      //case 'RENT': return rentDisplay;
      //case 'LEASED': return '- / ' + rentDisplay;
      default:
        return priceDisplay + ' / ' + rentDisplay;
      // if (isNonEmpty(priceDisplay) && isNonEmpty(rentDisplay)) {
      //   return priceDisplay + ' / ' + rentDisplay;
      // } else if (isNonEmpty(priceDisplay)) {
      //   return priceDisplay;
      // } else if (isNonEmpty(rentDisplay)) {
      //   return rentDisplay;
      // } else return '';
    }
  }

  const priceRentNetColumnDisplay = (property: PropertyListItemDTO) => {
    const priceDisplay = !isNonEmpty(property.pricePerNet) ? '-' : Math.round(property.pricePerNet);
    const rentDisplay = !isNonEmpty(property.rentPerNet) ? '-' : Math.round(property.rentPerNet);
    switch (property.status) {
      //case 'SALES': return priceDisplay + ' / -';
      //case 'SOLD': return priceDisplay;
      //case 'RENT': return '- / ' + rentDisplay;
      //case 'LEASED': return rentDisplay;
      default:
        return priceDisplay + ' / ' + rentDisplay;
      // if (isNonEmpty(priceDisplay) && isNonEmpty(rentDisplay)) {
      //   return priceDisplay + ' / ' + rentDisplay;
      // } else if (isNonEmpty(priceDisplay)) {
      //   return priceDisplay;
      // } else if (isNonEmpty(rentDisplay)) {
      //   return rentDisplay;
      // } else return '';
    }
  }
  //for filtering
  // const [stockCartFilterOption, setStockCartFilterOption] = useState<string | undefined>(initialStockCartFilterOption);

  const getInitialSelectedFormIndex = () => {
    return stockCartFilterOption === 'RENT' ? 2 : 1;
  }

  const [selectedFormIndex, setSelectedFormIndex] = React.useState(getInitialSelectedFormIndex());
  const refreshCartByPropertyStatus = (status?: string) => {
    dispatch({
      type: 'StockCart.FetchRequested', cid: cid ?? '', payload: {
        page: page, size: limit, sort: {}
      }, propertyStatus: status ?? undefined
    });
  }
  // useEffect(()=>{
  //   switch(selectedFormIndex) {
  //     case 1: 
  //     case 4:
  //       refreshCartByPropertyStatus('SALES'); break;
  //     case 2: 
  //     case 5:
  //       refreshCartByPropertyStatus('RENT'); break;
  //     default:
  //       return;
  //   }
  // }, [selectedFormIndex])

  useEffect(() => {
    setColOrders(columnsOrder);
  }, [columnsOrder]);

  const propertyNoList = properties.filter(p => selectedProperties.includes(p.id)).map(p => p.propertyNo)?.join('_');
  const [openPropertySummaryDialog, setOpenPropertySummaryDialog] = React.useState(false);

  const handleClickOpenPropertySummaryDialog = () => {
    setOpenPropertySummaryDialog(true);
  };

  const handleClosePropertySummaryDialog = () => {
    setOpenPropertySummaryDialog(false);
  };

  const printStockCartFilterButton = () => {
    return (
      <FormControl variant="outlined" margin="dense" style={{ marginTop: '0px', paddingLeft: '3px' }}>
        <TextField style={{ marginTop: '0px', paddingLeft: '3px' }}
          fullWidth
          label={langClientDetail.actionFilter}
          margin="dense"
          select
          variant="outlined"
          value={stockCartFilterOption}
          onChange={(e) => {
            setStockCartFilterOption?.(e.target.value);
            refreshCartByPropertyStatus(e.target.value);
            setSelectedFormIndex(e.target.value === 'RENT' ? 2 : 1);
            setSelectedProperties([]);
          }}
        >
          {
            ['SALES', 'RENT'].map((key) =>
              <MenuItem key={key} value={key}>{key === 'RENT' ? langClientDetail.captionRentPropertyStock : langClientDetail.captionBuyPropertyStock}</MenuItem>)
          }

        </TextField>
      </FormControl>
    )
  }

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleAnchorElClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAnchorElClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const printApprovalStatusLegend = () => {
    return !shouldCollapse ? <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: locale === 'zh_HK' ? '20rem' : '30rem' }}>
      <div className={classes.legendGroup}>
        <div className={clsx(classes.approvalStageUnapproved, classes.legend)} />
        <div className={classes.legendCaption}>{lang.captionNotApproved}</div>
      </div>
      <div className={classes.legendGroup}>
        <div className={clsx(classes.approvalStageMatching, classes.legend)} />
        <div className={classes.legendCaption}>{lang.captionApproveStage1}</div>
      </div>
      <div className={classes.legendGroup}>
        <div className={clsx(classes.approvalStageClientApp, classes.legend)} />
        <div className={classes.legendCaption}>{lang.captionApproveStage2}</div>
      </div>
      <div className={classes.legendGroup}>
        <div className={classes.legendCaption}>{lang.captionWithPhoto}</div>
      </div>
    </div> : <div>
      <Button variant="contained" color="primary" onClick={handleAnchorElClick}>
        {langCommon.legend}
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleAnchorElClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div style={{ minWidth: '5rem', maxWidth: '12rem', display: 'flex', flexDirection: 'column', padding: '0.8rem' }}>
          <div className={classes.legendGroup}>
            <div className={clsx(classes.approvalStageUnapproved, classes.legend)} />
            <div className={classes.legendCaption}>{lang.captionNotApproved}</div>
          </div>
          <div className={classes.legendGroup}>
            <div className={clsx(classes.approvalStageMatching, classes.legend)} />
            <div className={classes.legendCaption}>{lang.captionApproveStage1}</div>
          </div>
          <div className={classes.legendGroup}>
            <div className={clsx(classes.approvalStageClientApp, classes.legend)} />
            <div className={classes.legendCaption}>{lang.captionApproveStage2}</div>
          </div>
          <div className={classes.legendGroup}>
            <div className={classes.legendCaption}>{lang.captionWithPhoto}</div>
          </div>
        </div>
      </Popover>
    </div>
  }

  const tableRowStyle = (stage: number, published: boolean, canBeViewedFromClientApp?: boolean) => {
    switch (stage) {
      case 0: return canBeViewedFromClientApp ? classes.approvalStageClientApp : classes.approvalStageUnapproved;
      case 1: return canBeViewedFromClientApp ? classes.approvalStageClientApp : classes.approvalStageMatching;
      case 2: return published || canBeViewedFromClientApp ? classes.approvalStageClientApp : classes.approvalStageMatching;
      default: return classes.approvalStageUnapproved;
    }
  }

  // 
  // 

  const alertDialog = useInfoDialog();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      {confirmDialog.render()}
      {alertDialog.render()}
      <PropertySummaryReportDialog open={openPropertySummaryDialog} handleClose={handleClosePropertySummaryDialog} propertyNoList={propertyNoList} />

      {matchingClient ? <CardHeader
        title={<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', }}>
          <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
            <Typography>{lang.captionPropertyMatching1}</Typography>
            <Typography style={{ paddingLeft: '4px' }} color="secondary">{multiLang(locale, matchingClient.name, matchingClient.nameEn)}</Typography>
            <Typography style={{ paddingLeft: '4px' }}>{lang.captionPropertyMatching2}</Typography>
          </div>
          {selectedProperties.length > 0 && <Button style={{ marginLeft: 10, marginBottom: 10 }} variant="outlined"
            onClick={() => handleUpdateStockCart?.(selectedProperties.map(String))} color="secondary" startIcon={<AddIcon />}>
            {lang.actionAddToWishlist} ({selectedProperties.length})
          </Button>}
          <Button style={{ marginLeft: 10, marginBottom: 10 }} variant="outlined" onClick={onCancelPropertyMatching}>{lang.actionCancelMatching}</Button>
        </div>}
      /> : null}

      <CardActions className={classes.actions}>
        {/* () => handleDeleteStockCart?.(selectedProperties) */}
        {wishlistVariant && setFormSigned && !formSigned ?
          printStockCartFilterButton()
          : null}
        {wishlistVariant && setFormSigned && !formSigned ?
          // <Button disabled tooltip={'Will be ready with Form 4/6 UI'} variant="outlined" color="secondary" onClick={() => setFormSigned(true)} style={{ marginRight: 10 }}>簽署睇樓紙</Button>
          <FullScreenDialogForm4And6 cid={cid} selectedProperties={selectedIncludesNotGrantedProperties ? [] : selectedProperties.map(String)} validSelectedProperties={selectedIncludedInCurrentProperties}
            selectedFormIndex={selectedFormIndex} setSelectedFormIndex={setSelectedFormIndex} fileForm={fileForm} clientuploadform4or6={clientuploadform4or6} unsaved={unsaved} onSave={onSave}
            setSelectedProperties={setSelectedProperties}
          />
          : null}

        {wishlistVariant && !formSigned ?
          <Button disabled={selectedProperties.length === 0} onClick={handleClickOpenPropertySummaryDialog} variant="outlined" color="secondary">{langClientDetail.actionGeneratePropertySummaryReport}</Button>
          : null}

        {wishlistVariant && !formSigned ? <Button disabled={selectedProperties.length === 0} variant="outlined" onClick={() => {
          confirmDialog.confirm(langClientDetail.msgConfirmDeleteSelected, langCommon.actionConfirm, langCommon.actionCancel).then((confirmed) => {
            if (confirmed) {
              handleDeleteStockCart?.(selectedProperties.map(String));
              setSelectedProperties([]);
            }
          })
        }
        }>{langCommon.actionRemoveSelected}</Button> : null}
        {/* <div style={{ flexGrow: 1 }} /> */}
        <div style={{ paddingLeft: '0.5rem', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          {printApprovalStatusLegend()}
          <TablePagination
            classes={{
              actions: classes.tablePaginationIconButton,
              toolbar: classes.tablePaginationToolbar,
            }}
            // component="div"
            count={totalElements}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            totalPages={totalPages}
            rowsPerPage={limit}
            labelRowsPerPage={langUser.rowsCount}
            // rowsPerPageOptions={Object.keys(rowsCountPrefOptions).map(Number)}
            rowsPerPageOptions={rowsCountOptions}
          />
        </div>
        {/* {Object.keys(sortOrders ?? {}).length ?
          <div className={classes.clearSortingContainer}>
            <Button style={{ marginRight: 0 }} variant="outlined" size="small" onClick={() => handleSort?.(null)}>{lang.actionClearSorting}</Button>
          </div>
        : null} */}

      </CardActions>

      <Divider />

      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {matchingClient || (wishlistVariant && !formSigned) ? <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedIncludesAllCurrentProperties}
                      color="primary"
                      indeterminate={
                        selectedProperties.length > 0
                        // selectedProperties.length < properties.length
                        && selectedProperties.filter(sp => properties.map(p => p.id).includes(sp)).length < properties.length
                        && selectedIncludedInCurrentProperties
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell> : null}
                  {
                    Object.keys(tableHeadersSorted)
                      .filter((key) => !!tableHeadersSorted[key as keyof PropertyListItemDTO])
                      .filter((key) => !disabledColumns?.includes(columnNameToColumnPreferenceMapping[key]))
                      .map((key, idx) =>
                        <TableCell key={idx} className={classes.tableCell} style={tableHeadersSorted[key as keyof PropertyListItemDTO]?.overrideStyle}>
                          <TableSortLabel active={sortOrders?.[key] !== undefined} direction={sortOrders?.[key]} onClick={() => handleSort?.(key)}>
                            {sortOrders?.['price'] && key === 'price' ? lang.price + (isRent ? '' : '*') + ' / ' + lang.rent + (isRent ? '*' : '') :
                              sortOrders?.['pricePerNet'] && key === 'pricePerNet' ? isRent ? lang.rentPerNetSorted : lang.pricePerNetSorted :
                                sortOrders?.['pricePerGross'] && key === 'pricePerGross' ? isRent ? lang.rentPerGrossSorted : lang.pricePerGrossSorted :
                                  tableHeadersSorted[key as keyof PropertyListItemDTO]?.title}
                          </TableSortLabel>
                        </TableCell>
                      )
                  }
                  {isSellerStockPage ? null :
                    <TableCell className={classes.tableCell}>
                      {lang.captionTransactionHistory}
                    </TableCell>
                  }
                  {
                    isDormant && canClaimBy !== 'N' ?
                      <TableCell className={classes.tableCell}>{lang.canClaimBy}</TableCell> : null
                  }
                </TableRow>

              </TableHead>
              <TableBody>
                {properties.filter((p) => matchingClient ? p.status : true).map(property => (
                  <DataRow
                    className={clsx(classes.tableRow, tableRowStyle(property.approvalStage, property.published, property.canBeViewedFromClientApp))}
                    hover
                    key={property.id}
                    selected={selectedProperties.indexOf(property.id!) !== -1}
                    onClick={handleNavigateToItem(String(property.propertyNo!))}
                    href={PUBLIC_URL + navigationLink(String(property.propertyNo!))}
                  >
                    {matchingClient || (wishlistVariant && !formSigned) ? <TableCell size="small" className={classes.tableCell} key={`${property.id!}-checkbox`} padding="checkbox" onClick={(ev) => { ev.stopPropagation(); ev.preventDefault(); }}>
                      {property.status != 'PENDING' && property.status != 'SOLD' && property.status != 'LEASED' ?
                        <Checkbox
                          onClick={(ev) => { ev.stopPropagation(); ev.preventDefault(); handleSelectOne(ev, String(property.id!)) }}
                          checked={selectedProperties.indexOf(property.id ?? '') >= 0}
                          color="primary"
                          // onChange={event => handleSelectOne(event, String(property.id!))}
                          value="true"
                        /> : null}
                      {property.fullAddressAvailable ? null : <IconButton onClick={(ev) => {
                        ev.stopPropagation(); ev.preventDefault();
                        alertDialog.confirm(langClientDetail.msgNotGrantedWishlistItem, langCommon.actionConfirm, langCommon.actionConfirm);
                      }}>
                        <InfoIcon />
                      </IconButton>}
                    </TableCell> : null}
                    {
                      Object.keys(tableHeadersSorted)
                        .filter((key) => !!tableHeadersSorted[key as keyof PropertyListItemDTO])
                        .filter((key) => !disabledColumns?.includes(columnNameToColumnPreferenceMapping[key]))
                        .map((key) => {
                          // 
                          return <TableCell style={(property.propertyNo === sessionStorage.getItem('lastClicked')) ? { ...tableBodyStyle[key], fontWeight: 'bold', color: '#551A8B ' } : { ...tableBodyStyle[key] }} size="small" className={classes.tableCell} key={`${property.id}-${key}`}>{overrideCols[key] ? overrideCols[key](property) : property[key as keyof PropertyListItemDTO]}</TableCell>
                        })
                    }
                    {isSellerStockPage ? null :
                      <TableCell style={{ cursor: 'default' }} onClick={(ev) => ev.stopPropagation()} size="small" className={classes.tableCell} key={`${property.id}-viewIcon`}>
                        <IconButton style={{ width: '21px', height: '21px' }} onClick={(ev) => {
                          ev.stopPropagation();
                          ev.preventDefault();
                          handleViewTransaction(property);
                          // history.push(`/transactions?building=${encodeURIComponent(multiLang(locale, property.buildingNameZh ?? property.buildingNameEn, property.buildingNameEn ?? property.buildingNameZh) ?? '')}&block=${encodeURIComponent(multiLang(locale, property.blockZh ?? property.blockEn, property.blockEn ?? property.blockZh) ?? '')}&level=${property.level}&unit=${property.unit}`);
                        }}>
                          <ViewIcon />
                        </IconButton>
                      </TableCell>
                    }
                    {
                      isDormant && canClaimBy !== 'N' && property.dormancyStatus != null ?
                        <TableCell className={classes.tableCell}>
                          {dormancyOptions[property.dormancyStatus]}
                        </TableCell> : null
                    }
                    {/* {
                      !isCurrentUserStock ? 
                        <TableCell><Button variant="outlined" onClick={(ev) => {
                          ev.stopPropagation();
                          confirmDialog.confirm('確認認領樓盤？', '確認', '取消').then((confirmed) => {
                            if (confirmed) {
                              handleClaimProperty(String(property.id!));
                            }
                          })
                        }         
                        }>{lang.actionClaimProperty}</Button>
                        </TableCell> : null
                    } */}
                  </DataRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>

      </CardContent>
      <div style={{ background: colors.grey[50], paddingLeft: '1.1rem', paddingRight: '0.5rem', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        {printApprovalStatusLegend()}
        <TablePagination
          classes={{
            actions: classes.tablePaginationIconButton,
            toolbar: classes.tablePaginationToolbar,
          }}

          // component="div"
          count={totalElements}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          totalPages={totalPages}
          rowsPerPage={limit}
          labelRowsPerPage={langUser.rowsCount}
          // rowsPerPageOptions={Object.keys(rowsCountPrefOptions).map(Number)}
          rowsPerPageOptions={rowsCountOptions}
        />
      </div>
    </Card>
  );
};

export default PropertiesMatchingTable;
