import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { TransitionProps } from '@material-ui/core/transitions';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { useConfirmDialog } from 'common/ConfirmDialog';
import { Form1DTO, Form2DTO, Form3DTO, Form5DTO, PropertyStockDTO, UserDTO } from 'common/dto';
import NavigationBlockerDialog from 'common/elements/NavigationBlockerDialog';
import NewFormSigningDialog from 'common/elements/NewFormSigningDialog';
import { FormPresenter, useAppBarHeight, useForm } from 'common/hooks';
import { autoFillCompanyInfo, autoFillNameAndLicenseNumberOfAgent, filterUndefinedFields, genAddress, multiLang, priceFromView, priceToView } from 'common/utils';
import deepEqual from 'deep-equal';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState, PASDispatch, PropertyActions } from 'reducers';
import { Form1Validations, Form2Validations, Form3Validations, Form5Validations } from './form-validations';
import Form1Dialog, { form1Initial } from './Form1Dialog';
import Form2Dialog, { form2Initial } from './Form2Dialog';
import Form3Dialog, { form3Initial } from './Form3Dialog';
import Form5Dialog, { form5Initial } from './Form5Dialog';

const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];
const EMPTY = {};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    dialogTitle: {
      marginLeft: theme.spacing(2),
      flex: 1,
      color: 'white',
      fontWeight: 'bold',
    },
    dialogRoot: {
      '& .MuiDialog-paper': {
        overflowX: 'hidden',
      }
    },
  }),
);


const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const trimSpace = (array: string[] | undefined) => {
  const joinStr = array?.filter(v => v != '').join(',');
  if (joinStr == '') {
    return undefined;
  }
  return joinStr;
}

interface DialogProps {
  open: boolean;
  handleClose: () => void;
  handleClickOpen: () => void;
  propertyStockId: string;
  propertyNo: string;
  selectedFormNo: number;
  form: FormPresenter<PropertyStockDTO>;
  landsearchdate: string;
}

const FullScreenDialog = ({ selectedFormNo, propertyStockId, propertyNo, form, open, landsearchdate, handleClickOpen, handleClose }: DialogProps) => {
  const classes = useStyles();
  const dispatch = useDispatch() as PASDispatch;
  const { iosStatusBarHeight } = useSelector((state: IRootState) => state.layout);
  const appBarHeight = useAppBarHeight();
  const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);

  const { lang, langPropertyStock, districtHkiOptions, districtKltOptions, districtNtOptions, locale,
    langForm1, langForm2, langForm3, langForm5, langSignDialog } = useSelector((state: IRootState) => state.locale);
  const selectFormBtnMenuList = [langPropertyStock.titleForm, langPropertyStock.titleForm1, langPropertyStock.titleForm2, langPropertyStock.titleForm3, langPropertyStock.titleForm5];

  const { token } = useSelector((state: IRootState) => state.login);

  //Forms 
  const multiSelectForm = useForm<{ [type: string]: string[] }>({
    costOfPurchaserOptions: [],
  });


  //address

  const districtOptions = {
    ...districtHkiOptions,
    ...districtKltOptions,
    ...districtNtOptions,
  };

  const propertyStockDto = useSelector((state: IRootState) => state.property.fetchedDetails[propertyNo]) ?? EMPTY as Partial<PropertyStockDTO>;
  const propertyAddress = genAddress(locale,
    propertyStockDto.unit?.join(',') ?? '',
    propertyStockDto.floor?.join(',') ?? '',
    propertyStockDto.block ?? '', propertyStockDto.blockEn ?? '',
    propertyStockDto.building ?? '', propertyStockDto.buildingEn ?? '',
    propertyStockDto.street ?? '', propertyStockDto.streetEn ?? '',
    districtOptions[propertyStockDto.district ?? '']
  );

  // Form 1
  const currentForm1 = useSelector((state: IRootState) => state.property.form1 ?? form1Initial);
  const form1 = useForm<Form1DTO>({}, { validations: Form1Validations(langForm1), scrollToErrorOnValidate: true, dataKeyPrefix: 'form1' });

  // Form 2
  const currentForm2 = useSelector((state: IRootState) => state.property.form2 ?? form2Initial);
  const form2 = useForm<Form2DTO>({}, { validations: Form2Validations(langForm2), scrollToErrorOnValidate: true, dataKeyPrefix: 'form2' });

  // Form 3
  const currentForm3 = useSelector((state: IRootState) => state.property.form3 ?? form3Initial);
  const form3 = useForm<Form3DTO>({}, { validations: Form3Validations(langForm3), scrollToErrorOnValidate: true, dataKeyPrefix: 'form3' });

  // Form 5
  const currentForm5 = useSelector((state: IRootState) => state.property.form5 ?? form5Initial);
  const form5 = useForm<Form5DTO>({}, { validations: Form5Validations(langForm5), scrollToErrorOnValidate: true, dataKeyPrefix: 'form5' });

  //company setting
  const companyInfoSystemSetting = useSelector((state: IRootState) =>
    state.systemSettings?.Company?.AGENCY_COMPANY_INFO) ?? EMPTY;

  //agent profile
  const currentUid = useSelector((state: IRootState) => state.login.uid);
  const userDto = useSelector((state: IRootState) => state.user.fetchedDetails[currentUid!]) ?? EMPTY as Partial<UserDTO>;

  //auto-filled fields
  const autoFilledFieldsForm1And2 = {
    formLocale: locale,
    propertyAddress: propertyAddress,
    ownerName: form.values.owner,
    annualGovRent: form.values.annualGovRent,
    quarterlyRates: form.values.quarterlyRates,
    monthlyManagementFee: form.values.monthlyMgtFee,
    ...autoFillCompanyInfo('form1', locale, companyInfoSystemSetting),
    ...autoFillNameAndLicenseNumberOfAgent('form1', locale, userDto),
  };

  const autoFilledFieldsForm3 = useMemo(() => ({
    formLocale: locale,
    vendorName: form.values.owner,
    propertyAddress: propertyAddress,
    listPrice: form.values.price ?? 0,
    validityPeriodStart: moment().hours(0).minutes(0).seconds(0).milliseconds(0).toISOString(),
    validityPeriodEnd: moment().add(6, 'M').hours(0).minutes(0).seconds(0).milliseconds(0).toISOString(),
    // commissionAmount: priceFromView(form.values.price ?? 0 , locale) * 0.01, 
    ...autoFillCompanyInfo('form3', locale, companyInfoSystemSetting),
    ...autoFillNameAndLicenseNumberOfAgent('form3', locale, userDto),
    vendorPhoneNumber: form.values.defaultClientPhoneNumber,
  }), [form.values, propertyAddress, locale, userDto, companyInfoSystemSetting]);

  const autoFilledFieldsForm5 = {
    formLocale: locale,
    landlordName: form.values.owner,
    propertyAddress: propertyAddress,
    listRental: (form.values.rent ?? 0),
    validityPeriodStart: moment().hours(0).minutes(0).seconds(0).milliseconds(0).toISOString(),
    validityPeriodEnd: moment().add(6, 'M').hours(0).minutes(0).seconds(0).milliseconds(0).toISOString(),
    // commissionAmount: (form.values.rent ?? 0) * 0.5,
    ...autoFillCompanyInfo('form5', locale, companyInfoSystemSetting),
    ...autoFillNameAndLicenseNumberOfAgent('form5', locale, userDto),
    landlordPhoneNumber: form.values.defaultClientPhoneNumber,
  };

  // Unsaved Check
  const [initialForm1Values, setInitialForm1Values] = useState<any>({});
  const [initialForm2Values, setInitialForm2Values] = useState<any>({});
  const [initialForm3Values, setInitialForm3Values] = useState<any>({});
  const [initialForm4Values, setInitialForm4Values] = useState<any>({});
  const [initialForm5Values, setInitialForm5Values] = useState<any>({});
  const [initialForm6Values, setInitialForm6Values] = useState<any>({});

  //update form values when one form is updated/created
  useEffect(() => {
    if (!open) {
      return;
    }
    form1.setValues({ ...currentForm1, ...autoFillCompanyInfo('form1', currentForm1.formLocale ?? locale, companyInfoSystemSetting) });
    multiSelectForm.setValues({
      ...multiSelectForm.values,
      costOfPurchaserOptions: currentForm1.costOfPurchaserOptions?.trim().split(',') ?? [],
    });
    if (currentForm1.id == null) {
      form1.setValues({ ...form1Initial, ...autoFilledFieldsForm1And2 });
      form1.updateValues('propertyAddress', propertyAddress);
      setInitialForm1Values([
        { ...form1Initial, ...autoFilledFieldsForm1And2 },
        { ...multiSelectForm.values, costOfPurchaserOptions: currentForm1.costOfPurchaserOptions?.trim().split(',') ?? [] }
      ]);
    } else {
      setInitialForm1Values([
        { ...currentForm1, ...autoFillCompanyInfo('form1', currentForm1.formLocale ?? locale, companyInfoSystemSetting) },
        { ...multiSelectForm.values, costOfPurchaserOptions: currentForm1.costOfPurchaserOptions?.trim().split(',') ?? [] }
      ]);
    }

  }, [currentForm1, propertyStockDto.form1, locale, open]);

  useEffect(() => {
    if (!open) {
      return;
    }
    form2.setValues({ ...currentForm2, ...autoFillCompanyInfo('form2', currentForm2.formLocale ?? locale, companyInfoSystemSetting) });
    if (currentForm2.id == null) {
      form2.setValues({ ...form2Initial, ...autoFilledFieldsForm1And2 });
      setInitialForm2Values({ ...form2Initial, ...autoFilledFieldsForm1And2 });
    } else {
      setInitialForm2Values({ ...currentForm2, ...autoFillCompanyInfo('form2', currentForm2.formLocale ?? locale, companyInfoSystemSetting) });
    }
  }, [currentForm2, propertyStockDto.form2, locale, open]);

  // Object.keys(raw)
  // .filter(key => allowed.includes(key))
  // .reduce((obj, key) => {
  //   obj[key] = raw[key];
  //   return obj;
  // }, {});
  const unsavedStates: { [index: number]: boolean } = {
    1: selectedFormNo !== 1 ? false : !deepEqual(
      [
        filterUndefinedFields(form1.values), multiSelectForm.values
      ], [filterUndefinedFields(initialForm1Values[0]), initialForm1Values[1]], { strict: true }),
    2: selectedFormNo !== 2 ? false : !deepEqual(filterUndefinedFields(form2.values), filterUndefinedFields(initialForm2Values), { strict: true }),
    3: selectedFormNo !== 3 ? false : !deepEqual(filterUndefinedFields(form3.values), filterUndefinedFields(initialForm3Values), { strict: true }),
    4: selectedFormNo !== 4 ? false : !deepEqual(filterUndefinedFields(form5.values), filterUndefinedFields(initialForm5Values), { strict: true }),
  }

  // 
  // 
  // 
  // 
  // 

  // 
  // 

  useEffect(() => {
    if (!open) {
      return;
    }
    form3.setValues({ ...currentForm3, listPrice: priceToView(currentForm3.listPrice ?? 0, locale), ...autoFillCompanyInfo('form3', currentForm3.formLocale ?? locale, companyInfoSystemSetting) });
    if (currentForm3.id == null) {
      form3.setValues({ ...form3Initial, ...autoFilledFieldsForm3 });
      setInitialForm3Values({ ...form3Initial, ...autoFilledFieldsForm3 })
    } else {
      setInitialForm3Values({ ...currentForm3, listPrice: priceToView(currentForm3.listPrice ?? 0, locale), ...autoFillCompanyInfo('form3', currentForm3.formLocale ?? locale, companyInfoSystemSetting) })
    }
  }, [ /* form3.setValues, */ currentForm3, propertyStockDto.form3, autoFilledFieldsForm3, locale, open]);

  useEffect(() => {
    if (!open) {
      return;
    }
    form5.setValues({ ...currentForm5, ...autoFillCompanyInfo('form5', currentForm5.formLocale ?? locale, companyInfoSystemSetting) });
    if (currentForm5.id == null) {
      form5.setValues({ ...form5Initial, ...autoFilledFieldsForm5 });
      setInitialForm5Values({ ...form5Initial, ...autoFilledFieldsForm5 });
    } else {
      setInitialForm5Values({ ...currentForm5, ...autoFillCompanyInfo('form5', currentForm5.formLocale ?? locale, companyInfoSystemSetting) });
    }
  }, [currentForm5, propertyStockDto.form5, locale, open]);


  const warnOverwriteDialog = useConfirmDialog();

  //for save action
  const handleSave = (extraAction?: 'GENERATE_PDF' | 'SIGN_FORM', doOverwrite = false) => {
    // setSigningDialogOpen(true);
    switch (selectedFormNo) {
      case 1: saveForm1(extraAction, doOverwrite); break;
      case 2: saveForm2(extraAction, doOverwrite); break;
      case 3: saveForm3(extraAction, doOverwrite); break;
      case 4: saveForm5(extraAction, doOverwrite); break;
      default:
        return;
    }
  };


  const getFormStatus = () => {
    switch (selectedFormNo) {
      case 1: return currentForm1.status;
      case 2: return currentForm2.status;
      case 3: return currentForm3.status;
      case 4: return currentForm5.status;
    }
  }

  const handleGenerate = () => {
    switch (selectedFormNo) {
      case 1: return form1.validate(); break;
      case 2: return form2.validate(); break;
      case 3: return form3.validate(); break;
      case 4: return form5.validate(); break;
      default:
        return false;
    }
  };

  const signIndex: { [no: number]: string } = {
    1: 'form1',
    2: 'form2',
    3: 'form3',
    4: 'form5',
  };

  const requiredSellerSign: { [key: string]: () => boolean } = {
    'form1': () => form1.values.informationProvidedByVendor ?? false,
    // 'form1': () => false,
    'form2': () => false,
    'form3': () => true,
    'form5': () => true,
  };

  const [signingDialogOpen, setSigningDialogOpen] = useState(false);

  const saveForm1 = (extraAction?: 'GENERATE_PDF' | 'SIGN_FORM', doOverwrite = false) => {
    if (form1.values.id != undefined && form1.values.id != null) {
      dispatch({
        type: 'Form1.UpdateRequested', payload: {
          ...form1.values,
          date: (extraAction === 'GENERATE_PDF' || extraAction === undefined) ? null : moment().toISOString(),
          costOfPurchaserOptions: trimSpace(multiSelectForm.values.costOfPurchaserOptions),
          status: doOverwrite ? 'D' : currentForm1.status,
          extraAction,
          vendorDate: (extraAction === 'GENERATE_PDF' || extraAction === undefined) ? null : moment().toISOString(),
        }
      } as PropertyActions);
    } else {
      dispatch({
        type: 'Form1.CreationRequested', payload: {
          ...form1.values,
          date: (extraAction === 'GENERATE_PDF' || extraAction === undefined) ? null : moment().toISOString(),
          propertyStockId: propertyStockId,
          costOfPurchaserOptions: trimSpace(multiSelectForm.values.costOfPurchaserOptions),
          status: doOverwrite ? 'D' : currentForm1.status,
          extraAction,
          vendorDate: (extraAction === 'GENERATE_PDF' || extraAction === undefined) ? null : moment().toISOString(),
        }
      } as PropertyActions);
    }
  };

  const saveForm2 = (extraAction?: 'GENERATE_PDF' | 'SIGN_FORM', doOverwrite = false) => {
    if (form2.values.id != undefined && form2.values.id != null) {
      dispatch({
        type: 'Form2.UpdateRequested', payload: {
          ...form2.values,
          date: (extraAction === 'GENERATE_PDF' || extraAction === undefined) ? null : moment().toISOString(),
          status: doOverwrite ? 'D' : currentForm2.status,
          extraAction,
        }
      } as PropertyActions);
    } else {
      dispatch({
        type: 'Form2.CreationRequested', payload: {
          ...form2.values,
          date: (extraAction === 'GENERATE_PDF' || extraAction === undefined) ? null : moment().toISOString(),
          propertyStockId: propertyStockId,
          status: doOverwrite ? 'D' : currentForm2.status,
          extraAction,
        }
      } as PropertyActions);
    }
  };

  const saveForm3 = (extraAction?: 'GENERATE_PDF' | 'SIGN_FORM', doOverwrite = false) => {
    // if (!form3.validate() && !!form3.validationErrors.isValidityPeriodValid) return;
    if (form3.values.id != undefined && form3.values.id != null) {
      dispatch({
        type: 'Form3.UpdateRequested', payload: {
          ...form3.values,
          listPrice: priceFromView(form3.values.listPrice ?? 0, locale),
          status: doOverwrite ? 'D' : currentForm3.status,
          extraAction,
          vendorDate: (extraAction === 'GENERATE_PDF' || extraAction === undefined) ? null : moment().toISOString(),
          agentDate: (extraAction === 'GENERATE_PDF' || extraAction === undefined) ? null : moment().toISOString(),
        }
      } as PropertyActions);
    } else {

      dispatch({
        type: 'Form3.CreationRequested', payload: {
          ...form3.values,
          listPrice: priceFromView(form3.values.listPrice ?? 0, locale),
          propertyStockId: propertyStockId,
          status: doOverwrite ? 'D' : currentForm3.status,
          extraAction,
          vendorDate: (extraAction === 'GENERATE_PDF' || extraAction === undefined) ? null : moment().toISOString(),
          agentDate: (extraAction === 'GENERATE_PDF' || extraAction === undefined) ? null : moment().toISOString(),
        }
      } as PropertyActions);
    }
  };

  const saveForm5 = (extraAction?: 'GENERATE_PDF' | 'SIGN_FORM', doOverwrite = false) => {
    // if (!form5.validate() && !!form5.validationErrors.isValidityPeriodValid) return;
    if (form5.values.id != undefined && form5.values.id != null) {
      dispatch({
        type: 'Form5.UpdateRequested', payload: {
          ...form5.values,
          status: doOverwrite ? 'D' : currentForm5.status,
          extraAction,
          landlordDate: (extraAction === 'GENERATE_PDF' || extraAction === undefined) ? null : moment().toISOString(),
          agentDate: (extraAction === 'GENERATE_PDF' || extraAction === undefined) ? null : moment().toISOString(),
        }
      } as PropertyActions);
    } else {

      dispatch({
        type: 'Form5.CreationRequested', payload: {
          ...form5.values,
          propertyStockId: propertyStockId,
          status: doOverwrite ? 'D' : currentForm5.status,
          extraAction,
          landlordDate: (extraAction === 'GENERATE_PDF' || extraAction === undefined) ? null : moment().toISOString(),
          agentDate: (extraAction === 'GENERATE_PDF' || extraAction === undefined) ? null : moment().toISOString(),
        }
      } as PropertyActions);
    }
  };

  const formSigningLocale = () => {
    switch (selectedFormNo) {
      case 1: return form1.values.formLocale;
      case 2: return form2.values.formLocale;
      case 3: return form3.values.formLocale;
      case 4: return form5.values.formLocale;
      default:
        return locale;
    }
  }

  //--------------- check exist signed form with overlapping validity period
  const confirmSignAgainDialog = useConfirmDialog();



  useEffect(() => {
    if (selectedFormNo === 1) {
      if (currentForm1.isExist === true) {
        const msg = langSignDialog.msgWarnInvitationSentAlready;
        confirmSignAgainDialog.confirm(msg, lang.actionYes, lang.actionNo).then((confirmed: boolean) => {
          if (confirmed) {
            setSigningDialogOpen(true);
          }
        });
      } else if (currentForm1.isExist === false) {
        setSigningDialogOpen(true);
      }
    }
  }, [currentForm1.isExist, currentForm1.invitationSent])

  useEffect(() => {
    if (selectedFormNo === 3) {
      if (form.values.hasUnapprovedForm3) { return; }
      if (currentForm3.isExist === true) {
        const msg = currentForm3.invitationSent ? langSignDialog.msgWarnInvitationSentAlready : langSignDialog.msgWarnSignedFormExist;
        confirmSignAgainDialog.confirm(msg, lang.actionYes, lang.actionNo).then((confirmed: boolean) => {
          if (confirmed) {
            setSigningDialogOpen(true);
          }
        });
      } else if (currentForm3.isExist === false) {
        setSigningDialogOpen(true);
      }
    }
  }, [currentForm3.isExist, currentForm3.invitationSent])

  useEffect(() => {
    if (selectedFormNo === 4) {
      if (form.values.hasUnapprovedForm5) { return; }
      if (currentForm5.isExist === true) {
        const msg = currentForm5.invitationSent ? langSignDialog.msgWarnInvitationSentAlready : langSignDialog.msgWarnSignedFormExist;
        confirmSignAgainDialog.confirm(msg, lang.actionYes, lang.actionNo).then((confirmed: boolean) => {
          if (confirmed) {
            setSigningDialogOpen(true);
          }
        });
      } else if (currentForm5.isExist === false) {
        setSigningDialogOpen(true);
      }
    }
  }, [currentForm5.isExist, currentForm5.invitationSent])


  // ------------- for form signing dialog
  const agentName = multiLang(locale, userDto.chineseName, userDto.englishName);

  const getClientName = () => {
    switch (selectedFormNo) {
      case 1: return form1.values.informationProvidedByVendor ? currentForm1.vendorName : multiLang(locale, form.values.sellerZh, form.values.sellerEn);
      case 2: return multiLang(locale, form.values.sellerZh, form.values.sellerEn);
      case 3: return currentForm3.vendorName;
      case 4: return currentForm5.landlordName;
    }
  }

  const getFormPropertyAddress = () => {
      return propertyAddress?.toUpperCase();
  }


  return (
    <div>
      {confirmSignAgainDialog.render()}
      {warnOverwriteDialog.render()}
      <NavigationBlockerDialog matchesUrl={`/properties-edit/${form.values.propertyNo}/form-view`} unsaved={open ? unsavedStates[selectedFormNo] : false} goBack />
      <Button style={{ display: 'none' }} variant="outlined" color="primary" onClick={handleClickOpen}>
        Open full-screen dialog
      </Button>
      <Dialog style={{ paddingTop: appBarHeight }} className={classes.dialogRoot} fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar style={{ position: 'fixed', top: 0, paddingTop: iosStatusBarHeight, transform: 'translate3d(0,0,0)', }} className={classes.appBar}>
          <Toolbar >
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.dialogTitle}>
              {selectFormBtnMenuList[selectedFormNo]}
            </Typography>
            <Button autoFocus color="inherit" style={{ color: unsavedStates[selectedFormNo] ? '#FFBF00' : 'inherit', fontWeight: unsavedStates[selectedFormNo] ? 'bold' : 'inherit' }}
              onClick={() => {
                if ("P" === getFormStatus()) {
                  warnOverwriteDialog.confirm(langSignDialog.msgWarnOverwrite, lang.actionYes, lang.actionNo).then((confirmed: boolean) => {
                    if (confirmed) {
                      handleSave(undefined, true);
                    }
                  });
                } else {
                  handleSave(undefined);
                }
              }}
            >
              {`${lang.actionSaveForm} ${unsavedStates[selectedFormNo] ? '*' : ''}`}
            </Button>
            <Button
              autoFocus color="inherit" onClick={(ev) => {
                ev.preventDefault();
                if (handleGenerate()) {
                  if ("P" === getFormStatus()) {
                    warnOverwriteDialog.confirm(langSignDialog.msgWarnOverwrite, lang.actionYes, lang.actionNo).then((confirmed: boolean) => {
                      if (confirmed) {
                        handleSave('GENERATE_PDF', true);
                      }
                    });
                  } else {
                    handleSave('GENERATE_PDF');
                  }

                }
              }} {...{ component: 'a', target: '_blank', href: `${BASE_URL}/form/generate/${propertyStockId}/${signIndex[selectedFormNo]}/zh_HK/pdf?t=${token}` } as any}>
              {lang.actionGenerate}
            </Button>
            <Button autoFocus color="inherit" onClick={() => {
              if (handleGenerate()) {

                if ("P" === getFormStatus()) {
                  warnOverwriteDialog.confirm(langSignDialog.msgWarnOverwrite, lang.actionYes, lang.actionNo).then((confirmed: boolean) => {
                    if (confirmed) {
                      handleSave('SIGN_FORM', true);
                      // checkOverwriteAndSave('SIGN_FORM');
                      if (selectedFormNo == 1 || selectedFormNo == 3 || selectedFormNo == 4) {
                        if (selectedFormNo == 1) {
                          dispatch({ type: 'Form1.CheckExistSignedFormResultLoaded', payload: { isExist: undefined, invitationSent: undefined } });
                        }
                        if (selectedFormNo == 3 && form.values.hasUnapprovedForm3) {
                          confirmSignAgainDialog.confirm(langPropertyStock.msgDeleteExisingForm3, '', 'OK');
                        } else if (selectedFormNo == 4 && form.values.hasUnapprovedForm5) {
                          confirmSignAgainDialog.confirm(langPropertyStock.msgDeleteExisingForm5, '', 'OK');
                        } else if ((selectedFormNo == 4 && !(form.values.hasUnapprovedForm5)) || (selectedFormNo == 3 && !form.values.hasUnapprovedForm3)) {
                          dispatch({ type: 'Form3.CheckExistSignedFormResultLoaded', payload: { isExist: undefined, invitationSent: undefined } });
                          dispatch({ type: 'Form5.CheckExistSignedFormResultLoaded', payload: { isExist: undefined, invitationSent: undefined } });
                        }
                      } else {
                        setSigningDialogOpen(true);
                      }
                    }
                  });
                } else {
                  handleSave('SIGN_FORM');
                  if (selectedFormNo == 1 || selectedFormNo == 3 || selectedFormNo == 4) {
                    // checkExistSignedForm();
                    if (selectedFormNo == 1) {
                      dispatch({ type: 'Form1.CheckExistSignedFormResultLoaded', payload: { isExist: undefined, invitationSent: undefined } });
                    }
                    if (selectedFormNo == 3 && form.values.hasUnapprovedForm3) {
                      confirmSignAgainDialog.confirm(langPropertyStock.msgDeleteExisingForm3, '', 'OK');

                    } else if (selectedFormNo == 4 && form.values.hasUnapprovedForm5) {
                      confirmSignAgainDialog.confirm(langPropertyStock.msgDeleteExisingForm5, '', 'OK');

                    } else if ((selectedFormNo == 4 && !form.values.hasUnapprovedForm5) || (selectedFormNo == 3 && !form.values.hasUnapprovedForm3)) {
                      dispatch({ type: 'Form3.CheckExistSignedFormResultLoaded', payload: { isExist: undefined, invitationSent: undefined } });
                      dispatch({ type: 'Form5.CheckExistSignedFormResultLoaded', payload: { isExist: undefined, invitationSent: undefined } });
                    }

                  } else {
                    setSigningDialogOpen(true);
                  }
                }

                // if (selectedFormNo == 3 || selectedFormNo == 4){
                //   // checkExistSignedForm();
                //   dispatch({ type: 'Form3.CheckExistSignedFormResultLoaded', payload: { isExist: undefined, invitationSent: undefined } });
                //   dispatch({ type: 'Form5.CheckExistSignedFormResultLoaded', payload: { isExist: undefined, invitationSent: undefined } });
                // }else{
                //   setSigningDialogOpen(true);
                // }

              }
            }}>
              {lang.actionGenerateAndSign ?? '簽署表格'}
            </Button>
          </Toolbar>
        </AppBar>

        <NewFormSigningDialog
          open={signingDialogOpen}
          onClose={() => {
            setSigningDialogOpen(false);
            handleClose();
            dispatch({ type: 'Property.FetchRequested', payload: { id: propertyStockId } });
            dispatch({ type: 'Memo.FetchRequested', payload: { id: propertyStockId } });
          }}
          id={propertyStockId}
          formIndex={signIndex[selectedFormNo]}
          requireAgentSign
          requireSellerSign={requiredSellerSign[signIndex[selectedFormNo]]?.()}
          locale={formSigningLocale()}
          autoFillClientVerificationContact={form.values.mainContact ?? ''}
          autoFillClientEmail={form.values.sellerEmailIfAny ?? ''}
          onCloseSigningDialog={() => { setSigningDialogOpen(false); }}

          agentName={agentName}
          clientName={getClientName()}
          propertyAddress={getFormPropertyAddress()}
          displayFormName={selectFormBtnMenuList[selectedFormNo]}
        />
        <div>
          {selectedFormNo == 1 ? <Form1Dialog propertyStockId={propertyStockId ?? ''} form1={form1} multiSelectForm={multiSelectForm} /> : null}
          {selectedFormNo == 2 ? <Form2Dialog form2={form2} propertyStockId={propertyStockId ?? ''} /> : null}
          {selectedFormNo == 3 ? <Form3Dialog form3={form3} propertyStockId={propertyStockId ?? ''} landsearchdate={landsearchdate ?? null} /> : null}
          {selectedFormNo == 4 ? <Form5Dialog form5={form5} propertyStockId={propertyStockId ?? ''} landsearchdate={landsearchdate ?? null} /> : null}
        </div>
      </Dialog>
    </div>
  );
}

export default FullScreenDialog;