import { makeStyles } from '@material-ui/core';
import { hasPermission } from 'common/access-control';
import usePageTitle from 'common/hooks';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState, PASDispatch } from 'reducers';
import RoleTable from './components/RoleTable';
import RoleToolbar from './components/RoleToolbar';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  search: {
    marginBottom: theme.spacing(2)
  }
}));

const RoleList = () => {
	const classes = useStyles(0);

	const dispatch = useDispatch() as PASDispatch;
	const roles = useSelector((state: IRootState) => state.rolePrivilege.currentList);
  const { totalPages, totalElements } = useSelector((state: IRootState) => state.rolePrivilege);
	const { langRolePrivilege } = useSelector((state: IRootState) => state.locale);
	const [ page, setPage ] = useState(0);
  const [ limit ] = useState(10);
	const [ sortOrders, setSortOrders ] = useState<{ [k: string]: 'desc' | 'asc' }>({
    dataStatus: 'asc',
    roleName: 'asc'
	});
	
	usePageTitle(langRolePrivilege.titlePageHeading);
	
	const handleSort = (key: string | null) => {
    if (key === null) {
      setSortOrders({});
      // dispatch({ 
			// 	type: 'UserList.FetchRequested', 
			// 	payload: { page, size: limit, sort: {}, ...values }
      // });
      return;
    }
    const newSortOrders = { [key]: sortOrders[key] === 'asc' ? 'desc' : 'asc' };
    setSortOrders(newSortOrders as any);
    // dispatch({ type: 'UserList.FetchRequested', payload: {
		// 	page, size: limit, sort: newSortOrders, ...values
		// }});
	}
	
	useEffect(() => {
    // dispatch({ type: 'UserList.FetchRequested', payload: { page, size: limit } });
    // dispatch({ type: 'UserList.FetchRequested', payload: {
		// 	page, size: limit, sort: sortOrders, 
    // } });
    
    dispatch({ type: 'RoleList.FetchRequested', payload: {
			page, size: limit, sort: sortOrders, 
    } });
    
	}, [ page, limit, sortOrders ]);

	const { privileges } = useSelector((state: IRootState) => state.login);
	const canCreate= hasPermission(privileges, 'CREATE', 'ROLE');
	
	return (
		<div className={classes.root}>
			<RoleToolbar canCreate={canCreate ? "true" : "false"}/>
			<div className={classes.content}>				
				
				<RoleTable
					page={page}
					limit={limit}
					totalPages={totalPages}
					totalElements={totalElements}
					setPage={(page) => {
						setPage(page);
						// dispatch({ 
						// 	type: 'UserList.FetchRequested', 
						// 	payload: { page, size: limit }
            // });
					}}
					roles={roles}
					sortOrders={sortOrders}
					handleSort={handleSort}
				/>
			</div>
		</div>
	);
};

export default RoleList;