import {
	Card, CardContent, CardHeader,
	Divider, FormControlLabel, Grid, MenuItem,
	Button as MuiButton,
	Checkbox as MuiCheckbox,
	TextField, Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import EditLocationIcon from '@material-ui/icons/EditLocation';
import { hasPermission } from "common/access-control";
import { useConfirmDialog } from "common/ConfirmDialog";
import { ContactsDTO, PropertyDraftUpdateDTO, TransactionDetailDTO } from "common/dto";
import DistrictSingleSelect from 'common/elements/DistrictSingleSelect';
import usePageTitle, { useForm } from "common/hooks";
import { tooltipHoc } from 'common/ui';
import { handlePriceDisplay, handlePriceDisplayUnit, multiLang } from 'common/utils';
import NavigationBar from 'layouts/Main/components/Topbar/NavigationBar';
import moment from 'moment';
import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from "react-router";
import { IRootState, PASDispatch } from "reducers";
import propertyDraftEditValidations from './property-draft-edit-validations';

const Button = tooltipHoc(MuiButton);
const Checkbox = tooltipHoc(MuiCheckbox);

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(2)
	},
	patchOutlineAutocomplete: {
		'& .MuiAutocomplete-inputRoot': {
			padding: 0,
			paddingLeft: 8
		}
	}
}));
const EMPTY = {};

const TransactionDetail = (props: any) => {
	const classes = useStyles();
	const dispatch = useDispatch() as PASDispatch;
	const history = useHistory();
	const { id }: any = useParams();

	const { langTransaction: lang, lang: langCommon, langBuilding, locale, districtHkiOptions, districtKltOptions, districtNtOptions, usageOptions, levelOptions, contactOptions, transactionStatusOptions } = useSelector((state: IRootState) => state.locale);
	const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);
	const { privileges } = useSelector((state: IRootState) => state.login);
	const transactionDetail: TransactionDetailDTO = useSelector((state: IRootState) => state.transaction.fetchedDetails[id!]) ?? EMPTY;
	const isDraft = transactionDetail.id && !transactionDetail.propertyIsValid;

	const canCreateBuilding = hasPermission(privileges, 'CREATE', 'BUILDING');
	const canUpdateTransaction = hasPermission(privileges, 'UPDATE', 'TRANSACTION');
	const districtOptions = {
		...districtHkiOptions,
		...districtKltOptions,
		...districtNtOptions,
	};
	
	usePageTitle(lang.titleTransactionDetail);

	useEffect(() => {
		dispatch({ type: 'Transaction.FetchRequested', payload: { id: id ?? '' } });
	}, [])

	const onCreatePropertyStock = () => {
		// dispatch({ type: 'Transaction.CreatePropertyStockRequested', payload: { id: id ?? ''}});
		history.push({
			pathname: '/properties/new',
			state: {
				transactionId: transactionDetail.id ?? '',
				buildingId: transactionDetail.buildingId ?? '',
				floor: transactionDetail.floor,
				unit: transactionDetail.unit,
				usage: transactionDetail.usage,
				lot: transactionDetail.lot,
				streetZh: transactionDetail.streetZh,
				streetEn: transactionDetail.streetEn,
			}
		});
	};


	const confirmDialog = useConfirmDialog();
	const onUpdatePropertyDraft = () => {
		if (draftEditForm.validate()) {
			confirmDialog.confirm(lang.msgAddDraftAsAlias, langCommon.actionYes, langCommon.actionNo).then(confirmed => {
				dispatch({
					type: 'Transaction.UpdatePropertyDraftRequested', payload: {
						update: {
							...draftEditForm.values,
							shouldCopyAsAlias: confirmed,
						} as PropertyDraftUpdateDTO,
					}
				});
			});
		}
	};

	const isSold = transactionDetail?.status === 'SOLD';

	const draftEditForm = useForm<PropertyDraftUpdateDTO>({}, {
		validations: propertyDraftEditValidations(lang, usageOptions, districtOptions),
	});
	useEffect(() => {
		if (transactionDetail?.id && isDraft) {
			draftEditForm.setValues({
				transactionId: +(transactionDetail.id ?? 0),
				propertyId: transactionDetail.propertyId,
				buildingNameEn: transactionDetail.buildingNameEn,
				buildingNameZh: transactionDetail.buildingNameZh,
				floor: transactionDetail.floorLiteral,
				unit: transactionDetail.unitLiteral,
				usage: transactionDetail.usage,
				district: transactionDetail.district,
			});
			draftEditForm.validate();
		}
	}, [transactionDetail?.id]);

	//show create ps button
	const canCreateUnownedPropertyStock = hasPermission(privileges, 'CREATE', 'UNOWNED_PROPERTY_STOCK');
	const canCreateCompanyPropertyStock = hasPermission(privileges, 'CREATE', 'COMPANY_STOCK');
	const canCreateOwnPropertyStock = hasPermission(privileges, 'CREATE', 'OWN_PROPERTY_STOCK');
	const canReadUnownedClient = hasPermission(privileges, 'READ', 'UNOWNED_CLIENT');

	const showCreatePsButton = canCreateCompanyPropertyStock || canCreateOwnPropertyStock || (canCreateUnownedPropertyStock && canReadUnownedClient);


	return (
		<div className={classes.root}>
			{confirmDialog.render()}
			<NavigationBar title={lang.titleTransactionDetail}>
				<Grid item container
					lg={6}
					md={6}
					xl={6}
					xs={6}
					justify="flex-end">
					<Grid item >
						{!isDraft ? <Button
							disabled={!showCreatePsButton}
							color="primary"
							variant="text"
							onClick={onCreatePropertyStock}
							startIcon={<AddLocationIcon />}
						>{lang.actionNewPropertyStock}</Button> : null}
						{isDraft ? <Button
							disabled={!canCreateBuilding || !canUpdateTransaction}
							color="primary"
							variant="text"
							onClick={onUpdatePropertyDraft}
							startIcon={<EditLocationIcon />}
						>{lang.actionUpdatePropertyDraft}</Button> : null}
					</Grid>
				</Grid>
			</NavigationBar>

			<form                   // should be placed outside grid ??
				autoComplete="off"
				noValidate
			>

				{isDraft ? <Grid container md={12} xs={12} spacing={1}>
					<Grid item md={12} xs={12} spacing={1}>
						<Card>
							<CardContent>
								<Typography color="error">{lang.msgPropertyDraftUpdateRequired}</Typography>
							</CardContent>
						</Card>
					</Grid>
				</Grid> : null}

				<Grid
					container
					md={12}
					sm={12}
					xs={12}
					spacing={1}
				>
					<Grid
						item
						md={6}
						sm={12}
						xs={12}
					>
						<Card>
							<CardHeader
								title={lang.titleBasicInfo}
							/>
							<Divider />
							<CardContent>
								<Grid
									container
									spacing={1}
								>
									<Grid
										item
										md={3}
										sm={3}
										xs={6}
									>
										<TextField
											fullWidth
											label={lang.status}
											margin="dense"
											variant="outlined"
											InputProps={{
												readOnly: true,
											}}
											InputLabelProps={{
												shrink: true
											}}
											value={transactionStatusOptions[transactionDetail.status]}
										/>
									</Grid>
									<Grid
										item
										md={3}
										sm={3}
										xs={6}
									>
										{!isDraft ? <TextField
											fullWidth
											label={lang.usage}
											margin="dense"
											variant="outlined"
											InputProps={{
												readOnly: true,
											}}
											InputLabelProps={{
												shrink: true
											}}
											value={usageOptions[transactionDetail.usage]}
										/> : <TextField
											fullWidth
											select
											label={lang.usage}
											margin="dense"
											variant="outlined"
											InputLabelProps={{
												shrink: true
											}}
											// value={usageOptions[transactionDetail.usage]}
											{...draftEditForm.bind('usage')}
										>
											{Object.keys(usageOptions).map(key => <MenuItem key={key} value={key}>
												{usageOptions[key]}
											</MenuItem>)}
										</TextField>}
									</Grid>
									<Grid
										item
										md={6}
										sm={6}
										xs={12}
									>
										{!isDraft ? <TextField
											fullWidth
											label={lang.district}
											margin="dense"
											variant="outlined"
											InputProps={{
												readOnly: true,
											}}
											InputLabelProps={{
												shrink: true
											}}
											value={districtOptions[transactionDetail.district]}
										/> : <DistrictSingleSelect
											{...draftEditForm.bind('district')}
											fullWidth
											label={lang.district}
											margin="dense"
											required
											select
											variant="outlined"
											districtValue={draftEditForm.values.district ?? ''}
											onToggleChip={(value) => draftEditForm.updateValues('district', value)}
										/>}
									</Grid>

									{!isDraft ? <Grid item md={8} sm={8} xs={12}>
										<TextField
											fullWidth
											label={lang.buildingOrVillageName}
											margin="dense"
											variant="outlined"
											InputProps={{
												readOnly: true,
											}}
											InputLabelProps={{
												shrink: true
											}}
											value={multiLang(locale, transactionDetail.buildingNameZh, transactionDetail.buildingNameEn)}
										/>
									</Grid> : null}

									{isDraft ? <React.Fragment>
										<Grid item md={6} sm={6} xs={12}>
											<TextField
												fullWidth
												label={langBuilding.buildingOrVillageZh}
												margin="dense"
												variant="outlined"
												{...draftEditForm.bind('buildingNameZh')}
											/>
										</Grid>

										<Grid item md={6} sm={6} xs={12}>
											<TextField
												fullWidth
												label={langBuilding.buildingOrVillageEn}
												margin="dense"
												variant="outlined"
												{...draftEditForm.bind('buildingNameEn')}
											/>
										</Grid>
									</React.Fragment> : null}

									<Grid
										item
										md={4}
										sm={4}
										xs={12}
									>
										<TextField
											fullWidth
											label={lang.block}
											margin="dense"
											variant="outlined"
											InputProps={{
												readOnly: true,
											}}
											InputLabelProps={{
												shrink: true
											}}
											value={multiLang(locale, transactionDetail.blockZh, transactionDetail.blockEn)}
										/>
									</Grid>

									<Grid
										item
										md={12}
										sm={12}
										xs={12}
									>
										<TextField
											fullWidth
											label={lang.street}
											margin="dense"
											variant="outlined"
											InputProps={{
												readOnly: true,
											}}
											InputLabelProps={{
												shrink: true
											}}
											value={multiLang(locale, transactionDetail.streetZh, transactionDetail.streetEn)}
										/>
									</Grid>

									<Grid
										item
										md={12}
										sm={12}
										xs={12}
									>
										<TextField
											fullWidth
											label={lang.lot}
											margin="dense"
											variant="outlined"
											InputProps={{
												readOnly: true,
											}}
											InputLabelProps={{
												shrink: true
											}}
											value={transactionDetail.lot}
										/>
									</Grid>

									<Grid
										item
										md={3}
										sm={3}
										xs={6}
									>
										<TextField
											fullWidth
											label={lang.floor}
											margin="dense"
											variant="outlined"
											InputProps={{
												readOnly: !isDraft,
											}}
											InputLabelProps={{
												shrink: true
											}}
											{...(isDraft ?
												draftEditForm.bind('floor') :
												{ value: transactionDetail.floor?.join(',') }
											)}
										/>
									</Grid>

									{/* <Grid
										item
										md={2}
										sm={2}
										xs={4}
									>
										<TextField
											fullWidth
											label={lang.level}
											margin="dense"
											variant="outlined"
											InputProps={{
												readOnly: true,
											}}
											InputLabelProps={{
												shrink: true
											}}
											value={transactionDetail.level?.map((l: string) => levelOptions[l]).join(',')}
											// value={transactionDetail.level?.split(',').map((l: string) => levelOptions[l.trim()]).join(',') ?? ''}
										/>
									</Grid> */}

									<Grid
										item
										md={3}
										sm={3}
										xs={6}
									>
										<TextField
											fullWidth
											label={lang.unit}
											margin="dense"
											variant="outlined"
											InputProps={{
												readOnly: !isDraft,
											}}
											InputLabelProps={{
												shrink: true
											}}
											{...(isDraft ?
												draftEditForm.bind('unit') :
												{ value: transactionDetail.unit?.join(',') }
											)}
										/>
									</Grid>

									<Grid
										item
										md={3}
										sm={3}
										xs={6}
									>
										<TextField
											fullWidth
											label={lang.gross}
											margin="dense"
											variant="outlined"
											InputProps={{
												readOnly: true,
											}}
											InputLabelProps={{
												shrink: true
											}}
											value={transactionDetail.gross}
										/>
									</Grid>

									<Grid
										item
										md={3}
										sm={3}
										xs={6}
									>
										<TextField
											fullWidth
											label={lang.net}
											margin="dense"
											variant="outlined"
											InputProps={{
												readOnly: true,
											}}
											InputLabelProps={{
												shrink: true
											}}
											value={transactionDetail.net}
										/>
									</Grid>

									<Grid
										item
										md={4}
										sm={4}
										xs={6}
									>
										<TextField
											fullWidth
											label={lang.buildingAge}
											margin="dense"
											variant="outlined"
											InputProps={{
												readOnly: true,
											}}
											InputLabelProps={{
												shrink: true
											}}
											value={transactionDetail.buildingAge ?? ''}
										/>
									</Grid>

									<Grid item md={4} sm={4} xs={6}>
										<TextField
											fullWidth
											label={lang.occupancyPermitDate}
											margin="dense"
											variant="outlined"
											InputProps={{
												readOnly: true,
											}}
											InputLabelProps={{
												shrink: true
											}}
											value={transactionDetail.occupancyPermitDate ? moment(transactionDetail.occupancyPermitDate).format(DISPLAY_DATE_FORMAT) : ''}
										/>
									</Grid>

									<Grid
										item
										md={4}
										sm={4}
										xs={12}
									>
										<FormControlLabel
											control={
												<Checkbox disabled checked={!!transactionDetail.internal} />
											}
											label={lang.internal}
											labelPlacement='start'
										/>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>

					<Grid
						item
						md={6}
						sm={12}
						xs={12}
					>
						<Card>
							<CardHeader
								title={lang.titleTransactionInfo}
							/>
							<Divider />
							<CardContent>
								<Grid
									container
									spacing={1}
								>
									<Grid
										item
										md={3}
										sm={3}
										xs={6}
									>
										<TextField
											fullWidth
											label={isSold ? lang.price : lang.rent}
											margin="dense"
											variant="outlined"
											InputProps={{
												readOnly: true,
											}}
											InputLabelProps={{
												shrink: true
											}}
											value={isSold ?
												(String(handlePriceDisplay(transactionDetail.price ?? 0, locale)) + handlePriceDisplayUnit(transactionDetail.price ?? 0, locale, langCommon))
												: transactionDetail.rent
											}
										// value={transactionDetail.price}
										/>
									</Grid>
									<Grid
										item
										md={3}
										sm={3}
										xs={6}
									>
										<TextField
											fullWidth
											label={isSold ? lang.pricePerGross : lang.rentPerGross}
											margin="dense"
											variant="outlined"
											InputProps={{
												readOnly: true,
											}}
											InputLabelProps={{
												shrink: true
											}}
											value={isSold ? Math.round(transactionDetail.pricePerGross) : Math.round(transactionDetail.rentPerGross)}
										/>
									</Grid>

									<Grid
										item
										md={3}
										sm={3}
										xs={6}
									>
										<TextField
											fullWidth
											label={isSold ? lang.pricePerNet : lang.rentPerNet}
											margin="dense"
											variant="outlined"
											InputProps={{
												readOnly: true,
											}}
											InputLabelProps={{
												shrink: true
											}}
											value={isSold ? Math.round(transactionDetail.pricePerNet) : Math.round(transactionDetail.rentPerNet)}
										/>
									</Grid>
									<Grid item md={3} sm={3} xs={6}>
										<TextField
											fullWidth
											label={lang.documentDate}
											margin="dense"
											variant="outlined"
											InputProps={{
												readOnly: true,
											}}
											InputLabelProps={{
												shrink: true
											}}
											value={transactionDetail.transactionDate ? moment(transactionDetail.transactionDate).format(DISPLAY_DATE_FORMAT) : ''}
										/>
									</Grid>
									<Grid item md={12} sm={12} xs={12}>
										<Typography style={{ fontWeight: 'bold' }}>{isSold ? lang.buyer : lang.tenant}</Typography>
									</Grid>
									<Grid item md={12} sm={12} xs={12}>
										<TextField
											fullWidth
											label={lang.contactName}
											margin="dense"
											variant="outlined"
											InputProps={{
												readOnly: true,
											}}
											InputLabelProps={{
												shrink: true
											}}
											value={multiLang(locale, transactionDetail.buyerZh, transactionDetail.buyerEn)}
										/>
									</Grid>
									<Grid item md={6} sm={6} />
									{
										transactionDetail.buyerContact?.map((contact: ContactsDTO) => (
											<Grid container md={12} sm={12} xs={12} spacing={1} style={{ paddingLeft: '4px' }}>
												<Grid item md={6} sm={6} xs={12}>
													<TextField
														fullWidth
														label={lang.contactType}
														margin="dense"
														variant="outlined"
														InputProps={{
															readOnly: true,
														}}
														InputLabelProps={{
															shrink: true
														}}
														value={contactOptions[contact.type] ?? contact.type}
													/>
												</Grid>
												<Grid item md={6} sm={6} xs={12}>
													<TextField
														fullWidth
														margin="dense"
														variant="outlined"
														InputProps={{
															readOnly: true,
														}}
														InputLabelProps={{
															shrink: true
														}}
														value={contact.value}
													/>
												</Grid>
											</Grid>
										))
									}

									<Grid item md={12} sm={12} xs={12}>
										<Typography style={{ fontWeight: 'bold' }}>{isSold ? lang.seller : lang.landlord}</Typography>
									</Grid>
									<Grid item md={12} sm={12} xs={12}>
										<TextField
											fullWidth
											label={lang.contactName}
											margin="dense"
											variant="outlined"
											InputProps={{
												readOnly: true,
											}}
											InputLabelProps={{
												shrink: true
											}}
											value={multiLang(locale, transactionDetail.sellerZh, transactionDetail.sellerEn)}
										/>
									</Grid>
									<Grid item md={6} sm={6} />
									{
										transactionDetail.sellerContact?.map((contact: ContactsDTO) => (
											<Grid container md={12} sm={12} xs={12} spacing={1} style={{ paddingLeft: '4px' }}>
												<Grid item md={6} sm={6} xs={12}>
													<TextField
														fullWidth
														label={lang.contactType}
														margin="dense"
														variant="outlined"
														InputProps={{
															readOnly: true,
														}}
														InputLabelProps={{
															shrink: true
														}}
														value={contactOptions[contact.type] ?? contact.type}
													/>
												</Grid>
												<Grid item md={6} sm={6} xs={12}>
													<TextField
														fullWidth
														margin="dense"
														variant="outlined"
														InputProps={{
															readOnly: true,
														}}
														InputLabelProps={{
															shrink: true
														}}
														value={contact.value}
													/>
												</Grid>
											</Grid>
										))
									}
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</form>
		</div>
	);
};

export default TransactionDetail;