import { Grid, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PDFIcon } from 'common/ui';
import { isNonEmpty } from 'common/utils';
import React from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';

const successWord = '#33A64C';
const warningWord = '#D17212';

export interface FormSigningInfoProps {
	formType?: string;
	formTypeEn?: string;
	propertyAddress?: string;
	propertyAddressEn?: string;
	clientName?: string;
	clientNameEn?: string;
	agentName?: string;
	agentNameEn?: string;

	documentUrl?: string;
	noteForDocument?: string;
	noteForDocumentIsWarning?: boolean;

	showEn?: boolean;

	completed?: boolean

}
const When = ({ test, children }: { test?: boolean, children: JSX.Element }): JSX.Element => test ? children : <span />;


const useStyles = makeStyles(theme => ({
	root: {
	},
	typography: {
		width: 'fit-content',
		whiteSpace: 'pre-line',
	},
	labelColumn: {
		minWidth: '108px',
		maxWidth: '108px',
		display: 'flex',
		alignItems: 'center',
		paddingRight: '10px',
	},
	valueColumn: {
		display: 'flex',
		alignItems: 'center',
		width: 'fit-content',
	},
	infoSection: {
		maxWidth: '600px',
		width: 'fit-content',
		display: 'flex',
		flexDirection: 'column',
	}
}));

const FormSigningInfo = (props: FormSigningInfoProps) => {
	const classes = useStyles();
	const { formType, formTypeEn, propertyAddress, propertyAddressEn, clientName, clientNameEn, agentName, agentNameEn, documentUrl, noteForDocument, noteForDocumentIsWarning, showEn, completed } = props;
	const { langSignDialog } = useSelector((state: IRootState) => state.locale);
	const { langSignDialog: langSignDialogZh } = useSelector((state: IRootState) => state.locale._bundle['zh_HK']);
	const { langSignDialog: langSignDialogEn } = useSelector((state: IRootState) => state.locale._bundle['en']);
	const getLabelValueRow = (label: string, value: string | undefined, labelEn = '', valueEn = '', showEng = false) => {
		return (<Grid item container spacing={0}
			md={12}
			sm={12}
			xs={12}
			style={{ width: 'fit-content' }}

		>
			<Grid item container spacing={0}
				md={12}
				sm={12}
				xs={12}
				style={{ width: 'fit-content' }}

			>
				<Grid item
					// md={2}
					// sm={2}
					// xs={12}
					className={classes.labelColumn}
					// style={{ display: 'flex', alignItems: 'center' }}
					style={{ display: 'block', flexShrink: 1 }}
				>
					<Typography className={classes.typography}>{label}{': '}</Typography>
				</Grid>
				<Grid item
					// md={10}
					// sm={10}
					// xs={12}
					className={classes.valueColumn}
					// style={{ display: 'flex', alignItems: 'center' }}
					xs
				>
					<Typography className={classes.typography}>{value}</Typography>
				</Grid>
			</Grid>

			{showEng && <Grid item container spacing={0}
				md={12}
				sm={12}
				xs={12}
				style={{ width: 'fit-content' }}
			>
				<Grid item
					// md={2}
					// sm={2}
					// xs={12}
					className={classes.labelColumn}
					style={{ display: 'block', flexShrink: 1 }}
				>
					<Typography className={classes.typography}>{labelEn}{': '}</Typography>
				</Grid>
				<Grid item
					// md={10}
					// sm={10}
					// xs={12}
					className={classes.valueColumn}
					xs
				>
					<Typography className={classes.typography}>{valueEn}</Typography>
				</Grid>
			</Grid>}

		</Grid>)
	}

	return <Grid item container spacing={2} className={classes.infoSection}>
		{/* ------ form type ------*/}
		<When test={isNonEmpty(formType)}>
			<Grid item container spacing={1}
				// md={12}
				// sm={12}
				// xs={12}
				style={{ width: 'fit-content' }}
			>
				{getLabelValueRow(showEn ? langSignDialogZh.captionFormType : langSignDialog.captionFormType, formType, langSignDialogEn.captionFormType, formTypeEn, showEn ?? false)}

			</Grid>
		</When>
		{/* ------ property address ------*/}
		<When test={isNonEmpty(propertyAddress)}>
			<Grid item container spacing={1}
				// md={12}
				// sm={12}
				// xs={12}
				style={{ width: 'fit-content' }}
			>
				{getLabelValueRow(showEn ? langSignDialogZh.captionProperty : langSignDialog.captionProperty, propertyAddress, langSignDialogEn.captionProperty, propertyAddressEn?.toUpperCase(), showEn ?? false)}
			</Grid>
		</When>
		{/* ------ client name ------*/}
		<When test={isNonEmpty(clientName)}>
			<Grid item container spacing={1}
				// md={12}
				// sm={12}
				// xs={12}
				style={{ width: 'fit-content' }}
			>
				{getLabelValueRow(showEn ? langSignDialogZh.captionClient : langSignDialog.captionClient, clientName, langSignDialogEn.captionClient, clientNameEn, showEn ?? false)}

			</Grid>
		</When>
		{/* ------ agent name ------*/}
		<When test={isNonEmpty(agentName)}>
			<Grid item container spacing={1}
				// md={12}
				// sm={12}
				// xs={12}
				style={{ width: 'fit-content' }}
			>
				{getLabelValueRow(showEn ? langSignDialogZh.captionAgent : langSignDialog.captionAgent, agentName, langSignDialogEn.captionAgent, agentNameEn, showEn ?? false)}

			</Grid>
		</When>
		{/* ------ preview ------*/}
		<When test={isNonEmpty(documentUrl)}>
			<Grid item container spacing={1}
				// md={12}
				// sm={12}
				// xs={12}
				style={{ display: 'flex', alignItems: 'center', width: 'fit-content' }}
			>
				<Grid item
					// md={2}
					// sm={2}
					// xs={12}
					className={classes.labelColumn}
				>
					<Typography className={classes.typography}>{`${showEn ? langSignDialogZh.actionDocumentPreview : langSignDialog.actionDocumentPreview}:` + (showEn ? `\n${langSignDialogEn.actionDocumentPreview}:` : '')}</Typography>
				</Grid>
				<Grid item container spacing={1}
					// md={10}
					// sm={10}
					// xs={12}
					className={classes.valueColumn}
				>
					<Grid item>
						<IconButton
							style={{ color: '#b30b00' }}
							{...{
								component: 'a',
								// href: `${BASE_URL}/files/${signedFormUrl}`,
								href: completed ? `${documentUrl}?inline=1` : `${documentUrl}`,
								target: '_blank',
							} as any}
						>
							<PDFIcon />
						</IconButton>
					</Grid>
					<When test={isNonEmpty(noteForDocument)}>
						<Grid item
							style={{ display: 'flex', alignItems: 'center' }}
						>
							<Typography style={{ color: noteForDocumentIsWarning ? warningWord : successWord, fontStyle: 'italic', fontSize: '12px' }}>{`(${noteForDocument})`}</Typography>
						</Grid>
					</When>
				</Grid>
			</Grid>
		</When>

	</Grid>
}

export default FormSigningInfo; 